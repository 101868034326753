import axiosInstance from '../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../constants/apiConfig';
import { logError } from '../../global/utils/logger';
import { KM_TEXT, MILES_TEXT, TRAVEL_MODE_DRIVING } from '../constants';

const { GET_DST_TRAINERS, GET_STORE_DISTANCES_URL } = API_CONFIG;

const getDSTTrainers = async () => {
    try {
        const { data, error, status } = await axiosInstance.get(GET_DST_TRAINERS);
        return { data, error, status };
    } catch (error) {
        logError(error, false, 'getDSTTrainers', []);
        return { error };
    }
};
const getStoreDistances = async (sourceLatLong, destinationLatLong) => {
    const params = {
        sourceLat: sourceLatLong?.lat,
        sourceLong: sourceLatLong?.long,
        destinationLat: destinationLatLong?.lat,
        destinationLong: destinationLatLong?.long,
        travelMode: TRAVEL_MODE_DRIVING,
        distanceUnit: parseInt(localStorage.getItem('companyID')) == 1 ? MILES_TEXT : KM_TEXT
    };
    try {
        const { data, error } = await axiosInstance.get(GET_STORE_DISTANCES_URL, { params });
        return { data: data?.data, error };
    } catch (error) {
        logError(error, false, 'getStoreDistances', [sourceLatLong, destinationLatLong]);
        return { error };
    }
};

export { getDSTTrainers, getStoreDistances };
