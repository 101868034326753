import React, { useContext } from 'react';
import { func } from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../../../contexts/cart';
import { useFilterState } from '../../../../../cap';
import useAnalytics from '../../../../../../hooks/useAnalytics';
import { useAnalyticsContext } from '../../../../../../config/GoogleTagManagerEvents';
import { ModalContext } from '../../../../../../aem-core-components/context/ModalContext';
import RentalReviewListView from '../../../../../checkoutv2/RentalReviewList/RentalReviewListView/RentalReviewListView';
import Button from '../../../../atoms/button';
import MaxQtyErrorComponent from '../../../../atoms/maxQtyErrorComponent';
import { VARIABLE_CONFIG } from '../../../../../../constants/analyticsConstants/Variables';
import { logError } from '../../../../utils/logger';
import classes from './addedToCart.css';
import { pdpDataLocators } from '../../../../../pdp/dataLocators';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../../../../constants/analyticsConstants/Page';
import config from '../../../../../App/config';

const AddedToCart = props => {
    const [{ cart }] = useCartState();
    const [{ startDate, endDate }] = useFilterState();
    const { handleCloseModal, localItemObj, error } = props;
    const intl = useIntl();
    const body = document.querySelector('body');
    const useModalState = () => useContext(ModalContext);
    const { closeModal } = useModalState();
    const { sendEventsForEcommercePage, sendEventsForPageUser } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const orderEstimates = cart?.estimatesResponse?.estimate;

    const onKeepShoppingCartClick = () => {
        closeModal();
        handleCloseModal();
        body.classList.remove('overflow-hidden');
    };

    const handleViewCart = () => {
        closeModal();
        window.location.href = window.location.origin + config?.pagePaths?.cartPage;
    };

    const isRentalDatesEmpty = () => {
        return !(startDate && endDate);
    };

    const handleViewCartClick = () => {
        //track navigation to cart for all users - credit,cash and guest
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CART,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'handleViewCartClick');
        }
        closeModal();
        handleViewCart();
    };
    const renderDetails = () => {
        return (
            <RentalReviewListView
                isRentalDatesEmpty={isRentalDatesEmpty()}
                orderEstimates={orderEstimates}
                isFromPDP={true}
                pdpQty={localItemObj?.qty || 1}
                localItemObj={localItemObj}
            />
        );
    };

    return (
        <div className={classes.addedToCartRoot}>
            <div className={classes.listStyle}>{renderDetails() || ''}</div>
            <MaxQtyErrorComponent
                qtyError={error}
                dataTestId={pdpDataLocators.pdp_add_to_cart_quantity_exceeded_error_label}
            />
            <div className={classes.buttonList}>
                <div className={classes.viewCartButton}>
                    <Button
                        className="button button-primary button-block button-marginbottom"
                        type="button"
                        onClick={handleViewCartClick}
                        data-testid={pdpDataLocators.added_to_cart_view_your_cart_cta}
                        buttonAriaLabel={intl.formatMessage({ id: 'pdp:alert-btn-viewcart' })}>
                        {intl.formatMessage({ id: 'pdp:alert-btn-viewcart' })}
                    </Button>
                </div>
                <Button
                    type="button"
                    className={'button button-outline-primary'}
                    onClick={onKeepShoppingCartClick}
                    data-testid={pdpDataLocators.added_to_cart_keep_shopping_cta}
                    buttonAriaLabel={intl.formatMessage({ id: 'pdp:alert-btn-keep-shopping' })}>
                    {intl.formatMessage({ id: 'pdp:alert-btn-keep-shopping' })}
                </Button>
            </div>
        </div>
    );
};
AddedToCart.propTypes = {
    handleCloseModal: func
};
AddedToCart.defaultProps = {
    localItemObj: {
        title: '-',
        img: '',
        qty: 1,
        price: '-'
    },
    handleCloseModal: () => {}
};

export default AddedToCart;
