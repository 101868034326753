import '../../productSportlightWrapper/js/cascadeSlider';
(function () {
    try {
        window.addEventListener('load', function (e) {
            try {
                var productSpotlightCarousels = document?.querySelectorAll('.productspotlight__carousel');

                productSpotlightCarousels?.forEach(function (productSpotlight) {
                    try {
                        if (!productSpotlight.closest('.productSpotlightWrapper')) {
                            const mediaQuery = window?.matchMedia('(max-width: 639.98px)');

                            // Function to update buttons based on the active slide number
                            function updateButtons(currentSlide, totalSlides, tabContent) {
                                try {
                                    const buttons = tabContent.querySelectorAll('.productspotlight__action');
                                    buttons.forEach((button, index) => {
                                        const { productSku } = button?.dataset;
                                        button.style.display = currentSlide === parseInt(productSku) ? 'block' : 'none'; // Show the button for the current slide
                                    });
                                } catch (error) {
                                    console.error('Error updating buttons:', error);
                                }
                            }

                            function updateSliderData(slide, tabContent) {
                                try {
                                    const dataset = slide?.dataset;
                                    const activeSliderData = tabContent.querySelector('.productspotlight__info');
                                    activeSliderData.innerHTML = `<div>
                                        <h3 class="eyebrow productspotlight__info-eyebrow">${dataset?.catLabel || ''} ${dataset?.productSku || ''}</h3>
                                        <h4 class="productspotlight__info-title">${
                                            dataset?.productname?.replace(/\s+/g, ' ') || ''
                                        }</h4></div>`;
                                } catch (error) {
                                    console.error('Error updating slider data:', error);
                                }
                            }

                            function handleLargeMedia(slider) {
                                try {
                                    if ($(slider).hasClass('slick-initialized')) {
                                        $(slider)?.slick('unslick'); // Unslick if screen width is more than 640px
                                    }
                                    // Initialize Cascade Slider for larger screens
                                    var $parentWrapper = slider.closest('.productspotlight__carousel-wrap');

                                    var prevButton = $($parentWrapper)?.find('.productspotlight__action-prev');
                                    var nextButton = $($parentWrapper)?.find('.productspotlight__action-next');

                                    $($parentWrapper)?.cascadeSlider({
                                        itemClass: 'productspotlight__carousel-item'
                                    });

                                    let currentSKU = parseInt($parentWrapper?.querySelector('.now')?.dataset.productSku);
                                    updateSliderData($parentWrapper?.querySelector('.now'), slider.closest('.productspotlight__block'));
                                    updateButtons(currentSKU, '', slider.closest('.productspotlight__block'));

                                    $(nextButton)?.on('click', function () {
                                        try {
                                            let currentSKU = parseInt($parentWrapper?.querySelector('.now')?.dataset.productSku);
                                            updateSliderData($parentWrapper?.querySelector('.now'), slider.closest('.productspotlight__block'));
                                            updateButtons(currentSKU, '', slider.closest('.productspotlight__block'));
                                        } catch (error) {
                                            console.error('Error handling next button click:', error);
                                        }
                                    });

                                    // Event listeners for custom buttons
                                    $(prevButton)?.on('click', function () {
                                        try {
                                            let currentSKU = parseInt($parentWrapper?.querySelector('.now')?.dataset.productSku);
                                            updateSliderData($parentWrapper?.querySelector('.now'), slider.closest('.productspotlight__block'));
                                            updateButtons(currentSKU, '', slider.closest('.productspotlight__block'));
                                        } catch (error) {
                                            console.error('Error handling previous button click:', error);
                                        }
                                    });
                                } catch (error) {
                                    console.error('Error handling large media:', error);
                                }
                            }

                            function handleSliderEvents(slider) {
                                try {
                                    var productspotlightCarouselWrap = slider?.closest('.productspotlight__carousel-wrap');
                                    var prevArrowButton = $(productspotlightCarouselWrap)?.find('.productspotlight__action-prev');
                                    var nextArrowButton = $(productspotlightCarouselWrap)?.find('.productspotlight__action-next');

                                    $(slider)?.on('init', function (event, slick, currentSlide) {
                                        try {
                                            updateSliderData(
                                                event.target.querySelector('.slick-active'),
                                                slider.closest('.productspotlight__block')
                                            );
                                            updateButtons(
                                                parseInt(event.target.querySelector('.slick-active')?.dataset?.productSku),
                                                slick.slideCount,
                                                slider.closest('.productspotlight__block')
                                            );
                                        } catch (error) {
                                            console.error('Error initializing slider:', error);
                                        }
                                    });

                                    $(slider)?.slick({
                                        arrows: true,
                                        infinite: true,
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                        adaptiveHeight: true,
                                        prevArrow: prevArrowButton,
                                        nextArrow: nextArrowButton
                                    });

                                    $(slider)?.on('afterChange', function (event, slick, currentSlide) {
                                        try {
                                            updateSliderData(
                                                event.target.querySelector('.slick-active'),
                                                slider.closest('.productspotlight__block')
                                            );
                                            updateButtons(
                                                parseInt(event.target.querySelector('.slick-active')?.dataset?.productSku),
                                                slick.slideCount,
                                                slider.closest('.productspotlight__block')
                                            );
                                        } catch (error) {
                                            console.error('Error after slider change:', error);
                                        }
                                    });
                                } catch (error) {
                                    console.error('Error handling slider events:', error);
                                }
                            }

                            function handleSliders(mediaQuery) {
                                try {
                                    if (mediaQuery.matches) {
                                        handleSliderEvents(productSpotlight);
                                    } else {
                                        handleLargeMedia(productSpotlight);
                                    }
                                } catch (error) {
                                    console.error('Error handling sliders:', error);
                                }
                            }

                            handleSliders(mediaQuery);

                            // Initialize Slick Slider for mobile screens
                            mediaQuery?.addEventListener('change', function () {
                                try {
                                    handleSliders(mediaQuery);
                                } catch (error) {
                                    console.error('Error handling media query change:', error);
                                }
                            });
                        }
                    } catch (error) {
                        console.error('Error handling product spotlight carousel:', error);
                    }
                });
            } catch (error) {
                console.error('Error during window load:', error);
            }
        });
    } catch (error) {
        console.error('Error in main IIFE:', error);
    }
})();