import { ENV_CONFIG } from '../../constants/envConfig';

export const googleMapStyles = [
    {
        elementType: 'geometry',
        stylers: [
            {
                color: '#f5f5f5'
            }
        ]
    },
    {
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off'
            }
        ]
    },
    {
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161'
            }
        ]
    },
    {
        elementType: 'labels.text.stroke',
        stylers: [
            {
                color: '#f5f5f5'
            }
        ]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#bdbdbd'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee'
            }
        ]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5'
            }
        ]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [
            {
                color: '#ffffff'
            }
        ]
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#757575'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [
            {
                color: '#dadada'
            }
        ]
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#616161'
            }
        ]
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [
            {
                color: '#e5e5e5'
            }
        ]
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [
            {
                color: '#eeeeee'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [
            {
                color: '#c9c9c9'
            }
        ]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#9e9e9e'
            }
        ]
    }
];
export const DEFAULT_LAT_DST = 36.7608;
export const DEFAULT_LONG_DST = -105.891;
export const MILES_TEXT = 'mi';
export const KM_TEXT = 'km';
export const TRAVEL_MODE_DRIVING = 'driving';

const getDynamicDstFilters = () => {
    let filterStateString = ENV_CONFIG.DST_TAG_NAMES;
    let filterStateArray = [];
    let filterStateDynamic = {};
    if (filterStateString?.length > 2) {
        filterStateString = filterStateString?.substring(1, filterStateString.length - 1);
        filterStateArray = filterStateString?.split(',');
        filterStateArray.forEach(element => {
            const objArr = element.split('=');
            const searchKey = objArr[0]?.trim();
            const displayValue = objArr[1]?.trim();
            //It creates the dynamic filter array to the user
            if (filterStateDynamic[displayValue]) {
                filterStateDynamic[displayValue]['searchKey'].push(searchKey);
            } else {
                filterStateDynamic[displayValue] = {
                    searchKey: [searchKey]
                };
            }
        });
    }
    return filterStateString?.length > 0 ? filterStateDynamic : {};
};
export const dstFilterState = getDynamicDstFilters();

export const mockFilterOptionsObj = {
    "Bi-Lingual Spanish": {
        "searchKey": [
            "sunbelt-rentals:training-set/bi-lingual-spanish"
        ]
    },
    "TTT": {
        "searchKey": [
            "sunbelt-rentals:training-set/ttt"
        ]
    },
    "Forklift": {
        "searchKey": [
            "sunbelt-rentals:training-set/forklift"
        ]
    },
    "Mewp": {
        "searchKey": [
            "sunbelt-rentals:training-set/mewp"
        ]
    },
    "Earth Moving": {
        "searchKey": [
            "sunbelt-rentals:training-set/earth-moving"
        ]
    },
    "Fall Protection": {
        "searchKey": [
            "sunbelt-rentals:training-set/fall-protection"
        ]
    },
    "Spotter Training": {
        "searchKey": [
            "sunbelt-rentals:training-set/spotter-training"
        ]
    },
    "Carts? (Polaris ATV)": {
        "searchKey": [
            "sunbelt-rentals:training-set/carts--(polaris-atv)"
        ]
    },
    "Scaffolding (supported)": {
        "searchKey": [
            "sunbelt-rentals:training-set/scaffolding-(supported)"
        ]
    },
    "Scaffolding (suspended)": {
        "searchKey": [
            "sunbelt-rentals:training-set/scaffolding-(suspended)"
        ]
    },
    "Shoring/trenching": {
        "searchKey": [
            "sunbelt-rentals:training-set/shoring-trenching"
        ]
    },
    "Confined Space (CANADA ONLY)": {
        "searchKey": [
            "sunbelt-rentals:training-set/confined-space-(canada-only)"
        ]
    },
    "Rigging & Signaling": {
        "searchKey": [
            "sunbelt-rentals:training-set/rigging-&-signaling"
        ]
    },
    "Propane": {
        "searchKey": [
            "sunbelt-rentals:training-set/propane"
        ]
    },
    "Propane and Nat Gas": {
        "searchKey": [
            "sunbelt-rentals:training-set/propane-and-nat-gas"
        ]
    },
    "Propane In Roofing": {
        "searchKey": [
            "sunbelt-rentals:training-set/propane-in-roofing"
        ]
    },
    "Propane Filling": {
        "searchKey": [
            "sunbelt-rentals:training-set/propane-filling"
        ]
    },
    "Chainsaw": {
        "searchKey": [
            "sunbelt-rentals:training-set/chainsaw"
        ]
    },
    "Hoisting And Rigging": {
        "searchKey": [
            "sunbelt-rentals:training-set/hoisting-and-rigging"
        ]
    },
    "Lock out tag out": {
        "searchKey": [
            "sunbelt-rentals:training-set/lock-out-tag-out"
        ]
    },
    "WHMIS": {
        "searchKey": [
            "sunbelt-rentals:training-set/whmis"
        ]
    }
}
