import { func } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import BackIcon from '../../../../../resources/images/arrow-ios-left.svg';
import CloseIcon from '../../../../../resources/images/close.svg';
import Button from '../../../../global/atoms/button';
import '../createWishList.scss';

const CreateWishListHeader = ({ handleBack }) => {
    const intl = useIntl();

    return (
        <>
            <Button
                className="back-icon"
                onClick={handleBack}
                customButtonAriaLabel={intl.formatMessage({ id: 'common:back' })}
                tabindex="0">
                <BackIcon aria-hidden={true} />
                {intl.formatMessage({ id: 'common:back' })}
            </Button>
            <Button
                className="popup__closeicon"
                onClick={handleBack}
                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                tabindex="0">
                <CloseIcon aria-hidden={true} />
            </Button>
        </>
    );
};

CreateWishListHeader.propsType = {
    handleBack: func
};

CreateWishListHeader.defaultProps = {
    handleBack: () => {}
};

export default CreateWishListHeader;
