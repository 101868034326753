import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../contexts/cart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import AddToCartProductTileReact from '../../../global/modules/productTile/addToCartProductTileReact';
import { logError } from '../../utils/logger';
import { RECENTLY_VIEWED, RECENTLY_VIEWED_SIZE } from './constants';
import { usePricing } from '../../../../hooks/usePricing';
import { checkIsRequestQuote } from '../../utils/commonUtils';

const RecentlyViewed = props => {
    const { recentlyViewedArray, recentlyViewedDataLength } = props;
    const { sendEventsForClick } = useAnalyticsContext();
    const [{ isProfileLoaded }] = useUserContext();
    const [{ cidPcList }] = useCartState();
    const intl = useIntl();
    const { getProductPrices } = usePricing();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();

    let productList = localStorage.getItem(RECENTLY_VIEWED) ? JSON.parse(localStorage.getItem(RECENTLY_VIEWED)) : [];

    const { recentlyCTA = '', recentlyLink = '' } = document.querySelector('.combined_tabs')?.dataset?.productdata
        ? JSON.parse(document.querySelector('.combined_tabs')?.dataset?.productdata || '{}')
        : {};

    useEffect(() => {
        const getPriceForProducts = async () => {
            if (isProfileLoaded) {
                if (isRentalDetailsAvailable() && cidPcList?.length > 0 && recentlyViewedArray?.length > 0) {
                    await getProductPrices(
                        recentlyViewedArray?.filter(item => {
                            const { isRequestQuote } = checkIsRequestQuote(
                                item?.showonlinecatalog,
                                item?.disableaddtocartoption
                            );
                            return !isRequestQuote;
                        }),
                        VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE
                    );
                }
            }
        };

        getPriceForProducts();
    }, [cidPcList, isProfileLoaded, recentlyViewedArray?.length]);

    const itemClickRecentlyViewedEvent = productName => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.RECENTLY_VIEWED,
                VARIABLE_CONFIG.ACTION.SELECT,
                productName?.producttitle
            );
        } catch (e) {
            logError(error, false, 'itemClickRecentlyViewedEvent');
        }
    };

    const viewAllRecentlyViewedEvent = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.RECENTLY_VIEWED,
                VARIABLE_CONFIG.ACTION.SELECT,
                VARIABLE_CONFIG.EVENT_CATEGORY.VIEW_ALL_RECENTLY_VIEWED
            );
        } catch (e) {
            logError(error, false, 'viewAllRecentlyViewedEvent');
        }
    };

    return (
        <>
            <div className="row">
                {recentlyViewedArray?.map(item => (
                    <div className="col-md-3 col-lg-3 col-6 frequent__item" key={item.catclass}>
                        <AddToCartProductTileReact cardData={item} trackAnalytics={itemClickRecentlyViewedEvent} />
                    </div>
                ))}
            </div>
            {recentlyCTA && JSON.parse(recentlyCTA) && recentlyViewedDataLength > RECENTLY_VIEWED_SIZE && (
                <div class="row frequent__actions" onClick={viewAllRecentlyViewedEvent}>
                    <div class="col-xl-4 col-md-6">
                        <a class="button button-outline-primary button-block" href={recentlyLink}>
                            {intl.formatMessage({ id: 'view-all-recently-viewed' })}
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(RecentlyViewed);

RecentlyViewed.propTypes = {
    recentlyViewedArray: PropTypes.arrayOf(
        PropTypes.shape({
            catclass: PropTypes.string,
            producttitle: PropTypes.string,
            productimageurl: PropTypes.string,
            producturl: PropTypes.string,
            categoryname: PropTypes.string,
            productcategoryname: PropTypes.string,
            subcategoryname: PropTypes.string,
            category_path: PropTypes.array,
            catid: PropTypes.string,
            catsku: PropTypes.string,
            showonlinecatalog: PropTypes.bool,
            disableaddtocartoption: PropTypes.bool
        })
    )
};

RecentlyViewed.defaultProps = {
    recentlyViewedArray: []
};
