import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
    SET_CAP_ACCOUNT_SELECTED,
    SET_SHOW_CHOOSE_ACCOUNT_DRAWER,
    SET_SHOW_JOB_SITE_DRAWER
} from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../contexts/cart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useMedia from '../../../../hooks/useMedia';
import { usePageType } from '../../../../hooks/usePageType';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import AutoCompleteDropdown from '../../atoms/autoComplete/AutoCompleteDropdown';
import JobsitesListingModal from '../jobsiteDrawer/jobsitesListingModal';
import Button from '../../atoms/button/button';
import ErrorInput from '../../atoms/errorInput/ErrorInput';
import { getAddressBylatLong } from '../location-autocomplete/api/getLocations';
import { isValidString } from '../../utils/logger';
import { DELAY_TIME, isWebView } from '../../utils/commonUtils';
import LocationNavigation from '../../../../resources/images/location-navigation.svg';
import './jobsiteAddressAutoComplete.scss';
import { ENTER_KEY, MEDIA_TYPE } from '../../../../constants/screenConstants';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { isP2PWithoutIframe, useCheckAuthorityType, useCheckUser } from '../../../../hooks/useCheckUser';
import {
    EDIT_VIEW_CLOSE,
    EDIT_VIEW_DETAILS,
    EDIT_VIEW_OPEN,
    RESET_SUGGESTED_JOBSITE_ADDRESS,
    SET_IS_SUGGESTIONS_LOADING
} from '../../../cap/constants';
import { useFilterState } from '../../../cap';
import { AUTHORITY_TYPE } from '../../constants';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';

const JobsiteAddressAutoComplete = props => {
    const capIntl = useIntl();
    const {
        inputId,
        prefilledAddress,
        onSelectAddress,
        selectedAddress,
        placeholderText,
        buttonTestId,
        autocompleteWrapper,
        listItemTestId,
        autoCompleteInputWrapper,
        labelContent,
        onJobsiteSelect,
        isLocationPersisted,
        focusInput,
        selectedProjectId,
        setSelectedProjectId,
        inputAriaLabel,
        isAddressChanged,
        setIsAddressChanged,
        displayJobsiteModal,
        disableCurrentLocation,
        onLocationInputFocus,
        setIsCapInteracted,
        jobAndAddressState,
        setJobAndAddressState,
        stringSearchedForSuggestion,
        setStringSearchedForSuggestion,
        inputErrorMsg
    } = props;
    const [{ userAccount }] = useCartState();
    const { accountNumber } = userAccount || {};
    const [
        {
            jobsiteSuggestions,
            addressSuggestions,
            isJobsiteAddressSuggestionLoading,
            recommendedJobsites,
            isJobsiteRecommendationLoading,
            editViewSource
        },
        filterDispatch,
        { getJobsitesAddressSuggestion }
    ] = useFilterState();
    const [{ userProfile }] = useUserContext();
    const [userState, { dispatch: userDispatch }] = useUserContext();
    const { showChooseAccountDrawer, showJobSiteDrawer, favoriteJobsiteListData, isFavoritesLoading } = userState;
    const userType = useCheckUser();
    const [favoriteJobsites, setFavoriteJobsites] = useState([]);
    const [showJobsitesDrawer, setShowJobsitesDrawer] = useState(false);
    const [isJobsiteInputReadonly, setIsJobsiteInputReadonly] = useState(false);
    const [autocompleteText, setAutocompleteText] = useState(prefilledAddress || '');
    const [isOpen, setIsOpen] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [isSuggestionsfetching, setIsSuggestionsfetching] = useState(false);
    const [isJobsiteOpen, setIsJobSiteOpen] = useState(false);
    const [latLong, setLatLong] = useState({});
    const [isCurrentLocationDisabled, setIsCurrentLocationDisabled] = useState(false);
    const [openFavoritesTab, setOpenFavoritesTab] = useState(0);
    const mediaType = useMedia();
    const { sendEventsForUnavailableCta, sendEventsForUnavailableItem } = useAnalyticsContext();
    const pageType = usePageType();
    const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
    const { fetchLocationCoordinates } = useCheckLocationEmpty();
    let debouncedFetchLocation = null;
    const {
        disablecurrentlocationpromptdesktop,
        disablecurrentlocationprompttablet,
        disablecurrentlocationpromptmobile,
        matchingaddressnotfoundtitle,
        matchingaddressnotfoundtext,
        addressnotfoundhelpline
    } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const noAddressFoundTitle = matchingaddressnotfoundtitle || capIntl.formatMessage({ id: 'cap:no-address-found' });
    const noAddressFoundText =
        matchingaddressnotfoundtext || capIntl.formatMessage({ id: 'cap:no-address-found-text' });
    const noAddressFoundHelpline = addressnotfoundhelpline || capIntl.formatMessage({ id: 'cap:no-address-helpline' });
    const isCurrentLocationPromptDesktopDisabled = disablecurrentlocationpromptdesktop || false;
    const isCurrentLocationPromptTabletDisabled = disablecurrentlocationprompttablet || false;
    const isCurrentLocationPromptMobileDisabled = disablecurrentlocationpromptmobile || false;
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const isNonCorp = userProfile?.isSelectedAccountIsNonCorpAccount;
    const isAccountSelected = !!accountNumber;
    const showRecommendedJobsite =
        userType === USER_TYPE.CREDIT && (autocompleteText?.length === 0 || !isAddressChanged);
    const showFavoriteJobsite =
        isP2PUser && favoriteJobsiteListData?.length && (autocompleteText?.length === 0 || !isAddressChanged);
    const checkIsP2PWithoutIframe = isP2PWithoutIframe();
    const radius = ENV_CONFIG.DEFAULT_AUTOSUGGEST_RADIUS * 1609.34;

    useEffect(() => {
        if (favoriteJobsiteListData?.length) {
            const copyOfFavoriteJobsiteListData = [...favoriteJobsiteListData];
            const favoritesLimit = Math.min(copyOfFavoriteJobsiteListData?.length, 6);
            const updatedFavoriteJobsiteList = copyOfFavoriteJobsiteListData?.slice(-favoritesLimit)?.reverse();
            setFavoriteJobsites(updatedFavoriteJobsiteList);
        }
    }, [favoriteJobsiteListData]);

    const clearSuggestions = () => {
        setStringSearchedForSuggestion('');
        filterDispatch({ type: RESET_SUGGESTED_JOBSITE_ADDRESS });
        setShowSuggestions(false);
    };

    const fetchCoordinates = () => {
        const { localLat = '', localLong = '' } = fetchLocationCoordinates();
        let lat = localLat;
        let long = localLong;
        if (!localLat) {
            lat = ENV_CONFIG.DEFAULT_AUTOSUGGEST_LAT;
            long = ENV_CONFIG.DEFAULT_AUTOSUGGEST_LONG;
        }
        const locationCoordinates = { lat, long };
        return locationCoordinates;
    };

    useEffect(() => {
        // calling suggestions api if the dropdown is open
        if (isOpen && autocompleteText !== stringSearchedForSuggestion && !showRecommendedJobsite) {
            if (autocompleteText?.length > 1) {
                if (autocompleteText?.length === 2 || autocompleteText === prefilledAddress) {
                    const { lat, long } = fetchCoordinates();
                    setIsSuggestionsfetching(true);
                    getJobsitesAddressSuggestion(
                        accountNumber,
                        autocompleteText,
                        autocompleteText,
                        userType !== USER_TYPE.GUEST,
                        `${lat},${long}`,
                        radius
                    );
                    setStringSearchedForSuggestion(autocompleteText);
                } else {
                    debouncedFetchLocation = setTimeout(() => {
                        const { lat, long } = fetchCoordinates();
                        setIsSuggestionsfetching(true);
                        getJobsitesAddressSuggestion(
                            accountNumber,
                            autocompleteText,
                            autocompleteText,
                            userType !== USER_TYPE.GUEST,
                            `${lat},${long}`,
                            radius
                        );
                        setStringSearchedForSuggestion(autocompleteText);
                    }, DELAY_TIME);
                    return () => {
                        clearTimeout(debouncedFetchLocation);
                    };
                }
            } else {
                clearSuggestions();
            }
        }
        // show suggestions if dropdown is open and we already have suggestions for this autocompleteText
        if (isOpen && autocompleteText && autocompleteText === stringSearchedForSuggestion) {
            setShowSuggestions(true);
        }
    }, [autocompleteText, isOpen]);

    useEffect(() => {
        // showing suggestions when the api is not loading
        // isSuggestionsfetching condition to know the api call is made so we don't show no matching jobsite for a fraction of time
        if (!isJobsiteAddressSuggestionLoading && isSuggestionsfetching) {
            setShowSuggestions(true);
        }
    }, [isJobsiteAddressSuggestionLoading]);

    useEffect(() => {
        setAutocompleteText(prefilledAddress);
    }, [prefilledAddress]);

    useEffect(() => {
        setIsCurrentLocationDisabled(disableCurrentLocation);
    }, [disableCurrentLocation]);

    useEffect(() => {
        if (
            showSuggestions &&
            !jobsiteSuggestions?.length &&
            !addressSuggestions?.length &&
            !isJobsiteAddressSuggestionLoading
        ) {
            sendEventsForUnavailableItem(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_ALERT,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_NO_MATCHING_ADDRESS_FOUND
            );
        }
    }, [showSuggestions, jobsiteSuggestions, addressSuggestions, isJobsiteAddressSuggestionLoading]);

    useEffect(() => {
        const { latitude, longitude } = latLong;
        const setAddressByLatLong = async () => {
            const { data } = await getAddressBylatLong(latitude, longitude);
            const location = data?.results?.[0]?.formattedAddress;
            if (location && setAutocompleteText) {
                setIsAddressChanged(true);
                setAutocompleteText(location);
            }
        };
        if (latitude && longitude) {
            setAddressByLatLong();
        }
    }, [latLong]);

    useEffect(() => {
        if (isP2PUser && !isAccountSelected) {
            setIsJobsiteInputReadonly(true);
        } else {
            setIsJobsiteInputReadonly(false);
        }
    }, [isAccountSelected]);

    const sendCapCtaAnalytics = linkName => {
        const formName = isHomePage
            ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_HERO
            : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_HEADER;
        sendEventsForUnavailableCta(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            formName,
            linkName,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_TYPE,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_LINK_LOCATION,
            ''
        );
    };

    const toggleJobsiteDrawer = () => {
        filterDispatch({ type: showJobSiteDrawer ? EDIT_VIEW_OPEN : EDIT_VIEW_CLOSE });
        !showJobSiteDrawer && setIsCapInteracted(true);
        userDispatch({ type: SET_SHOW_JOB_SITE_DRAWER, payload: !showJobSiteDrawer });
        if (!showJobsitesDrawer) {
            sendCapCtaAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_SEE_ALL_JOBSITES);
        }
    };

    const toggleAllJobsiteDrawer = () => {
        toggleJobsiteDrawer();
        setOpenFavoritesTab(0);
    };

    const toggleFavoriteJobsiteDrawer = () => {
        toggleJobsiteDrawer();
        setOpenFavoritesTab(1);
    };

    const handleDrawer = () => {
        if (isAccountSelected) {
            //Accout is not selected so it will open account side drawer
            userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: !showChooseAccountDrawer });
            userDispatch({ type: SET_CAP_ACCOUNT_SELECTED, payload: true });
        } else {
            userDispatch({ type: SET_SHOW_JOB_SITE_DRAWER, payload: !showJobSiteDrawer });
        }
    };

    const onJobsiteClick = (item, isMatchingJobsite) => {
        const fullAddress = createFullAddress(item);
        const linkName = isMatchingJobsite
            ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_JOBSITE_ADDRESS
            : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_SUGGESTED_JOBSITE;
        setIsOpen(false);
        onJobsiteSelect(
            {
                projectName: item?.jobName,
                projectAddress1: item?.address1,
                projectAddress2: item?.address2,
                selectedProjectJobId: item?.jobNumber,
                selectedRMJobId: item?.rmJobsiteId,
                selectedProjectLatititude: item?.latitude,
                selectedProjectLongitude: item?.longitude,
                selectedProjectState: item?.state,
                selectedProjectCity: item?.city,
                selectedProjectZip: item?.zip,
                locationPC: item?.locationPC,
                primaryContactName: item?.contactName,
                phoneNumber: item?.contactPhone,
                accessNotes: item?.deliveryInstructions,
                poNumber: item?.customerPO,
                isValueUpdated: true,
                CJDLVY: item?.CJDLVY
            },
            fullAddress,
            isCurrentLocationDisabled
        );
        setIsAddressChanged(true);
        setJobAndAddressState({
            chosenJobName: item?.jobName,
            chosenAddress: fullAddress
        });
        setAutocompleteText(item?.jobName ? `${item?.jobName} ${fullAddress}` : '');
        setSelectedProjectId(item?.jobNumber);
        if (fullAddress !== autocompleteText) {
            clearSuggestions();
        }
        sendCapCtaAnalytics(linkName);
    };

    const onJobsiteSelectedFromDrawer = item => {
        toggleJobsiteDrawer();
        if (item) {
            onJobsiteClick(item);
        }
    };

    const onChangeInput = e => {
        setIsAddressChanged(true);
        if (e.target.value.length > 0) {
            setIsOpen(true);
        }
        setIsCurrentLocationDisabled(false);
        setAutocompleteText(e.target.value);
        if (e.target.value.length < 2) {
            setShowSuggestions(false);
        }
    };

    const handleClick = e => {
        if (handleDrawerOpenOnFocus() && isP2PUser) {
            userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: true });
            userDispatch({ type: SET_CAP_ACCOUNT_SELECTED, payload: true });
        } else {
            onInputFocus();
            setIsJobSiteOpen(true);
        }
    };

    const onPressAutoCompleteList = async (e, val, placeId) => {
        setIsOpen(false);
        selectedAddress(val, isCurrentLocationDisabled, placeId);
        setAutocompleteText(val);
        onSelectAddress(placeId);
        setSelectedProjectId('');
        setIsAddressChanged(true);
        if (autocompleteText !== val) {
            clearSuggestions();
        }
        sendCapCtaAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_MATCHING_ADDRESS);
    };

    const onResetBtnClick = e => {
        setAutocompleteText('');
        setSelectedProjectId('');
        setShowSuggestions(false);
        setIsCurrentLocationDisabled(false);
    };

    const onEnterKeyPressed = (e, onClick) => {
        if (e.key === ENTER_KEY) {
            onClick();
        }
    };

    const highlightTextInSuggestion = text => {
        if (!text || !autocompleteText) return text;
        const regex = new RegExp(autocompleteText, 'gi');
        const match = text.match(regex);
        if (match) {
            const startIndex = text.indexOf(match[0]);
            const endIndex = startIndex + match[0].length;
            return (
                <>
                    {text.slice(0, startIndex)}
                    <b>{text.slice(startIndex, endIndex)}</b>
                    {text.slice(endIndex)}
                </>
            );
        }
        return text;
    };

    const createFullAddress = item => {
        if (item?.address1) {
            return `${item?.address1}, ${item?.city}, ${item?.state}, ${
                item?.companyID === 2
                    ? capIntl.formatMessage({ id: 'location-overlay:canada' })
                    : capIntl.formatMessage({ id: 'common:country-usa' })
            }`;
        }
        return '';
    };

    const renderJobsiteSuggestion = (item, isMatchingJobsite) => {
        let projectName = item?.jobName;
        let location = createFullAddress(item);

        return renderSuggestion({
            id: item?.jobnumber,
            className: 'jobsite-suggestions',
            ariaLabel: `${projectName} ${location}`,
            content: (
                <>
                    {projectName && <span className="project-name">{highlightTextInSuggestion(projectName)}</span>}{' '}
                    {highlightTextInSuggestion(location)}
                </>
            ),
            onClick: () => onJobsiteClick(item, isMatchingJobsite)
        });
    };

    const renderSuggestion = ({ id, className, ariaLabel, content, onClick }) => {
        return (
            isOpen && (
                <div
                    key={id}
                    id={id}
                    tabIndex={0}
                    className={`listItems ${className}`}
                    onClick={onClick}
                    onKeyPress={e => onEnterKeyPressed(e, onClick)}
                    data-testid={listItemTestId}>
                    <li
                        tabIndex={-1}
                        onKeyPress={e => onEnterKeyPressed(e, onClick)}
                        data-testid={buttonTestId}
                        role="option"
                        aria-label={`${capIntl.formatMessage({
                            id: 'cap:account-ac-common-suggested-msg'
                        })} ${ariaLabel}`}>
                        {content}
                    </li>
                </div>
            )
        );
    };

    const setOpenDropDown = () => {
        setIsOpen(false);
    };

    const onInputFocus = () => {
        filterDispatch({ type: EDIT_VIEW_OPEN });
        setIsOpen(true);
        onLocationInputFocus();
    };

    const inputFocusHandler = () => {
        onInputFocus();
    };

    const showCurrentLocation = () => {
        if (!checkIsP2PWithoutIframe && isP2PUser) {
            return false;
        } else if (isWebView) {
            return false;
        } else if (mediaType === MEDIA_TYPE.DESKTOP && isValidString(isCurrentLocationPromptDesktopDisabled)) {
            return false;
        } else if (mediaType === MEDIA_TYPE.TABLET && isValidString(isCurrentLocationPromptTabletDisabled)) {
            return false;
        } else if (mediaType === MEDIA_TYPE.MOBILE && isValidString(isCurrentLocationPromptMobileDisabled)) {
            return false;
        } else {
            return true;
        }
    };

    const getCurrentLocation = () => {
        if (!isCurrentLocationDisabled) {
            filterDispatch({ type: SET_IS_SUGGESTIONS_LOADING, isLoading: true });
            clearSuggestions();
            setIsCurrentLocationDisabled(true);
            if (navigator.geolocation && !isWebView) {
                navigator?.geolocation?.getCurrentPosition(position => {
                    const { latitude, longitude } = position?.coords || {};
                    setLatLong({ latitude, longitude });
                });
            }
            sendCapCtaAnalytics(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_USE_CURRENT_LOCATION_LINK);
        }
    };

    const onOutsideClickOrFocus = () => {
        setIsOpen(false);
        if (autocompleteText !== prefilledAddress) {
            setAutocompleteText(prefilledAddress);
            setIsCurrentLocationDisabled(disableCurrentLocation);
            clearSuggestions();
        } else {
            setShowSuggestions(false);
        }
        if (editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW) {
            filterDispatch({ type: EDIT_VIEW_DETAILS, editViewSource: '' });
        }
    };

    const renderSeeAllJobsites = () => (
        <Button
            type="button"
            className="see-all-jobsites listItems"
            id="see-all-jobsites"
            buttonAriaLabel={capIntl.formatMessage({ id: 'cap:see-all-jobsites' })}
            onClick={toggleJobsiteDrawer}>
            {capIntl.formatMessage({ id: 'cap:see-all-jobsites' })}
        </Button>
    );

    const renderSeeAllFavoriteJobsites = () => (
        <Button
            type="button"
            className="see-favorite-jobsites listItems"
            id="see-favorite-jobsites"
            buttonAriaLabel={capIntl.formatMessage({ id: 'cap:see-favorite-jobsites' })}
            onClick={toggleFavoriteJobsiteDrawer}>
            {capIntl.formatMessage({ id: 'cap:see-favorite-jobsites' })}
        </Button>
    );

    const renderNoAddressFound = isJobSitesIncluded => (
        <div className="address-not-found">
            <p className="address-not-found__title">
                {isJobSitesIncluded
                    ? capIntl.formatMessage({ id: 'cap:no-jobsites-address-found' })
                    : noAddressFoundTitle}
            </p>
            <p className="address-not-found__text">
                {noAddressFoundText}{' '}
                <a
                    href={`tel:${noAddressFoundHelpline}`}
                    className="address-not-found__tel"
                    aria-label={`${noAddressFoundHelpline} ${capIntl.formatMessage({ id: 'cap:common-call-us' })}`}>
                    {noAddressFoundHelpline}
                </a>
                .
            </p>
            {isJobSitesIncluded && isP2PUser && renderSeeAllFavoriteJobsites()}
            {isJobSitesIncluded && renderSeeAllJobsites()}
        </div>
    );

    const renderFavoritesJobsites = () => {
        return (
            <div className="suggestion-box border-top-line">
                <h6 className="eyebrow suggestion-box__title">
                    {capIntl.formatMessage({ id: 'cap:favorite-jobsites' })}
                </h6>
                {isFavoritesLoading ? (
                    <div className="suggestion-box__recommendation-shimmer shimmer" />
                ) : (
                    favoriteJobsites?.map(item => renderJobsiteSuggestion(item))
                )}
                {isP2PUser && renderSeeAllFavoriteJobsites()}
                {renderSeeAllJobsites()}
            </div>
        );
    };

    const showAddressSuggestionOrMessage = () => {
        const suggestedAddress =
            userType === USER_TYPE.CREDIT ? addressSuggestions?.slice(0, 3) : addressSuggestions?.slice(0, 5);
        if (addressSuggestions?.length > 0) {
            return suggestedAddress?.map(item => {
                return renderSuggestion({
                    id: item?.placeId,
                    ariaLabel: item?.description,
                    content: <>{highlightTextInSuggestion(item?.description)}</>,
                    onClick: () => onPressAutoCompleteList(null, item?.description, item?.placeId)
                });
            });
        }
        return renderNoAddressFound(false);
    };

    const renderRecommendedJobsites = () => {
        return (
            <div className="suggestion-box border-top-line">
                <h6 className="eyebrow suggestion-box__title">
                    {capIntl.formatMessage({ id: 'cap:suggested-jobsites' })}
                </h6>
                {isJobsiteRecommendationLoading ? (
                    <div className="suggestion-box__recommendation-shimmer shimmer" />
                ) : recommendedJobsites?.length > 0 ? (
                    recommendedJobsites?.map(item => renderJobsiteSuggestion(item))
                ) : (
                    <p className="no-jobsites-found">
                        {capIntl.formatMessage({ id: 'cap:no-suggested-jobsite-found' })}
                    </p>
                )}
                {isP2PUser && renderSeeAllFavoriteJobsites()}
                {renderSeeAllJobsites()}
            </div>
        );
    };

    const renderMatchingJobsites = () => {
        const jobsites = jobsiteSuggestions?.slice(0, 3);
        return (
            <div className="suggestion-box border-top-line">
                {jobsites?.length > 0 ? (
                    <>
                        <h6 className="eyebrow suggestion-box__title">
                            {capIntl.formatMessage({ id: 'cap:matching-jobsites' })}
                        </h6>
                        {jobsites?.map(item => renderJobsiteSuggestion(item, true))}
                    </>
                ) : (
                    <p className="no-jobsites-found">{capIntl.formatMessage({ id: 'cap:no-jobsite-found' })}</p>
                )}
                {isP2PUser && renderSeeAllFavoriteJobsites()}
                {renderSeeAllJobsites()}
            </div>
        );
    };

    const renderJobsitesAndAddress = () => {
        // rendering suggested jobsites for text with less than 2 chars
        if (userType === USER_TYPE.CREDIT) {
            if (showFavoriteJobsite) {
                return renderFavoritesJobsites();
            }
            if (showRecommendedJobsite) {
                return renderRecommendedJobsites();
            }
            if (autocompleteText?.length > 1 && showSuggestions) {
                // when text has more than 2 chars and there is no jobsite or address
                if (jobsiteSuggestions.length === 0 && addressSuggestions.length === 0) {
                    return renderNoAddressFound(true);
                }
                return (
                    <>
                        {renderMatchingJobsites()}
                        <div className="suggestion-box border-top-line">
                            <h6 className="eyebrow suggestion-box__title">
                                {capIntl.formatMessage({ id: 'cap:matching-addresses' })}
                            </h6>
                            {showAddressSuggestionOrMessage()}
                        </div>
                    </>
                );
            }
        } else if (autocompleteText?.length > 1 && showSuggestions) {
            return <>{showAddressSuggestionOrMessage()}</>;
        }
        return null;
    };

    const renderCapAutoCompleteList = () => {
        return (
            <>
                {inputErrorMsg && <ErrorInput errorMsg={inputErrorMsg} />}
                {(isOpen || (isLocationPersisted && !isP2PUser)) && showCurrentLocation() && (
                    <Button
                        className="current-location-option"
                        tabIndex={0}
                        onClick={getCurrentLocation}
                        buttonAriaLabel={capIntl.formatMessage({ id: 'cap:use-current-location' })}>
                        <LocationNavigation tabIndex={-1} aria-hidden={true} />
                        {capIntl.formatMessage({ id: 'cap:use-current-location' })}
                    </Button>
                )}
                {isOpen && renderJobsitesAndAddress()}
            </>
        );
    };

    const handleDrawerOpenOnFocus = () => {
        if (!isAccountSelected) {
            return true;
        }
        return false;
    };

    return (
        <>
            <AutoCompleteDropdown
                inputId={inputId}
                placeholderText={placeholderText}
                showResetBtn={isOpen || isLocationPersisted}
                onChange={onChangeInput}
                onSelect={setOpenDropDown}
                onReset={onResetBtnClick}
                isDropDownOpen={isOpen || isLocationPersisted}
                suggestions={[]}
                value={autocompleteText}
                jobAndAddressState={jobAndAddressState}
                autoCompleteWrapperClass={`location-autocomplete ${autocompleteWrapper}`}
                autoCompleteInputWrapper={autoCompleteInputWrapper}
                labelContent={labelContent}
                onInputFocus={inputFocusHandler}
                onOutsideClickOrFocus={() => onOutsideClickOrFocus()}
                renderCustomSuggestions={renderCapAutoCompleteList}
                autoCompleteInputClass={'cmp-Field__field__input'}
                focusInput={focusInput}
                aria-label={inputAriaLabel}
                handleDrawer={handleDrawer}
                p2pClass={isP2PUser ? 'p2p_suggestion__list' : ''}
                handleClick={handleClick}
                isOpen={isP2PUser ? isJobsiteOpen : isOpen}
                expandOnFocus={isP2PUser ? isAccountSelected : true}
                readOnly={isJobsiteInputReadonly}
                focusOnReset
                disableSubmitOnEnter
                selectIdFromItem
                enableKeyboardNavigation
                openDropdown={isOpen || isLocationPersisted}
            />

            {showJobSiteDrawer && (
                <JobsitesListingModal
                    onModalClose={toggleJobsiteDrawer}
                    onJobsiteSelected={onJobsiteSelectedFromDrawer}
                    showAccountDetails={userProfile?.accounts?.length > 1 && isP2PUser && !isNonCorp}
                    selectedProjectId={selectedProjectId}
                    openFavoritesTab={openFavoritesTab}
                />
            )}
        </>
    );
};

JobsiteAddressAutoComplete.propTypes = {
    inputId: PropTypes.string,
    autocompleteWrapper: PropTypes.string,
    prefilledAddress: PropTypes.string,
    placeholderText: PropTypes.string,
    listItemTestId: PropTypes.string,
    autoCompleteInputWrapper: PropTypes.string,
    labelContent: PropTypes.func,
    onSelectAddress: PropTypes.func,
    onJobsiteSelect: PropTypes.func,
    selectedAddress: PropTypes.func,
    isLocationPersisted: PropTypes.bool,
    focusInput: PropTypes.bool,
    selectedProjectId: PropTypes.string,
    setSelectedProjectId: PropTypes.func,
    inputAriaLabel: PropTypes.string,
    isAddressChanged: PropTypes.bool,
    setIsAddressChanged: PropTypes.func,
    displayJobsiteModal: PropTypes.bool,
    buttonTestId: PropTypes.string,
    disableCurrentLocation: PropTypes.bool,
    onLocationInputFocus: PropTypes.func,
    setIsCapInteracted: PropTypes.func,
    jobAndAddressState: PropTypes.object,
    setJobAndAddressState: PropTypes.func,
    stringSearchedForSuggestion: PropTypes.string,
    setStringSearchedForSuggestion: PropTypes.func,
    inputErrorMsg: PropTypes.string
};

JobsiteAddressAutoComplete.defaultProps = {
    inputId: '',
    autocompleteWrapper: '',
    prefilledAddress: '',
    placeholderText: '',
    listItemTestId: '',
    autoCompleteInputWrapper: '',
    labelContent: () => {},
    onSelectAddress: () => {},
    onJobsiteSelect: () => {},
    selectedAddress: () => {},
    isLocationPersisted: false,
    focusInput: false,
    selectedProjectId: '',
    setSelectedProjectId: () => {},
    inputAriaLabel: '',
    isAddressChanged: false,
    setIsAddressChanged: () => {},
    displayJobsiteModal: false,
    buttonTestId: '',
    disableCurrentLocation: false,
    onLocationInputFocus: () => {},
    setIsCapInteracted: () => {},
    stringSearchedForSuggestion: '',
    setStringSearchedForSuggestion: () => {},
    inputErrorMsg: ''
};

export default JobsiteAddressAutoComplete;
