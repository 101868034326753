import { getCookie } from '../../common/js/common';

window.addEventListener('load', event => {
    const mobileAppDownloadModal = document.querySelector('.mobileAppDownloadModal');
    const storedMobileAppModal = sessionStorage.getItem('hideappmodal');
    const mobileAppModal = document?.querySelector('.mobile-app-modal');
    const mobileAppModalClose = document?.querySelector('.mobile-app-modal__close');
    const mobileAppModalAction = document?.querySelector('.mobile-app-modal___action');
    const headerWrap = document.querySelector('header');
    const checkSearchPage = window.location.pathname.includes('search');
    const getAuthorityType = getCookie('authority_type');

    if (mobileAppDownloadModal && mobileAppDownloadModal?.childElementCount !== 0 ) {
        const appModalDefaultValues = {
            appurl: 'sbrapp://com.sunbeltrentals.mobileapp',
            googlestoreurl: 'https://play.google.com/store/apps/details?id=com.sunbeltrentals.mobileapp&hl=en_IN&gl=US',
            applestoreurl: 'https://apps.apple.com/us/app/sunbelt-rentals/id1553310095',
            timeoutconfig: 100
        };
        const {
            appurl = appModalDefaultValues.appurl,
            googlestoreurl = appModalDefaultValues.googlestoreurl,
            applestoreurl = appModalDefaultValues.applestoreurl,
            timeoutconfig = appModalDefaultValues.timeoutconfig
        } = mobileAppModal?.dataset || {};

        const checkUserAgent = () => {
            const userAgent = navigator.userAgent || window.opera;
            let storelink = '';
            if (/Android/i.test(userAgent)) {
                storelink = googlestoreurl;
            } else {
                storelink = applestoreurl;
            }
            window.open(
                storelink,
                '_blank' // <- This is what makes it open in a new window.
            );
        };

        const handleModalClick = () => {
            sessionStorage.setItem('hideappmodal', true);
            mobileAppModalClose?.closest('.mobileAppDownloadModal')?.classList.toggle('mobileAppDownloadModal--show');
            headerWrap.parentElement.style.paddingTop = `0`;
            headerWrap?.classList?.toggle('header--app-modal');
        };

        mobileAppModalClose?.addEventListener('click', function () {
            handleModalClick();
        });
        mobileAppModalAction?.addEventListener('click', function () {
            handleModalClick();
            checkUserAgent();
        });
        if (!storedMobileAppModal) {
            mobileAppDownloadModal.classList.toggle('mobileAppDownloadModal--show');
            if (checkSearchPage) {
                headerWrap.classList.toggle('header--app-modal');
                headerWrap.parentElement.style.paddingTop = `${mobileAppModal.clientHeight}px`;
            }
        }
      
        if (getAuthorityType === 'p2p' || getAuthorityType === 'punchout') {
            mobileAppModalClose?.closest('.mobileAppDownloadModal')?.classList.toggle('mobileAppDownloadModal--show');
            headerWrap.parentElement.style.paddingTop = `0`;
            headerWrap?.classList?.toggle('header--app-modal');
        }
    }
});
