import { useMemo } from 'react';
import {
    HOME_PAGE,
    SEARCH_RESULTS,
    SEARCH,
    LOCATION,
    CATEGORY_PAGE,
    CUSTOM_PLP,
    EQUIPMENT_RENTAL
} from '../constants/screenConstants';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { HOME_PAGE_TITLE, EQUIPMENT_AND_TOOLS_TITLE } from '../components/global/constants';

export const usePageType = () => {
    const memoizedPageType = useMemo(() => {
        let pageType = '';
        const { pathname } = window.location;
        const isPDP = document.querySelector('.block--pdp-hero')?.dataset;
        const level = document.querySelector("meta[name='categoryLevel']")?.content;
        const documentTitle = document.title;
        if (documentTitle.includes(HOME_PAGE) || documentTitle.includes(HOME_PAGE_TITLE) || pathname == '/') {
            pageType = VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
        } else if (isPDP) {
            pageType = VARIABLE_CONFIG.ECOMMERCE_PAGE.DETAIL;
        } else if (
            level == 3 ||
            level == 4 ||
            documentTitle.includes(CATEGORY_PAGE) ||
            documentTitle.includes(CUSTOM_PLP)
        ) {
            pageType = VARIABLE_CONFIG.PAGE_TYPE.PLP;
        } else if (documentTitle.includes(SEARCH_RESULTS) || pathname.includes(SEARCH)) {
            pageType = VARIABLE_CONFIG.PAGE_TYPE.SEARCH_PAGE;
        } else if (pathname.includes(LOCATION) || documentTitle.includes(LOCATION)) {
            pageType = VARIABLE_CONFIG.PAGE_TYPE.LOCATION_PAGE;
        } else if (documentTitle.toLowerCase().includes(VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT)) {
            pageType = VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT;
        } else if (documentTitle.includes(EQUIPMENT_AND_TOOLS_TITLE) || pathname.includes(EQUIPMENT_RENTAL)) {
            pageType = VARIABLE_CONFIG.PAGE_TYPE.EQUIPMENT_AND_TOOLS_PAGE;
        } else {
            pageType = documentTitle;
        }
        return pageType;
    }, []);
    return memoizedPageType;
};
