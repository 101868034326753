import React, { createContext, useContext, useReducer } from 'react';
import { func, object, shape } from 'prop-types';
import { initialPdpState } from './state';
import { pdpActions} from './actions';

export const PDPContext = createContext();

export const PdpProvider = props => {
    const reducer = props.reducer || pdpActions;
    const initialState = props.initialState || initialPdpState;
    return <PDPContext.Provider value={useReducer(reducer, initialState)}>{props.children}</PDPContext.Provider>;
};

PdpProvider.propTypes = {
    reducer: func,
    initialState: shape({
        rates: object
    })
};

export const usePdpState = () => useContext(PDPContext);
