export const PURCHASE_PAGE_SIZE = 20;
export const PURCHASE_ORDER_LIST_DATE_FORMAT = 'MM/DD/YYYY';
export const PURCHASE_ORDER_LIST_ISSUED_DATE_FORMAT = 'YYYYMMDD';
export const DEFAULT_SELECTED_PO_COLUMN = [
    'poListNumber',
    'poNumber',
    'accountName',
    'accountNumber',
    'poDate',
    'poAmount',
    'accruedAmount',
    'poPercentage',
    'remainingBalance',
    'createdBy'
];
export const DEFAULT_SELECTED__NON_CORP_PO_COLUMN = [
    'poListNumber',
    'poNumber',
    'poDate',
    'poAmount',
    'accruedAmount',
    'poPercentage',
    'remainingBalance',
    'createdBy'
];
export const PO_HEADER_FIELDS_MAPPING = {
    poNumber: 'po_number',
    accountName: 'account_name',
    accountNumber: 'account_number',
    poDate: 'po_date',
    poAmount: 'po_amount',
    accruedAmount: 'accrued_amount',
    poPercentage: 'po_percentage',
    remainingBalance: 'remaining_balance',
    createdBy: 'contact_name',
    poReceivedDate: 'po_received_date',
    poExpirationDate: 'po_expiration_date',
    poThresholdPercent: 'po_threshold_percent'
};
export const DISABLE_PO_COLUMN_SELECT = ['poListNumber', 'poNumber'];
export const HIDE_LABEL_PO_COLUMN_SELECT = ['poListNumber', 'poNumber'];
export const PAGE_NUMBER = 1;
export const PAGE_SIZE = 20;
export const SORT_INPUT = 'po_date-DESC';
export const PURCHASE_ORDER_FILTER_ACCOUNT = 'accounts';
export const PURCHASE_ORDER_FILTER_PO_NUMBER = 'poNumber';
export const PURCHASE_ORDER_FILTER_ISSUED_DATE_RANGE = 'issued_date_range';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const DEFAULT_START_DATE = 30;
export const DEFAULT_MIN_DATE = 60;
export const SORT_ASCENDING = 'ASC';
export const SORT_DESCENDING = 'DESC';

export const PO_FILTER_KEYS = {
    accountNumber: 'account_number',
    poNumber: 'po_number',
    poIssuedDate: 'po_received_date'
};
export const DEFAULT_PO_COLUMN_KEYS = [
    {
        key: 'accountName',
        minWidth: 199
    },
    {
        key: 'accountNumber',
        minWidth: 115
    },
    {
        key: 'poDate',
        minWidth: 117
    },
    {
        key: 'poAmount',
        minWidth: 136
    },
    {
        key: 'accruedAmount',
        minWidth: 155
    },
    {
        key: 'poPercentage',
        minWidth: 166
    },
    {
        key: 'remainingBalance',
        minWidth: 171
    },
    {
        key: 'createdBy',
        minWidth: 121
    },
    {
        key: 'poReceivedDate',
        minWidth: 141
    },
    {
        key: 'poExpirationDate',
        minWidth: 146
    }
];
