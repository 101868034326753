import axios from 'axios';
import { API_CONFIG, getClientId, getClientSecret } from './apiConfigGR';
import axiosInstanceGR from './axiosInstanceGR';

export const getAuthToken = async () => {
    try {
        const requestBody = {
            client_id: getClientId(),
            client_secret: getClientSecret(),
            scope: 'Mulesoft',
            grant_type: 'client_credentials'
        };
        const { data, error, status } = await axiosInstanceGR.post(API_CONFIG.GET_AUTH_TOKEN, requestBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });
        return { data, error, status };
    } catch (error) {
        return { error };
    }
};

export const verifyEmail = async emailId => {
    try {
        const { data, error } = await axiosInstanceGR.post(API_CONFIG.VERIFY_EMAIL, {
            params: {
                email: emailId
            }
        });
        return { data, error };
    } catch (error) {
        return { error };
    }
};

export const sendOtpToEmail = async emailId => {
    try {
        const { data, error, status } = await axiosInstanceGR.post(API_CONFIG.SEND_OTP, {
            to: emailId,
            channel: 'email'
        });
        return { data, error, status };
    } catch (error) {
        return { error };
    }
};

export const verifyToken = async (emailId, token) => {
    try {
        const requestBody = {
            to: emailId,
            code: token
        };
        const { data, error } = await axiosInstanceGR.post(API_CONFIG.VERIFY_CODE, requestBody);
        return { data, error };
    } catch (error) {
        return { error };
    }
};

export const duplicateProfiles = async emailId => {
    try {
        const requestBody = {
            email: emailId
        };
        const { data, error, status } = await axiosInstanceGR.post(API_CONFIG.DUPLICATE_PROFILES, requestBody);
        return { data, error, status };
    } catch (error) {
        return { error };
    }
};

export const createAccount = async (country, payload) => {
    try {
        const response = await axiosInstanceGR.post(
            API_CONFIG.CREATE_ACCOUNT,
            { ...payload },
            {
                headers: {
                    companyId: country
                }
            }
        );
        const { data = {}, error = {} } = response || {};
        return { data, error };
    } catch (error) {
        return { error };
    }
};

export const getSubmissionId = async payload => {
    try {
        const { data, error, status } = await axiosInstanceGR.post(API_CONFIG.CCA_SUBMISSION_ID, { ...payload });
        return { data, error, status };
    } catch (error) {
        return { error };
    }
};

export const getClientIp = async () => {
    try {
        const response = await axios.get(API_CONFIG.CLIENT_IP);
        return response;
    } catch (error) {
        return error;
    }
};
