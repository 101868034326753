import axios from 'axios';
import { getApiConfigByEnv } from './apiConfigGR';
import { generateCorrelationId, logError } from '../../global/utils/logger';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

const config = getApiConfigByEnv();
const axiosInstanceGR = axios.create({
    ...config
});

var getAuthToken = () => {
    var token = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.GR_TOKEN);
    return `Bearer ${token}`;
};

axiosInstanceGR.interceptors.request.use(
    req => {
        req.headers = {
            ...req.headers,
            'x-correlation-Id': generateCorrelationId(),
            Authorization: getAuthToken()
        };
        return req;
    },
    err => {
        return Promise.reject(err);
    }
);
axiosInstanceGR.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error?.response?.status >= 500)
            //internal server error
            logError(error?.response, false);
        else {
            logError(error);
            return Promise.reject(error);
        }
    }
);

export default axiosInstanceGR;
