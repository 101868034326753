import { SET_LOCATION_DATA } from '../aem-core-components/actions/constants';
import { useFilterState } from '../components/cap';
import { useCartState } from '../contexts/cart';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { useCookieValue } from '../aem-core-components/utils/hooks';
import useReservation from '../components/checkoutv2/hooks/useReservation';
import { isValidString } from '../components/global/utils/logger';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckUser } from './useCheckUser';
import { isCheckoutPage, setFourHourRentals } from '../components/global/utils/commonUtils';
import { usePdpState } from '../contexts/pdp/pdpContext';

const useUserData = () => {
    const userType = useCheckUser();
    const [{ userProfile }] = useUserContext();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const [{ userAccount, guestUserDetails, cart, cartId, howToGetYourOrderDetails, homeAssetsRates }] = useCartState();
    const [{ rates }] = usePdpState();
    const [settingsCookie, setSettingsCookie] = useCookieValue('Settings');

    const { estimatedTotal } = howToGetYourOrderDetails;
    const [{ getStartDate, getEndDate }] = useReservation();

    const ccCookies = [
        'SLLat',
        'SLLong',
        'SLA',
        'SLC',
        'SLS',
        'SLZ',
        'CurrentJobSite',
        'locationPC',
        'projectName',
        'projectAddress2',
        'primaryContactName',
        'phoneNumber',
        'accessNotes',
        'poNumber',
        'RMJobsiteId'
    ];

    const getRentalDuration = (startDate, endDate) => {
        return (new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24);
    };

    const setUserDetails = () => {
        let userDetails = {};
        switch (userType) {
            case USER_TYPE.CASH:
            case USER_TYPE.CREDIT:
                userDetails = {
                    firstName: userProfile?.firstName || '',
                    lastName: userProfile?.lastName || '',
                    company: localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1',
                    email: userProfile?.email || '',
                    phone: userProfile?.phone || '',
                    address: userProfile?.address1 || '',
                    city: userProfile?.city || '',
                    state: userProfile?.state || '',
                    country: userProfile?.country || '',
                    postalCode: userProfile?.zip || '' // check for canada also
                };

                break;
            case USER_TYPE.GUEST:
                userDetails = {
                    firstName: guestUserDetails?.firstName || '',
                    lastName: guestUserDetails?.lastName || '',
                    company: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                    email: guestUserDetails?.email || '',
                    phone: guestUserDetails?.phoneNumber || '',
                    address: viewCart?.location || '',
                    city: viewCart?.jobSiteCity || '',
                    state: viewCart?.jobSiteState || '',
                    country: viewCart?.country || '',
                    postalCode: viewCart?.jobSiteState || '' // check for canada also
                };
                break;
            default:
                break;
        }
        return userDetails;
    };

    const getAssets = cartData => {
        let items = [];
        if (cartData) {
            items = isCheckoutPage() ? cartData?.availableCartItems : cartData?.items;
        } else {
            items = isCheckoutPage() ? cart?.availableCartItems : cart?.items;
        }

        let assets = [];
        if (!items?.length) {
            //if cart is not available, for eg, on page load since cart call doesnt happen, use this storage to fetch cartdetails
            return JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCAL_ASSETS_DATA) || '[]') || [];
        }
        items?.forEach((item, index) => {
            const { sku = '' } = item?.product;
            const quantity = item?.quantity;
            assets.push({
                productId: sku,
                quantity,
                sequenceNumber: index
            });
        });
        return assets;
    };

    //TODO : to check for rmJobsiteId
    const getProjectsFromCookie = () => {
        const projectObj = {};
        const projectsInfo = settingsCookie?.split('&');
        for (let i in projectsInfo) {
            let cookiePair = projectsInfo[i].split('=');
            projectObj[cookiePair[0]] = decodeURIComponent(cookiePair[1]);
        }
        return projectObj;
    };

    const clearProjectDetailCookies = () => {
        let settingsKey = [];
        let settingData = (settingsCookie && settingsCookie.split('&')) || [];
        let settingCookieData = '';
        for (let i in settingData) {
            let cookieKey = settingData[i].split('=');
            if (ccCookies.indexOf(cookieKey[0]) === -1) {
                settingsKey.push(settingData[i]);
            }
        }
        settingsKey.push(`CurrentJobSite=${encodeURIComponent('')}`);
        settingsKey.push(`locationPC=${encodeURIComponent('')}`);
        settingsKey.push(`projectName=${encodeURIComponent('')}`);
        settingsKey.push(`projectAddress2=${encodeURIComponent('')}`);
        settingsKey.push(`primaryContactName=${encodeURIComponent('')}`);
        settingsKey.push(`phoneNumber=${encodeURIComponent('')}`);
        settingsKey.push(`accessNotes=${encodeURIComponent('')}`);
        settingsKey.push(`poNumber=${encodeURIComponent('')}`);
        settingsKey.push(`SLLat=${encodeURIComponent('')}`);
        settingsKey.push(`SLLong=${encodeURIComponent('')}`);
        settingsKey.push(`SLA=${encodeURIComponent('')}`);
        settingsKey.push(`SLC=${encodeURIComponent('')}`);
        settingsKey.push(`SLS=${encodeURIComponent('')}`);
        settingsKey.push(`SLZ=${encodeURIComponent('')}`);
        settingsKey.push(`RMJobsiteId=${encodeURIComponent('')}`);
        settingCookieData = settingsKey.join('&');
        setSettingsCookie(settingCookieData);
    };

    const updateGeography = (
        pc,
        pcLat,
        pcLong,
        distance,
        specialtyTypes = [],
        localLat = '',
        localLong = '',
        assets = []
    ) => {
        const NewAddress = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)) || false;
        let browsePCObj =
            startDate &&
            endDate &&
            (isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0 ||
                assets?.length > 0)
                ? STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ
                : STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ;
        sessionStorage.setItem(
            browsePCObj,
            JSON.stringify({
                pc: pc,
                lat: pcLat,
                long: pcLong,
                localLat:
                    userType == USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectLatititude
                        : localLat || viewCart?.lat,
                localLong:
                    userType == USER_TYPE.CREDIT && !NewAddress
                        ? projectDetails?.selectedProjectLongitude
                        : localLong || viewCart?.long,
                distance: distance,
                specialtyTypes
            })
        );
        if (localLat && localLong && !(userType == USER_TYPE.CREDIT && !NewAddress)) {
            filterDispatch({ type: SET_LOCATION_DATA, ...viewCart, lat: localLat, long: localLong });
        }
        filterDispatch({ type: SET_LOCATION_DATA, ...viewCart, pc, pcLat, pcLong });
    };

    const updateFourHourRentals = (sku, isItemAdded = true) => {
        const isPDP = document.querySelector('.block--pdp-hero')?.dataset;
        const pdpRatesObj = rates?.[0] || {};
        if (isItemAdded) {
            if (isPDP && pdpRatesObj?.productId == sku) {
                setFourHourRentals(pdpRatesObj?.rates?.suggestedRates, sku);
            } else if (Object.keys(homeAssetsRates).length > 0) {
                setFourHourRentals(homeAssetsRates[sku], sku);
            }
        } else {
            let fourHourRentals = JSON.parse(
                localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS) || '{}'
            );
            delete fourHourRentals[sku];
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS, JSON.stringify(fourHourRentals));
        }
    };

    return [
        {
            setUserDetails,
            getAssets,
            getProjectsFromCookie,
            clearProjectDetailCookies,
            updateGeography,
            updateFourHourRentals
        }
    ];
};

export default useUserData;
