import { useEffect } from 'react';
import { useCartState } from './cartContext';
import { useCookieValue } from '../../aem-core-components/utils/hooks';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import { CART_AGE } from '../../constants/cartConstants';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { useCheckUser } from '../../hooks/useCheckUser';

const CartInitializer = props => {
    const [{ cartId: stateCartId }, dispatch] = useCartState();
    const [{ cartId: registeredCartId, isUserProfileLoading }] = useUserContext();

    const CART_COOKIE = 'cif.cart';

    const [cartId, setCartCookie] = useCookieValue(CART_COOKIE);
    const userType = useCheckUser();

    useEffect(() => {
        if (cartId && cartId.length > 0 && !stateCartId && !isUserProfileLoading) {
            dispatch({ type: 'cartId', cartId });
        }
    }, [cartId, isUserProfileLoading]);

    useEffect(() => {
        if (stateCartId && (!cartId || cartId.length === 0) && !isUserProfileLoading) {
            if (userType == VARIABLE_CONFIG.AUTHENTICATION.GUEST) {
                setCartCookie(stateCartId, CART_AGE.GUEST_USER);
            } else {
                setCartCookie(stateCartId, CART_AGE.AUTHENTICATED_USER);
            }
        }
    }, [stateCartId, isUserProfileLoading]);

    useEffect(() => {
        if (registeredCartId) {
            if (userType == VARIABLE_CONFIG.AUTHENTICATION.GUEST) {
                setCartCookie(registeredCartId, CART_AGE.GUEST_USER);
            } else {
                setCartCookie(registeredCartId, CART_AGE.AUTHENTICATED_USER);
            }
            dispatch({
                type: 'cartId',
                cartId: registeredCartId
            });
        }
    }, [registeredCartId]);

    return props.children;
};

export default CartInitializer;
