import moment from 'moment';
import { useCheckUser } from './useCheckUser';
import useComputeLocation from './useComputeLocation';
import { useCartState } from '../contexts/cart';
import { useFilterState } from '../components/cap';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { isValidString } from '../components/global/utils/logger';
import isObjectEmpty from '../aem-core-components/utils/isObjectEmpty';
import { getlatLongByAddress } from '../components/global/modules/location-autocomplete/api/getLocations';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { mktoLeadCreation } from '../../api/mktoLeadCreation';

const useMarketo = () => {
    const userType = useCheckUser();
    const [{ userProfile }] = useUserContext();
    const [{ userAccount, cart, cartId, consumables }] = useCartState();
    const [{ projectDetails, startDate, endDate }] = useFilterState();
    const {cityAndZipValidationAddressComponent, extractAddressComponents} = useComputeLocation();
    const getRentablesForMarketo = pcObj => {
        const productsPayload = [];
        const rentalObj = consumables?.selected || {};
        Object.keys(rentalObj).forEach(item => {
            let selectedArray = Object.keys(rentalObj[item]);
            if (selectedArray.includes('rentals')) {
                let rentalArray = Object.values(rentalObj[item]['rentals']);
                rentalArray.forEach(itemRental => {
                    productsPayload.push({
                        productId: itemRental?.itemObj?.product?.sku,
                        productName: itemRental?.name,
                        quantityAdded: itemRental?.qty,
                        categoryId: itemRental?.itemObj?.product?.sku?.substring(0, 3),
                        categoryName: itemRental?.itemObj?.product?.category_name,
                        rate: itemRental?.price,
                        specialtyId: pcObj?.specialtyTypes?.[0],
                        superCategory: itemRental?.itemObj?.product?.categories?.[0]?.name,
                        productImageURL: itemRental?.itemObj?.product?.thumbnail?.url?.replace('fmt=webp', 'fmt=jpg'),
                        productURL: window?.location?.origin + itemRental?.itemObj?.product?.product_page_url
                    });
                });
            }
        });

        return productsPayload;
    };

    const getCartItemsForMarketo = pcObj => {
        const items = cart?.items?.map(item => ({
            productId: item?.product?.sku,
            productName: item?.product?.name,
            quantityAdded: item?.quantity,
            categoryId: item?.product?.sku?.substring(0, 3),
            categoryName: item?.product?.category_name,
            rate: item?.prices?.price?.value,
            specialtyId: pcObj?.specialtyTypes?.[0],
            superCategory: item?.product?.categories?.[0]?.name,
            productImageURL: item?.product?.thumbnail?.url?.replace('fmt=webp', 'fmt=jpg'),
            productURL: window?.location?.origin + item?.product?.product_page_url
        }));

        return items || [];
    };

    const atcMarketoLeadCreation = (
        productId,
        productName,
        quantityAdded,
        categoryId,
        categoryName,
        rate,
        superCategory,
        productImageURL,
        productURL,
        cart_id,
        created_at,
        updated_at
    ) => {
        const specialtyId = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}')
            ?.specialtyTypes?.[0];
        let marketoPayload = {
            cart_id,
            created_at,
            updated_at,
            item: {
                productId,
                productName,
                quantityAdded,
                categoryId,
                categoryName,
                rate,
                specialtyId,
                superCategory,
                productImageURL,
                productURL
            }
        };
        mktoLeadCreation(getCartDetailsForMarketo('', marketoPayload));
    };

    const getCartDetailsForMarketo = (reservationId = '', addedItemDetails = {}) => {
        let rentalDuration = '';
        let items = [];
        const browsePCObj =
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) &&
            localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) &&
            isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY)) > 0
                ? STORAGE_CONFIG.SESSION_STORAGE.SOURCEPCOBJ
                : STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ;

        const pcObj = JSON.parse(sessionStorage.getItem(browsePCObj) || '{}');

        if (startDate && endDate) {
            const starts = moment(startDate);
            const ends = moment(endDate);
            const days = ends.diff(starts, 'days');
            const years = ends.diff(starts, 'years');
            const hours = ends.diff(starts, 'hours');
            const months = ends.diff(starts, 'months');
            rentalDuration = `${years}years-${months}months--${days}days--${hours}hours`;
        }

        items =
            Object.keys(addedItemDetails)?.length > 0
                ? [addedItemDetails?.item]
                : [...getCartItemsForMarketo(pcObj), ...getRentablesForMarketo(pcObj)];

        return {
            cartId: cartId || addedItemDetails?.cart_id,
            cartSource: 'Web',
            cartCreatedAt: cart?.created_at || addedItemDetails?.created_at,
            cartUpdatedAt: cart?.updated_at || addedItemDetails?.updated_at,
            rentalDuration,
            reservationID: reservationId,
            sessionType: userType,
            projectName: projectDetails?.projectName,
            oktaId: userProfile?.oktaUserId || '',
            accountId: userAccount?.accountNumber,
            userType: userType === USER_TYPE.GUEST ? '' : userType,
            items
        };
    };

    const setCountryFields = (form, country, addressFieldKeys) => {
        const provinces = ['AB', 'BC', 'MB', 'ON', 'SK', 'NB', 'NS', 'QC'];
        if (country === 'Canada') {
            searchCountryLocation(form, 'ca', addressFieldKeys);
            Array.from(document.querySelector(`#${addressFieldKeys.STATE}`).options).forEach(function (option) {
                if (provinces.indexOf(option.value) === -1) {
                    option.style.display = 'none';
                }
            });
        } else {
            searchCountryLocation(form, 'us', addressFieldKeys);
        }
    };

    const searchCountryLocation = (form, country, addressFieldKeys) => {
        var formEl = form.getFormElem()[0],
            mktoFieldNames = {
                country: 'Country',
                address: addressFieldKeys.ADDRESS,
                city: addressFieldKeys.CITY,
                state: addressFieldKeys.STATE,
                postalCode: addressFieldKeys.POSTAL_CODE,
                country: 'Country'
            },
            mktoNewValuesObj = {};

        var mktoFieldEls = Object.keys(mktoFieldNames).reduce(function (acc, next) {
            acc[next] = formEl.querySelector("input[name='" + mktoFieldNames[next] + "']");
            return acc;
        }, {});

        var placeBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(-90, -180),
            new google.maps.LatLng(90, 180)
        );

        var placeOptions = {
            bounds: placeBounds,
            componentRestrictions: { country: country },
            types: ['establishment']
        };
        delete window.autocomplete;
        window.autocomplete = new google.maps.places.Autocomplete(mktoFieldEls.address, placeOptions);

        window.autocomplete.addListener('place_changed', function () {
            var acPlace = window.autocomplete.getPlace();
            var acAddressComponents = acPlace.address_components;
            mktoNewValuesObj[mktoFieldNames.address] = '';

            // interesting address types need deeper checks
            acAddressComponents?.forEach(function (component) {
                var types = component.types,
                    short_name = component.short_name,
                    long_name = component.long_name;
                types?.forEach(function (type) {
                    switch (type) {
                        case 'administrative_area_level_1':
                            mktoNewValuesObj[mktoFieldNames.state] = short_name;
                            break;
                        case 'locality':
                        case 'sublocality':
                            mktoNewValuesObj[mktoFieldNames.city] = long_name;
                            break;
                        case 'route':
                        case 'street_number':
                            mktoNewValuesObj[mktoFieldNames.address] +=
                                (mktoNewValuesObj[mktoFieldNames.address] ? ' ' : '') + long_name;
                            break;
                        case 'postal_code':
                            mktoNewValuesObj[mktoFieldNames.postalCode] = long_name;
                            break;
                        case 'country':
                            mktoNewValuesObj[mktoFieldNames.country] = long_name;
                            setCountryFields(form, long_name, addressFieldKeys);
                            break;
                    }
                });
            });

            form.setValues(mktoNewValuesObj);
        });
    };

    const MktoForms2WhenReady = async (form, isRequestQuote) => {
        let ADDRESS_FIELD_KEYS = isRequestQuote ? VARIABLE_CONFIG.MKTO_REQUEST_QUOTE : VARIABLE_CONFIG.MKTO_CONTACT_US;
        let manualAddressChecked = false;
        const country = document.getElementById('Country');
        const subject = document.getElementById('mktoContactUsSubject');
        if (subject) {
            subject.addEventListener('change', function (val) {
                if (val.currentTarget.value === 'Rent Equipment') {
                    ADDRESS_FIELD_KEYS = VARIABLE_CONFIG.MKTO_REQUEST_QUOTE;
                    setCountryFields(form, country.value, ADDRESS_FIELD_KEYS);
                }
            });
        }
        if (country) {
            country.addEventListener('change', function (val) {
                setCountryFields(form, val.currentTarget.value, ADDRESS_FIELD_KEYS);
            });
            setCountryFields(form, country.value, ADDRESS_FIELD_KEYS);
        }

        form.onValidate(async function (nativeValid) {
            var extendedValidators = [
                {
                    field: 'FirstName',
                    pattern: /(?=.*?[A-Za-z])^[A-Za-z0-9\-]{1,50}$/,
                    message: 'Your first name can only contain letters and hyphens.'
                },
                {
                    field: 'LastName',
                    pattern: /(?=.*?[A-Za-z])^[A-Za-z0-9\-]{1,50}$/,
                    message: 'Your last name can only contain letters and hyphens.'
                },
                {
                    field: 'Email',
                    pattern: /^[A-Za-z0-9]+[\w.-]*@[A-Za-z0-9-]+\.[a-z]{2,6}$/,
                    message: 'Enter valid email address. Example@yourdomain.com'
                },
                {
                    field: 'Phone',
                    pattern: /(^\d{10}$)|(^\d{0}$)/,
                    message: 'Enter a phone number that includes 10 digits.'
                },
                {
                    field: ADDRESS_FIELD_KEYS.POSTAL_CODE,
                    patternUS: /^([0-9]{5})(?:[-\s]*([0-9]{4}))?$/, // US ZIP Code
                    patternCanada: /^([A-Z][0-9][A-Z])\s*([0-9][A-Z][0-9])$/, // Canada Postal Code
                    messageUS: 'Enter a valid zip code.',
                    messageCanada: 'Enter a valid postal code.'
                },
                {
                    field: 'Company',
                    pattern: /(?=.*?[A-Za-z])^[A-Za-z0-9 \-]{1,50}$/,
                    message: 'Company name can only contain letters and hyphens.'
                }
            ];
            let countryShortName;
            let companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
            if (!nativeValid) return;
            var formEl = form.getFormElem()[0];
            form.submittable(false);
            var formError = {};
            extendedValidators.some(function (vtor) {
                var regExp = vtor.pattern,
                    errorMsg = vtor.message;
                if (vtor.field == ADDRESS_FIELD_KEYS.POSTAL_CODE) {
                    switch (form.getValues()['Country']) {
                        case 'United States':
                            regExp = vtor.patternUS;
                            errorMsg = vtor.messageUS;
                            countryShortName = 'us';
                            companyID = '1';
                            break;
                        case 'Canada':
                            regExp = vtor.patternCanada;
                            errorMsg = vtor.messageCanada;
                            countryShortName = 'ca';
                            companyID = '2';
                            break;
                        default:
                            break;
                    }
                }
                if (vtor.field === 'Company' && !form.getValues()[vtor.field]) {
                    return true;
                }
                if (!regExp.test(form.getValues()[vtor.field]?.trim())) {
                    formError[vtor.field] = errorMsg;
                    return true;
                }
            });
            // check manual address with geocode response
            if (!manualAddressChecked) {
                let manualAddress = `${form.getValues()[ADDRESS_FIELD_KEYS.ADDRESS]}, ${
                    form.getValues()[ADDRESS_FIELD_KEYS.CITY]
                }, ${form.getValues()[ADDRESS_FIELD_KEYS.STATE]}, ${form.getValues()[ADDRESS_FIELD_KEYS.POSTAL_CODE]}`;
                if (manualAddress) {
                    const responseFromGeocode = await getlatLongByAddress(companyID, manualAddress);
                    if (
                        responseFromGeocode?.error ||
                        responseFromGeocode?.data?.results?.length == 0 ||
                        isObjectEmpty(responseFromGeocode?.data) ||
                        !cityAndZipValidationAddressComponent(responseFromGeocode?.data?.results[0], countryShortName)
                    ) {
                        formError[ADDRESS_FIELD_KEYS.ADDRESS] = 'Location invalid';
                    } else {
                        let { jobSiteAddr2, jobSiteCity, jobSiteState, jobSiteZip } = extractAddressComponents(
                            responseFromGeocode?.data?.results[0],
                            true
                        );
                        let addressByGeo = responseFromGeocode?.data?.results[0]?.formattedAddress?.split(',')[0];
                        const formValues = {
                            FirstName: form.getValues()['FirstName']?.trim(),
                            LastName: form.getValues()['LastName']?.trim()
                        };
                        formValues[ADDRESS_FIELD_KEYS.ADDRESS] = addressByGeo;
                        formValues[ADDRESS_FIELD_KEYS.CITY] = jobSiteCity;
                        formValues[ADDRESS_FIELD_KEYS.STATE] = jobSiteState;
                        formValues[ADDRESS_FIELD_KEYS.POSTAL_CODE] = jobSiteZip;
                        form.setValues(formValues);
                        if (jobSiteZip) {
                            delete formError[ADDRESS_FIELD_KEYS.POSTAL_CODE];
                        }
                        if (Object.keys(formError).length <= 0) {
                            manualAddressChecked = true;
                            form.submit();
                        }
                    }
                }
            }
            // manual address validation ends

            if (Object.keys(formError).length > 0) {
                for (let key in formError) {
                    form.showErrorMessage(formError[key], MktoForms2.$(formEl.querySelector('#' + key)));
                }
            } else {
                form.submittable(true);
            }
        });
    };

    return [
        {
            getCartDetailsForMarketo,
            setCountryFields,
            searchCountryLocation,
            MktoForms2WhenReady,
            atcMarketoLeadCreation
        }
    ];
};

export default useMarketo;
