window.addEventListener('load', event => {
    try {
        var url = window.location.search;
        var email;
        var password;
        if (checkForEmpty(url)) {
            email = getQueryParam('username', url);
            password = getQueryParam('password', url);
            if (checkForEmpty(email) && checkForEmpty(password)) apicall(email, password);
        }
    } catch (error) {
        console.error('An error occurred while initializing the Jquery:', error);
    }
});

function apicall(email, password) {
    var currentdate = new Date();
    var datetime =
        currentdate.getFullYear() +
        '-' +
        ('0' + (currentdate.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + currentdate.getDate()).slice(-2) +
        'T' +
        ('0' + currentdate.getHours()).slice(-2) +
        ':' +
        ('0' + currentdate.getMinutes()).slice(-2) +
        ':' +
        ('0' + currentdate.getSeconds()).slice(-2);

    var json = {
        email: email,
        password: password,
        clientOS: 'Android',
        sourceApp: 'CustomerMobileApp2021',
        loginDateTime: datetime
    };

    $.ajax({
        method: 'POST',
        url: 'https://qa-api.sunbeltrentals.com/sbr-session-mgmt-v2-qa2/api/v2/session/login',
        data: JSON.stringify(json),
        headers: {
            clientKey: '709367c6eb824328a76f4af92ad60aa5',
            clientSecret: '1bf2Db58D82f41b5a9874608D6d2fbf2',
            'Request-Id': 'marketorequestlogin1',
            'X-Forwarded-For': '23.235.46.133',
            deviceToken: 'devtokenapiautomate123',
            Authorization:
                'Bearer F8wZWcMGAN15GK9fCGOrMEfQmxDZ5WgxEdg1U26SETZ1uECMw03wjbxeU1zBsjkb2QVhrZkNqa5A3UQq6gnjbQ=='
        },
        contentType: 'application/json',
        dataType: 'json',
        success: function (response, status, jqXHR) {
            if (checkForEmpty(response) && response.hasOwnProperty('data'))
                typeof Storage !== 'undefined' ? localStorage.setItem('user-login', JSON.stringify(response.data)) : '';
        },
        error: function (response) {
            console.log('error:' + response);
        }
    });
}

function getQueryParam(param, url) {
    var rx = new RegExp('[?&]' + param + '=([^&]+).*$');
    var returnVal = url.match(rx);
    return returnVal === null ? '' : returnVal[1];
}

function checkForEmpty(param) {
    if (param !== '' && param !== null) return true;
    else return false;
}
