import React, { useEffect } from 'react';
import { SET_LOCATION_DATA } from '../../../../aem-core-components/actions/constants';
import { useCookieValue } from '../../../../aem-core-components/utils/hooks';
import useComputeLocation from '../../../../hooks/useComputeLocation';
import { getAddressBylatLong } from '../../modules/location-autocomplete/api/getLocations';
import { isPaymentSectionVisited, isWebView } from '../../utils/commonUtils';
import { logWarning } from '../../utils/logger';
import { DENIED, GEOLOCATION, GRANTED, PROMPT } from './constants';
import { useFilterState } from '../../../cap';

const GetGeoLocation = () => {
    const [magentoTokenCookie, setMagentoTokenCookie] = useCookieValue('cif.magentoToken');
    const [locationPrompt, setLocationPrompt] = useCookieValue('locationPrompt');
    const [, filterDispatch] = useFilterState();
    const { getPC } = useComputeLocation();
    useEffect(() => {

        if (!magentoTokenCookie && !isPaymentSectionVisited() && !isWebView) {
            handlePermission();
        }
    }, []);

    const handlePermission = () => {
        if (navigator?.userAgent?.indexOf('Chrome') != -1 ) {
            navigator?.permissions?.query({ name: GEOLOCATION })?.then(function (result) {
                if (result?.state == GRANTED) {
                } else if (result?.state == PROMPT) {
                    askForGeolocation();
                } else if (result?.state == DENIED) {
                }
            });
        } else {
            if (!locationPrompt) {
                setLocationPrompt(true);
                askForGeolocation();
            }
        }
    };

    const getDataFromResponse = (response, filterName) => {
        const filteredData = response.filter(address => address?.types[0] === filterName);
        if (filteredData.length > 0) {
            return filteredData[0]?.shortName;
        } else return '';
    };
    const setLocation = async position => {
        const { data, error } = await getAddressBylatLong(position.coords.latitude, position.coords.longitude);
        // to mock location
        // const { data, error } = await getAddressBylatLong(37.09024, -95.712891);
        // const { pcVal, pcLat, pcLong } = await getPC(37.09024, -95.712891);
        const { pcVal, pcLat, pcLong } = await getPC(position.coords.latitude, position.coords.longitude);
        if (data) {
            let index = 0;
            for (var i = 0; i < data?.results?.length; i++) {
                if (getDataFromResponse(data?.results[i]?.addressComponents, 'postal_code')) {
                    break;
                }
                index++;
            }

            if (index < data?.results?.length) {
                if (
                    getDataFromResponse(data?.results[index]?.addressComponents, 'country') === 'CA' ||
                    getDataFromResponse(data?.results[index]?.addressComponents, 'country') === 'US'
                ) {
                    const plusCode = getDataFromResponse(data?.results[index]?.addressComponents, 'plus_code');
                    let location = data?.results[index]?.formattedAddress;
                    let formattedLocation = location?.replace(plusCode, '')?.trim();
                    filterDispatch({
                        type: SET_LOCATION_DATA,
                        pc: pcVal,
                        location: formattedLocation,
                        jobSiteCity: getDataFromResponse(
                            data?.results[index]?.addressComponents,
                            'administrative_area_level_2'
                        ),
                        jobSiteState: getDataFromResponse(
                            data?.results[index]?.addressComponents,
                            'administrative_area_level_1'
                        ),
                        jobSiteZip: getDataFromResponse(data?.results[index]?.addressComponents, 'postal_code'),
                        lat: position.coords.latitude,
                        long: position.coords.longitude,
                        pcLat: pcLat,
                        pcLong: pcLong
                    });
                    localStorage.setItem(
                        'companyID',
                        getDataFromResponse(data?.results[index]?.addressComponents, 'country') === 'CA' ? '2' : '1'
                    );
                }
            }
        } else {
            throw new Error(error);
        }
    };
    const errorGettingLocation = err => {
        logWarning(err);
    };
    const askForGeolocation = () => {
        if (navigator.geolocation) {
            navigator?.geolocation?.getCurrentPosition(setLocation, errorGettingLocation);
        }
    };

    return <></>;
};

export default GetGeoLocation;
