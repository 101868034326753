import { gql } from '@apollo/client';

const QUERY_PRODUCT_LIST_STATIC = gql`
    query listingProducts($sku: FilterEqualTypeInput, $pageSize: Int) {
        products(filter: { sku: $sku},pageSize: $pageSize ) {
            items {
                catclass
                ec_pc_inventory
            }
        }
    }
`;
export default QUERY_PRODUCT_LIST_STATIC;