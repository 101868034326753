import { gql } from '@apollo/client';

const QUERY_PRODUCT_LIST = gql`
    query listingProducts($sku: FilterEqualTypeInput, $pageSize: Int) {
        products(filter: { sku: $sku},pageSize: $pageSize ) {
            items {
                catclass
                sku
                name
                disableaddtocartoption
                showonlinecatalog
                category_name
                parent_category_name
                category_path
                product_page_url
                ec_pc_inventory
                image {
                    url
                    label
                }
                categories {
                    category_page_url
                }
                meta_title
                meta_description
                specialtytypes
                specialtytypescode
                analysisgroupcode
            }
        }
    }
`;
export default QUERY_PRODUCT_LIST;
