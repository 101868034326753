export const SET_PURCHASE_ORDER_LIST = 'SET_PURCHASE_ORDER_LIST';
export const SET_UPDATED_PURCHASE_ORDER_LIST = 'SET_UPDATED_PURCHASE_ORDER_LIST';
export const SET_PURCHASE_ORDER_LIST_RESPONSE = 'SET_PURCHASE_ORDER_LIST_RESPONSE';
export const SET_PO_LIST_ERROR = 'SET_PO_LIST_ERROR';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const SET_ACCOUNTS_FILTER = 'SET_ACCOUNTS_FILTER';
export const SET_ACCOUNTS_SELECTION = 'SET_ACCOUNTS_SELECTION';
export const SET_PO_NUMBER_SELECTION = 'SET_PO_NUMBER_SELECTION';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_ACCOUNTS_AND_PO_NUMBER_FILTER = 'SET_ACCOUNTS_AND_PO_NUMBER_FILTER';
export const SET_IS_ACCOUNTS_CHANGED = 'SET_IS_ACCOUNTS_CHANGED';
export const SET_ISSUED_DATE_RANGE = 'SET_ISSUED_DATE_RANGE';
export const SET_CLEAR_FILTER = 'SET_CLEAR_FILTER';
export const SET_ARE_FILTERS_RESET = 'SET_ARE_FILTERS_RESET';
export const SET_IS_APPLY_SELECTION_CTA_CLICKED = 'SET_IS_APPLY_SELECTION_CTA_CLICKED';
export const SET_PURCHASE_ORDER_DETAILS = 'SET_PURCHASE_ORDER_DETAILS';
export const SET_IS_REDIRECTED_PO = 'SET_IS_REDIRECTED_PO';
export const SET_SELECTED_ACCORDION = 'SET_SELECTED_ACCORDION';
export const SET_FILTER_DATA = 'SET_FILTER_DATA';
export const SORT_TYPE = 'SORT_TYPE';
export const SET_PURCHASE_ORDER_ACCOUNT_LIST = 'SET_PURCHASE_ORDER_ACCOUNT_LIST';
export const SET_PURCHASE_ORDER_PO_NUMBER_LIST = 'SET_PURCHASE_ORDER_PO_NUMBER_LIST';
export const SET_APPLY_FILTERS_CTA = 'SET_APPLY_FILTERS_CTA';
export const SET_PO_SEARCH_KEY_FLAG = 'SET_PO_SEARCH_KEY_FLAG';
export const SET_PRIVIOUS_SELECTED_FILTER = 'SET_PRIVIOUS_SELECTED_FILTER';
