import { cookieValue } from "../../../aem-core-components/utils/cookieUtils";

export const getProjectsFromCookie = () => {
    const settingsCookie = cookieValue('Settings');
    const projectObj = {};
    if (settingsCookie) {
        const projectsInfo = settingsCookie.split('&');
        for (let i = 0; i < projectsInfo.length; i++) {
            const [key, value] = projectsInfo[i].split('=');
            projectObj[key] = decodeURIComponent(value);
        }
    }
    return projectObj;
};