import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { isCCPage, isCheckoutPage, isTier2Radius } from '../../../../components/global/utils/commonUtils';
import { isValidString } from '../../../../components/global/utils/logger';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import AlertCircle from '../../../../resources/images/alert-circle-black.svg';
import './LocationDistanceWarningBanner.scss';
import { useFilterState } from '../../../../components/cap';
import { usePageType } from '../../../../hooks/usePageType';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

const LocationDistanceWarningBanner = () => {
    const orderSummaryIntl = useIntl();
    const alertRef = useRef(null);
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const pageType = usePageType();
    const isCapEnabled = document.querySelector('.cap__header');
    const { inventorycheckbanner = '' } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};

    let distanceWarningFlag =
        isValidString(isTier2Radius()) && (!isCapEnabled || pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE);
    if (isCheckoutPage() && viewCart?.isInStorePickup) {
        // will show banner on checkout page if delivery is selected
        distanceWarningFlag = false;
    }
    const [showBanner, setShowBanner] = useState(distanceWarningFlag);
    const inventoryNotification =
        inventorycheckbanner || orderSummaryIntl.formatMessage({ id: 'order-summary:product-availability-error' });
    const isShowBannerConditionFulfilled = () => {
        let overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        return showBanner && !isCCPage() && !window.location.pathname.includes('/login') && !overridePC?.pc;
    };

    useEffect(() => {
        if (distanceWarningFlag) {
            setShowBanner(true);
        } else {
            setShowBanner(false);
        }
    }, [distanceWarningFlag, projectDetails?.selectedProjectLatititude, viewCart?.lat]);

    useEffect(() => {
        if (showBanner) {
            alertRef?.current?.focus();
        }
    }, [showBanner]);

    return (
        <>
            {isShowBannerConditionFulfilled() ? (
                <div ref={alertRef} className={`inventory inventory--inactive`} tabIndex={0} aria-live="polite">
                    <div className="container inventory__container">
                        <AlertCircle className="inventory__icon" aria-hidden="true" tabIndex={'-1'} />
                        <div className="inventory__content">
                            <span dangerouslySetInnerHTML={{ __html: inventoryNotification }} />
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default React.memo(LocationDistanceWarningBanner);
