import { callGtm } from "../../GTM/";
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if(isGTMHidden === "false"){
	var heroComponents = document.querySelectorAll(".block--cat-hero .cmp-button"); 
	heroComponents.forEach((heroComponent) => {    
		heroComponent.addEventListener("click", function (e) { 
			var eventAction = heroComponent.innerText;
			var eventLabel = e.target.closest('.block--cat-hero').querySelector(".block-title-link").innerText;
			callGtm('uaevent', 'undefined', 'hero banner', eventAction, eventLabel);     
		});   
	});
}