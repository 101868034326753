export const LOCATION = 'LOCATION';
export const ADD_TO_CART = 'ADD_TO_CART';
export const DATE_PICKER = 'DATE_PICKER';
export const CHOOSE_STORE = 'CHOOSE_STORE';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SIDE_DRAWER = 'SIDE_DRAWER';
export const SIDE_DRAWER_CREATE_FLOW = 'SIDE_DRAWER_CREATE_FLOW';
export const TOOLTIP_MODAL = 'TOOLTIP_MODAL';
export const RENTAL_CHANGE_CONFIRMATION_MODAL = 'RENTAL_CHANGE_CONFIRMATION_MODAL';
