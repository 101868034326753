window.addEventListener('load', event => {
    var seasonalCarousel = $('.image-desc__carousel');
    seasonalCarousel.each(function (index, element) {
        var slider = $(this);
        var prevArrowButton = slider.closest('.block--image-desc').find('.image-desc__action-prev');
        var nextArrowButton = slider.closest('.block--image-desc').find('.image-desc__action-next');

        slider?.slick({
            arrows: true,
            dots: true,
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 3,
            prevArrow: prevArrowButton,
            nextArrow: nextArrowButton,
            accessibility: false,
            responsive: [
                {
                    breakpoint: 1024.98,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 639.98,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        });
    });

    var seasonalCarouselItems = document?.querySelectorAll('.imageDesc__carousel-item');
    var handleTabindex = function (elements) {
        [...elements]?.forEach(seasonalCarouselItem => {
            const cardImageLinks = seasonalCarouselItem.querySelectorAll('.card--imageDesc-content a');
            cardImageLinks?.forEach(link => {
                link.tabIndex = '0';
                link.ariaHidden = 'false';
            });
            seasonalCarouselItem.tabIndex = '-1';
            seasonalCarouselItem.ariaHidden = 'true';
        });
    };

    handleTabindex(seasonalCarouselItems);
});
