import { array, number, object, string } from 'prop-types';
import { VARIABLE_CONFIG } from './Variables';

export const EVENT_PAGE_NAMES_CONFIG = {
    PAGE_USER: 'page.user',
    PAGE_ELEMENT_CLICK: 'page.elementClick',
    PAGE_CONTENT: 'page.content',
    PAGE_USER_ERROR: 'page.userError',
    SEND_EVENT_FOR_CLICK: 'page.sendEventForClick',
    USER_DATA_PROVIDED: 'user_data_provided',
    QUOTE_DETAIL_VIEWED: 'quote_detail_viewed'
};

export const EVENT_PAGE_PAYLOAD_CONFIG = {
    page: {
        user: {
            v: '1.1',
            userId: string,
            emails: array,
            regDate: number,
            environment: string,
            notifications: string,
            accountId: string,
            accountName: string,
            accountPaymentType: string,
            authentication: string,
            businessSize: string,
            corplinkedAccounts: string,
            isEmployee: string,
            jobTitle: string,
            salesRegion: string,
            sbmId: string,
            sbuId: string,
            sbaId: string,
            sbsId: string
        },
        elementClick: {
            v: '1.1',
            type: string,
            action: string,
            style: string,
            text: string,
            placement: string,
            modal: string,
            selectMethod: string,
            sunbeltLocation: string
        },
        content: {
            v: '1.1',
            post: object,
            listings: object,
            posts: object
        },
        userError: {
            v: '1.1',
            errorType: string,
            errorLocation: string,
            errorMessage: array
        },
        error: {
            event: VARIABLE_CONFIG.ERROR.error,
            errorType: string,
            errorMessage: string
        },
        sendEventForClick: {
            event: string,
            ecommerce: string,
            eventCategory: string,
            eventAction: string,
            eventLabel: string
        }
    }
};

export const PAGE_PAYLOAD_FUNC = {
    payloadForPageElementClick: (
        type,
        action,
        style,
        text,
        placement,
        modal,
        selectMethod,
        sunbeltLocation,
        callback
    ) => {
        let payload = {
            ...EVENT_PAGE_PAYLOAD_CONFIG.page.elementClick,
            type: type,
            action: action,
            style: style,
            text: text,
            placement: placement,
            modal: modal,
            selectMethod: selectMethod,
            sunbeltLocation: sunbeltLocation
        };
        callback(EVENT_PAGE_NAMES_CONFIG.PAGE_ELEMENT_CLICK, payload);
    },
    payloadForPageContent: (post, listings, posts, callback) => {
        let payload = {
            ...EVENT_PAGE_PAYLOAD_CONFIG.page.content,
            post: post,
            listings: listings,
            posts: posts
        };
        callback(EVENT_PAGE_NAMES_CONFIG.PAGE_CONTENT, payload);
    },
    payloadForPageUserError: (errorType, errorLocation, errorMessage, callback) => {
        let payload = {
            ...EVENT_PAGE_PAYLOAD_CONFIG.page.userError,
            errorType: errorType,
            errorLocation: errorLocation,
            errorMessage: errorMessage
        };
        callback(EVENT_PAGE_NAMES_CONFIG.PAGE_USER_ERROR, payload);
    },
    payloadForError: (errorType, errorMessage, callback) => {
        let payload = {
            ...EVENT_PAGE_PAYLOAD_CONFIG.page.error,
            errorType: errorType,
            errorMessage: errorMessage
        };
        callback(payload);
    },
    payloadForSendEventForClick: (event, ecommerce, eventCategory, eventAction, eventLabel, callback) => {
        let payload = {
            ...EVENT_PAGE_PAYLOAD_CONFIG.page.sendEventForClick,
            event: event,
            ecommerce: ecommerce,
            eventCategory: eventCategory,
            eventAction: eventAction,
            eventLabel: eventLabel
        };
        callback(payload);
    }
};
