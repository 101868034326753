import './cascadeSlider.js';
(function () {
    try {
        var productspotlights = document?.querySelectorAll('.productspotlight__wrap');

        function initializeSection(section) {
            try {
                const mobileTabDropdown = section?.querySelector('.productspotlight__mobilebtn');
                const productspotlightTabsWrapper = section?.querySelector('.productspotlight__tabs');
                const productspotlightTabs = section?.querySelectorAll('.productspotlight__tab');
                const tabContents = section?.querySelectorAll('.productspotlight__content');
                const sliders = section?.querySelectorAll('.productspotlight__carousel');

                const mediaQuery = window?.matchMedia('(max-width: 639.98px)');

                mobileTabDropdown?.addEventListener('click', function () {
                    try {
                        productspotlightTabsWrapper?.classList?.add('productspotlight__tabs--show');
                    } catch (error) {
                        console.error('Error handling mobile tab dropdown click:', error);
                    }
                });

                // Function to show a tab and hide others
                function showTab(tabId, tabName) {
                    try {
                        tabContents?.forEach(function (content) {
                            content?.classList?.remove('productspotlight__content--show');
                        });
                        const activeTab = section?.querySelector(`#${tabId}`);
                        if (activeTab) {
                            activeTab?.classList?.add('productspotlight__content--show');
                            const slider = activeTab?.querySelector('.productspotlight__carousel');

                            function handleMediaTabSliderRefresh(mediaQuery) {
                                if (mediaQuery?.matches) {
                                    $(slider)?.slick('refresh'); // Refresh the slick slider
                                } else {
                                    if ($(slider).hasClass('slick-initialized')) {
                                        $(slider)?.slick('unslick'); // Unslick if screen width is more than 640px
                                    }
                                }
                            }

                            if (slider) {
                                handleMediaTabSliderRefresh(mediaQuery);

                                mediaQuery?.addEventListener('change', function () {
                                    handleMediaTabSliderRefresh(mediaQuery);
                                });
                            }
                        }
                        updateTabName(tabName);
                    } catch (error) {
                        console.error('Error showing tab:', error);
                    }
                }

                // Function to update the active tab name
                function updateTabName(tabName) {
                    try {
                        mobileTabDropdown.querySelector('span').textContent = tabName;
                    } catch (error) {
                        console.error('Error updating tab name:', error);
                    }
                }

                // Function to handle slider events
                function handleSliderEvents(slider) {
                    try {
                        $(slider)?.on('init', function (event, slick, currentSlide) {
                            updateSliderData(
                                slick?.$slides[slick?.currentSlide],
                                slider.closest('.productspotlight__content')
                            );
                            updateButtons(
                                parseInt(slick?.$slides[slick?.currentSlide]?.dataset?.productSku),
                                slick.slideCount,
                                slider.closest('.productspotlight__content')
                            );
                        });

                        $(slider)?.slick({
                            arrows: true,
                            infinite: true,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            adaptiveHeight: true,
                            prevArrow: $(slider)
                                .closest('.productspotlight__content')
                                .find('.productspotlight__action-prev'),
                            nextArrow: $(slider)
                                .closest('.productspotlight__content')
                                .find('.productspotlight__action-next')
                        });

                        $(slider)?.on('afterChange', function (event, slick, currentSlide) {
                            updateSliderData(
                                slick?.$slides[slick?.currentSlide],
                                slider.closest('.productspotlight__content')
                            );
                            updateButtons(
                                parseInt(slick?.$slides[slick?.currentSlide]?.dataset?.productSku),
                                slick.slideCount,
                                slider.closest('.productspotlight__content')
                            );
                        });
                    } catch (error) {
                        console.error('Error handling slider events:', error);
                    }
                }

                // Function to update slider data in the div
                function updateSliderData(slide, tabContent) {
                    try {
                        const dataset = slide?.dataset;
                        const activeSliderData = tabContent.querySelector('.productspotlight__info');
                        activeSliderData.innerHTML = `<div>
                            <h3 class="eyebrow productspotlight__info-eyebrow">${dataset?.catlabel || ''} ${
                            dataset?.productSku || ''
                        }</h3>
                            <h4 class="productspotlight__info-title">${
                                dataset?.productname?.replace(/\s+/g, ' ') || ''
                            }</h4>
                        </div>`;
                    } catch (error) {
                        console.error('Error updating slider data:', error);
                    }
                }

                // Function to update buttons based on the active slide number
                function updateButtons(currentSlide, totalSlides, tabContent) {
                  
                    try {
                    const buttons = tabContent.querySelectorAll('.productspotlight__action');
                    buttons.forEach((button, index) => {
                        const { productSku } = button?.dataset;
                        button.style.display = currentSlide === parseInt(productSku) ? 'block' : 'none'; // Show the button for the current slide
                    });
                    } catch (error) {
                        console.error('Error updating buttons:', error);
                    }
                }

                function handleLargeMedia(slider) {
                    try {
                        // Initialize Cascade Slider for larger screens
                        var $parentWrapper = slider.closest('.productspotlight__carousel-wrap');
                        var prevButton = $($parentWrapper)?.find('.productspotlight__action-prev');
                        var nextButton = $($parentWrapper)?.find('.productspotlight__action-next');

                        $($parentWrapper)?.cascadeSlider({
                            itemClass: 'productspotlight__carousel-item'
                        });

                        let currentSKU = parseInt($parentWrapper?.querySelector('.now')?.dataset.productSku);
                        updateSliderData(
                            $parentWrapper?.querySelector('.now'),
                            slider.closest('.productspotlight__content')
                        );
                        updateButtons(currentSKU, '', slider.closest('.productspotlight__content'));

                        // Event listeners for custom buttons
                        $(prevButton).on('click', function () {
                            try {
                                let currentSKU = parseInt($parentWrapper?.querySelector('.now')?.dataset.productSku);
                                updateSliderData(
                                    $parentWrapper?.querySelector('.now'),
                                    slider.closest('.productspotlight__content')
                                );
                                updateButtons(currentSKU, '', slider.closest('.productspotlight__content'));
                            } catch (error) {
                                console.error('Error handling previous button click:', error);
                            }
                        });

                        $(nextButton).on('click', function () {
                            try {
                                let currentSKU = parseInt($parentWrapper?.querySelector('.now')?.dataset.productSku);
                                updateSliderData(
                                    $parentWrapper?.querySelector('.now'),
                                    slider.closest('.productspotlight__content')
                                );
                                updateButtons(currentSKU, '', slider.closest('.productspotlight__content'));
                            } catch (error) {
                                console.error('Error handling next button click:', error);
                            }
                        });
                    } catch (error) {
                        console.error('Error handling large media:', error);
                    }
                }

                sliders.forEach(function (slider) {
                    try {
                        function handleSliders(mediaQuery) {
                            try {
                                if (mediaQuery.matches) {
                                    // Initialize Slick Slider for mobile screens
                                    handleSliderEvents(slider);
                                } else {
                                    // Initialize Cascade Slider for larger screens
                                    handleLargeMedia(slider);
                                }
                            } catch (error) {
                                console.error('Error handling sliders:', error);
                            }
                        }

                        handleSliders(mediaQuery);

                        mediaQuery?.addEventListener('change', function () {
                            handleSliders(mediaQuery);
                        });
                    } catch (error) {
                        console.error('Error initializing sliders:', error);
                    }
                });

                productspotlightTabs?.forEach(function (tab) {
                    try {
                        tab.addEventListener('click', function () {
                            try {
                                const tabId = tab.dataset.tab;
                                const tabName = tab.textContent;
                                showTab(tabId, tabName);
                                productspotlightTabs.forEach(function (content) {
                                    content.classList.remove('productspotlight__tab--active');
                                });
                                tab.classList.add('productspotlight__tab--active');
                                productspotlightTabsWrapper.classList.remove('productspotlight__tabs--show');
                            } catch (error) {
                                console.error('Error handling tab click:', error);
                            }
                        });
                    } catch (error) {
                        console.error('Error initializing tab click events:', error);
                    }
                });

                // Show the first tab by default
                if (productspotlightTabs.length > 0) {
                    try {
                        showTab(
                            productspotlightTabs[0]?.dataset.tab,
                            productspotlightTabs[0]?.textContent,
                            productspotlightTabs[0]?.classList?.add('productspotlight__tab--active')
                        );
                    } catch (error) {
                        console.error('Error showing default tab:', error);
                    }
                }
            } catch (error) {
                console.error('Error initializing section:', error);
            }
        }

        productspotlights?.forEach(function (productspotlight) {
            try {
                initializeSection(productspotlight);
            } catch (error) {
                console.error('Error initializing product spotlight:', error);
            }
        });
    } catch (error) {
        console.error('Error in main IIFE:', error);
    }
})();
