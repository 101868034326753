import { useEffect, useCallback } from 'react';
import { useApolloClient } from '@apollo/client';
import { cookieValue } from './cookieUtils';
import { AUTHORITY_TYPE } from '../../components/global/constants';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';

export const useEventListener = (target, type, listener, ...rest) => {
    useEffect(() => {
        target.addEventListener(type, listener, ...rest);

        // return a callback, which is called on unmount
        return () => {
            target.removeEventListener(type, listener, ...rest);
        };
    }, [listener, rest, target, type]);
};

export const useCookieValue = cookieName => {
    if (!cookieName || cookieName.length === 0) {
        return '';
    }
    const value = cookieValue(cookieName);
    const setCookieValue = (value, age, isSession) => {
        // let domainNameQASunbelt = 'qa-www.sunbeltrentals.com';
        // let domainNameDevSunbelt = 'dev-www.sunbeltrentals.com';
        // let domainNameLocal = 'localhost';
        const cookieSettingSunbelt = !isSession
            ? `path=/; Max-Age=${age !== undefined ? age : 3600 * 24}; SameSite=None; Secure`
            : `path=/; SameSite=None; Secure`;
        // const cookieSettingDevSunbelt = `path=/; Max-Age=${age !== undefined ? age : 3600 * 24 * 30}`;
        // const cookieSettingLocal = `path=/; Max-Age=${age !== undefined ? age : 3600 * 24 * 30}`;
        document.cookie = `${cookieName}=${value};${cookieSettingSunbelt}`;
    };

    return [value, setCookieValue];
};

/**
 * @param {'accesstoken' | 'refreshtoken'} cookieName - The name of the cookie to retrieve the token i.e accesstoken or refreshtoken.
 *
 * This method is specificially to get access and refresh token.
 * For older punchout flow the token values will be returned from access_token and refresh_token cookies.
 * For P2P and DOTCOM it will return token from accesstoken and refreshtoken.
 */
export const useAuthorityToken = cookieName => {
    let localCookieName = cookieName;
    const authorityType = useCheckAuthorityType();
    if (!localCookieName || localCookieName.length === 0) {
        return '';
    }

    switch (authorityType) {
        case AUTHORITY_TYPE.PUNCHOUT:
            if (localCookieName === 'accesstoken') {
                localCookieName = 'access_token';
            } else if (localCookieName === 'refreshtoken') {
                localCookieName = 'refresh_token';
            }
            break;

        default:
            break;
    }

    return useCookieValue(localCookieName);
};

/**
 * This hook is taken from the Peregrine library.
 * We don't use it because upgrading to the peregrine library that exports it would mean bringing in some dependencies we don't need (i.e. Redux)
 *
 * @param {DocumentNode} query - parsed GraphQL operation description
 *
 * @returns {Function} callback that runs the query and returns a Promise
 */
export const useAwaitQuery = query => {
    const apolloClient = useApolloClient();

    return useCallback(
        options => {
            return apolloClient.query({
                ...options,
                query
            });
        },
        [apolloClient, query]
    );
};

/**
 * This hook makes the query parameters of the URL available.
 */
export const useQueryParams = () => {
    // Better to use useLocation from react router here, but this doesn't work because of dependency mess up.
    return new URLSearchParams(window.location.search);
};
