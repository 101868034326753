import { array, bool, func } from 'prop-types';
import React from 'react';
import LoadingIndicator from '../../../../aem-core-components/components/LoadingIndicator';
import classes from './projectListing.css';

const Listing = props => {
    const { displaySearchResults, projectList, isProjectsLoading } = props;

    return (
        <>
            {isProjectsLoading ? (
                <div className={classes.jobsitesLoaderMinicart}>
                    <LoadingIndicator />
                </div>
            ) : (
                projectList?.length > 0 && (
                    <div className={`${classes.searchRoot} ${classes.searchRootMargin}`} role="radiogroup">
                        {projectList?.map(data => (
                            <React.Fragment key={data?.rmJobsiteId}>{displaySearchResults(data)}</React.Fragment>
                        ))}
                    </div>
                )
            )}
        </>
    );
};

Listing.propTypes = {
    displaySearchResults: func,
    projectList: array,
    isProjectsLoading: bool
};

Listing.defaultProps = {
    displaySearchResults: () => {},
    projectList: [],
    isProjectsLoading: false
};

export default React.memo(Listing);
