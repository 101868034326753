import { useFilterState } from '../filterContext';
import { usePageType } from '../../../hooks/usePageType';
import useAnalytics from '../../../hooks/useAnalytics';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { logError } from '../../global/utils/logger';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { SET_IS_FORM_STARTED_EVENT_TRIGGERED } from '../constants';

export const useCapAnalytics = () => {
    const [{ viewCart, isFormStartedEventTriggered }, dispatch] = useFilterState();
    const {
        sendEventsForLocationFormSubmit,
        sendEventsForFormCompleted,
        sendEventsForDatePickerFormViewed,
        sendEventsForDatePickerFormInteraction,
        sendEventsForNoStoreNearby
    } = useAnalyticsContext();
    const [
        {
            getEcommerceData,
            payloadEcommerceLocationActionSubmitted,
            payloadEcommerceLocationActionAnalytics,
            payloadEcommerceActionAnalytics,
            payloadEcommerceNoStoreNearby,
            sendAnalyticsDatePickerModalEvents
        }
    ] = useAnalytics();
    const pageType = usePageType();
    const isHomePage = pageType === VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE;
    const pageNameForAnalytics = isHomePage
        ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_HERO
        : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_HEADER;

    const onLoadPageTypeEventAnalytics = () => {
        try {
            sendEventsForFormCompleted(EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_VIEWED, pageNameForAnalytics, {
                ...payloadEcommerceActionAnalytics(false),
                ecommerce: getEcommerceData()
            });
        } catch (error) {
            logError(error, false, 'onLoadPageTypeEventAnalytics', []);
        }
    };

    const formInteractionStartedEventAnalytics = formFieldName => {
        try {
            if (!isFormStartedEventTriggered) {
                sendEventsForDatePickerFormViewed(
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_LOCATION_STARTED,
                    pageNameForAnalytics,
                    formFieldName,
                    payloadEcommerceLocationActionAnalytics(false)
                );
                dispatch({ type: SET_IS_FORM_STARTED_EVENT_TRIGGERED, isFormStartedEventTriggered: true });
            }
        } catch (error) {
            logError(error, false, 'formInteractionStartedEventAnalytics', [formFieldName]);
        }
    };

    const triggerFormSubmitEvent = localLocation => {
        try {
            const buttonName = isHomePage
                ? EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_START_RENTING
                : EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CAP_APPLY;
            sendEventsForLocationFormSubmit(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_EVENT,
                pageNameForAnalytics,
                buttonName,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FIELD_FORM_SUBMITTED_LINK_TYPE,
                { ...payloadEcommerceLocationActionSubmitted(localLocation), ecommerce: getEcommerceData() }
            );
        } catch (error) {
            logError(error, false, 'triggerFormSubmitEvent', [localLocation]);
        }
    };

    const triggerFormInteractedEvent = type => {
        try {
            sendEventsForDatePickerFormViewed(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_INTERACTED,
                pageNameForAnalytics,
                type,
                payloadEcommerceLocationActionAnalytics(false)
            );
        } catch (error) {
            logError(error, false, 'triggerFormInteractedEvent', [type]);
        }
    };

    const sendDeliveryInteractedEvent = (method, value) => {
        try {
            sendEventsForDatePickerFormInteraction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_INTERACTED,
                pageNameForAnalytics,
                method,
                value,
                payloadEcommerceLocationActionAnalytics(false)
            );
        } catch (error) {
            logError(error, false, 'sendDeliveryInteractedEvent', [method, value]);
        }
    };

    const triggerFormCompleteEvent = localLocation => {
        try {
            sendEventsForFormCompleted(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_FORM_COMPLETED,
                pageNameForAnalytics,
                { ...payloadEcommerceLocationActionSubmitted(localLocation), ecommerce: getEcommerceData() }
            );
        } catch (error) {
            logError(error, false, 'triggerFormCompleteEvent', [localLocation]);
        }
    };

    const getLocalLocation = (isJobsiteSelected, jobsiteDetails) => {
        if (isJobsiteSelected) {
            return {
                address1: jobsiteDetails?.projectAddress1,
                address2: jobsiteDetails?.projectAddress2,
                city: jobsiteDetails?.selectedProjectCity,
                state: jobsiteDetails?.selectedProjectState,
                zip: jobsiteDetails?.selectedProjectZip,
                projectName: jobsiteDetails?.projectName
            };
        } else {
            return { ...viewCart };
        }
    };

    const onSubmitEventHandler = (isJobsiteSelected, jobsiteDetails) => {
        try {
            const localLocation = getLocalLocation(isJobsiteSelected, jobsiteDetails);
            triggerFormSubmitEvent(localLocation);
            triggerFormCompleteEvent(localLocation);
        } catch (error) {
            logError(error, false, 'onSubmitEventHandler', [isJobsiteSelected, jobsiteDetails]);
        }
    };

    const sendAnalyticsForNoStoreNearBy = (addressDetails, addressText, jobsiteDetails, isJobsiteSelected) => {
        try {
            const selectedAddress = isJobsiteSelected ? jobsiteDetails?.projectAddress1 : addressText;
            sendEventsForNoStoreNearby(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_NO_STORE_NEARBY,
                payloadEcommerceNoStoreNearby(addressDetails, selectedAddress)
            );
        } catch (error) {
            logError(error, false, 'sendAnalyticsForNoStoreNearBy', [
                addressDetails,
                addressText,
                jobsiteDetails,
                isJobsiteSelected
            ]);
        }
    };

    const sendClickAnalyticsEvent = (storeDetailData, storesData, isChooseStore) => {
        const eventLabel = isChooseStore ? VARIABLE_CONFIG.EVENT_LABEL.CHOOSE_STORE : VARIABLE_CONFIG.EVENT_LABEL.BACK;
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0]?.pc} ${eventLabel}`
            );
        } catch (error) {
            logError(error, false, 'sendClickAnalyticEvents', [storeDetailData, storesData]);
        }
    };

    const handleDateEventsInteraction = (date, fieldName) => {
        try {
            sendEventsForDatePickerFormInteraction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_INTERACTION,
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
                fieldName,
                date,
                payloadEcommerceActionAnalytics(false)
            );
        } catch (error) {
            logError(error, false, 'handleDateEventsInteraction', [date, fieldName])
        }
    };

    const sendDatePickerModalEvents = (eventName, startDate, endDate) => {
        sendAnalyticsDatePickerModalEvents(eventName, startDate, endDate);
    };

    return {
        onLoadPageTypeEventAnalytics,
        formInteractionStartedEventAnalytics,
        triggerFormInteractedEvent,
        sendDeliveryInteractedEvent,
        sendAnalyticsForNoStoreNearBy,
        onSubmitEventHandler,
        sendClickAnalyticsEvent,
        handleDateEventsInteraction,
        sendDatePickerModalEvents
    };
};
