import { gql } from '@apollo/client';

const ADD_TO_FAVOURITES = gql`
    mutation addFavorites($accountNumber: Int!, $sku: String!) {
        addFavorites(accountNumber: $accountNumber, sku: $sku) {
            status
            message
            favoriteID
        }
    }
`;

export default ADD_TO_FAVOURITES;
