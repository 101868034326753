window.addEventListener('load', event => {
    var benefitsComponent = document.querySelectorAll('.benefits-component');
    var benefitDescriptionArr = [];
    var benefitsCardTitleArr = [];
    var benefitDescriptionMaxHeight = 0;
    var benefitsCardTitleMaxHeight = 0;

    try {
        benefitsComponent?.forEach(benefitDescription => {
            var benefitDescriptionp = benefitDescription.querySelector('.benefits-card-description');
            var benefitsCardTitle = benefitDescription.querySelector('.benefits-card-wrap');
            benefitDescriptionArr.push(benefitDescriptionp.clientHeight);
            benefitsCardTitleArr.push(benefitsCardTitle.clientHeight);
        });
        benefitDescriptionMaxHeight = Math.max(...benefitDescriptionArr);
        benefitsCardTitleMaxHeight = Math.max(...benefitsCardTitleArr);
        $('.benefits-card-wrap').height(benefitsCardTitleMaxHeight);
        $('.benefits-card-description').height(benefitDescriptionMaxHeight);
    } catch (error) {
        console.error('An error occurred while initializing the Jquery:', error);
    }
});
