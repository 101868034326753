import React, { useEffect, useState, memo } from 'react';
import { useIntl } from 'react-intl';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { usePageType } from '../../../hooks/usePageType';
import useAnalytics from '../../../hooks/useAnalytics';
import AutoCompleteDropdown from '../../global/atoms/autoComplete';
import config from '../../App/config';
import { ENTER_KEY } from '../../../constants/screenConstants';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import ArrowRight from '../../../resources/images/arrow-ios-right.svg';
import './typeahead.scss';
import { buildContextBasedOnCompanyId } from '../Engine';
import { logError } from '../../global/utils/logger';
import { ENV_CONFIG } from '../../../constants/envConfig';

function Typeahead(props) {
    const {
        myCustomStandaloneSearchBox,
        engine,
        source,
        searchVarient = 'headersearch',
        tagetElement = ENV_CONFIG.SEARCH_COVEO
    } = props;
    const [searchState, setSearchState] = useState(myCustomStandaloneSearchBox?.state);
    const [isOpen, setIsOpen] = useState(true);
    const [groupResultRange, setGroupResultRange] = useState([]);
    const intl = useIntl();

    const {
        productslabel,
        productscount,
        categorieslabel,
        categoriescount,
        searchtermslabel,
        searchtermscount,
        relatedinfolabel,
        relatedinfocount,
        searchplaceholderlabel,
        searchctalabel
    } = tagetElement || {};

    const { sendEventsForClick } = useAnalyticsContext();
    const pageType = usePageType();
    const [{ handleAnalyticsOnFocus }] = useAnalytics();
    const companyID = localStorage.getItem('companyID') || 1;

    useEffect(() => {
        setGroupResultRange([
            { group: productslabel || 'Products', key: 'ProductSuggestions', range: productscount || 4 },
            { group: categorieslabel || 'Categories', key: 'L2Categories', range: categoriescount || 3 },
            { group: searchtermslabel || 'Search Terms', key: 'default', range: searchtermscount || 3 },
            {
                group: relatedinfolabel || 'Related Info',
                key: 'RelatedContentSuggestions',
                range: relatedinfocount || 3
            }
        ]);
    }, [
        productslabel,
        productscount,
        categorieslabel,
        categoriescount,
        searchtermslabel,
        searchtermscount,
        relatedinfolabel,
        relatedinfocount
    ]);
    const getCategory = itemGroup => {
        let catGorup = groupResultRange?.filter(res => {
            if (res?.key === itemGroup) return res?.group;
        });
        return catGorup[0]?.group;
    };
    const updateState = () => {
        setSearchState(myCustomStandaloneSearchBox?.state);
        // console.dir('myCustomStandaloneSearchBox?.state', myCustomStandaloneSearchBox?.state);
    };
    useEffect(() => {
        //Register our handler.
        myCustomStandaloneSearchBox.subscribe(updateState);
    }, []);
    useEffect(() => {
        if (searchState && window.location.pathname.includes('search')) {
            const params = new URLSearchParams(window.location.search);
            const search = params.get('q');
            if (search) {
                const state = { ...searchState };
                state.value = search;
                setSearchState(state);
            }
        }
    }, []);
    useEffect(() => {
        const handleClickOutside = e => {
            if (!e.target.matches('.formContainer')) {
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
    }, [isOpen]);

    useEffect(() => {
        if (companyID) {
            buildContextBasedOnCompanyId([engine]);
        }
    }, [companyID]);

    const handleSearchSubmit = event => {
        event.preventDefault();
    };

    const handleItemClick = async item => {
        //var searchTerm = document.getElementsByClassName("autocomplete__input")[0].firstChild.value;
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.SEARCH_BAR,
                `${VARIABLE_CONFIG.EVENT_ACTION.SELECT} ${getCategory(item?.group)}`,
                `${item?.rawValue} :: ${pageType}`
            );
        } catch (error) {
            logError(error, false, 'handleItemClick');
        }
        myCustomStandaloneSearchBox.updateText(item?.rawValue);
        //log click analytics
        // loadClickAnalyticsActions(productListingEngine).logDocumentOpen(item)
        if (item?.group.toLowerCase().includes('product')) {
            window.location.href = item?.result?.raw?.ec_product_url;
        } else if (item?.group.toLowerCase().includes('default')) {
            window.location.href = `${config?.pagePaths?.searchPage}?q=${item?.rawValue}`;
        } else if (item?.group.toLowerCase().includes('related')) {
            window.location.href = item?.result?.ClickUri;
        } else if (item?.group.toLowerCase().includes('categories')) {
            window.location.href = item?.path;
        }
        setIsOpen(false);
    };
    const onEnterKeyPressed = (e, item) => {
        if (e.key === ENTER_KEY) {
            handleItemClick(item);
        }
    };

    const renderGroup = group => {
        if (groupResultRange) {
            const currentGroup = groupResultRange.filter(result =>
                group?.toLowerCase().includes(result?.key.toLowerCase())
            );
            if (currentGroup.length > 0) return currentGroup[0].group;
            else return '';
        }
    };

    const getSuggestions = () => {
        if (searchState?.value) {
            const suggestion = searchState?.groups?.filter(
                (item, index) => searchState?.groups.indexOf(item) === index
            );
            suggestion.forEach(item => {
                if (item.name.toLowerCase().includes('product')) {
                    item.pos = 1;
                } else if (item.name.toLowerCase().includes('categories')) {
                    item.pos = 2;
                } else if (item.name.toLowerCase().includes('default')) {
                    item.pos = 3;
                } else if (item.name.toLowerCase().includes('related')) {
                    item.pos = 4;
                }
            });
            suggestion.sort((group1, group2) => group1.pos - group2.pos);
            return suggestion.map(item => item.name);
        } else return [];
    };
    const renderSuggestion = (group, index) => {
        var suggestions = searchState?.newSuggestions?.filter(item => item.group === group);
        if (groupResultRange) {
            const currentGroup = groupResultRange.filter(result =>
                group.toLowerCase().includes(result?.key.toLowerCase())
            );
            if (currentGroup.length > 0) suggestions.splice(currentGroup[0]?.range, suggestions?.length - 1);
        }
        return suggestions?.length > 0 && isOpen ? ( // toggle here
            <React.Fragment key={`${group}${index}`}>
                <li className="borderBottom">
                    <span className="groupHeader">{renderGroup(group)}</span>
                    {suggestions.map((item, index) => (
                        <div
                            tabIndex={0}
                            id={item?.highlightedValue}
                            aria-label={item?.rawValue}
                            className="listItems"
                            onClick={() => handleItemClick(item)}
                            onKeyPress={e => onEnterKeyPressed(e, item)}
                            key={`${item?.id}${index}`}>
                            <div
                                id={item.highlightedValue}
                                aria-selected="false"
                                role="option"
                                dangerouslySetInnerHTML={{ __html: item.highlightedValue }}></div>
                            <span>
                                <ArrowRight />
                            </span>
                        </div>
                    ))}
                </li>
            </React.Fragment>
        ) : null;
    };

    const customOnSearchClick = value => {
        window.location.href = `${config?.pagePaths?.searchPage}?q=${value}`;
    };

    const renderBannerSearch = () => {
        return (
            <form role="search" className={`bannerSearchContainer`} onSubmit={handleSearchSubmit}>
                <span className="icon icon-search-icon bannerSearchContainer__icon" aria-hidden="true"></span>
                <AutoCompleteDropdown
                    placeholderText={
                        searchplaceholderlabel || intl.formatMessage({ id: 'location:search-placeholder' })
                    }
                    showResetBtn={true}
                    onChange={e => {
                        myCustomStandaloneSearchBox.updateText(e.target.value);
                        setIsOpen(true);
                    }}
                    onSelect={selectedText => {
                        myCustomStandaloneSearchBox.updateText(selectedText);
                        myCustomStandaloneSearchBox.submit();
                        setIsOpen(false);
                    }}
                    onReset={() => {
                        myCustomStandaloneSearchBox.updateText('');
                        setIsOpen(true);
                    }}
                    showSearchIcon={false}
                    isDropDownOpen={isOpen}
                    renderSuggestion={renderSuggestion}
                    suggestions={getSuggestions()}
                    value={searchState.value}
                    customOnSearchClick={customOnSearchClick}
                    autoCompleteInputClass={'cmp-Field__field__input'}
                    autoCompleteWrapperClass={'header-autocomplete'}
                    source={VARIABLE_CONFIG.PLACEMENT.HEADER}
                    handleEcomLocationAnalyticsOnFocus={() => handleAnalyticsOnFocus(source)}
                    explicitSearchCTAText={searchctalabel}
                    enableExplicitSearchCTA={true}
                />
            </form>
        );
    };

    const renderHeaderSearch = () => {
        return (
            <form role="search" className={`formContainer`} onSubmit={handleSearchSubmit}>
                <AutoCompleteDropdown
                    placeholderText={
                        searchplaceholderlabel || intl.formatMessage({ id: 'location:search-placeholder' })
                    }
                    showResetBtn={true}
                    onChange={e => {
                        myCustomStandaloneSearchBox.updateText(e.target.value);
                        setIsOpen(true);
                    }}
                    onSelect={selectedText => {
                        myCustomStandaloneSearchBox.updateText(selectedText);
                        myCustomStandaloneSearchBox.submit();
                        setIsOpen(false);
                    }}
                    onReset={() => {
                        myCustomStandaloneSearchBox.updateText('');
                        setIsOpen(true);
                    }}
                    showSearchIcon={true}
                    isDropDownOpen={isOpen}
                    renderSuggestion={renderSuggestion}
                    suggestions={getSuggestions()}
                    value={searchState.value}
                    customOnSearchClick={customOnSearchClick}
                    autoCompleteInputClass={'cmp-Field__field__input'}
                    autoCompleteWrapperClass={'header-autocomplete'}
                    source={VARIABLE_CONFIG.PLACEMENT.HEADER}
                    handleEcomLocationAnalyticsOnFocus={() => handleAnalyticsOnFocus(source)}
                />
            </form>
        );
    };

    const renderTypeHeadType = searchVarient => {
        switch (searchVarient) {
            case 'bannerSearch':
                return renderBannerSearch();
            default:
                return renderHeaderSearch();
        }
    };

    return <>{renderTypeHeadType(searchVarient)}</>;
}

export default memo(Typeahead);
