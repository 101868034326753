import { func, string } from 'prop-types';
import React from 'react';
import LocationPin20 from '../../../../../../resources/images/locationpin20.svg';
import Button from '../../../../atoms/button/button';
import { productTileDataLocator } from '../../../addToCartTile/dataLocators';

const SelectRentalLocationDates = ({ emptyDetailsText, openEmptyLocationDateModal }) => {
    return (
        <Button
            className="producttile__rentaldates"
            buttonAriaLabel={emptyDetailsText}
            onClick={openEmptyLocationDateModal}
            data-testid={productTileDataLocator.productTile_add_rental_details}>
            <LocationPin20 tabIndex={'-1'} aria-hidden={true} className="producttile__rentaldates-pin" />
            <span>{emptyDetailsText}</span>
        </Button>
    );
};
export default React.memo(SelectRentalLocationDates);

SelectRentalLocationDates.defaultProps = {
    emptyDetailsText: '',
    openEmptyLocationDateModal: () => {}
};

SelectRentalLocationDates.propTypes = {
    emptyDetailsText: string,
    openEmptyLocationDateModal: func
};
