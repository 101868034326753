export const SET_QUOTE_LIST = 'SET_QUOTE_LIST';
export const SET_UPDATED_QUOTE_LIST = 'SET_UPDATED_QUOTE_LIST';
export const SET_QUOTES_ERROR = 'SET_QUOTES_ERROR';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';
export const SET_JOBSITE_LIST = 'SET_JOBSITE_LIST';
export const SET_ACCOUNTS_FILTER = 'SET_ACCOUNTS_FILTER';
export const SET_ACCOUNTS_SELECTION = 'SET_ACCOUNTS_SELECTION';
export const SET_JOBSITES_FILTER = 'SET_JOBSITES_FILTER';
export const SET_JOBSITES_SELECTION = 'SET_JOBSITES_SELECTION';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_FULFILLMENT_TYPE_FILTER = 'SET_FULFILLMENT_TYPE_FILTER';
export const SET_RENTAL_START_DATE = 'SET_RENTAL_START_DATE';
export const SET_ESTIMATED_RENTAL_DATE = 'SET_ESTIMATED_RENTAL_DATE';
export const SET_ACCOUNT_NUMBER = 'SET_ACCOUNT_NUMBER';
export const SET_QUOTES_LIMIT = 'SET_QUOTES_LIMIT';
export const SET_CLEAR_FILTER = 'SET_CLEAR_FILTER';
export const SET_ORDERED_BY_LIST = 'SET_ORDERED_BY_LIST';
export const SET_ORDERED_BY_FILTER = 'SET_ORDEREDBY_FILTER';
export const SET_ARE_FILTERS_RESET = 'SET_ARE_FILTERS_RESET';
export const SET_IS_APPLY_SELECTION_CTA_CLICKED = 'SET_IS_APPLY_SELECTION_CTA_CLICKED';
export const SET_IS_ACCOUNTS_LOADED = 'SET_IS_ACCOUNTS_LOADED';
export const SET_INITIAL_ACCOUNTS_LIST = 'SET_INITIAL_ACCOUNTS_LIST';
export const SET_INITIAL_JOBSITES_LIST = 'SET_INITIAL_JOBSITES_LIST';
export const SET_QUOTE_DETAILS = 'FETCH_QUOTE_DETAILS';
export const SET_IS_TRANSMITTED = 'SET_IS_TRANSMITTED';
export const SET_SELECTED_QUOTE_RATES = 'SET_SELECTED_QUOTE_RATES';
export const SET_IS_REDIRECTED_QUOTE = 'SET_IS_REDIRECTED_QUOTE';
export const SET_TRANSMIT_QUOTES = 'SET_TRANSMIT_QUOTES';
export const SET_QUOTE_SUMMARY = 'SET_QUOTE_SUMMARY';

//create quote action types
export const SET_TRANSMITTED_QUOTE_ID = 'SET_TRANSMITTED_QUOTE_ID';
export const SET_SALESFORCE_QUOTE_ID = 'SET_SALESFORCE_QUOTE_ID';
export const QUOTE_ORDER_ESTIMATES = 'QUOTE_ORDER_ESTIMATES';
export const SET_QUOTE_SEARCH_TERM = 'SET_QUOTE_SEARCH_TERM';
export const QUOTE_ESTIMATES_LOADED = 'QUOTE_ESTIMATES_LOADED';
