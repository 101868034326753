import { logError } from "../global/utils/logger";

export const syncFavoriteProducts = (newProducts, existingFavProducts) => {
    try{
        const updatedFavoriteProducts = [...(existingFavProducts || [])];
        newProducts?.forEach(payloadProduct => {
            const productIndex = updatedFavoriteProducts?.findIndex(product => product?.sku === payloadProduct?.sku);
            // If the product exists in favoriteProducts
            if (productIndex !== -1) {
                // If isFavorite is false in payload, remove the product from favoriteProducts
                if (!payloadProduct?.isFavorite) {
                    updatedFavoriteProducts.splice(productIndex, 1); // Remove product at productIndex
                }
                // If isFavorite is true, keep it (do nothing as it's already in favoriteProducts)
            } else {
                // If the product does not exist in favoriteProducts and isFavorite is true, add it
                if (payloadProduct?.isFavorite) {
                    updatedFavoriteProducts.push(payloadProduct); // Add new product to favoriteProducts
                }
            }
        });
        return updatedFavoriteProducts;
    } catch(error) {
        logError(error, false, 'syncFavoriteProducts');
    }
}