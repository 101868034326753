export const SCREEN_NAME = {
    PDP_SCREEN: 'pdp',
    CHECKOUT_SCREEN: 'checkout'
};

export const MAP_RADIUS = 200;
export const MILES_TO_METER_CONVERSION_FACTOR = 1609.344;
export const MEDIA_TYPE = {
    MOBILE: 'MOBILE',
    TABLET: 'TABLET',
    DESKTOP: 'DESKTOP'
};
export const KEYDOWN_EVENT = 'keydown';
export const KEYUP_EVENT = 'keyup';
export const ESCAPE_KEY = 'Escape';
export const ENTER_KEY = 'Enter';

// page constants
export const HOME_PAGE = 'Home Page';
export const EQUIPMENT_RENTAL = 'equipment-rental';
export const SEARCH_RESULTS_PAGE = 'Search Results Page';
export const SEARCH_RESULTS = 'Search Results';
export const SEARCH = 'search';
export const LOCATION = 'location';
export const CATEGORY_PAGE = 'Category page';
export const CUSTOM_PLP = 'CustomPLP';
export const PDP = 'pdp';
export const PLP = 'plp';
export const CATEGORY_PAGE_TEMPLATE_STRING = 'category-page';
export const CART_PAGE = 'cart';
export const MINICART = 'minicart';
