import React, { useEffect, useState } from 'react';
import { useCheckAuthorityType, useCheckUser } from '../../../../hooks/useCheckUser';
import { useUserContext } from '../../../context/UserContext';
import { useCartState } from '../../../../contexts/cart';
import DelinquentAccountModal from '../DelinquentAccountModal/DelinquentAccountModal';
import AlertCircle from '../../../../resources/images/alert-circle-black.svg';
import './delinquentbanner.scss';
import config from '../../../../components/App/config';
import { SET_SHOW_DELINQUENT_ACCOUNT_MODAL } from '../../../actions/constants';
import { AUTHORITY_TYPE, EMPLOYEE } from '../../../../components/global/constants';
import {
    isAccountBlocked,
    isAccountClosed,
    reloadOnAccountChange
} from '../../../../components/global/utils/commonUtils';
import { isValidString } from '../../../../components/global/utils/logger';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';


const STATUS_TYPE = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    HOLD: 'hold'
};

const DelinquentBanner = () => {
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const notificationTitle = isValidString(document.querySelector('.alert__banner')?.dataset?.title)
        ? document.querySelector('.alert__banner')?.dataset?.title
        : 'There’s an issue with your account';
    const notificationInactiveDescription = isValidString(
        document.querySelector('.alert__banner')?.dataset?.descriptionblocked
    )
        ? document.querySelector('.alert__banner')?.dataset?.descriptionblocked
        : `New ${isP2PUser ? 'Quotes' : 'reservations' } cannot be created. For more information, contact us at: `;
    const notificationPhone = isValidString(document.querySelector('.alert__banner')?.dataset?.phonenumber)
        ? document.querySelector('.alert__banner')?.dataset?.phonenumber
        : '800-508-4756';
    const [type, setType] = useState(STATUS_TYPE.ACTIVE);
    const [{ userAccount, showDelinquentModal }, dispatch] = useCartState();
    const [showDelinqModal, setShowDelinqModal] = useState(showDelinquentModal);
    const [userState, { logoutUser }] = useUserContext();
    const userType = useCheckUser();
    const { userProfile, hasAccountChanged, isProfileLoaded } = userState;

    useEffect(() => {
        if (isProfileLoaded) {
            if (isAccountClosed(userAccount)) {
                setType(STATUS_TYPE.INACTIVE);
            } else {
                setType(STATUS_TYPE.ACTIVE);
            }
        }
    }, [isProfileLoaded, userAccount.accountNumber]);
    useEffect(() => {
        setShowDelinqModal(showDelinquentModal);
    }, [showDelinquentModal]);

    const isMultipleAccounts = () => {
        if (userProfile?.accounts?.length > 1 || userProfile?.type == EMPLOYEE) {
            return true;
        }
        return false;
    };
    const onDelinquentModalClose = () => {
        if (isAccountBlocked(userAccount)) {
            logoutUser();
        }
        if (hasAccountChanged) {
            reloadOnAccountChange(userType);
        }
        dispatch({
            type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
            showDelinquentModal: false
        });
        setShowDelinqModal(false);
    };
    const isCloseIconRequired = () => {
        if (!isMultipleAccounts() || isAccountClosed(userAccount)) {
            return true;
        }
        return false;
    };
    const delinquentBannerClass = {
        hold: 'delinquentbanner--hold',
        inactive: 'delinquentbanner--inactive'
    };
    const renderDelinquentModal = () => {
        if (userType === USER_TYPE.CREDIT && !window.location.href.includes(config.pagePaths.signInHandler())) {
            return (
                <DelinquentAccountModal
                    isOpen={showDelinqModal}
                    showCTA={isMultipleAccounts()}
                    onRequestClose={onDelinquentModalClose}
                    showCloseIcon={isCloseIconRequired()}
                />
            );
        }
        return null;
    };

    const isEveryAccountDelinquent =
        userProfile?.accounts?.length > 1 && userProfile?.accounts?.every(acc => isAccountClosed(acc)); // return true if accounts more than 1 and all of them are inactive staus
    if (isP2PUser && (userProfile?.accounts?.length === 0 || isEveryAccountDelinquent) && isProfileLoaded) {
        return <DelinquentAccountModal isOpen={true} showCTA={false} onRequestClose={() => {}} showCloseIcon={false} />;
    }

    if (type === STATUS_TYPE.INACTIVE) {
        return (
            <>
                {renderDelinquentModal()}
                <section
                    className={`delinquentbanner ${delinquentBannerClass[type]}`}
                    tabIndex={'0'}
                    aria-live="polite">
                    <div className="container delinquentbanner__container">
                        <AlertCircle className="delinquentbanner__icon" tabIndex={'-1'} aria-hidden={true} />
                        <div className="delinquentbanner__content">
                            <strong>{notificationTitle} </strong>{' '}
                            <span dangerouslySetInnerHTML={{ __html: notificationInactiveDescription }} />
                            <a href={`tel:${notificationPhone}`} title="call for help">
                                {notificationPhone}
                            </a>
                        </div>
                    </div>
                </section>
            </>
        );
    } else {
        return renderDelinquentModal();
    }
};

export default React.memo(DelinquentBanner);
