import React, { useEffect, useState } from 'react';
import { bool, func, node, string } from 'prop-types';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { MEDIA_TYPE } from '../../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import ArrowLeftSvg from '../../../../resources/images/arrow-left-black.svg';
import CloseIcon from '../../../../resources/images/close.svg';
import Button from '../../atoms/button/button';
import { AUTHORITY_TYPE } from '../../constants';
import { logError } from '../../utils/logger';
import MobileView from '../mobileView/MobileView';
import SideDrawerModal from '../modals/SideDrawerModal';
import DatePickerInput from './DatePickerInput';
import RangeCalander from './rangeCalander';
import './rangePicker.scss';

const RangePickerOverlay = props => {
    const {
        isOpen,
        handleToggle,
        rangeState,
        setRangeState,
        selectedStartDate,
        selectedEndDate,
        setSelectedEndDate,
        setSelectedStartDate,
        checkIfDayDisabled,
        setStartDateError,
        setEndDateError,
        minDate,
        customOnModalOpen,
        showWarning,
        setShowWarning,
        setIsEndDateEmpty,
        setIsStartDateEarly,
        renderWarningComponent,
        showOnDrawerForTablet,
        hideBackButton,
        endDateLabel,
        pickerOverlayClass,
        startDateLabel,
        handleSelectedDatesOnDone,
        onResetClick,
        handleDateEventsInteraction,
        sendDatePickerModalEvents,
        calendarDateInteraction,
        mediaType,
        isEditView,
        calenderTitle
    } = props;

    const intl = useIntl();
    const authorityType = useCheckAuthorityType();
    const [isResetClicked, setIsResetClicked] = useState(false);
    const [focusedRange, setFocusedRange] = useState([0, 0]);
    const customStyle = { overlay: { background: 'rgba(0,0,0,0.4)' } };
    const isEstimatedEndDateLabel = endDateLabel === intl.formatMessage({ id: 'cart-header:estimated-end-date' });
    const rangepickerStart = document.querySelector('.rangepicker__start');
    const isP2P = AUTHORITY_TYPE.P2P === authorityType;

    useEffect(() => {
        const body = document.querySelector('body');
        const liveChatButton = document.querySelector('.chat-button');
        if (isOpen) {
            body.classList.add('calender-overflow-hidden');
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'true';
                liveChatButton.tabIndex = '-1';
            }
            setFocusedRange([0, 0]);
        } else {
            body.classList.remove('calender-overflow-hidden');
        }
    }, [isOpen]);

    useEffect(() => {
        try {
            if (rangeState[0]?.startDate && calendarDateInteraction && !isResetClicked) {
                handleDateEventsInteraction(
                    rangeState[0]?.startDate?.toString(),
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_START_DATE_FORM_FIELD
                );
            }
        } catch (error) {
            logError(error, false, 'rangeState startDate', [rangeState]);
        }
    }, [rangeState[0]?.startDate]);

    useEffect(() => {
        try {
            if (rangeState[0]?.endDate && calendarDateInteraction && !isResetClicked) {
                handleDateEventsInteraction(
                    rangeState[0]?.endDate?.toString(),
                    EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_END_DATE_FORM_FIELD
                );
            }
        } catch (error) {
            logError(error, false, 'rangeState endDate', [rangeState]);
        }
    }, [rangeState[0]?.endDate]);

    useEffect(() => {
        setRangeState([
            {
                key: 'selection',
                startDate: selectedStartDate ? new Date(selectedStartDate) : '',
                endDate: selectedEndDate ? new Date(selectedEndDate) : '',
                color: '#F2F2F2'
            }
        ]);
    }, [selectedEndDate, selectedStartDate]);

    useEffect(() => {
        if (isOpen) {
            setRangeState([
                {
                    key: 'selection',
                    startDate: selectedStartDate ? new Date(selectedStartDate) : '',
                    endDate: selectedEndDate ? new Date(selectedEndDate) : '',
                    color: '#F2F2F2'
                }
            ]);
            const isDateElapsed = customOnModalOpen();
            if (isDateElapsed) {
                setRangeState([
                    {
                        key: 'selection',
                        startDate: '',
                        endDate: '',
                        color: '#F2F2F2'
                    }
                ]);
            }
        }
    }, [isOpen]);

    const renderRangePicker = () => {
        return (
            <RangeCalander
                setShowWarning={setShowWarning}
                setIsEndDateEmpty={setIsEndDateEmpty}
                setIsStartDateEarly={setIsStartDateEarly}
                minDate={minDate}
                rangeState={rangeState}
                setRangeState={setRangeState}
                focusedRange={focusedRange}
                onFocusedRangeChange={setFocusedRange}
                months={2}
                setIsResetClicked={setIsResetClicked}
                dateDisplayFormat={'M/d/YY'}
                checkIfDayDisabled={checkIfDayDisabled}
                classes={showWarning ? 'calendarwrap__rental-warning' : ''}
            />
        );
    };

    const mobileFooter = () => {
        return (
            <div className="mobile__datepicker__footer">
                <button className="button resetButton rangepicker__reset" onClick={handleResetDate} tabIndex={'0'}>
                    {intl.formatMessage({ id: 'calendar:reset-dates' })}
                </button>
                <button className="button button-primary mobile-done-btn" onClick={handleDoneClick} tabIndex={'0'}>
                    {intl.formatMessage({ id: 'common:done' })}
                </button>
            </div>
        );
    };

    const calenderHeader = () => {
        return (
            <>
                <div className="calendar-header">
                    {hideBackButton ? (
                        <div className="header-with-close-icon">
                            <h5>{intl.formatMessage({ id: 'calendar-header-select-your-rental-dates' })}</h5>
                            <Button
                                tabIndex={'0'}
                                onClick={handleBackBtnClick}
                                customButtonAriaLabel={intl.formatMessage({ id: 'calendar-close' })}>
                                <CloseIcon ariaHidden="true" tabIndex={'-1'} />
                            </Button>
                        </div>
                    ) : (
                        <>
                            <button className="button rangepicker__back" onClick={handleBackBtnClick} tabIndex={'0'}>
                                <ArrowLeftSvg tabIndex={'-1'} aria-hidden={true} />
                                <span className="calendar_header_back_btn_text">
                                    {intl.formatMessage({ id: 'cart:back-btn-text' })}
                                </span>
                            </button>
                            <p className="header__text__select__your__dates">
                                {intl.formatMessage({ id: 'calendar-header-select-your-rental-dates' })}
                            </p>
                        </>
                    )}
                    <div className="rangepicker__header-cal">
                        {rangeState.map((item, index) => (
                            <>
                                <DatePickerInput
                                    isOpen={isOpen}
                                    titleStartDate={`Click here for ${startDateLabel || 'start date'}`}
                                    titleEndDate={`Click here for ${endDateLabel || 'end date'}`}
                                    key={index}
                                    startDate={
                                        item.startDate
                                            ? isNaN(new Date(item.startDate).getDate())
                                                ? ''
                                                : new Date(item.startDate).toLocaleDateString('en-US', {
                                                      month: 'numeric',
                                                      day: 'numeric',
                                                      year: '2-digit'
                                                  })
                                            : ''
                                    }
                                    endDate={
                                        item.endDate
                                            ? isNaN(new Date(item.endDate).getDate())
                                                ? ''
                                                : new Date(item.endDate).toLocaleDateString('en-US', {
                                                      month: 'numeric',
                                                      day: 'numeric',
                                                      year: '2-digit'
                                                  })
                                            : ''
                                    }
                                    customClass={`datePickerWidth ${isEstimatedEndDateLabel && 'estimated-end-date'}`}
                                    isResetClicked={isResetClicked}
                                    isVisibleInModal={false}
                                    showWarning={showWarning}
                                    endDateLabel={endDateLabel}
                                    startDateLabel={startDateLabel}
                                    showDatePlaceholder
                                />
                            </>
                        ))}
                    </div>
                </div>
            </>
        );
    };

    const handleBackBtnClick = () => {
        handleToggle(!isOpen);
        setFocusedRange([0, 0]);
        setIsResetClicked(false);
        sendDatePickerModalEvents(VARIABLE_CONFIG.EVENT.CLOSED);
        if (rangepickerStart) {
            rangepickerStart?.focus();
        }
    };
    const handleResetDate = () => {
        setIsResetClicked(true);
        onResetClick();
        setRangeState([
            {
                key: 'selection',
                startDate: '',
                endDate: '',
                color: '#F2F2F2'
            }
        ]);
        setFocusedRange([0, 0]);
        setEndDateError('');
        setStartDateError('');
        if (mediaType === MEDIA_TYPE.DESKTOP) {
            const prevBtn = document.querySelector('.rdrPprevButton');
            prevBtn.classList.add('hidden_arrow_btn');
            const nextBtn = document.querySelector('.rdrNextButton');
            nextBtn.classList.remove('hidden_arrow_btn');
        }
        setShowWarning(false);
        setIsEndDateEmpty(false);
        sendDatePickerModalEvents(VARIABLE_CONFIG.EVENT.RESET);
    };

    const handleDoneClick = async () => {
        sendDatePickerModalEvents(
            VARIABLE_CONFIG.EVENT.SUBMIT,
            isNaN(new Date(rangeState[0]?.startDate).getDate()) ? '' : rangeState[0]?.startDate?.toString(),
            isNaN(new Date(rangeState[0]?.endDate).getDate()) ? '' : rangeState[0]?.endDate?.toString()
        );
        const isValid = await handleSelectedDatesOnDone(rangeState[0]?.startDate, rangeState[0]?.endDate);
        if (isValid) {
            handleToggle();
            setEndDateError('');
            setStartDateError('');
            setSelectedEndDate(rangeState[0]?.endDate);
            setSelectedStartDate(rangeState[0]?.startDate);
            sendDatePickerModalEvents(
                VARIABLE_CONFIG.EVENT.COMPLETE,
                isNaN(new Date(rangeState[0]?.startDate).getDate()) ? '' : rangeState[0]?.startDate?.toString(),
                isNaN(new Date(rangeState[0]?.endDate).getDate()) ? '' : rangeState[0]?.endDate?.toString()
            );
        }
        rangepickerStart?.focus();
    };

    const adjustHeader = () => {
        document.getElementsByClassName('header__checkout')[0]?.classList.add('resetZindex');
        document.getElementsByClassName('dropdownRoot')[0]?.classList.add('resetZindex');
    };

    const resetHeader = () => {
        document.getElementsByClassName('header__checkout')[0]?.classList.remove('resetZindex');
        document.getElementsByClassName('dropdownRoot')[0]?.classList.remove('resetZindex');
        onResetClick();
    };
    const tabletView = () => {
        if (showOnDrawerForTablet) {
            const content = () => (
                <>
                    {showWarning && <>{renderWarningComponent}</>}
                    {renderRangePicker()}
                </>
            );
            return (
                <SideDrawerModal
                    isModalOpen={isOpen}
                    modalHeaderClass="calendar-drawer__header"
                    modalContentClass="calendar-drawer__content"
                    modalFooterClass="calendar-drawer__footer"
                    header={calenderHeader()}
                    footer={mobileFooter()}
                    content={content()}
                    handleModalToggle={handleBackBtnClick}
                />
            );
        }
        return (
            <Modal onRequestClose={handleBackBtnClick} isOpen={isOpen} className={`calendar-modal__tab`}>
                {calenderHeader()}
                {showWarning && <>{renderWarningComponent}</>}
                {renderRangePicker()}
                <div className="calendar-modal__tab-footer">{mobileFooter()}</div>
            </Modal>
        );
    };

    return (
        <>
            {isOpen && (
                <>
                    {mediaType === MEDIA_TYPE.MOBILE ? (
                        <MobileView
                            rootClass={`mobile-view-root mobile-view-root-calender ${pickerOverlayClass}`}
                            customHeaderClass={'rangepicker__header'}
                            customHeader={calenderHeader()}
                            // To do planned for R2
                            // customHeaderWrapClass={isP2P ? 'mobile_header_dark' : ''}
                            footerContent={mobileFooter}
                            customFooterClass={'rangepicker__footer'}
                            mobileBodyClass={`rangepicker-mobile__body`}
                            onRequestClose={handleBackBtnClick}
                            isOpen={isOpen}>
                            {showWarning && <>{renderWarningComponent}</>}
                            {renderRangePicker()}
                        </MobileView>
                    ) : mediaType === MEDIA_TYPE.TABLET ? (
                        tabletView()
                    ) : (
                        <Modal
                            role="none"
                            isOpen={isOpen}
                            className="modalContent"
                            shouldFocusAfterRender={true}
                            shouldReturnFocusAfterClose={true}
                            onAfterOpen={adjustHeader}
                            onAfterClose={resetHeader}
                            style={customStyle}
                            overlayClassName={'calendar-transition'}>
                            <div
                                className={`wrapper calender__wrapper ${
                                    showWarning ? 'calender__wrapper--error' : ''
                                }`}>
                                <div className="datePickerSection">
                                    <p className="header__text__select__your__dates">
                                        {calenderTitle
                                            ? calenderTitle
                                            : intl.formatMessage({ id: 'calendar-header-select-your-rental-dates' })}
                                    </p>
                                    <>
                                        <Button
                                            tabIndex={'0'}
                                            className="rangepicker__crossicon"
                                            onClick={handleBackBtnClick}
                                            customButtonAriaLabel={intl.formatMessage({ id: 'calendar-close' })}>
                                            <CloseIcon aria-hidden="true" tabIndex={'-1'} />
                                        </Button>

                                        {rangeState.map((item, index) => (
                                            <div key={item?.key} className="datePickerSection__top">
                                                <DatePickerInput
                                                    isOpen={isOpen}
                                                    key={index}
                                                    startDate={
                                                        item.startDate
                                                            ? isNaN(new Date(item.startDate).getDate())
                                                                ? ''
                                                                : new Date(item.startDate).toLocaleDateString('en-US', {
                                                                      month: 'numeric',
                                                                      day: 'numeric',
                                                                      year: '2-digit'
                                                                  })
                                                            : ''
                                                    }
                                                    endDate={
                                                        item.endDate
                                                            ? isNaN(new Date(item.endDate).getDate())
                                                                ? ''
                                                                : new Date(item.endDate).toLocaleDateString('en-US', {
                                                                      month: 'numeric',
                                                                      day: 'numeric',
                                                                      year: '2-digit'
                                                                  })
                                                            : ''
                                                    }
                                                    customClass={`datePickerWidth ${
                                                        isEstimatedEndDateLabel && 'estimated-end-date'
                                                    }`}
                                                    isResetClicked={isResetClicked}
                                                    isVisibleInModal={true}
                                                    showWarning={showWarning}
                                                    endDateLabel={endDateLabel}
                                                    startDateLabel={startDateLabel}
                                                />
                                                <Button
                                                    tabIndex={'0'}
                                                    customButtonAriaLabel={intl.formatMessage({
                                                        id: 'calendar:reset-dates'
                                                    })}
                                                    type="button"
                                                    className="button rangepicker__reset resetButton heightSetter"
                                                    onClick={handleResetDate}
                                                    buttonAriaLabel={intl.formatMessage({
                                                        id: 'calendar:reset-dates'
                                                    })}>
                                                    {intl.formatMessage({ id: 'calendar:reset-dates' })}
                                                </Button>
                                            </div>
                                        ))}
                                    </>
                                </div>
                                <div>
                                    {showWarning && <>{renderWarningComponent}</>}
                                    {renderRangePicker()}
                                </div>
                                <div className="buttonBorder rangepicker-footer">
                                    <Button
                                        tabIndex={'0'}
                                        customButtonAriaLabel={intl.formatMessage({ id: 'common:done' })}
                                        type="button"
                                        className={'button button-primary heightSetter'}
                                        onClick={handleDoneClick}
                                        buttonAriaLabel={intl.formatMessage({ id: 'confirm-done' })}
                                        data-testid={'rangepicker-done-button'}>
                                        {intl.formatMessage({ id: 'common:done' })}
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                    )}
                    {/* {mediaType} */}
                </>
            )}
        </>
    );
};
export default RangePickerOverlay;

RangePickerOverlay.propsType = {
    isOpen: bool,
    handleToggle: func,
    setRangeState: func,
    selectedStartDate: string,
    selectedEndDate: string,
    setSelectedEndDate: func,
    setSelectedStartDate: func,
    checkIfDayDisabled: func,
    setStartDateError: func,
    setEndDateError: func,
    customOnModalOpen: func,
    setShowWarning: func,
    setIsEndDateEmpty: func,
    showWarning: bool,
    renderWarningComponent: node,
    endDateLabel: string,
    showOnDrawerForTablet: bool,
    hideBackButton: bool,
    startDateLabel: string,
    pickerOverlayClass: string,
    onResetClick: func,
    handleSelectedDatesOnDone: func,
    handleDateEventsInteraction: func,
    sendDatePickerModalEvents: func,
    calendarDateInteraction: bool,
    mediaType: string,
    calenderTitle: string
};

RangePickerOverlay.defaultProps = {
    isOpen: false,
    handleToggle: () => {},
    rangeState: [],
    setRangeState: () => {},
    selectedStartDate: '',
    selectedEndDate: '',
    setSelectedEndDate: () => {},
    setSelectedStartDate: () => {},
    checkIfDayDisabled: () => {},
    setStartDateError: () => {},
    setEndDateError: () => {},
    customOnModalOpen: () => {},
    minDate: new Date(),
    showWarning: false,
    setShowWarning: () => {},
    setIsEndDateEmpty: () => {},
    renderWarningComponent: null,
    endDateLabel: '',
    startDateLabel: '',
    showOnDrawerForTablet: false,
    hideBackButton: false,
    pickerOverlayClass: '',
    handleSelectedDatesOnDone: () => {},
    onResetClick: () => {},
    handleDateEventsInteraction: () => {},
    sendDatePickerModalEvents: () => {},
    calendarDateInteraction: false,
    mediaType: '',
    calenderTitle: ''
};
