export function callGtm(event, ecommerce, eventCategory, eventAction, eventLabel, printTitle) {
	// If printTitle is true then Page title will be appended to eventLabel
	// Below if condition will be executed if callGtm(event, ecommerce, eventCategory, eventAction, eventLabel) is called
	if(printTitle == undefined || printTitle == true){
		eventLabel = eventLabel + '::' + document.title.toLowerCase().replace(/ /g,'');
	}
    (window.dataLayer = window.dataLayer || []).push({
        'event': event,
        'ecommerce': ecommerce,
        'eventCategory': eventCategory,
        'eventAction': eventAction,
        'eventLabel': eventLabel
    });
}