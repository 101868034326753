export const EVENT_P2P_NAMES_CONFIG = {
    //events
    P2P_CTA_CLICKED: 'cta_clicked',
    P2P_QUOTE_EDITED: 'quote_edited',
    P2P_PURCHASE_ORDER_SEARCH: 'purchase_order_searched',
    P2P_PURCHASE_ORDER_VIEWED: 'purchase_order_viewed',
    P2P_PUNCHOUT_QUOTE_SEARCH: 'quote_search',
    P2P_CREATE_QUOTE_STARTED: 'quote_started',
    P2P_CREATE_QUOTE_SUCCESS: 'quote_completed',
    P2P_ADDED_TO_CART: 'added_to_cart',
    P2P_QUOTE_TRANSMITTED: 'quote_transmitted',
    //link_name
    P2P_LINK_NAME_BACK_TO_CART: 'Back to cart',
    P2P_LINK_NAME_CANCEL_EDITING: 'Cancel editing',
    P2P_LINK_NAME_SAVE_AND_CONTINUE: 'Continue/Save?',
    P2P_LINK_NAME_RENAME_LIST: 'Rename list',
    P2P_LINK_NAME_DELETE_LIST: 'delete',
    P2P_LINK_NAME_REMOVE_PRODUCTS: 'remove',
    P2P_LINK_NAME_MOVE_TO: 'Move to',
    P2P_LINK_NAME_COPY_TO: 'Copy to',
    P2P_LINK_NAME_SELECT_ALL: 'Select all',
    P2P_LINK_NAME_CLEAR_ALL: 'Clear all',
    P2P_LINK_NAME_ADD_TO_CART: 'Add to cart',
    P2P_LINK_NAME_ADD_ALL_TO_CART: 'Add all to Cart',
    P2P_LINK_NAME_PO_EXPORT: 'Export',
    P2P_LINK_NAME_SAVE_QUOTE: 'Save',
    P2P_LINK_NAME_TRANSMIT_QUOTE: 'Transmit',
    P2P_LINK_NAME_SAVE_FAVORITE: 'Save',
    P2P_LINK_NAME_CREATE_FAVORITE_LIST: 'Create new list',
    P2P_LINK_NAME_BACK_TO_PROCUREMENT: 'Back to procurement',
    //link_destination
    P2P_LINK_DESTINATION_BACK_TO_CART: '#backtocart',
    P2P_LINK_DESTINATION_CANCEL_EDITING: '#cancelediting',
    P2P_LINK_DESTINATION_SAVE_AND_CONTINUE: '#continueorsave?',
    P2P_LINK_DESTINATION_BACK_TO_CART: '#backtocart',
    P2P_LINK_DESTINATION_CANCEL_EDITING: '#cancelediting',
    P2P_LINK_DESTINATION_RENAME_LIST: '#renamelist',
    P2P_LINK_DESTINATION_DELETE_LIST: '#delete',
    P2P_LINK_DESTINATION_MOVE_TO: '#moveto',
    P2P_LINK_DESTINATION_COPY_TO: '#copyto',
    P2P_LINK_DESTINATION_REMOVE_PRODUCTS: '#remove',
    P2P_LINK_DESTINATION_SELECT_ALL: '#selectall',
    P2P_LINK_DESTINATION_CLEAR_ALL: '#clearall',
    P2P_LINK_DESTINATION_ADD_TO_CART: '#addtocart',
    P2P_LINK_DESTINATION_ADD_ALL_TO_CART: '#addalltocart',
    P2P_LINK_DESTINATION_PO_EXPORT: '#export',
    P2P_LINK_DESTINATION_SAVE_QUOTE: '#save',
    P2P_LINK_DESTINATION_TRANSMIT_QUOTE: '#transmit',
    P2P_LINK_DESTINATION_SAVE_FAVORITE: '#savefavorite',
    P2P_LINK_DESTINATION_CREATE_FAVORITE_LIST: '#createlist',
    P2P_LINK_DESTINATION_BACK_TO_PROCUREMENT: '#backtoprocurement',
    //link_type
    P2P_LINK_TYPE_ANCHOR: 'anchor',
    P2P_LINK_TYPE_BUTTON: 'button',
    //link_location
    P2P_LINK_LOCATION_BODY: 'body',
    P2P_QUOTE_EDITED: 'quote_edited',
    P2P_QUOTES_EXPORT_TEXT: 'Export',
    P2P_QUOTES_SEARCH_TEXT: 'quote_searched',
    P2P_QUOTES_VIEWED_TEXT: 'quote_viewed',
    P2P_FIELD_FORM_SUBMITTED_TYPE: 'button',
    P2P_QUOTES_DESTINATION_EXPORT: '#export',
    P2P_DATE_FORMAT: 'MM/DD/YYYY',
    P2P_PAGE_LOCATION: "/procure-to-pay/punchout/my-favorites/",
    P2P_PAGE_TITLE: "My Favorites List Detail"
};
