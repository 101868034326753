import React from 'react';
import './toggle.scss';
const Toggle = props => {
    const { onClick, testid, isSelected, title } = props;
    return (
        <button
            data-testid={testid}
            onClick={onClick}
            className={`tab-button ${isSelected ? 'selected' : 'unselected'}`}
            role="tab"
            aria-selected={isSelected ? true : false}>
            {title}
        </button>
    );
};
export default Toggle;
