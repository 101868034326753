export const SET_ALL_PCS = 'SET_ALL_PCS';
export const SET_ALL_TRAINERS = 'SET_ALL_TRAINERS';
export const SET_TRAINER_LIST = 'SET_TRAINER_LIST';
export const SET_TRAINER_LIST_BEFORE_FILTERS = 'SET_TRAINER_LIST_BEFORE_FILTERS';
export const SET_NUMBER_OF_FILTERS = 'SET_NUMBER_OF_FILTERS';
export const SET_SEARCHED_PC_LIST = 'SET_SEARCHED_PC_LIST';
export const SET_SEARCHED_TRAINER_LIST = 'SET_SEARCHED_TRAINER_LIST';
export const SET_SEARCHED_TRAINER_LIST_BEFORE_FILTERS = 'SET_SEARCHED_TRAINER_LIST_BEFORE_FILTERS';

export const mockGetDSTTrainers = {
    data: {
        dstTrainerMapList: {
            items: [
                {
                    assignedPc: 1474,
                    firstName: 'Billy',
                    lastName: 'Phillips',
                    email: 'william.phillips@sunbeltrentals.com',
                    phoneNumber: '804-249-0922',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 514,
                    firstName: 'John',
                    lastName: 'Partain',
                    email: 'john.partain@sunbeltrentals.com',
                    phoneNumber: '864-209-2288',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/rigging-&-signaling'
                    ]
                },
                {
                    assignedPc: 211,
                    firstName: 'Michael',
                    lastName: 'Nason',
                    email: 'michael.nason@sunbeltrentals.com',
                    phoneNumber: '330-353-5682',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 1042,
                    firstName: 'Matthew',
                    lastName: 'Wells',
                    email: 'matt.wells@sunbeltrentals.com',
                    phoneNumber: '704-400-7296',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/spotter-training',
                        'sunbelt-rentals:training-set/carts--(polaris-atv)',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/shoring-trenching',
                        'sunbelt-rentals:training-set/lock-out-tag-out'
                    ]
                },
                {
                    assignedPc: 968,
                    firstName: 'Randy',
                    lastName: 'Garcia',
                    email: 'randy.garcia@sunbeltrentals.com',
                    phoneNumber: '972-202-6707',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/shoring-trenching'
                    ]
                },
                {
                    assignedPc: 1324,
                    firstName: 'Lauri',
                    lastName: 'Bender',
                    email: 'laurene.bender@sunbeltrentals.com',
                    phoneNumber: '619-921-7862',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 138,
                    firstName: 'William "Neil"',
                    lastName: 'Brittain',
                    email: 'william.brittain@sunbeltrentals.com',
                    phoneNumber: '865-382-5118',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 633,
                    firstName: 'Tim',
                    lastName: 'Day',
                    email: 'tim.day@sunbeltrentals.com',
                    phoneNumber: '336-362-4804',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection'
                    ]
                },
                {
                    assignedPc: 76,
                    firstName: 'Jeromy',
                    lastName: 'Hall',
                    email: 'jeromy.hall@sunbeltrentals.com',
                    phoneNumber: '205-260-5379',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 115,
                    firstName: 'Dave',
                    lastName: 'Amezcua',
                    email: 'david.amezcua@sunbeltrentals.com',
                    phoneNumber: '910-512-1359',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/spotter-training',
                        'sunbelt-rentals:training-set/carts--(polaris-atv)',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/scaffolding-(suspended)',
                        'sunbelt-rentals:training-set/shoring-trenching',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)',
                        'sunbelt-rentals:training-set/rigging-&-signaling',
                        'sunbelt-rentals:training-set/lock-out-tag-out'
                    ]
                },
                {
                    assignedPc: 8,
                    firstName: 'Leon',
                    lastName: 'Wade',
                    email: 'leon.wade@sunbeltrentals.com',
                    phoneNumber: '910-620-1130',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/scaffolding-(suspended)',
                        'sunbelt-rentals:training-set/rigging-&-signaling'
                    ]
                },
                {
                    assignedPc: 459,
                    firstName: 'Anthony',
                    lastName: 'Martinez',
                    email: 'anthony.martinez@sunbeltrentals.com',
                    phoneNumber: '571-205-9309',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/carts--(polaris-atv)'
                    ]
                },
                {
                    assignedPc: 209,
                    firstName: 'Bradley',
                    lastName: 'McQuillen',
                    email: 'bradley.mcquillen@sunbeltrentals.com',
                    phoneNumber: '419-544-2082',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 312,
                    firstName: 'Elizabeth',
                    lastName: 'Roe',
                    email: 'liz.roe@sunbeltrentals.com',
                    phoneNumber: '463-701-7935',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 227,
                    firstName: 'Emilio',
                    lastName: 'Rivera',
                    email: 'emilio.rivera@sunbeltrentals.com',
                    phoneNumber: '727-483-3964',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 632,
                    firstName: 'Isaac',
                    lastName: 'Rivera',
                    email: 'isaac.rivera@sunbeltrentals.com',
                    phoneNumber: '805-861-0347',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/shoring-trenching'
                    ]
                },
                {
                    assignedPc: 191,
                    firstName: 'Homero',
                    lastName: 'Martinez',
                    email: 'homero.martinez@sunbeltrentals.com',
                    phoneNumber: '704-400-7296',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 515,
                    firstName: 'Brian',
                    lastName: 'Suppes',
                    email: 'brian.suppes@sunbeltrentals.com',
                    phoneNumber: '269-207-4011',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 10,
                    firstName: 'Joseph',
                    lastName: 'Parham',
                    email: 'joseph.parham@sunbeltrentals.com',
                    phoneNumber: '404-307-2896',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)'
                    ]
                },
                {
                    assignedPc: 158,
                    firstName: 'Richard',
                    lastName: 'Drennan',
                    email: 'richard.drennan@sunbeltrentals.com',
                    phoneNumber: '804-709-8048',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 237,
                    firstName: 'Richard',
                    lastName: 'Trochio',
                    email: 'casey.holmes@sunbeltrentals.com',
                    phoneNumber: '856-397-8130',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 356,
                    firstName: 'Ryan',
                    lastName: 'Landers',
                    email: 'ryan.landers@sunbeltrentals.com',
                    phoneNumber: '314-379-8343',
                    qualifiedTrainingSet: ['sunbelt-rentals:training-set/forklift', 'sunbelt-rentals:training-set/mewp']
                },
                {
                    assignedPc: 126,
                    firstName: 'Joe',
                    lastName: 'Tena',
                    email: 'joseph.tena@sunbeltrentals.com',
                    phoneNumber: '615-585-6805',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/carts--(polaris-atv)',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)'
                    ]
                },
                {
                    assignedPc: 7064,
                    firstName: 'Lynsey',
                    lastName: 'Grimmond',
                    email: 'lynsey.grimmond@sunbeltrentals.com',
                    phoneNumber: '403-305-7597',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/propane-filling',
                        'sunbelt-rentals:training-set/whmis'
                    ]
                },
                {
                    assignedPc: 927,
                    firstName: 'Carlos',
                    lastName: 'Nieves',
                    email: 'carlos.nieves@sunbeltrentals.com',
                    phoneNumber: '407-462-0910',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 668,
                    firstName: 'Vincent',
                    lastName: 'Capone',
                    email: 'vincent.capone@sunbeltrentals.com',
                    phoneNumber: '631-356-9358',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 325,
                    firstName: 'Alex',
                    lastName: 'Staffon',
                    email: 'astaffon@sunbeltrentals.com',
                    phoneNumber: '503-209-6115',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 61,
                    firstName: 'Justin',
                    lastName: 'Smith',
                    email: 'justin.smith2@sunbeltrentals.com',
                    phoneNumber: '904-248-2706',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 7120,
                    firstName: 'Michael',
                    lastName: 'Fitzpatrick',
                    email: 'michael.fitzpatrick@sunbeltrentals.com',
                    phoneNumber: '506-227-2421',
                    qualifiedTrainingSet: null
                },
                {
                    assignedPc: 170,
                    firstName: 'Juan',
                    lastName: 'Cortez',
                    email: 'juan.cortez@sunbeltrentals.com',
                    phoneNumber: '240-220-1856',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 115,
                    firstName: 'Pete',
                    lastName: 'Perez',
                    email: 'pete.perez@sunbeltrentals.com',
                    phoneNumber: '786-610-8505',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 8046,
                    firstName: 'Taylor',
                    lastName: 'Levangie',
                    email: 'taylor.levangie@sunbeltrentals.com',
                    phoneNumber: '617-291-6559',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 238,
                    firstName: 'Tom',
                    lastName: 'Sichelstiel',
                    email: 'thomas.sichelstiel@sunbeltrentals.com',
                    phoneNumber: '412-295-0245',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 582,
                    firstName: 'Bill',
                    lastName: 'Duncan',
                    email: 'bill.duncan@sunbeltrentals.com',
                    phoneNumber: '704-724-4604',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/spotter-training',
                        'sunbelt-rentals:training-set/scaffolding-(supported)',
                        'sunbelt-rentals:training-set/shoring-trenching',
                        'sunbelt-rentals:training-set/rigging-&-signaling'
                    ]
                },
                {
                    assignedPc: 240,
                    firstName: 'Dane',
                    lastName: 'Kelty',
                    email: 'dane.kelty@sunbeltrentals.com',
                    phoneNumber: '502-492-8271',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 365,
                    firstName: 'Kevin',
                    lastName: 'Kershasky',
                    email: 'kevin.kershasky@sunbeltrentals.com',
                    phoneNumber: '262-232-3636',
                    qualifiedTrainingSet: ['sunbelt-rentals:training-set/forklift', 'sunbelt-rentals:training-set/mewp']
                },
                {
                    assignedPc: 7029,
                    firstName: 'Richard',
                    lastName: 'Blair',
                    email: 'easterncanada.training@sunbeltrentals.com',
                    phoneNumber: '613-227-4904',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)',
                        'sunbelt-rentals:training-set/propane',
                        'sunbelt-rentals:training-set/propane-and-nat-gas',
                        'sunbelt-rentals:training-set/propane-in-roofing',
                        'sunbelt-rentals:training-set/chainsaw',
                        'sunbelt-rentals:training-set/hoisting-and-rigging',
                        'sunbelt-rentals:training-set/whmis'
                    ]
                },
                {
                    assignedPc: 7029,
                    firstName: 'Paula',
                    lastName: 'Richards',
                    email: 'easterncanada.training@sunbeltrentals.com',
                    phoneNumber: '519-496-0141',
                    qualifiedTrainingSet: null
                },
                {
                    assignedPc: 7029,
                    firstName: 'Paul',
                    lastName: 'Pellizzari',
                    email: 'easterncanada.training@sunbeltrentals.com',
                    phoneNumber: '519-242-9510',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)',
                        'Propane',
                        'sunbelt-rentals:training-set/propane-and-nat-gas',
                        'sunbelt-rentals:training-set/propane-in-roofing',
                        'sunbelt-rentals:training-set/whmis',
                        'sunbelt-rentals:training-set/propane',
                        'sunbelt-rentals:training-set/propane-filling'
                    ]
                },
                {
                    assignedPc: 7029,
                    firstName: 'Dave',
                    lastName: 'Vandenberg',
                    email: 'easterncanada.training@sunbeltrentals.com',
                    phoneNumber: '519-373-7765',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)',
                        'sunbelt-rentals:training-set/propane',
                        'sunbelt-rentals:training-set/propane-and-nat-gas',
                        'sunbelt-rentals:training-set/lock-out-tag-out',
                        'sunbelt-rentals:training-set/whmis'
                    ]
                },
                {
                    assignedPc: 182,
                    firstName: 'Matt',
                    lastName: 'Verdi',
                    email: 'matthew.verdi@sunbeltrentals.com',
                    phoneNumber: '215-817-4063',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 171,
                    firstName: 'Casey',
                    lastName: 'Holmes',
                    email: 'casey.holmes@sunbeltrentals.com',
                    phoneNumber: '856-397-8130',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 231,
                    firstName: 'Julio',
                    lastName: 'Torres',
                    email: 'julio.torres@sunbeltrentals.com',
                    phoneNumber: '832-438-9944',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/bi-lingual-spanish',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/scaffolding-(supported)'
                    ]
                },
                {
                    assignedPc: 213,
                    firstName: 'Jack',
                    lastName: 'Wright',
                    email: 'jack.wright@sunbeltrentals.com',
                    phoneNumber: '513-484-3858',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 7055,
                    firstName: 'Tom',
                    lastName: 'Davis',
                    email: 'tom.davis@sunbeltrentals.com',
                    phoneNumber: '250-812-0965',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/ttt',
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/propane-filling'
                    ]
                },
                {
                    assignedPc: 7029,
                    firstName: 'Steve',
                    lastName: 'Oakes',
                    email: 'easterncanada.training@sunbeltrentals.com',
                    phoneNumber: '289-325-4736',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/fall-protection',
                        'sunbelt-rentals:training-set/propane',
                        'sunbelt-rentals:training-set/propane-and-nat-gas'
                    ]
                },
                {
                    assignedPc: 155,
                    firstName: 'Kevin',
                    lastName: 'Dominguez',
                    email: 'kevin.dominguez@sunbeltrentals.com',
                    phoneNumber: '301-310-2333',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 517,
                    firstName: 'Brook',
                    lastName: 'Russo',
                    email: 'brook.russo@sunbeltrentals.com',
                    phoneNumber: '925-596-3493',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving',
                        'sunbelt-rentals:training-set/confined-space-(canada-only)'
                    ]
                },
                {
                    assignedPc: 8044,
                    firstName: 'David',
                    lastName: 'Goins',
                    email: 'david.goins@sunbeltrentals.com',
                    phoneNumber: '270-925-5670',
                    qualifiedTrainingSet: ['sunbelt-rentals:training-set/forklift', 'sunbelt-rentals:training-set/mewp']
                },
                {
                    assignedPc: 267,
                    firstName: 'Josh',
                    lastName: 'Cooper',
                    email: 'joshua.cooper@sunbeltrentals.com',
                    phoneNumber: '850-208-1607',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                },
                {
                    assignedPc: 1394,
                    firstName: 'Leann',
                    lastName: 'Lyskowsky',
                    email: 'leann.lyskowsky@sunbeltrentals.com',
                    phoneNumber: '602-695-4200',
                    qualifiedTrainingSet: [
                        'sunbelt-rentals:training-set/forklift',
                        'sunbelt-rentals:training-set/mewp',
                        'sunbelt-rentals:training-set/earth-moving'
                    ]
                }
            ]
        }
    }
};
