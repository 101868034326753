var seasonalCarousel = $(".image-desc__carousel");
        seasonalCarousel.each(function (index, element) {
            var slider = $(this);
            var prevArrowButton = slider
                .closest(".block--image-desc")
                .find(".image-desc__action-prev");
            var nextArrowButton = slider
                .closest(".block--image-desc")
                .find(".image-desc__action-next");

            slider?.slick({
                arrows: true,
                dots: true,
                infinite: false,
                slidesToShow: 3,
                slidesToScroll: 3,
                prevArrow: prevArrowButton,
                nextArrow: nextArrowButton,
                responsive: [
                    {
                        breakpoint: 1024.98,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                    {
                        breakpoint: 639.98,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            });

        });