import { callGtm } from "../../GTM/";
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if(isGTMHidden === "false"){
	var resource = document.querySelectorAll('.header__resources .header__eyebrow');
	resource?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var eyeMsg = document.querySelectorAll('.head .item');
	eyeMsg?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var headInfo = document.querySelectorAll('.header__information .header__eyebrow');
	headInfo?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var headInfoLink = document.querySelectorAll('.header__information .footer__top-link');
	headInfoLink?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var accMsgDefault = document.querySelectorAll('.default_greetingMsg');
	accMsgDefault?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var accMsg = document.querySelectorAll('.greetingMsg');
	accMsg?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var accIcon = document.querySelectorAll('.dropdown-account-icon');
	accIcon?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = 'account icon';
			callGtm('uaevent', 'undefined', 'header', 'select', eventLabel.toLowerCase());

		});
	});

	var eyebrowDropItem = document.querySelectorAll('.eyebrow__column .eyebrow__dropdown-item');
	eyebrowDropItem?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header L2', 'select', eventLabel.toLowerCase());
		});
	});

	var guestUserDropItem = document.querySelectorAll('.guestUser .dropdown-item');
	guestUserDropItem?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header L2', 'select', eventLabel.toLowerCase());

		});
	});

	var cashUserDropItem = document.querySelectorAll('.cashUser .dropdown-item');
	cashUserDropItem?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header L2', 'select', eventLabel.toLowerCase());
		});
	});

	var creditUserDropItem = document.querySelectorAll('.creditUser .dropdown-item');
	creditUserDropItem?.forEach(function (i) {
		i?.addEventListener('click', function () {
			var eventLabel = i.innerText;
			callGtm('uaevent', 'undefined', 'header L2', 'select', eventLabel.toLowerCase());
		});
	});
}