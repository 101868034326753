import React, { useState, useEffect, memo, useRef } from 'react';
import Slider from 'react-slick';
import { useIntl } from 'react-intl';
import AddToCartProductTileReact from '../../global/modules/productTile/addToCartProductTile';
import popularViewedRecommendationsList from '../controllers/popularrentalscontroller';
import '../../global/modules/rentalsCombineTab/rentalscombine.scss';
import Button from '../../global/atoms/button';

function PopularRentals() {
    const intl = useIntl();
    const [popularRentals, setPopularRentals] = useState([]);
    const [popularRentalsList, setPopularRentalsList] = useState([]);
    const { title, enableCta, ctaLabel, ctaLink } = document.querySelector('.popular__rentals')?.dataset?.showmore
        ? JSON.parse(document.querySelector('.popular__rentals')?.dataset?.showmore)
        : {};

    useEffect(() => {
        popularViewedRecommendationsList.refresh();
        popularViewedRecommendationsList.subscribe(updateState);
    }, []);

    useEffect(() => {
        if (popularRentals?.recommendations) {
            let splicedArray =
                popularRentals.recommendations?.length > 0 ? popularRentals.recommendations.slice(0, 20) : [];
            setPopularRentalsList(
                splicedArray.map((item, index) => {
                    return {
                        catclass: item?.additionalFields?.ec_skus[0],
                        producttitle: item?.ec_name,
                        productimageurl: item?.additionalFields?.ec_primary_image,
                        producturl: item?.additionalFields?.ec_product_url,
                        categoryname: item?.ec_category ? item?.ec_category[0] : '',
                        productcategoryname: item?.additionalFields?.categoryl2,
                        subcategoryname: item?.additionalFields?.categoryl3,
                        category_path: item?.ec_category
                            ? [
                                  item?.ec_category[0],
                                  item?.additionalFields?.categoryl2,
                                  item?.additionalFields?.categoryl3
                              ]
                            : [item?.additionalFields?.categoryl2, item?.additionalFields?.categoryl3],
                        position: index + 1,
                        catid: item?.catId,
                        catsku: item?.additionalFields?.ec_skus[0],
                        showonlinecatalog: item?.additionalFields?.ec_show_onlinecatalog === '1' ? 'YES' : 'NO',
                        disableaddtocartoption:
                            item?.additionalFields?.ec_disable_addtocartoption === '1' ? 'YES' : 'NO',
                        metatitle: item?.additionalFields?.ec_meta_title,
                        metadescription: item?.additionalFields?.ec_meta_description,
                        specialtytypes: item?.additionalFields?.ec_specialtytypes,
                        specialtytypescode: item?.additionalFields?.ec_specialtytypecode,
                        analysisgroupcode: item?.additionalFields?.ec_analysisgroupcode
                    };
                })
            );
        }
    }, [popularRentals]);

    const updateState = () => {
        setPopularRentals(popularViewedRecommendationsList.state);
    };

    const PopularNextArrow = props => {
        const { classes, onClick } = props;
        return (
            <Button className={classes} onClick={onClick}>
                <span class="icon icon-arrow-right-green"></span>
            </Button>
        );
    };
    const PopularPrevArrow = props => {
        const { classes, onClick } = props;
        return (
            <Button className={classes} onClick={onClick}>
                <span class="icon icon-arrow-left-green"></span>
            </Button>
        );
    };
    const sliderRef = useRef(null);
    const settings = {
        arrows: false,
        dots: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 767.98,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 639.98,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return (
        <>
            <section class="block block--tabpadding block--popular block--seasonal">
                <div class="container">
                    {/* <div class="seasonal__title-wrap">
                        <h4 class="seasonal__title">Popular rentals</h4>
                    </div> */}
                    {popularRentals?.recommendations?.length > 4 ? (
                        <div class="seasonal__carousel-wrap">
                            <Slider {...settings} ref={sliderRef} className="seasonal__carousel slider">
                                {popularRentalsList?.map(item => (
                                    <div class="seasonal__carousel-item">
                                        <AddToCartProductTileReact cardData={item} />
                                    </div>
                                ))}
                            </Slider>
                            <div class="seasonal__slider-actions">
                                <PopularPrevArrow
                                    classes={`seasonal__slide-action seasonal__action-prev`}
                                    onClick={() => sliderRef?.current?.slickPrev()}
                                />
                                <PopularNextArrow
                                    classes={`seasonal__slide-action seasonal__action-next`}
                                    onClick={() => sliderRef?.current?.slickNext()}
                                />
                            </div>
                            {enableCta && JSON.parse(enableCta) && (
                                <div class="seasonal__action-bottom row block__actions">
                                    <div class="col-xl-4 col-md-6">
                                        <a class="button button-outline-primary button-block" href={ctaLink}>
                                            {ctaLabel}
                                        </a>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <div class="row seasonal__row">
                                {popularRentalsList?.map(item => (
                                    <div class="col-lg-3 col-6 col-md-3">
                                        <AddToCartProductTileReact cardData={item} />
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </section>
        </>
    );
}

export default memo(PopularRentals);
