import PropTypes from 'prop-types';
import React from 'react';

export default function Body(props) {
    const { className, children } = props;
    return <div className={`${className}`}>{children}</div>;
}

Body.propTypes = {
    children: PropTypes.node
};
Body.defaultProps = {
    children: null,
    className: ''
};
