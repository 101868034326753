import { bool, func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Input from '../../../../global/atoms/input';
import { logError } from '../../../../global/utils/logger';
import { SET_WISHLIST_LIST, SET_WISHLIST_UPDATING } from '../../../actionTypes/actionTypes';
import {
    CREATE_LIST_MAX_CHARACTER_LENGTH,
    CREATE_LIST_MIN_CHARACTER_LENGTH,
    TOAST_ANIMATION_TYPE,
    actionBarDropdownConstants
} from '../../../constants';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import { useFavouritesState } from '../../../context';
import { favDataLocators } from '../../../dataLocators';
import useFailureToastNotification from '../../../hooks/useFailureToastNotification';
import useFavourites from '../../../hooks/useFavorites';
import '../createWishList.scss';

const CreateWishListContent = ({ submit, setSubmit, onSuccessfullCreateWishlist, showHeading }) => {
    const intl = useIntl();
    const [inputValue, setInputValue] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const { addWishlist } = useFavourites();
    const [{ favourites }, favDispatch] = useFavouritesState();
    const showFailureToastNotification = useFailureToastNotification();

    useEffect(() => {
        if (submit) {
            handleCreateList();
        }
    }, [submit]);

    const handleCreateList = async () => {
        const listName = inputValue?.trim();

        if (
            !listName ||
            inputValue?.length < CREATE_LIST_MIN_CHARACTER_LENGTH ||
            inputValue?.length > CREATE_LIST_MAX_CHARACTER_LENGTH
        ) {
            setErrorMsg(intl.formatMessage({ id: 'p2p-create-list-name-more-than-40-characters' }));
        } else if (isDuplicate()) {
            setErrorMsg(intl.formatMessage({ id: 'p2p-create-list-name-already-exists' }));
        } else {
            try {
                const corpAccDetails = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.CORP_ACCOUNT_DETAILS) || '{}');
                favDispatch({ type: SET_WISHLIST_UPDATING, payload: true });
                const { data } = await addWishlist({ name: inputValue?.trim(), accountId: corpAccDetails?.id || '' });
                if (data) {
                    favDispatch({ type: SET_WISHLIST_LIST, payload: [data?.createWishlist?.wishlist, ...favourites] });
                    onSuccessfullCreateWishlist();
                } else {
                    showFailureToastNotification({
                        action: actionBarDropdownConstants.CREATE,
                        toastContainerClass: 'alert_position_bottom_2x',
                        toastDirection: TOAST_ANIMATION_TYPE.BOTTOM
                    });
                }
            } catch (error) {
                logError(error, false, 'handleCreateList');
                showFailureToastNotification({
                    action: actionBarDropdownConstants.CREATE,
                    toastContainerClass: 'alert_position_bottom_2x',
                    toastDirection: TOAST_ANIMATION_TYPE.BOTTOM
                });
            } finally {
                favDispatch({ type: SET_WISHLIST_UPDATING, payload: false });
            }
        }
        setSubmit(false);
    };

    const isDuplicate = () => {
        const wishlistName = inputValue?.trim();
        const foundObject = favourites?.find(({ name }) => name?.toLowerCase() == wishlistName?.toLowerCase());
        return !!foundObject;
    };

    const handleInputChange = e => {
        setInputValue(e.target.value);
        setErrorMsg('');
    };

    return (
        <div className="create_wishlist_content_wrapper">
            {showHeading && (
                <h2 className="create_wishlist_heading" tabIndex={'0'}>
                    {intl.formatMessage({ id: 'p2p-create-wishlist-heading' })}
                </h2>
            )}
            <Input
                type="text"
                name="create_wishlist_input"
                classes="create_wishlist_label"
                fieldCustomClass="create_wishlist_label"
                errorIconClass="create_wishlist_erroricon"
                label={intl.formatMessage({ id: 'p2p-create-list-label' })}
                value={inputValue}
                dataTestId={favDataLocators.fav_create_list_input_testid}
                handleInputChange={handleInputChange}
                errorMsg={errorMsg}
                maxlength={40}
                showErrorIcon={true}
            />
        </div>
    );
};

CreateWishListContent.propsType = {
    submit: bool,
    setSubmit: string,
    onSuccessfullCreateWishlist: func,
    showHeading: bool
};

CreateWishListContent.defaultProps = {
    submit: false,
    setSubmit: () => {},
    onSuccessfullCreateWishlist: () => {},
    showHeading: true
};

export default React.memo(CreateWishListContent);
