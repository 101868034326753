import axios from 'axios';
import axiosInstance from '../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../constants/apiConfig';
import { ERROR_MSG } from '../../../constants/errorMappingCodes';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { formatAccountProjects } from '../utils/commonUtils';
import { isValidString, logError, logSuccess } from '../utils/logger';
import { useAuthorityToken } from '../../../aem-core-components/utils/hooks';

const { CUSTOMERS_API_URL, VALIDATE_REFRESH_TOKEN, GENERATE_ACCESS_TOKEN, AEM_COOKIE_CLEAR_ENDPOINT } = API_CONFIG;
const JOBSITES_LIMIT = parseInt(
    document.querySelector('meta[data-endpoint-key="jobSitesLimit"]')?.dataset?.endpointValue
);
let controller;

export const createDummyGuestAccount = async payload => {
    try {
        const { data, error } = await axiosInstance.post(CUSTOMERS_API_URL, payload);
        sessionStorage.setItem('dlNumberGuestUser', data?.data?.dlNumber);
        sessionStorage.setItem('dlStateGuestUser', data?.data?.dlState);
        return { data, error };
    } catch (error) {
        throw new Error(error);
    }
};

export const createGuestAccount = async payload => {
    try {
        const { data, error } = await axiosInstance.post(CUSTOMERS_API_URL, payload);
        if (error) return error;
        else return data;
    } catch (error) {
        return { error: error?.response?.data?.error };
    }
};

export const fetchAccountProjects = async payload => {
    const { fetchAccountProjectsMutation, accountId, search, invalidateCache, isprojectDetails } = payload;
    if (controller && isValidString(sessionStorage.getItem('projectsLoading'))) {
        controller.abort();
    }
    if(search && !isprojectDetails) {
        controller = new AbortController();
    }
    sessionStorage.setItem('projectsLoading', true);
    try {
        const { data, error } = await fetchAccountProjectsMutation({
            variables: {
                accountId: accountId.toString(),
                search: search ? search : undefined,
                limit: JOBSITES_LIMIT || 1000,
                includeActiveEquipment: 'false',
                invalidateCache: invalidateCache
            },
            fetchPolicy: 'network-only',
            context: { fetchOptions: { signal: controller?.signal } }
        });
        sessionStorage.setItem('projectsLoading', false);
        if (data?.getSbrJobSites?.data) {
            return { projectData: formatAccountProjects(data?.getSbrJobSites?.data), error: error };
        }
        return { projectData: [], error: error };
    } catch (error) {
        sessionStorage.setItem('projectsLoading', false);
        return { projectData: null, error: error };
    }
};
export const getOverridePC = async accountNumber => {
    const PC_LOCATION_URL = `/web/api/v1/customers/${accountNumber}/locations`;
    try {
        const { data, error } = await axiosInstance.get(PC_LOCATION_URL);
        return { data, error };
    } catch (error) {
        return { error };
    }
};

export const validateRefreshToken = async () => {
    try {
        const [refreshToken] = useAuthorityToken('refreshtoken');

        if (refreshToken) {
            const validateBody = { refreshToken };
            const { data, error } = await axiosInstance.post(VALIDATE_REFRESH_TOKEN, validateBody);
            return { data, error };
        } else {
            return {
                data: null,
                error: 'refresh token expired'
            };
        }
    } catch (error) {
        if (error?.response?.status >= 400) {
            return {
                data: null,
                error: error?.response?.data?.error
            };
        }
        return {
            data: {
                message: ERROR_MSG.CANCELLED_REQUEST
            }
        };
    }
};

export const generateAccessToken = async () => {
    var validateBody = new URLSearchParams();
    validateBody.append('grant_type', 'client_credentials');
    validateBody.append('client_id', '0oa2gn4a55aJXRJUO1d7');
    validateBody.append('client_secret', 'ZNKROZmuFbQbfvyiOaoVf69kLgLTTcPG3Fsd86fZ');
    validateBody.append('scope', 'Mulesoft');
    try {
        const { data, error } = await axiosInstance.post(GENERATE_ACCESS_TOKEN, validateBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        return { data, error };
    } catch (error) {
        return {
            data: null,
            error: error?.response?.data?.error
        };
    }
};


export const getIPaddressApi = async () => {
    try {
        const { data } = await axios.get(API_CONFIG.GET_IP_ADDRESS);
        if (data) {
            const ipAddress = data
                ?.split('\n')
                ?.find(item => item?.includes('ip='))
                ?.split('=')[1];
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.IP_ADDRESS, ipAddress);
        }
    } catch (e) {
        logError('unable to get IP');
    }
};

export const updateTokensOnCC = (accessToken, refreshToken) => {
    var data = new FormData();
    data.append('access_token', accessToken);
    data.append('refresh_token', refreshToken);

    var config = {
        method: 'post',
        url: '/shared/keepalive',
        data: data
    };

    axios(config)
        .then(function (response) {
            logSuccess(JSON.stringify(response.data));
        })
        .catch(function (error) {
            logError(error, false, 'updateTokensOnCC', [accessToken, refreshToken]);
        });
};
export const updateDefaultAccount = async (defaultAccount = 0, isCanadaValue = false, defaultAccountName = '') => {
    const payload = {
        wynneAcct: defaultAccount,
        isCanada: isCanadaValue,
        wynneAcctName: defaultAccountName
    };
    var config = {
        method: 'post',
        url: '/myaccount/setactiveaccount',
        data: payload
    };
    axios(config)
        .then(function (response) {
            logSuccess(JSON.stringify(response));
        })
        .catch(function (error) {
            logError(error, false, 'updateDefaultAccountJobsite', [defaultAccount, isCanadaValue, defaultAccountName]);
        });
};

export const clearAemCookies = () => {
    var config = {
        method: 'post',
        url: AEM_COOKIE_CLEAR_ENDPOINT,
    };
    return axios(config);
}
