import { gql } from '@apollo/client';

const VALIDATE_TOKEN_BY_REFRESH = gql`
    mutation validateTokenByRefresh($refreshToken: String!) {
        validateTokenByRefresh(refreshToken: $refreshToken) {
            status
            message
            access_token
            refresh_token
            magento_token
            expires_in
        }
    }
`;

export default VALIDATE_TOKEN_BY_REFRESH;
