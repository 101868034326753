import { getUserType,USER_TYPE } from "../../common/js/common";

window.addEventListener('load', event => {
    const productrecommendationsWrapper = document?.querySelector(".productrecommendations");
    try{
        if(getUserType() === USER_TYPE.CREDIT ){
            productrecommendationsWrapper?.classList?.toggle('productrecommendations--show')
        }
    }
    catch (error) {
        console.error('An error occurred while initializing the compoennt:', error);
    }
})