import React from 'react';
import { useIntl } from 'react-intl';
import { bool, func, string } from 'prop-types';
import Button from '../../../../atoms/button/button';
import ShoppingCart from '../../../../../../resources/images/carticon24.svg';
import { productTileDataLocator } from '../../../addToCartTile/dataLocators';

const AddToCart = ({ showCTA, isRequestQuoteCTA, handleClick, className, productName }) => {
    const intl = useIntl();

    const handleBtnClick = () => {
        handleClick(isRequestQuoteCTA);
    };

    return (
        <div
            data-testid={productTileDataLocator.productTile_addToCartIcon}
            className={`card__plp-addtocart-wrap ${className}`}>
            {showCTA ? (
                <Button
                    className="button button-primary button-block producttile__addtocart-action"
                    type="button"
                    onClick={handleBtnClick}
                    dataTestid={productTileDataLocator.productTile_add_to_cart_CTA_testid}
                    buttonAriaLabel={
                        isRequestQuoteCTA
                            ? `${intl.formatMessage({ id: 'pdp:request-quote' })} ${productName}`
                            : `${intl.formatMessage({ id: 'product:add-item' })} ${productName}`
                    }>
                    {!isRequestQuoteCTA && <ShoppingCart className="producttile__addtocart-icon" />}
                    {isRequestQuoteCTA
                        ? intl.formatMessage({ id: 'pdp:request-quote' })
                        : intl.formatMessage({ id: 'product:add-item' })}
                </Button>
            ) : (
                <div className="cart-item-price-empty"></div>
            )}
        </div>
    );
};

export default React.memo(AddToCart);

AddToCart.defaultProps = {
    isRequestQuoteCTA: false,
    showCTA: false,
    handleClick: () => {},
    className: '',
    productName:''
};

AddToCart.propTypes = {
    isRequestQuoteCTA: bool,
    showCTA: bool,
    handleClick: func,
    className: string,
    productName:string
};
