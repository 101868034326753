export const returnCurrentMonthAndYear = () => {
    let obj = {
        currentMonth: new Date().getMonth() + 1,
        currentYear: parseInt(new Date().getFullYear().toString().substr(-2))
    };
    return obj;
};

export const checkYearAndMonth = (expDate, currentMAndY) => {
    if (parseInt(expDate[1]) < currentMAndY.currentYear) return true;
    else if (parseInt(expDate[1]) > currentMAndY.currentYear) return false;
    else {
        if (parseInt(expDate[0]) > currentMAndY.currentMonth) return false;
        else if (parseInt(expDate[0]) < currentMAndY.currentMonth) return true;
        else return false;
    }
};

export const isCardExpired = expiryDate => {
    const res = expiryDate?.replace(new RegExp(`^(.{${2}})(.)`), `$1/$2`);
    let expDate = res.split('/');
    let currentMAndY = returnCurrentMonthAndYear();
    return checkYearAndMonth(expDate, currentMAndY);
};

export const cardTypeMapper = card => {
    switch (card) {
        case 'V':
            return 'VISA';
        case 'A':
            return 'Amex';
        case 'D':
            return 'Discover';
        case 'M':
            return 'Mastercard';
        case 'B':
            return '';
        default:
            return '';
    }
};

export const formattedCardTitle = (paymentType, last4Digits) => {
    return `${cardTypeMapper(paymentType)} ****${last4Digits}`;
};
