export const favDataLocators = {
    fav_create_list_input_testid: 'fav_create_list_input',
    fav_rename_list_input_testid: 'fav_rename_list_input',
    wishlist_check_box_testid: 'wishlist_check_box',
    wishlist_modal_footer: 'wishlist_modal_footer',
    wishlist_modal_cross_icon_testid: 'wishlist_modal_cross_icon',
    wishlist_grid_qty_selector_test_id: 'wishlist_grid_qty_selector_test_id',
    wishlist_action_bar: 'action_bar',
    nofavorites_create_cta_testid: 'nofavorites_create_cta',
    wishlist_action_link: 'wishlist_action_link'
};
