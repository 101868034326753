// @ts-ignore

//SAMPLE : PRoduct Recommendation buildPopularViewedRecommendationsList

//RELATED: this atomic framework could help but is using "buildFrequentlyBoughtTogetherList" : https://github.com/coveo/ui-kit/blob/94733ed5674a50b283b9804ec4bcf0e901fe538d/packages/atomic/src/components/atomic-frequently-bought-together/atomic-frequently-bought-together.tsx

//Headless repo:

const {
    buildProductRecommendationEngine,
    buildPopularViewedRecommendationsList
    /* other potential constructors*/
    //  buildCartRecommendationsList
    //  buildFrequentlyBoughtTogetherList
    //  buildFrequentlyViewedTogetherList
    //  buildPopularBoughtRecommendationsList
    //  buildUserInterestRecommendationsList
} = require('@coveo/headless/product-recommendation');

/* OPTIONS: core platform api parameters */
const options = {
    configuration: {
        organizationId: 'sunbeltrentalsnonproduction1nxhhxbub',
        accessToken: 'xxada9643d-39e5-45c0-b754-36d7529a4139', //nonprod1 "jpdery_tmp_dev_prodrecom"
        organizationEndpoints: 'https://platform.cloud.coveo.com',
        searchHub: 'Sunbelt-Commerce-Product-Recommendations',
        preprocessRequest: handlePreprocessRequest
    }
};

function handlePreprocessRequest(request, clientOrigin) {
    return request;
}

let engine, popularViewedRecommendationsList;

const popularViewedRecommendationsListProps = {
    options: {
        maxNumberOfRecommendations: 12,
        additionalFields: [
            'categoryl2',
            'categoryl3',
            'ec_primary_image',
            'ec_skus',
            'ec_product_url',
            'ec_show_onlinecatalog',
            'ec_disable_addtocartoption',
            'source',
            'ec_meta_description',
            'ec_meta_title',
            'ec_specialtytypes',
            'ec_specialtytypecode',
            'ec_analysisgroupcode'
        ]
    }
};

engine = buildProductRecommendationEngine(options);

popularViewedRecommendationsList = buildPopularViewedRecommendationsList(engine, popularViewedRecommendationsListProps);

export default popularViewedRecommendationsList;
