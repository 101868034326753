import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { func, object, shape } from 'prop-types';
import {
    SET_ALL_STORES,
    SET_ALL_STORE_LOCATIONS_BEFORE_FILTERS,
    SET_IS_ALL_STORES_DATA_PRESENT,
    SET_NUMBER_OF_FILTERS,
    SET_SPECIALTY_FILTER_DESCRIPTONS,
    SET_STORE_LOCATIONS,
    SET_STORE_LOCATIONS_BEFORE_FILTERS
} from './actions';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { getIPaddressApi } from '../../components/global/api/CommonResponseHandler';
import { isValidString } from '../../components/global/utils/logger';

export const initialLocationState = {
    storeLocations: {},
    storeLocationsBeforeFilters: {},
    numberOfFilters: 0,
    isAllStoresDataPresent: false,
    allStoreLocations: {},
    allStoreLocationsBeforeFilters: {},
    specialtyFilterDescriptions: []
};

export const locationReducer = (state, action) => {
    switch (action.type) {
        case SET_STORE_LOCATIONS:
            return {
                ...state,
                storeLocations: action.storeLocations
            };
        case SET_STORE_LOCATIONS_BEFORE_FILTERS:
            return {
                ...state,
                storeLocationsBeforeFilters: action.storeLocationsBeforeFilters
            };
        case SET_ALL_STORES:
            return {
                ...state,
                allStoreLocations: action.allStoreLocations
            };
        case SET_ALL_STORE_LOCATIONS_BEFORE_FILTERS:
            return {
                ...state,
                allStoreLocationsBeforeFilters: action.allStoreLocationsBeforeFilters
            };
        case SET_NUMBER_OF_FILTERS:
            return {
                ...state,
                numberOfFilters: action.numberOfFilters
            };
        case SET_IS_ALL_STORES_DATA_PRESENT:
            return {
                ...state,
                isAllStoresDataPresent: action.isAllStoresDataPresent
            };
        case SET_SPECIALTY_FILTER_DESCRIPTONS:
            return {
                ...state,
                specialtyFilterDescriptions: action.specialtyFilterDescriptions
            };
        default:
            return state;
    }
};

export const LocationContext = createContext();

export const LocationProvider = props => {
    const reducer = props.reducer || locationReducer;
    const initialState = props.initialState || initialLocationState;
    useEffect(() => {
        if (!isValidString(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.IP_ADDRESS))) {
            getIPaddressApi();
        }
    }, []);

    return (
        <LocationContext.Provider value={useReducer(reducer, initialState)}>{props.children}</LocationContext.Provider>
    );
};

LocationProvider.propTypes = {
    reducer: func,
    initialState: shape({
        storeLocations: object
    })
};

export const useLocationState = () => useContext(LocationContext);
