export const CLIENT_ERROR = {
    BAD_REQUEST: {
        code: '400-000',
        description: 'Request could not be processed as APIs is not invoked with minimal required inputs'
    },
    UNAUTHORIZED: {
        code: '401-000',
        description:
            ' Request could not be processed as user is not authorized. Please refer Logs for more details or contact API Support team.'
    },
    FORBIDDEN: {
        code: '403-000',
        description:
            'Request could not be processed as client is valid user but does not have access to requested resource.'
    },
    RESOURCE_NOT_FOUND: {
        code: '404-000',
        description:
            'Request could not be processed as requested resource that is defined as part of API URL or API Entity that does not exist.'
    },
    METHOD_NOT_ALLOWED: {
        code: '405-000',
        description:
            'Request could not be processed as server does not allow to perform specific action specified in API Request.'
    },
    NOT_ACCEPTABLE: {
        code: '406-000',
        description:
            'Request could not be processed as server cannot produce response based on input/ details defined in API Request'
    },
    UNSUPPORTED_MEDIA_TYPE: {
        code: '415-000',
        description:
            'Request could not be processed as server cannot accept the format that is specified in API Request. This is basically used for HTTP/POST or HTTP/PUT API operations.'
    }
};

export const SERVER_ERROR = {
    INTERNAL_SERVER_ERROR: {
        code: '500-000',
        description:
            'Request could not be processed due to exception encountered while processing API Request. There could be multiple scenarios like Parsing , Internal Server Error'
    },
    EXPRESSION_ERROR: {
        code: '500-001',
        description:
            'Request could not be processed due to exception encountered at MuleSoft platform related to expressions or Syntaxes while processing.'
    },
    STREAM_SIZE_ERROR: {
        code: '500-002',
        description:
            'Request could not be processed due to exception encountered at Mule-Soft platform related to data streaming or streaming size.'
    },
    SECURITY_ERROR: {
        code: '500-003',
        description:
            'Request could not be processed due to security related exception encountered while accessing back-end system / service. It can be due to one of the following scenarios Client Security , Security , Forbidden Request etc'
    },
    SERVICE_AVAILABILITY: {
        code: '500-004',
        description:
            'Request could not be processed due to service availability related exception encountered while accessing back-end system / service. It can be due to one of the following scenarios Service Unavailable , Time Out Error , Service Not Found  , Service Unavailable  , Service Method Not Allowed  / Not Acceptable  etc.'
    },
    SERVICE_CONNECTIVITY: {
        code: '500-005',
        description:
            'Request could not be processed due to service connectivity related exception encountered while accessing back-end system / service.'
    },
    NOT_FOUND: {
        code: '500-006',
        description: 'Request could not be processed as implementation does not exist at back-end systems.'
    },
    OVER_QUERY_LIMIT: {
        code: '500-007',
        description:
            'Request could not be processed due to quota restrictions at back-end systems. Please try after some time.'
    },
    REQUEST_DENIED: {
        code: '500-008',
        description: 'Request could not be processed due to authorization related issues at back-end systems.'
    }
};

export const CUSTOM_ERROR = {
    '0000': {
        code: '0000',
        description: 'Success'
    },
    '0101': {
        code: '0101',
        description: 'Invalid location._XXXXXXXX → pc number, this may occur if companyId is wrong or not given.'
    },
    '0102': {
        code: '0102',
        description: 'Invalid input details for Canada region'
    },
    401: {
        code: '401',
        description: 'Authorization Required Unauthorized - authentication failed.'
    }
};

export const ERROR_MSG = {
    NO_ERROR_CODE_MAPPING_FOUND: 'Please map the error code with specific description.',
    CART_EXPIRED: 'Could not find a cart with ID',
    INTERNAL_SERVER_ERROR: 'Internal server error',
    UNAUTHORIZED_USER: '401',
    UNAUTHORIZED_CART: 'The current user cannot perform operations on cart',
    INVALID_TOKEN: 'Invalid token',
    SESSION_EXPIRED: 'Session Expired',
    CANCELLED_REQUEST: 'Cancelled request',
    CART_INACTIVE: `The cart isn't active`,
    NO_ACCESS_TOKEN: 'Either Access Token or Guest Cart id is required',
    INVALID_ADDRESS: 'undefined, undefined, undefined, undefined',
    LOCATION_NOT_PRESENT: 'Location not present'
};
export const SUCCESS_MSG = {
    SESSION_ACTIVE: 'Session Still Active'
};
export const CANCELLED_REQUEST_ERRORS = [
    'TypeError: Failed to fetch',
    'ApolloError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
    'ApolloError: NetworkError when attempting to fetch resource.',
    'TypeError: cancelled',
    'TypeError: Cancelled',
    'ApolloError: cancelled',
    'ApolloError: Cancelled',
    'message: cancelled',
    'cancelled',
    'TypeError: Load failed',
    'ApolloError: Load failed',
    'Load failed',
    'Failed to fetch'
];
