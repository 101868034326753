import { ADDONS_SYNONYMS_ARRAY, P2P_ASIDE_NAV } from '../constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import {
    STOCK_CLASS_FUEL,
    STOCK_CLASS_LABOR
} from '../../molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import { uuid4 } from '@sentry/utils';
import axios from 'axios';
import { logError } from './logger';

const MUTATION_REVOKE_TOKEN_STRING = `
    mutation {
        revokeCustomerToken {
            result
        }
    }
`;

const AEM_COOKIE_CLEAR_ENDPOINT = '/services/logout';

const LOGOUT_STORAGE_CONFIG = {
    LOCAL_STORAGE: {
        ISCREDITNEWADDRESS: 'isCreditNewAddress',
        VIEWCART: 'viewCart',
        COMPANYID: 'companyID',
        GUID: 'guid',
        ISCHECKOUTLOADING: 'isCheckoutLoading',
        USERINFO: 'userInfo',
        PROJECTDETAILS: 'projectDetails',
        USERPROFILE: 'userProfile',
        OVERRIDEPCLIST: 'overridePCList',
        CHECKOUTREFERRER: 'checkoutReferrer',
        USER_LOGIN: 'user-login',
        USERTYPE: 'userType',
        FIRSTNAME: 'firstName',
        YOURDETAILS: 'yourDetails',
        RECENTLYVIEWED: 'recentlyViewed',
        SELECTEDSTOREDETAILS: 'selectedStoreDetails',
        NEARBYSTORES: 'nearbyStores',
        STARTINGLOCATIONFORGETDIRECTIONS: 'startingLocationForGetDirections',
        JOBSITE_SEARCH_KEYWORD: 'jobsiteSearchKeyword',
        SELECTED_ACCOUNT_ID: 'selectedAccountId',
        IP_ADDRESS: 'ipAddress',
        CARTTOTALQUANTITY: 'cartTotalQuantity',
        ISNEWADDRESSONOVERLAY: 'isNewAddressOnOverlay',
        ISINSTOREPICKUP: 'isInStorePickup',
        LOCATIONSEARCHFIELDS: 'locationSearchFields'
    },
    SESSION_STORAGE: {
        MKTOTOKEN: 'mktoToken',
        MKTOLOGGEDIN: 'mktoLoggedIn',
        OVERRIDEPC: 'overridePc',
        OVERRIDEPCLATITUDE: 'overridePcLatitude',
        OVERRIDEPCLONGITUDE: 'overridePcLongitude',
        STARTDATE: 'startDate',
        ENDDATE: 'endDate',
        SBMID: 'sbmid',
        SBSID: 'sbsid',
        CIF_MAGENTOTOKEN: 'cif.magentoToken',
        CIF_USERTOKEN: 'cif.userToken',
        ISADDONVISIBLE: 'isAddOnVisible',
        CARTCONTEXT: 'cartContext',
        PAYMENTSTEP: 'paymentStep',
        FORMSTEP: 'formStep',
        GUESTUSEREMAIL: 'guestUserEmail',
        DLNUMBERGUESTUSER: 'dlNumberGuestUser',
        DLSTATEGUESTUSER: 'dlStateGuestUser',
        TIMEOUTMESSAGETIMERID: 'timeoutMessageTimerId',
        LOCATIONPCOBJ: 'locationPCObj',
        SOURCEPCOBJ: 'sourcePCObj',
        ARROWCLICK: 'arrowClick',
        HOMEASSETSRATES: 'homeAssetsRates',
        PROJECTLOADINGTIMERID: 'projectLoadingTimerId',
        MARKETOFEED: 'marketoFeed',
        ISUSERPRESUBSCRIBED: 'isUserPreSubscribed',
        ISSOURCESLOADING: 'isSourcesLoading',
        USERTYPE: 'userType'
    },
    COOKIES: {
        CIF_MAGENTOTOKEN: 'cif.magentoToken',
        SBUID: 'sbuid',
        SBAID: 'sbaid',
        CIF_CART: 'cif.cart',
        ACCESS_TOKEN: 'access_token',
        REFRESH_TOKEN: 'refresh_token',
        SETTINGS: 'Settings',
        USERTYPE: 'userType',
        ADDPRODUCT: 'addProduct',
        CIF_USERTOKEN: 'cif.userToken',
        ACCESSTOKEN: 'accesstoken',
        COMPANYID: 'companyId',
        FIRSTNAME: 'firstName',
        REFRESHTOKEN: 'refreshtoken',
        SESSIONID: 'sessionid',
        GUEST_USER_ID: 'guest_user_id',
        GUESTSESSIONID: 'guestsessionid',
        LOGINTOKEN: 'loginToken',
        PUNCHOUT_RETURN_URL: 'punchout_return_url',
        PUNCHOUT_REDIRECT_URL: 'punchout_redirect_url',
        TOKENEXPTIME: 'tokenExpTime',
        WCMMODE: 'wcmmode',
        LOCATIONPROMPT: 'locationPrompt',
        POS: 'pos',
        BUYEREMAIL: 'buyerEMail',
        ID_TOKEN: 'id_token',
        TOKEN_TYPE: 'token_type',
        EXPIRES_IN: 'expires_in',
        AUTHORITY_TYPE: 'authority_type'
    }
};

export const setP2PLeftNavData = () => {
    const pathName = window.location.pathname.toLowerCase();
    const matchingPath = P2P_ASIDE_NAV.find(({ keyword }) => pathName.includes(keyword));
    const dropdownContent = document.querySelector('.p2puser.dropdown-content');
    if (dropdownContent) {
        const configData = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.P2P_LEFT_SIDE_NAV)) || [];
        if (!configData.length) {
            try {
                const anchorTags = dropdownContent.querySelectorAll('a');
                anchorTags.forEach(anchor => {
                    const textValue = anchor.innerText;
                    const hrefValue = anchor.href;
                    const isActive = matchingPath?.title == textValue;
                    configData.push({ value: textValue, path: hrefValue, isActive: isActive });
                });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.P2P_LEFT_SIDE_NAV, JSON.stringify(configData));
                return configData;
            } catch (error) {
                logError(error, false, 'setP2PLeftNavData');
                return [];
            }
        } else {
            const updatedActiveItem = configData.map(item =>
                item.value == matchingPath?.title ? { ...item, isActive: true } : { ...item, isActive: false }
            );
            return updatedActiveItem;
        }
    }
    return [];
};

export const isAddonItem = productObj => {
    return (
        (productObj?.forcedItem && productObj?.productData?.stockClass === STOCK_CLASS_FUEL) ||
        ADDONS_SYNONYMS_ARRAY.includes(productObj?.productData?.productType?.toLowerCase()) ||
        productObj?.productData?.stockClass === STOCK_CLASS_LABOR
    );
};

export const handleSignout = async () => {
    try {
        const [punchoutRedirectURL] = useCookieValue('punchout_redirect_url');
        const { data, error } = await logoutUser();
        if (!error) {
            await clearAemCookies();
            clearLocalStorage();
            sessionStorage.clear();
            deleteCookies();
            window.parent.location.href = punchoutRedirectURL;
        } else {
            console.error('error in miniaccount.js logoutUser', error);
        }
    } catch (error) {
        logError(error, false, 'handleSignout');
        console.error('error in miniaccount.js logoutUser', error);
    }
};

const useCookieValue = cookieName => {
    if (!cookieName || cookieName.length === 0) {
        return '';
    }
    const value = cookieValue(cookieName);
    const setCookieValue = (value, age, isSession) => {
        // let domainNameQASunbelt = 'qa-www.sunbeltrentals.com';
        // let domainNameDevSunbelt = 'dev-www.sunbeltrentals.com';
        // let domainNameLocal = 'localhost';
        const cookieSettingSunbelt = !isSession
            ? `path=/; Max-Age=${age !== undefined ? age : 3600 * 24}; SameSite=None; Secure`
            : `path=/; SameSite=None; Secure`;
        // const cookieSettingDevSunbelt = `path=/; Max-Age=${age !== undefined ? age : 3600 * 24 * 30}`;
        // const cookieSettingLocal = `path=/; Max-Age=${age !== undefined ? age : 3600 * 24 * 30}`;
        document.cookie = `${cookieName}=${value};${cookieSettingSunbelt}`;
    };
    return [value, setCookieValue];
};

const cookieValue = cookieName => {
    let name = cookieName + '=';
    let cookieVal = document.cookie;
    let ca = cookieVal.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
};

const logoutUser = async () => {
    let [magentoToken] = useCookieValue('cif.magentoToken');
    const projectInfoCookies = getProjectsFromCookie();
    if (!magentoToken) {
        magentoToken = sessionStorage.getItem('cif.magentoToken');
    }

    const { graphqlEndpoint, headers = {} } = JSON.parse(
        document.querySelector('meta[name="store-config"]')?.content ||
            document.querySelector('div[name="store-config"]')?.getAttribute('content') ||
            '{}'
    );
    const options = {
        method: 'POST',
        headers: {
            'x-correlation-Id': generateCorrelationId(),
            Authorization: `Bearer ${magentoToken}`,
            'company-id':
                parseInt(localStorage.getItem('companyID') || projectInfoCookies?.CurrentWynneAccountCID) || 1,
            ...headers,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: MUTATION_REVOKE_TOKEN_STRING,
            fetchPolicy: 'network-only'
        })
    };
    try {
        const result = await fetch(`${window.location.origin}${graphqlEndpoint}`, options);
        const logoutResult = await result.json();
        return logoutResult;
    } catch (err) {
        logError(err, false, 'logoutUser');
        console.error('revokeCustomerToken error', err);
        return { error: true, error: err };
    }
};

const getProjectsFromCookie = () => {
    var settingsCookie = cookieValue('Settings');
    var projectObj = {};
    var projectsInfo = settingsCookie?.split('&');
    for (let i in projectsInfo) {
        let cookiePair = projectsInfo[i].split('=');
        projectObj[cookiePair[0]] = decodeURIComponent(cookiePair[1]);
    }
    return projectObj;
};

const generateCorrelationId = () => {
    var sessionId = isValidString(cookieValue('guestsessionid')) || uuid4();
    let userId = cookieValue('sbuid') || cookieValue('guest_user_id');
    userId = isValidString(userId) ? userId : uuid4();

    const genericCorrelation = `${userId}${sessionId?.substring(0, 16)}`;
    return genericCorrelation ? `${genericCorrelation}${uuid4()?.substring(0, 8)}` : sessionId;
};

const isValidString = inputStr => {
    let str = String(inputStr)?.toLowerCase();
    if (
        str === 'null' ||
        str === 'undefined' ||
        str === '' ||
        str === 'false' ||
        str === 'no' ||
        str === '0' ||
        str === 'nan'
    ) {
        return false;
    } else if (str === 'true') {
        return true;
    }
    return str;
};

const clearAemCookies = () => {
    return axios({
        method: 'POST',
        url: AEM_COOKIE_CLEAR_ENDPOINT
    });
};

const deleteCookies = (exludeCookies = []) => {
    var allCookies = document.cookie
        .split(';')
        ?.filter(cookie => !exludeCookies.includes(cookie.split('=')[0]?.trim()));
    for (var i = 0; i < allCookies.length; i++)
        document.cookie =
            allCookies[i] + '=;expires=' + new Date(0).toUTCString() + '=; path=/; SameSite=None; Secure; Max-Age=0;';
};

const clearLocalStorage = (conserveKeys = []) => {
    const backupData = {};
    backupData[LOGOUT_STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID] = localStorage.getItem(
        LOGOUT_STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID
    );
    for (let key of conserveKeys) {
        backupData[key] = localStorage.getItem(key);
    }
    localStorage.clear();
    for (let key in backupData) {
        localStorage.setItem(key, backupData[key]);
    }
};
