import React from 'react';
import { useIntl } from 'react-intl';
import { oneOf, node, string, bool, func } from 'prop-types';
import './button.scss';
const Button = props => {
    const {
        className,
        children,
        priority,
        type,
        buttonAriaLabel,
        customButtonAriaLabel,
        isdisabled,
        dataTestid = '',
        btnRef,
        ...restProps
    } = props;
    const intl = useIntl();
    return (
        <button
            ref={btnRef}
            type={type}
            data-testid={dataTestid}
            {...restProps}
            className={className}
            aria-label={
                customButtonAriaLabel
                    ? `${customButtonAriaLabel}`
                    : `${intl.formatMessage({ id: 'account:ac-common-click-here-msg' })} ${buttonAriaLabel}`
            }
            disabled={isdisabled}>
            {children}
        </button>
    );
};

Button.propTypes = {
    type: oneOf(['button', 'reset', 'submit']),
    children: node,
    buttonAriaLabel: string,
    customButtonAriaLabel: string,
    isdisabled: bool,
    dataTestid: string,
    className: string,
    btnRef: func
};

Button.defaultProps = {
    buttonAriaLabel: '',
    customButtonAriaLabel: '',
    isdisabled: false,
    dataTestid: '',
    className: '',
    btnRef: () => {}
};
export default Button;
