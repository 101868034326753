let googleApiKey = document.querySelector('meta[data-endpoint-key="googleApiKey"]')?.dataset?.endpointValue || '';

(function () {
    const href = window.location.href;
    if (href.includes('customer-support/forms/contact/')) {
        const script = document.createElement('script');
        script.setAttribute('src', 'https://pages.sunbeltrentals.com/js/forms2/js/forms2.min.js');
        // script.async = true;
        script.setAttribute('defer', '');

        // we are using google map script for request a from button

        document.head.appendChild(script);
    }

    if (href.indexOf('/location/') == -1 && href.indexOf('/training/safety/trainer-locations') == -1) {
        const locationScript = document.createElement('script');
        locationScript.setAttribute(
            'src',
            `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places`
        );
        locationScript.setAttribute('defer', '');
        document.head.appendChild(locationScript);
    }
})();
