import isObjectWithKeys from './isObjectWithKeys';

export default error => {
    try {
        if (error?.message) {
            return error?.message;
        } else if (isObjectWithKeys(error?.networkError)) {
            return error.networkError;
        } else if (error?.graphQLErrors?.length > 0) {
            return error.graphQLErrors[0].message;
        } else {
            return JSON.stringify(error);
        }
    } catch (err) {
        JSON.stringify(err);
    }
};
