import { useMutation } from '@apollo/client';
import GET_FAVORITES from '../aem-core-components/queries/query_favourites.graphql';
import ADD_TO_FAVOURITES from '../aem-core-components/queries/mutation_addToFavourites.graphql';
import REMOVE_FROM_WISHLIST from '../aem-core-components/queries/mutation_removeFromFavourites.graphql';
import {
    addFavouritesFromPdp,
    getPdpDetails,
    removeFavouritesFromPdp
} from '../aem-core-components/actions/pdp/actions';
import { logError } from '../components/global/utils/logger';

const useFavourites = () => {
    const [pdpFavouritesQuery] = useMutation(GET_FAVORITES);
    const [addToFavWishlist] = useMutation(ADD_TO_FAVOURITES);
    const [removeFromFavWishlist] = useMutation(REMOVE_FROM_WISHLIST);
    const getFavourites = async accountNumber => {
        try {
            const { wishlist, error } = await getPdpDetails({ pdpFavouritesQuery, accountNumber });
            if (error) {
                logError(error, false, 'getFavourites', [accountNumber]);
            }
            return wishlist;
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'getFavourites', [accountNumber]);
            } else {
                logError(error, false, 'getFavourites', [accountNumber]);
            }
        }
    };
    const addToFavouritesWishlist = async (accountNumber, sku) => {
        try {
            const { favorites, error } = await addFavouritesFromPdp({
                addToFavWishlist,
                accountNumber,
                sku
            });
            if (error) {
                logError(error, false, 'addToFavouritesWishlist', [accountNumber, sku]);
            }
            return favorites;
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'addToFavouritesWishlist', [accountNumber, sku]);
            } else {
                logError(error, false, 'addToFavouritesWishlist', [accountNumber, sku]);
            }
        }
    };
    const removeFavouritesWishlist = async (accountNumber, favoriteID) => {
        try {
            const { data, error } = await removeFavouritesFromPdp({
                removeFromFavWishlist,
                accountNumber,
                favoriteID
            });
            if (error) {
                logError(error, false, 'removeFavouritesFromPdp', [accountNumber, favoriteID]);
            }
            return { data, error };
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'removeFavouritesWishlist', [accountNumber, favoriteID]);
            } else {
                logError(error, false, 'removeFavouritesWishlist', [accountNumber, favoriteID]);
            }
        }
    };
    const api = { getFavourites, addToFavouritesWishlist, removeFavouritesWishlist };
    return api;
};
export default useFavourites;
