var topcategoryActions = document?.querySelectorAll('.topcategories__action');
var topcategoryDataSet = document?.querySelector(".topcategories")?.dataset;

const { showmore = '', showless = '', showmorearialabel = '', showlessarialabel = ''} = topcategoryDataSet || {};

var toggleButtonText = function (button, textBeforeClick, textAfterClick) {
    // Check the current text content of the button
    if (button.textContent === textBeforeClick) {
        // Toggle to a different text when clicked
        button.textContent = textAfterClick;
        button.ariaLabel= showlessarialabel
    } else {
        // Toggle back to the original text when clicked again
        button.textContent = textBeforeClick;
        button.ariaLabel= showmorearialabel
    }
}

topcategoryActions.forEach(function (topcatAction) {
    topcatAction.textContent = showmore;
    topcatAction.ariaLabel= showmorearialabel
    topcatAction?.addEventListener('click', function () {
        let topcatActionClosest = topcatAction?.closest('.topcategories__row');
        topcatActionClosest?.classList?.toggle('topcategories__row-show');
        toggleButtonText(topcatAction, showmore, showless);
    });
});
