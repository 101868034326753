import axiosInstance from '../../../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../../../constants/apiConfig';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import { updateStoreBasedOnSelectedStore } from '../../../utils/computeAddressUtil';
const { GET_PC } = API_CONFIG;
/* assets structure
 "equipmentCategory": 68,
"equipmentClass": 210,
"requestedQuantity": 1,
"sequenceNumber": 0
*/
const getStoresData = async ({
    latitude,
    longitude,
    pickupDateTime,
    returnDateTime,
    assets = [],
    isDelivery = false,
    isExpressDelivery = false
}) => {
    try {
        if (assets?.length > 0) {
            let deliveryStoreIndex = 0;
            const storePayload = {
                companyId: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                sourceSystem: 'B2B',
                isDelivery: isDelivery,
                latitude,
                longitude,
                startDateTime: pickupDateTime,
                endDateTime: returnDateTime,
                products: assets,
                sessionId: 'defaultSessionId',
                expressDelivery: isExpressDelivery
            };
            const { data, error, status } = await axiosInstance.post(
                GET_PC,
                { ...storePayload },
                {
                    headers: {
                        companyId: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
                    }
                }
            );
            deliveryStoreIndex = updateStoreBasedOnSelectedStore(data, deliveryStoreIndex);
            return { data, error, status, deliveryStoreIndex };
        } else {
            return { error: 'items not present' };
        }
    } catch (error) {
        return { error };
    }
};
export { getStoresData };
