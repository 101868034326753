import { gql } from '@apollo/client';

const REMOVE_PRODUCT_FROM_WISHLIST = gql`
    mutation removeProductFromWishlist(
        $wishlistId: ID!,
        $wishlistItemId: [ID!]!,
        $productSortBy: WishlistProductSortingEnum!,
        $productPageNo: Int,
        $productsSize: Int,
        $accountId: Int!
    ){
        removeProductsFromWishlist(
            wishlistId: $wishlistId
            wishlistItemsIds: $wishlistItemId
            accountId: $accountId
        ) {
            wishlist{
            id
            name
            visibility
            items_count
            updated_at
            items_v2(
                currentPage: $productPageNo
                pageSize: $productsSize
                sortBy: $productSortBy
            ) {
                items{
                    id
                    product {
                        sku
                        name
                        thumbnail {
                        url
                        }
                    }
                }
                page_info {
                    current_page
                    page_size
                    total_pages
                }
            }
            }
        }
        }
`;
export default REMOVE_PRODUCT_FROM_WISHLIST;