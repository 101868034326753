import { bool, func, instanceOf, oneOfType, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Calendar from '../../../../resources/images/calendar.svg';
import SmallCalendarIcon from '../../../../resources/images/calendar_20x20.svg';
import Error from '../../../../resources/images/error.svg';
import './datePickerInput.scss';
import { ENTER_KEY } from '../../../../constants/screenConstants';
import { datePickerDataLocators } from './dataLocators';
import { useCartState } from '../../../../contexts/cart/cartContext';
import { SET_START_CLICK, SET_END_CLICK } from '../../../../aem-core-components/actions/constants';
import { isFourHourRental } from '../../utils/commonUtils';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';

export default function DatePickerInput({
    startDate,
    endDate,
    handleToggle,
    isVisibleInModal,
    isResetClicked,
    startDateError,
    endDateError,
    titleStartDate,
    titleEndDate,
    isOpen,
    showWarning,
    endDateLabel,
    showIconWithLabel,
    startDateLabel,
    showDatePlaceholder,
    dynamicWidth,
    isWrapperClickable,
    ...rest
}) {
    const intl = useIntl();
    const endDateLabelValue = endDateLabel || intl.formatMessage({ id: 'cart-header:end-date' });
    const startDateLabelValue = startDateLabel || intl.formatMessage({ id: 'cart-header:start-date' });

    const [{ cart, startDateClick, endDateClick, calendarDateInteraction }, dispatch] = useCartState();
    const handleStartDateKeyPress = e => {
        if (e.key === ENTER_KEY) {
            handleStartClick();
        }
    };

    const handleEndDateKeyPress = e => {
        if (e.key === ENTER_KEY) {
            handleEndClick();
        }
    };
    const handleStartClick = () => {
        dispatch({ type: SET_START_CLICK });
        handleToggle(VARIABLE_CONFIG.EVENT.START_DATE_CLICK);
    };
    const handleEndClick = () => {
        dispatch({ type: SET_END_CLICK });
        handleToggle(VARIABLE_CONFIG.EVENT.END_DATE_CLICK);
    };

    const showEndDateError = () => {
        if (endDateError) {
            return true;
        }
        if (endDate == startDate && startDate != '') {
            if (isFourHourRental(cart) && showWarning) {
                // if is a four hr rental and warning is visible
                return true;
            }
        }
        return false;
    };
    const handleEndDateFocus = () => {
        if (isOpen && !showEndDateError()) {
            if (startDate !== '') {
                if (calendarDateInteraction && endDate === '') {
                    return true;
                } else if (!calendarDateInteraction && endDateClick) {
                    return true;
                } else if (calendarDateInteraction && startDate && endDate) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    };
    const handleStartDateFocus = () => {
        if (isOpen) {
            if (isResetClicked) {
                return true;
            }
            if (!calendarDateInteraction) {
                if (startDateClick) {
                    return true;
                } else if (endDateClick && endDate === '' && startDate === '') {
                    return true;
                } else {
                    return false;
                }
            }
        }
        return false;
    };
    const getPlaceholderValue = label => {
        if (isVisibleInModal) {
            return label;
        } else if (showDatePlaceholder) {
            return intl.formatMessage({ id: 'common:date-placeholder' });
        }
        return '';
    };
    const getAccessibilityProps = ({ isWrapper, isStartDate }) => {
        const error = isStartDate ? startDateError : endDateError;
        const selectedDate = isStartDate ? startDate : endDate;
        let label = isStartDate ? startDateLabelValue : endDateLabelValue;
        if (isWrapper && selectedDate) {
            label = `${label} ${intl.formatMessage({ id: 'common:selected' })} ${selectedDate}`; // label - 'Start date selected 18/10/23'
        }
        if (isWrapper === isWrapperClickable) {
            return {
                onClick: isStartDate ? handleStartClick : handleEndClick,
                onKeyPress: isStartDate ? handleStartDateKeyPress : handleEndDateKeyPress
            };
        } else {
            return {};
        }
    };
    return (
        <div className={`rangepicker ${rest.customClass}`}>
            <div
                className={`rangepicker__start ${rest.inputCustomClass}`}
                {...getAccessibilityProps({ isWrapper: true, isStartDate: true })}
                tabIndex={dynamicWidth ? 0 : -1}>
                {isVisibleInModal ? null : (
                    <label className={`rangepicker__label ${rest.datePickerLabelCustomClass}`}>
                        {showIconWithLabel && (
                            <SmallCalendarIcon
                                className="rangepicker__icon-with-label"
                                tabIndex={-1}
                                aria-hidden={true}
                            />
                        )}
                        {startDateLabelValue}
                    </label>
                )}
                <div
                    {...getAccessibilityProps({ isWrapper: false, isStartDate: true })}
                    className={`rangepicker__input-wrap ${
                        startDate ? 'rangepicker__input-wrap--withvalue' : 'rangepicker__input-wrap--withoutvalue'
                    }`}
                    data-testid={datePickerDataLocators.rangepicker_start_date_test_id}>
                    {!dynamicWidth && (
                        <input
                            title={`${titleStartDate} `}
                            className={`cmp-Field__field__input rangepicker__input ${startDateError && 'inputError'} ${
                                handleStartDateFocus() && 'inputFocus'
                            }`}
                            value={isVisibleInModal ? (!isResetClicked ? startDate : '') : startDate}
                            placeholder={getPlaceholderValue(startDateLabelValue)}
                            aria-label={startDateLabelValue}
                        />
                    )}
                    {(!showIconWithLabel || isVisibleInModal) && (
                        <Calendar className={'rangepicker__icon'} tabIndex={-1} aria-hidden={true} />
                    )}
                    {dynamicWidth && (
                        <span className="rangepicker__date" aria-live={startDate ? 'polite' : 'off'}>
                            {startDate && !isResetClicked ? startDate : getPlaceholderValue(startDateLabelValue)}
                        </span>
                    )}
                </div>
                {startDateError && (
                    <div className="dateselector__errorContainer">
                        <Error />
                        <span className="dateselector__errorText">{startDateError}</span>
                    </div>
                )}
            </div>
            <div
                className="rangepicker__end"
                {...getAccessibilityProps({ isWrapper: true, isStartDate: false })}
                tabIndex={dynamicWidth ? 0 : -1}>
                {isVisibleInModal ? null : (
                    <label
                        className={`rangepicker__label ${rest.datePickerLabelCustomClass}`}
                        data-testid="rangepicker__label_end">
                        {showIconWithLabel && (
                            <SmallCalendarIcon
                                className="rangepicker__icon-with-label"
                                tabIndex={-1}
                                aria-hidden={true}
                            />
                        )}
                        {endDateLabelValue}
                    </label>
                )}
                <div
                    {...getAccessibilityProps({ isWrapper: false, isStartDate: false })}
                    className={`rangepicker__input-wrap ${
                        endDate ? 'rangepicker__input-wrap--withvalue' : 'rangepicker__input-wrap--withoutvalue'
                    }`}
                    data-testid={datePickerDataLocators.rangepicker_end_date_test_id}>
                    {!dynamicWidth && (
                        <input
                            title={`${titleEndDate} `}
                            className={`cmp-Field__field__input rangepicker__input ${
                                showEndDateError() && 'inputError'
                            } ${handleEndDateFocus() && 'inputFocus'}`}
                            value={isVisibleInModal ? (!isResetClicked ? endDate : '') : endDate}
                            placeholder={getPlaceholderValue(endDateLabelValue)}
                            aria-label={endDateLabelValue}
                        />
                    )}
                    {(!showIconWithLabel || isVisibleInModal) && (
                        <Calendar className={'rangepicker__icon'} tabIndex={-1} aria-hidden={true} />
                    )}
                    {dynamicWidth && (
                        <span className="rangepicker__date" aria-live={endDate ? 'polite' : 'off'}>
                            {endDate && !isResetClicked ? endDate : getPlaceholderValue(endDateLabel)}
                        </span>
                    )}
                </div>
                {endDateError && (
                    <div className="dateselector__errorContainer">
                        <Error />
                        <span className="dateselector__errorText">{endDateError}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
DatePickerInput.defaultProps = {
    startDate: '',
    endDate: '',
    handleToggle: () => {},
    isResetClicked: false,
    isVisibleInModal: false,
    inputCustomClass: '',
    datePickerLabelCustomClass: '',
    startDateError: '',
    endDateError: '',
    titleStartDate: '',
    titleEndDate: '',
    isOpen: false,
    showWarning: false,
    endDateLabel: '',
    showIconWithLabel: false,
    startDateLabel: '',
    showDatePlaceholder: false,
    dynamicWidth: false,
    isWrapperClickable: false
};

DatePickerInput.propsType = {
    startDate: oneOfType([instanceOf(Date), string]),
    endDate: oneOfType([instanceOf(Date), string]),
    handleToggle: func,
    isResetClicked: bool,
    isVisibleInModal: bool,
    inputCustomClass: string,
    datePickerLabelCustomClass: string,
    startDateError: string,
    endDateError: string,
    titleStartDate: string,
    titleEndDate: string,
    isOpen: bool,
    showWarning: bool,
    endDateLabel: string,
    startDateLabel: string,
    showIconWithLabel: bool,
    showDatePlaceholder: bool,
    dynamicWidth: bool,
    isWrapperClickable: bool
};
