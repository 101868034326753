import { gql } from '@apollo/client';

export const UPDATE_ACCOUNT_FAVORITE_LIST = gql`
  mutation AddCustomerAccountToFavorites($account_id: Int!) {
    addCustomerAccountToFavorites(
        account_id: $account_id
     )
  }
`;

export default UPDATE_ACCOUNT_FAVORITE_LIST;