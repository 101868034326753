import { gql } from '@apollo/client';

const FETCH_ACCOUNTS_PROJECTS = gql`
    mutation getSbrJobSitesMutation(
        $accountId: String!
        $search: String
        $limit: Int!
        $includeActiveEquipment: String!
        $invalidateCache: Boolean
    ) {
        getSbrJobSites(
            accountId: $accountId
            search: $search
            limit: $limit
            includeActiveEquipment: $includeActiveEquipment
            invalidateCache: $invalidateCache
        ) {
            status
            message
            data {
                equipmentCount
                projectKey
                salesRepId
                projectId
                jobsiteId
                projectName
                accountName
                deliveryInstructions1
                deliveryInstructions2
                deliveryInstructions3
                deliveryInstructions4
                Location
                companyId
                isActive
                customerPO
                CJDLVY
                contact {
                    name
                    phone
                    id
                }
                address {
                    attn
                    city
                    line1
                    line2
                    state
                    zip
                    latitude
                    longitude
                    locationPC
                }
            }
        }
    }
`;

export default FETCH_ACCOUNTS_PROJECTS;
