import { gql } from '@apollo/client';

const ADD_WISHLIST = gql`
    mutation addWishlist($name: String!, $accountId: Int!) {
        createWishlist(input: { name: $name, accountId: $accountId, visibility: PRIVATE }) {
            wishlist {
                id
                name
                visibility
                items_count
                updated_at
            }
        }
    }
`;
export default ADD_WISHLIST;
