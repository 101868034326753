window.addEventListener('load', () => {
    try {
        const herosearchBox = document?.querySelector('.herosearch__box');
        const mobileAppDownloadModal = document?.querySelector('.mobileAppDownloadModal');

        const setWindowScroll = scrollHeight => {
            window?.scrollTo({
                top: scrollHeight,
                behavior: 'smooth'
            });
        };

        if (herosearchBox) {
            const minusValue = 16;
            const mediaQuery = window.matchMedia('(max-width: 639.98px)');
            const mobileAppDownloadModalShow =
                mobileAppDownloadModal?.classList?.contains('mobileAppDownloadModal--show');

            if (mediaQuery.matches) {
                herosearchBox?.addEventListener('focusin', () => {
                    if (herosearchBox?.contains(document?.activeElement)) {
                        const mobileAppDownloadModalHeight =
                            mobileAppDownloadModalShow && mobileAppDownloadModal?.clientHeight;
                        // Get the bounding rectangle when the input is focused
                        const herosearchBoxGetBounding = herosearchBox.getBoundingClientRect();
                        const herosearchBoxTop = herosearchBoxGetBounding.top + window.scrollY;
                        if (mobileAppDownloadModalHeight) {
                            setWindowScroll(herosearchBoxTop - (mobileAppDownloadModalHeight + minusValue));
                        } else {
                            setWindowScroll(herosearchBoxTop - minusValue);
                        }
                    }
                });
                herosearchBox?.addEventListener('focusout', () => {
                    // Reset to the original scroll position when focus is lost
                    setWindowScroll(0);
                });
            }
        }
    } catch (error) {
        console.error('An error occurred while initializing the Hero Search wrapper:', error);
    }
});
