import React, { memo, useEffect, useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useFilterState } from '../filterContext';
import { useCapUtils } from '../hooks/useCapUtils';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import JobsiteAddressAutoComplete from '../../global/modules/jobsitesAddressAutoComplete';
import SideDrawerModal from '../../global/modules/modals/SideDrawerModal';
import ErrorInput from '../../global/atoms/errorInput/ErrorInput';
import BackIcon from '../../../resources/images/arrow-ios-left.svg';
import CloseIcon from '../../../resources/images/cross_24x24.svg';
import Button from '../../global/atoms/button/button';
import { createFullAddress } from '../utils/capHelper';
import './drawerRentalLocation.scss';
import { capDataLocator } from '../dataLocator';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { AUTHORITY_TYPE } from '../../global/constants';
import { EDIT_VIEW_CLOSE } from '../constants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

const DrawerRentalLocation = ({
    addressText,
    userType,
    setIsCapInteracted,
    isJobsiteSelected,
    jobsiteDetails,
    isCurrentLocationDisabled,
    jobAndAddressState,
    setJobAndAddressState,
    locationContainerRef,
    isAddressChanged,
    setIsAddressChanged,
    stringSearchedForSuggestion,
    setStringSearchedForSuggestion,
    onFocusRentalLocation,
    onAddressSelect,
    onJobsiteSelect
}) => {
    const capIntl = useIntl();
    const [{ projectDetails, editViewSource, showAvsErrorInCap }, dispatch] = useFilterState();
    const { getCapUserDetails, getCapLocationLabel } = useCapUtils();
    const avsResponse = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.AVS_RESPONSE) || '{}');
    const { avsError } = avsResponse || {};
    const [showDrawer, setShowDrawer] = useState(false);
    const rentalLocationLabel =
        getCapUserDetails()?.rentalLocationText || capIntl.formatMessage({ id: 'cap:rental-location' });
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;

    const chooseAccountAndJobsiteLabel = getCapLocationLabel(rentalLocationLabel);
    const jobsiteLabel = isP2PUser ? capIntl.formatMessage({ id: 'cap:jobsite' }) : rentalLocationLabel;
    const labelBasedOnEmptyField =
        addressText || jobAndAddressState?.chosenJobName ? jobsiteLabel : chooseAccountAndJobsiteLabel;
    const placeholderText = isP2PUser ? '' : capIntl.formatMessage({ id: 'cap:location-drawer-placeholder' });
    const [showJobsitesDrawer, setShowJobsitesDrawer] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [showCustomFocus, setShowCustomFocus] = useState(false);

    useEffect(() => {
        setSelectedProjectId(projectDetails?.selectedProjectJobId);
    }, [projectDetails?.selectedProjectLatititude]);

    useEffect(() => {
        if (editViewSource === VARIABLE_CONFIG.CAP_VIEW_MODE.LOCATION_VIEW) {
            setShowCustomFocus(true);
        }
    }, [editViewSource]);

    const closeViewMode = () => {
        setIsCapInteracted(false);
        dispatch({ type: EDIT_VIEW_CLOSE });
    };

    const toggleDrawer = () => {
        setShowDrawer(!showDrawer);
        setShowCustomFocus(false);
    };

    const addressSelectHandler = (address, currentLocationDisabledState, placeId) => {
        onAddressSelect(address, currentLocationDisabledState, placeId);
        setShowDrawer(false);
    };

    const jobsiteSelectHandler = async (jobsite, fullAddress, currentLocationDisabledState) => {
        onJobsiteSelect(jobsite, fullAddress, currentLocationDisabledState);
        setShowDrawer(false);
    };

    const jobsiteAddressDrawerHeading = () => {
        return (
            <>
                <Button
                    className="back-btn"
                    dataTestid={capDataLocator.back_btn_testid}
                    customButtonAriaLabel={capIntl.formatMessage({ id: 'cap:account-ac-minicart-back-btn-aria-label' })}
                    onClick={toggleDrawer}>
                    <BackIcon />
                    {capIntl.formatMessage({ id: 'cap:back' })}
                </Button>
                <Button
                    customButtonAriaLabel={capIntl.formatMessage({ id: 'cap:common-close-dialog' })}
                    onClick={closeViewMode}>
                    <CloseIcon />
                </Button>
            </>
        );
    };

    const labelContent = () => (
        <label
            className={`location-label ${showAvsErrorInCap ? 'drawer-rental-location-error__label' : ''}`}
            htmlFor={capDataLocator.rental_location_input_testid}>
            {labelBasedOnEmptyField}
        </label>
    );

    const renderAutoComplete = () => {
        return (
            <>
                {labelContent()}
                <JobsiteAddressAutoComplete
                    inputId={capDataLocator.rental_location_input_testid}
                    autocompleteWrapper={`cap-location-drawer ${
                        showAvsErrorInCap ? 'drawer-rental-location-error__input-wrapper' : ''
                    }`}
                    placeholderText={placeholderText}
                    prefilledAddress={addressText}
                    isLocationAvailable={userType !== USER_TYPE.GUEST}
                    userType={userType}
                    selectedAddress={addressSelectHandler}
                    onJobsiteSelect={jobsiteSelectHandler}
                    isLocationPersisted
                    focusInput
                    selectedProjectId={selectedProjectId}
                    setSelectedProjectId={setSelectedProjectId}
                    isAddressChanged={isAddressChanged}
                    setIsAddressChanged={setIsAddressChanged}
                    showJobsitesDrawer={showJobsitesDrawer}
                    setShowJobsitesDrawer={setShowJobsitesDrawer}
                    disableCurrentLocation={isCurrentLocationDisabled}
                    onLocationInputFocus={onFocusRentalLocation}
                    setIsCapInteracted={setIsCapInteracted}
                    jobAndAddressState={jobAndAddressState}
                    setJobAndAddressState={setJobAndAddressState}
                    displayJobsiteModal={isP2PUser}
                    stringSearchedForSuggestion={stringSearchedForSuggestion}
                    setStringSearchedForSuggestion={setStringSearchedForSuggestion}
                    inputErrorMsg={showAvsErrorInCap ? avsError : ''}
                />
            </>
        );
    };

    const renderLocation = () => {
        if (!addressText) {
            return (
                <Button
                    className={`rental-input-btn placeholder-text ${
                        showAvsErrorInCap ? 'drawer-rental-location-error__input' : ''
                    } ${showCustomFocus ? 'rental-btn-focus' : ''}`}
                    onClick={toggleDrawer}
                    customButtonAriaLabel={`${capIntl.formatMessage({ id: 'cap:rental-location' })}`}
                    data-testid={capDataLocator.location_input_btn_testid}>
                    {capIntl.formatMessage({ id: 'cap:location-drawer-placeholder' })}
                </Button>
            );
        } else if (isJobsiteSelected && jobsiteDetails?.projectName) {
            return (
                <Button
                    className={`rental-input-btn jobsite-details text-ellipsis ${
                        showAvsErrorInCap ? 'drawer-rental-location-error__input' : ''
                    } ${showCustomFocus ? 'rental-btn-focus' : ''}`}
                    onClick={toggleDrawer}
                    buttonAriaLabel={capIntl.formatMessage({ id: 'cap:location-drawer-placeholder' })}
                    data-testid={capDataLocator.location_input_btn_testid}>
                    <span className="jobsite-name text-ellipsis">{jobsiteDetails?.projectName}</span>{' '}
                    {createFullAddress(jobsiteDetails)}
                </Button>
            );
        } else {
            return (
                <Button
                    className={`rental-input-btn text-ellipsis ${
                        showAvsErrorInCap ? 'drawer-rental-location-error__input' : ''
                    } ${showCustomFocus ? 'rental-btn-focus' : ''}`}
                    onClick={toggleDrawer}
                    customButtonAriaLabel={`${addressText} ${capIntl.formatMessage({
                        id: 'cap:common-contents-selected'
                    })} ${capIntl.formatMessage({
                        id: 'cap:rental-location'
                    })}`}
                    data-testid={capDataLocator.location_input_btn_testid}>
                    {addressText}
                </Button>
            );
        }
    };

    const renderLocationFieldGroup = () => (
        <div>
            {labelContent()}
            {renderLocation()}
            {showAvsErrorInCap && (
                <ErrorInput customClassName="drawer-rental-location-error__alert" errorMsg={avsError} />
            )}
        </div>
    );

    return (
        <>
            {renderLocationFieldGroup()}
            {showDrawer && (
                <SideDrawerModal
                    isModalOpen
                    handleModalToggle={toggleDrawer}
                    header={jobsiteAddressDrawerHeading()}
                    content={renderAutoComplete()}
                    modalContentClass="rental-drawer-location__content"
                    modalHeaderClass="rental-drawer-location__header"
                />
            )}
        </>
    );
};

DrawerRentalLocation.defaultProps = {
    userType: USER_TYPE.GUEST,
    addressText: '',
    setIsCapInteracted: () => {},
    isJobsiteSelected: false,
    jobsiteDetails: {},
    isCurrentLocationDisabled: false,
    locationContainerRef: () => {},
    isAddressChanged: false,
    setIsAddressChanged: () => {},
    stringSearchedForSuggestion: '',
    setStringSearchedForSuggestion: () => {},
    onFocusRentalLocation: () => {},
    onAddressSelect: () => {},
    onJobsiteSelect: () => {}
};

DrawerRentalLocation.propTypes = {
    addressText: string,
    userType: string,
    setIsCapInteracted: func,
    isJobsiteSelected: bool,
    jobsiteDetails: object,
    isCurrentLocationDisabled: bool,
    locationContainerRef: func,
    isAddressChanged: bool,
    setIsAddressChanged: func,
    stringSearchedForSuggestion: string,
    setStringSearchedForSuggestion: func,
    onFocusRentalLocation: func,
    onAddressSelect: func,
    onJobsiteSelect: func
};

export default memo(DrawerRentalLocation);
