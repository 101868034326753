import { node, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useIdleTimer, workerTimers } from 'react-idle-timer';
import { useIntl } from 'react-intl';
import Modal from 'react-modal';
import { useCartState } from '../../../../contexts/cart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAuthorityToken } from '../../../../aem-core-components/utils/hooks';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useAnalytics from '../../../../hooks/useAnalytics';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { ERROR_MSG } from '../../../../constants/errorMappingCodes';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { useCheckAuthorityType, useCheckUser } from '../../../../hooks/useCheckUser';
import AlertTriangle from '../../../../resources/images/alert-triangle.svg';
import Close from '../../../../resources/images/close-green.svg';
import config from '../../../App/config';
import { AUTHORITY_TYPE } from '../../constants';
import SessionTimeout from '../../modules/SessionTimeout/sessionTimeout';
import { isCCPage, isCheckoutPage } from '../../utils/commonUtils';
import { logError, logWarning } from '../../utils/logger';
import Button from '../button';
import './timeout.scss';

const Timeout = props => {
    // children is to load the application, DO NOT remove
    const { title, children } = props;
    const intl = useIntl();
    const userType = useCheckUser();
    const authorityType = useCheckAuthorityType();
    const [{ cart, errorMessage }] = useCartState();
    const [
        { isProfileLoaded },
        { logoutUser, resetUser, validateAndGenerateToken, handleLogoutForP2P }
    ] = useUserContext();
    const { sendEventsForPageLoad } = useAnalyticsContext();
    const isEmpty = cart && Object.entries(cart)?.length > 0 ? cart?.items?.length === 0 : true;
    const titleRef = useRef(null);

    // constants here
    const seconds = 60;
    const minutes =
        parseInt(
            ENV_CONFIG?.GENERIC_VARIABLES_META?.sessionExpiryTime ||
                ENV_CONFIG?.GENERIC_VARIABLES_DIV?.sessionExpiryTime
        ) || 59;
    const timeout = minutes * seconds * 1000; // 59 minutes
    const promptBeforeIdle = seconds * 1000;
    // constants ends

    // Modal open state
    const [open, setOpen] = useState(false);
    const [getSec, setGetSec] = useState(seconds);
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const [forceLogout, setForceLogout] = useState({ invoked: false, sessionExpired: false, returnUrl: '' });

    //SessionModal
    const [sessionModal, setSessionModal] = useState(false);

    const getMessageTimerID = () => {
        return sessionStorage.getItem('timeoutMessageTimerId');
    };

    const setMessageTimerID = id => {
        sessionStorage.setItem('timeoutMessageTimerId', id);
    };

    const resetUserSession = () => {
        if (userType !== 'guest') {
            handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.LOGOUT_ON_ERROR);
        } else {
            resetUser();
            window.location.reload();
        }
    };

    useEffect(() => {
        if (isProfileLoaded) {
            sendEventsForPageLoad(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_GLOBAL_USER_DATA_EVENT,
                payloadEcommerceLocationActionAnalytics(false, true)
            );
        }
    }, [isProfileLoaded]);

    useEffect(() => {
        const body = document?.querySelector('body');
        const liveChatButton = document?.querySelector('.chat-button');
        if (open) {
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'true';
                liveChatButton.tabIndex = '-1';
            }
        } else {
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'false';
                liveChatButton.tabIndex = '0';
            }
        }
    }, [open]);

    /** to handle cart expiry in case the graphql doesnt reach error.js for eg, checkout page */
    useEffect(() => {
        try {
            if (
                errorMessage &&
                (errorMessage.includes(ERROR_MSG.CART_EXPIRED) ||
                    errorMessage.includes(ERROR_MSG.UNAUTHORIZED_USER) ||
                    errorMessage.includes(ERROR_MSG.UNAUTHORIZED_CART) ||
                    errorMessage.includes(ERROR_MSG.CART_INACTIVE))
            ) {
                if (!isEmpty && !window.location.href.includes(config.pagePaths.checkoutPage)) {
                    alert('Your cart has been expired'); //Dont show alert for checkout page DOTC-18863
                }
                resetUserSession();
            }
        } catch (ex) {
            alert('Error Occurred, Your cart has been expired');
            resetUserSession();
        }
    }, [errorMessage]);

    useEffect(() => {
        if (forceLogout?.invoked) {
            logoutUser('', forceLogout?.sessionExpired, forceLogout?.returnUrl);
        }
    }, [forceLogout]);

    // Message handler
    const onMessage = data => {
        switch (data?.action) {
            case VARIABLE_CONFIG.TIMER_MODAL_ACTION.CONTINUE_SESSION:
                resetActivity();
                start();
                break;
            case VARIABLE_CONFIG.TIMER_MODAL_ACTION.RELOGIN:
                resetActivity();
                setForceLogout({ invoked: true, sessionExpired: true, returnUrl: '' });
                break;
            case VARIABLE_CONFIG.TIMER_MODAL_ACTION.RELOGIN_WITH_REDIRECTION:
                resetActivity();
                setForceLogout({ invoked: true, sessionExpired: true, returnUrl: window.location.href });
                break;
            case VARIABLE_CONFIG.TIMER_MODAL_ACTION.RESET_USER:
                resetActivity();
                setForceLogout({ invoked: true, sessionExpired: false, returnUrl: window.location.href });
                break;
            case VARIABLE_CONFIG.TIMER_MODAL_ACTION.LOGOUT_ON_ERROR:
                resetActivity();
                setForceLogout({ invoked: true, sessionExpired: false, returnUrl: '' });
                break;
            case VARIABLE_CONFIG.P2P.LOGOUT:
                handleLogoutForP2P();
                break;
            default:
                setForceLogout({ invoked: true, sessionExpired: false, returnUrl: '' });
        }
    };

    const onContinueSession = async () => {
        try {
            const [refreshToken] = useAuthorityToken('refreshtoken');
            handleContinueSession();
            if (refreshToken) {
                const data = await validateAndGenerateToken(true);
                if (!(data?.access_token && data?.refresh_token)) {
                    handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.LOGOUT_ON_ERROR);
                }
            } else {
                handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.LOGOUT_ON_ERROR);
            }
        } catch (e) {
            logError('session timed out onContinueSession', false);
        }
    };

    const resetActivity = () => {
        setOpen(false);
        setGetSec(seconds);
        clearInterval(getMessageTimerID());
    };

    const handleContinueSession = () => {
        message({ action: VARIABLE_CONFIG.TIMER_MODAL_ACTION.CONTINUE_SESSION }, true);
    };

    const handleSignout = (logoutCase = VARIABLE_CONFIG.TIMER_MODAL_ACTION.LOGOUT_ON_ERROR) => {
        message({ action: logoutCase }, true);
    };

    const handleRedirection = () => {
        if (userType !== USER_TYPE.GUEST && !window.location.pathname.includes('/login')) {
            switch (authorityType) {
                case AUTHORITY_TYPE.DOTCOM:
                    if (isCCPage()) {
                        handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.RELOGIN);
                    } else if (isCheckoutPage()) {
                        handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.RELOGIN_WITH_REDIRECTION);
                    } else {
                        setSessionModal(true);
                        setOpen(false);
                    }
                    break;

                case AUTHORITY_TYPE.P2P:
                case AUTHORITY_TYPE.PUNCHOUT:
                    handleSignout(VARIABLE_CONFIG.P2P.LOGOUT);
                    break;

                default:
                    break;
            }
        }
    };

    const onPrompt = () => {
        const isUserLoggedIn = userType !== USER_TYPE.GUEST;
        const isNotLoginPage = !window.location.pathname.includes('/login');

        if (isUserLoggedIn && isNotLoginPage && authorityType === AUTHORITY_TYPE.DOTCOM && !sessionModal) {
            setOpen(true);
        }
    };

    const onIdle = () => {
        logWarning('Logout 1:' + getRemainingTime() + getLastActiveTime() + new Date());
        handleRedirection();
    };

    const onActive = () => {
        resetActivity();
    };

    const handleSessionModalClose = () => {
        handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.RESET_USER);
    };

    const handleSignIn = () => {
        if (isCCPage()) {
            handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.RELOGIN);
        } else {
            handleSignout(VARIABLE_CONFIG.TIMER_MODAL_ACTION.RELOGIN_WITH_REDIRECTION);
        }
    };

    useEffect(() => {
        if (userType !== USER_TYPE.GUEST && !window.location.pathname.includes('/login') && open) {
            clearInterval(getMessageTimerID());
            const modalTimer = setInterval(() => {
                const remainingTime = Math.ceil(getRemainingTime() / 1000);
                if (remainingTime > 0 && remainingTime <= 60) {
                    setGetSec(remainingTime);
                } else {
                    logWarning('Logout 2:' + getRemainingTime() + getLastActiveTime() + new Date());
                    handleRedirection();
                }
            }, 1000);
            setMessageTimerID(modalTimer);
            return () => {
                clearInterval(modalTimer);
            };
        }
    }, [open]);
    var events = [
        'mousemove',
        'keydown',
        'wheel',
        'DOMMouseScroll',
        'mousewheel',
        'mousedown',
        'touchstart',
        'touchmove',
        'MSPointerDown',
        'MSPointerMove'
    ];

    const { getLastActiveTime, getRemainingTime, message, start } = useIdleTimer({
        timers: workerTimers,
        crossTab: true,
        syncTimers: 200,
        timeout,
        promptBeforeIdle,
        onPrompt,
        onIdle,
        onActive,
        onMessage,
        events,
        name: 'idle-timer-react'
    });

    const afterOpenModal = () => {
        titleRef.current.focus();
    };

    return (
        <>
            {sessionModal && (
                <SessionTimeout
                    title={'Session expired'}
                    handleOnClose={handleSessionModalClose}
                    handleSignIn={handleSignIn}
                    onOpen={resetUser}
                />
            )}
            <Modal
                role="none"
                isOpen={open}
                className={'notification'}
                overlayClassName="notification__overlay"
                onAfterOpen={afterOpenModal}>
                <div className="notification__header">
                    <div className="notification__title-wrap">
                        <AlertTriangle className={'notification__title-lefticon'} aria-hidden="true" tabIndex={'-1'} />
                        <h5 className="notification__title" tabIndex={0} ref={titleRef}>
                            {title}
                        </h5>
                    </div>
                    <Button
                        tabIndex={0}
                        buttonAriaLabel={intl.formatMessage({ id: 'timeout-close-text' })}
                        onClick={handleContinueSession}
                        className={'notification__close'}>
                        <Close aria-hidden={true} tabIndex={'-1'} />
                    </Button>
                </div>
                <div className="notification__body">
                    <div tabIndex={0} aria-live="polite">
                        {intl.formatMessage({ id: 'timeout-continue-bodytextone' })} <b>{getSec} second(s).</b>
                        <div>{intl.formatMessage({ id: 'timeout-continue-bodytexttwo' })} </div>
                    </div>

                    <Button
                        type="button"
                        className="button button-block button-primary notification__button-continue"
                        onClick={onContinueSession}
                        buttonAriaLabel={intl.formatMessage({ id: 'timeout-continue-button' })}>
                        {intl.formatMessage({ id: 'timeout-continue-text' })}
                    </Button>
                </div>
                <div className="notification__action">
                    <Button
                        type="button"
                        className="button button-outline-primary notification__button-signout button-block"
                        onClick={handleSignout}
                        buttonAriaLabel={intl.formatMessage({ id: 'timeout-signout-text' })}>
                        {intl.formatMessage({ id: 'timeout-signout-text' })}
                    </Button>
                </div>
            </Modal>
            {children}
        </>
    );
};

Timeout.propsType = {
    title: string,
    children: node
};

Timeout.defaultProps = {
    title: 'Timeout notification'
};

export default Timeout;
