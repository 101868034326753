import { func } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../../global/atoms/button';
import '../createWishList.scss';

const CreateWishListFooter = ({ handleBack, handleCreateList }) => {
    const intl = useIntl();

    return (
        <>
            <Button
                type="button"
                onClick={handleCreateList}
                className="button button-primary button-block create-button"
                customButtonAriaLabel={intl.formatMessage({ id: 'common:create-list' })}>
                {intl.formatMessage({ id: 'p2p-create-list-cta-title' })}
            </Button>
            <Button
                type="button"
                className={'button button-outline-primary button-block'}
                onClick={handleBack}
                customButtonAriaLabel={intl.formatMessage({ id: 'common:create-list-cancel' })}>
                {intl.formatMessage({ id: 'common:cancel' })}
            </Button>
        </>
    );
};

CreateWishListFooter.propsType = {
    handleBack: func,
    handleCreateList: func
};

CreateWishListFooter.defaultProps = {
    handleBack: () => {},
    handleCreateList: () => {}
};

export default React.memo(CreateWishListFooter);
