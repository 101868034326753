import React, { memo, useState } from 'react';
import { bool, number, oneOf, string } from 'prop-types';
import DefaultViewNoImage from '../defaultViewNoImage/defaultViewNoImage';
import makeOptimizedUrl from '../../../../aem-core-components/utils/makeUrl';

const ProductImage = ({ url, width, height, defaultViewCardType, name, className, shouldAriaBeHidden, tabIndex }) => {
    const [brokenImage, setBrokenImage] = useState(false);
    const src = url ? makeOptimizedUrl(url, { type: 'image-product', width, height }) : '';

    return src && !brokenImage ? (
        <img
            alt={name}
            aria-hidden={shouldAriaBeHidden}
            tabIndex={tabIndex}
            className={className}
            src={src}
            onError={() => {
                setBrokenImage(true);
            }}
        />
    ) : (
        <DefaultViewNoImage cardType={defaultViewCardType}></DefaultViewNoImage>
    );
};

ProductImage.propTypes = {
    url: string,
    name: string,
    className: string,
    width: number,
    height: number,
    defaultViewCardType: oneOf(['minicart', 'card', 'consumables']),
    shouldAriaBeHidden: bool,
    tabIndex: number,
};

ProductImage.defaultProps = {
    url: '',
    name: '',
    className: '',
    width: 56,
    height: 56,
    defaultViewCardType: 'minicart',
    shouldAriaBeHidden: false,
    tabIndex: 0
};

export default memo(ProductImage);
