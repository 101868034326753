
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import LoadingIndicator from '../LoadingIndicator';

const withSuspense = Component => {
    let WithSuspense = props => {
        return (
            <Suspense fallback={<LoadingIndicator />}>
                <Component {...props} />
            </Suspense>
        );
    };
    WithSuspense.displayName = `withSuspense(${Component.displayName || Component.name})`;
    return WithSuspense;
};

const MultiPortal = props => {
    let { selector, children } = props;
    var portals = []
    let elem;
    if (selector instanceof HTMLElement) {
        elem = selector;
    } else if (typeof selector === 'string') {
        elem = document.querySelectorAll(selector);
    }

    if (elem) {
        // Only render children if mounting point is available
        for (let i = 0; i < elem.length; i++) {
            if (elem[i].childNodes.length < 2) {
                portals.push(ReactDOM.createPortal(children, elem[i]))
            }
        }
        return portals;
    }

    return null;
};

MultiPortal.propTypes = {
    selector: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(HTMLElement)]).isRequired
};

export default withSuspense(MultiPortal);