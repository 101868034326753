import { node, string } from 'prop-types';
import React, { useEffect } from 'react';
import Modal from 'react-modal';
import './storemodal.scss';

const StoreModal = props => {
    const { children, scrollable, isAlert, classes, modalDialogClass, isOpen, onRequestClose, isChooseStoreView } =
        props;

    useEffect(() => {
        Modal.setAppElement('.root');
        document.body.classList.add('overflow-hidden');
        const liveChatButton = document.querySelector('.chat-button');
        if (liveChatButton) {
            liveChatButton.ariaHidden = 'true';
            liveChatButton.tabIndex = '-1';
        }
        return () => {
            document?.body?.classList?.remove('overflow-hidden');
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'false';
                liveChatButton.tabIndex = '0';
            }
        };
    }, []);
    return (
        <>
            <Modal
                role="none"
                className={'modal-wrap'}
                isOpen={isOpen || isChooseStoreView}
                onRequestClose={onRequestClose}
                aria={{
                    labelledby: 'chooseStoremodal'
                }}>
                <div
                    className={`${isAlert ? `${isAlert}` : 'modal-dialog'} ${
                        scrollable ? 'modal-dialog-scrollable' : ''
                    } ${modalDialogClass ? modalDialogClass : ''}`}>
                    <div className={`modal-content ${classes} `}>{children}</div>
                </div>
            </Modal>
        </>
    );
};

StoreModal.propTypes = {
    children: node,
    modalDialogClass: string,
    classes: string,
    isAlert: string
};
StoreModal.defaultProps = {
    modalDialogClass: '',
    classes: '',
    isAlert: ''
};

export default StoreModal;
