import React from 'react';
import { shape, string, func, bool } from 'prop-types';
import defaultClasses from './mask.css';
import mergeClasses from '../../utils/mergeClasses';

const Mask = ({ onClickHandler, isOpen, customClasses, ariaLabel }) => {
    const classes = mergeClasses(defaultClasses, customClasses);
    const className = isOpen ? classes.root_active : classes.root;

    return <button data-role="mask" data-testid="mask" className={className} onClick={onClickHandler} aria-label={ariaLabel}/>;
};

Mask.propTypes = {
    classes: shape({
        root: string,
        root_active: string
    }),
    onClickHandler: func.isRequired,
    isOpen: bool,
    customClasses: shape({
        root: string,
        root_active: string
    }),
    ariaLabel:string
};

export default Mask;
