import { cookieValue } from '../aem-core-components/utils/cookieUtils';
import { AUTHORITY_TYPE } from '../components/global/constants';
import { getProjectsFromCookie } from '../components/global/utils/projectUtils';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';

export const useCheckUser = () => {
    const userType = USER_TYPE.GUEST;
    let userTypeStorage = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE);
    /* validate subsequent userType checking from localStorage */
    if (userTypeStorage) {
        return userTypeStorage;
    } else {
        /* validate initial authenticated user or guest user checking */
        const refreshToken = cookieValue(STORAGE_CONFIG.COOKIES.REFRESHTOKEN);
        /* validate normal authenticated user checking */
        if (refreshToken) {
            return deriveAuthenticatedUser();
        } else if (window.location != window?.parent?.location) {
            /* validate punchout authenticated user checking */
            const punchoutRefreshToken = cookieValue(STORAGE_CONFIG.COOKIES.REFRESH_TOKEN);
            /* check if refresh token is present for punchout user */
            if (punchoutRefreshToken) {
                return deriveAuthenticatedUser();
            } else {
                return userType;
            }
        } else {
            /* validate guest user checking */
            return userType;
        }
    }
};

export const deriveAuthenticatedUser = () => {
    const projectCookies = getProjectsFromCookie();
    /* if account present, then credit user else cash user */
    if (projectCookies?.CurrentWynneAccount) {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, USER_TYPE.CREDIT);
        return USER_TYPE.CREDIT;
    } else {
        sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.USERTYPE, USER_TYPE.CASH);
        return USER_TYPE.CASH;
    }
};

/**
 * authority_type : p2p => for P2P flow
 * authority_type : punchout_old => for old Punchout flow
 */
export const useCheckAuthorityType = () => {
    const authorityType = cookieValue(STORAGE_CONFIG.COOKIES.AUTHORITY_TYPE)?.toLowerCase();

    const authorityTypeMap = {
        p2p: AUTHORITY_TYPE.P2P,
        punchout_old: AUTHORITY_TYPE.PUNCHOUT
    };
    return authorityTypeMap[authorityType] || AUTHORITY_TYPE.DOTCOM;
};

const isPageInIframe = () => {
    return window.location !== window.parent.location;
};

export const isP2PWithoutIframe = () => {
    const authorityType = useCheckAuthorityType();
    const isInIframe = isPageInIframe();

    return authorityType === AUTHORITY_TYPE.P2P && !isInIframe;
};
