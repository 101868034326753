import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../contexts/cart';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import useCheckLocationEmpty from '../../../../hooks/useCheckLocationEmpty';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { usePricing } from '../../../../hooks/usePricing';
import AddToCartProductTileReact from '../../../global/modules/productTile/addToCartProductTileReact';
import { checkIsRequestQuote } from '../../utils/commonUtils';
import { logError } from '../../utils/logger';
import { WISHLIST_SIZE } from './constants';

const WishList = ({ wishlistArray, wishListDataLength }) => {
    const intl = useIntl();
    const [{ isProfileLoaded }] = useUserContext();
    const [{ cidPcList }] = useCartState();
    const { sendEventsForClick } = useAnalyticsContext();
    const { getProductPrices } = usePricing();
    const { isRentalDetailsAvailable } = useCheckLocationEmpty();

    const { favCTA, favLink } = document.querySelector('.combined_tabs')?.dataset?.productdata
        ? JSON.parse(document.querySelector('.combined_tabs')?.dataset?.productdata || '{}')
        : {};

    useEffect(() => {
        const getPriceForProducts = async () => {
            if (isProfileLoaded) {
                if (isRentalDetailsAvailable() && cidPcList?.length > 0 && wishlistArray?.length > 0) {
                    await getProductPrices(
                        wishlistArray?.filter(item => {
                            const { isRequestQuote } = checkIsRequestQuote(
                                item?.showonlinecatalog,
                                item?.disableaddtocartoption
                            );
                            return !isRequestQuote;
                        }),
                        VARIABLE_CONFIG.PAGE_TYPE.HOME_PAGE
                    );
                }
            }
        };

        getPriceForProducts();
    }, [cidPcList, isProfileLoaded, wishlistArray?.length]);

    const itemClickFavoritesEvent = fav => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.FAVOURITE_PRODUCTS,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT,
                fav?.producttitle
            );
        } catch (error) {
            logError(error, false, 'itemClickFavoritesEvent');
        }
    };

    const viewAllFavoritesEvent = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.FAVOURITE_PRODUCTS,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT,
                VARIABLE_CONFIG.EVENT_LABEL.VIEW_ALL_FAVORITES
            );
        } catch (error) {
            logError(error, false, 'viewAllFavoritesEvent');
        }
    };

    return (
        <>
            <div className="row">
                {wishlistArray?.map((item, index) => (
                    <div className="col-md-3 col-lg-3 col-6 frequent__item" key={`${index}${item?.catclass}`}>
                        <AddToCartProductTileReact cardData={item} trackAnalytics={itemClickFavoritesEvent} />
                    </div>
                ))}
            </div>
            {favCTA && JSON.parse(favCTA) && wishListDataLength > WISHLIST_SIZE && (
                <div className="row frequent__actions">
                    <div className="col-xl-4 col-md-6">
                        <a
                            className="button button-outline-primary button-block"
                            href={favLink}
                            onClick={viewAllFavoritesEvent}>
                            {intl.formatMessage({ id: 'view-all-favorites' })}
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(WishList);

WishList.propTypes = {
    wishlistArray: PropTypes.arrayOf(
        PropTypes.shape({
            catclass: PropTypes.string,
            producttitle: PropTypes.string,
            productimageurl: PropTypes.string,
            producturl: PropTypes.string,
            categoryname: PropTypes.string,
            productcategoryname: PropTypes.string,
            subcategoryname: PropTypes.string,
            category_path: PropTypes.array,
            catid: PropTypes.string,
            catsku: PropTypes.string,
            showonlinecatalog: PropTypes.bool,
            disableaddtocartoption: PropTypes.bool
        })
    )
};

WishList.defaultProps = {
    wishlistArray: []
};
