import React, { useEffect, useState } from 'react';
import { func, number, oneOfType, shape, string } from 'prop-types';
import { useIntl } from 'react-intl';
import BaseProductTile from '../BaseProductTile';
import AlertModalWithButtons from '../../../atoms/AlertModalWithButtons';
import RequestQuote from '../../../atoms/requestQuote';
import AddToCart from '../atoms/addToCart';
import { checkIsRequestQuote } from '../../../utils/commonUtils';
import { productTileDataLocator } from '../../addToCartTile/dataLocators';
import { ENV_CONFIG } from '../../../../../constants/envConfig';
import { REQUEST_QUOTE_CONSTANT } from '../constants';

const Available = ({
    tileDetails,
    dailyProductRate,
    accountNumber,
    handleAnchorClick,
    handleRedirectClick,
    handleAddToCartClick,
    isAvailableWithWarning,
    handleAddToCartClickAnalytics,
    ...restProps
}) => {
    const [isQuoteFormOpen, setIsQuoteFormOpen] = useState(false);
    const intl = useIntl();
    const [isMaxQtyReachedModalOpen, setIsMaxQtyReachedModalOpen] = useState(false);
    const [showCTA, setShowCTA] = useState(true);
    const [isRequestQuoteCTA, setIsRequestQuoteCTA] = useState(false);
    const { showonlinecatalog, disableaddtocartoption } = tileDetails || {};
    const { quotationdescription, quotationheading } = ENV_CONFIG?.REQUEST_QUOTE || REQUEST_QUOTE_CONSTANT;

    useEffect(() => {
        const CTAState = checkIsRequestQuote(showonlinecatalog, disableaddtocartoption);
        setShowCTA(CTAState.showCTA);
        setIsRequestQuoteCTA(CTAState.isRequestQuote);
    }, [showonlinecatalog, disableaddtocartoption]);

    const handleBtnClick = (isRequestQuote = false) => {
        if (isRequestQuote) {
            setIsQuoteFormOpen(true);
        } else {
            handleAddToCartClick(
                tileDetails,
                dailyProductRate,
                setIsMaxQtyReachedModalOpen,
                handleAddToCartClickAnalytics
            );
        }
    };

    const footerContent = () => {
        return (
            <AddToCart
                showCTA={showCTA}
                isRequestQuoteCTA={isRequestQuoteCTA}
                handleClick={handleBtnClick}
                productName={tileDetails?.producttitle}></AddToCart>
        );
    };
    return (
        <>
            <BaseProductTile
                productUrl={tileDetails?.producturl}
                productImg={tileDetails?.productimageurl}
                catClass={tileDetails?.catclass}
                productName={tileDetails?.producttitle}
                dailyProductRate={dailyProductRate}
                accountNumber={accountNumber}
                footerContent={footerContent}
                handleAnchorClick={handleAnchorClick}
                handleRedirectClick={handleRedirectClick}
                productAltText={tileDetails.productAltText}
                isAvailableWithWarning={isAvailableWithWarning}
                {...restProps}></BaseProductTile>
            {isQuoteFormOpen && (
                <RequestQuote
                    isOpen={isQuoteFormOpen}
                    handleQuoteForm={setIsQuoteFormOpen}
                    categoryName={tileDetails?.subcategoryname}
                    catId={tileDetails?.catid}
                    catSku={tileDetails?.catsku}
                    productName={tileDetails?.producttitle}
                    quotationDescription={quotationdescription}
                    quotationHeading={quotationheading}
                    superCategory={tileDetails?.categoryname}
                    metaTitle={tileDetails?.metatitle}
                    metaDescription={tileDetails?.metadescription}
                    specialtyTypes={tileDetails?.specialtytypes}
                    specialtytypescode={tileDetails?.specialtytypescode}
                    analysisgroupcode={tileDetails?.analysisgroupcode}
                />
            )}
            <AlertModalWithButtons
                isOpen={isMaxQtyReachedModalOpen}
                title={intl.formatMessage({ id: 'product-listing-max-qty-reached-title' })}
                warningQuestion={intl.formatMessage({ id: 'product-listing-max-qty-reached-subhead' })}
                handleContinueClick={() => setIsMaxQtyReachedModalOpen(false)}
                continueButtonText={intl.formatMessage({ id: 'product-listing-max-qty-reached-return_CTA' })}
                handleCancelClick={() => setIsMaxQtyReachedModalOpen(false)}
                closeIconDataLocator={productTileDataLocator.productTile_max_quantity_close_testid}
                continueButtonDataLocator={productTileDataLocator.productTile_max_quantity_return_to_listing_testid}
                titleDataLocator={productTileDataLocator.productTile_max_quantity_modal_title_testid}
            />
        </>
    );
};

export default React.memo(Available);

Available.defaultProps = {
    tileDetails: {
        catclass: '',
        producttitle: '',
        productimageurl: '',
        producturl: '#',
        showonlinecatalog: 'YES',
        disableaddtocartoption: 'NO',
        categoryname: '',
        catsku: '',
        catid: '',
        metaTitle: '',
        metaDescription: '',
        productAltText: ''
    },
    dailyProductRate: '',
    handleAnchorClick: () => {},
    handleRedirectClick: () => {},
    handleAddToCartClick: () => {},
    handleAddToCartClickAnalytics: () => {}
};

Available.propTypes = {
    tileDetails: shape({
        catclass: oneOfType([number, string]),
        producttitle: string,
        productimageurl: string,
        producturl: string,
        showonlinecatalog: string,
        disableaddtocartoption: string,
        categoryname: string,
        catsku: oneOfType([number, string]),
        catid: oneOfType([number, string]),
        metaDescription: string,
        metaTitle: string,
        productAltText: string
    }),
    dailyProductRate: oneOfType([number, string]),
    handleAnchorClick: func,
    handleRedirectClick: func,
    handleAddToCartClick: func,
    handleAddToCartClickAnalytics: func
};
