window.addEventListener('load', event => {
    var jumbotronCarouselSlider = $('.jumbotron__carousel');
    jumbotronCarouselSlider?.each(function (index, element) {
        try {
            var JumbotronSlider = $(this);
            var JumbotronSliderElement = JumbotronSlider?.closest('.jumbotron__carousel-wrap');
            var prevArrowButton = JumbotronSliderElement?.find('.jumbotron__carousel__action-prev');
            var nextArrowButton = JumbotronSliderElement?.find('.jumbotron__carousel__action-next');

            JumbotronSlider?.slick({
                arrows: true,
                dots: true,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: prevArrowButton,
                nextArrow: nextArrowButton
            });
        } catch (error) {
            console.error('An error occurred while initializing the slick slider:', error);
        }
    });

})