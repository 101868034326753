import React, { useEffect, useState } from 'react';
import { bool, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { useUserContext } from '../../../context/UserContext';
import { useCartState } from '../../../../contexts/cart';
import { isCCPage } from '../../../../components/global/utils/commonUtils';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import Modal from 'react-modal';
import Close from '../../../../resources/images/close.svg';
import Button from '../../../../components/global/atoms/button';
import './delinquentaccountmodal.scss';
import { AUTHORITY_TYPE, EMPLOYEE } from '../../../../components/global/constants';
import config from '../../../../components/App/config';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { SET_SHOW_CHOOSE_ACCOUNT_DRAWER, SET_SHOW_DELINQUENT_ACCOUNT_MODAL } from '../../../actions/constants';
import { myAccountDataLocator } from '../dataLocators';


const DelinquentAccountModal = props => {
    const { isOpen, showCTA, onRequestClose, showCloseIcon } = props;
    const [{ showDelinquentModal }, dispatch] = useCartState();
    const [userState, {dispatch: userDispatch}] = useUserContext();
    const { userProfile } = userState;
    const intl = useIntl();
    const [showChooseAccountModal, setShowChooseAccountModal] = useState(false);
    const body = document.querySelector('body');
    const authorityType = useCheckAuthorityType();
    const isP2P = authorityType === AUTHORITY_TYPE.P2P;

    const onChooseDifAccountClick = () => {
        dispatch({
            type: SET_SHOW_DELINQUENT_ACCOUNT_MODAL,
            showDelinquentModal: false
        });
        if (userProfile?.type === EMPLOYEE && !isCCPage()) {
            window.location.href = config.pagePaths.quickRentPage + '?hideDelinquentModal=true';
        } else if (userProfile?.type === EMPLOYEE && isCCPage()) {
            // focus on account selector in cc page after closing delinquent modal
            document.querySelector('.AccountSearch2')?.focus();
        } else {
            setShowChooseAccountModal(true);
            userDispatch({ type: SET_SHOW_CHOOSE_ACCOUNT_DRAWER, payload: true });
        }
    };

    useEffect(() => {
        if (showDelinquentModal) {
            setShowChooseAccountModal(false);
        }
    }, [showDelinquentModal]);

    if (!showChooseAccountModal) {
        return (
            <Modal isOpen={isOpen} className={`delinquentmodal`} role="none">
                <div className={`delinquentmodal__content`}>
                    <div className={`delinquentmodal__header`}>
                        <div className="delinquentmodal__title" tabIndex={0}>
                            {intl.formatMessage({ id: 'delinquent-modal-hold-title' })}
                        </div>
                        {showCloseIcon && (
                            <Button tabIndex={0} onClick={onRequestClose} buttonAriaLabel={'click for close modal'} dataTestid={myAccountDataLocator.close_delinquent_modal_cta}>
                                <Close aria-hidden="true" tabIndex={'-1'} />
                            </Button>
                        )}
                    </div>
                    <div className={`delinquentmodal__text`}>
                        {intl.formatMessage({ id: 'delinquent-modal-hold-content' })}
                    </div>
                    <div className="delinquentmodal__phone">
                        <strong className="delinquentmodal__field-title">
                            {intl.formatMessage({ id: 'checkout:your-details-phone-number' })}
                        </strong>
                        <a
                            className="delinquentmodal__field-value"
                            href={`tel:${ENV_CONFIG?.HELP_DESK_PHONE}`}
                            title="call for help"
                            tabIndex={0}>
                            {ENV_CONFIG?.HELP_DESK_PHONE}
                        </a>
                    </div>

                    <div className="delinquentmodal__email">
                        <b className="delinquentmodal__field-title">{intl.formatMessage({ id: 'pdp:email' })}</b>
                        <a
                            className="delinquentmodal__field-value"
                            href={`mailto:${ENV_CONFIG?.HELP_DESK_EMAIL}`}
                            title="email"
                            tabIndex={0}>
                            {ENV_CONFIG?.HELP_DESK_EMAIL}
                        </a>
                    </div>

                    {showCTA && !isP2P && (
                        <Button
                            buttonAriaLabel={intl.formatMessage({ id: 'delinquent-choose-acount' })}
                            tabIndex={0}
                            onClick={onChooseDifAccountClick}
                            className="button button-primary button-block delinquentmodal__action"
                            data-testid={myAccountDataLocator.choose_account_cta}>
                            {intl.formatMessage({ id: 'delinquent-choose-acount' })}
                        </Button>
                    )}
                </div>
            </Modal>
        );
    }
};

DelinquentAccountModal.propTypes = {
    isOpen: bool,
    showCTA: bool,
    phone: string,
    showCloseIcon: bool
};
DelinquentAccountModal.defaultProps = {
    isOpen: false,
    showCTA: false,
    showCloseIcon: true
};

export default DelinquentAccountModal;
