import { bool, element, func, number, oneOfType, string } from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import ReactModal from 'react-modal';
import Button from '../../../atoms/button/button';
import CloseIcon from '../../../../../resources/images/close.svg';
import './SideDrawerModal.scss';

const SideDrawerModal = ({
    showModalHeader,
    header,
    footer,
    content,
    isModalOpen,
    handleModalToggle,
    nestIndex,
    setNestIndex,
    closeButtonClass,
    modalContentClass,
    modalFooterClass,
    modalHeaderClass,
    showModalInRight,
    toggleOnOutsideClick,
    showSliderTransition,
    customModalClass
}) => {
    const intl = useIntl();
    const componentRef = useRef(null);

    const clickOutside = e => {
        if (componentRef?.current && !componentRef.current?.portal?.content?.contains(e.target)) {
            // outside click
            toggleOnOutsideClick && handleModalToggle();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', clickOutside);
        return () => {
            document.removeEventListener('mousedown', clickOutside);
        };
    }, []);

    const handleNesting = () => {
        setNestIndex(nestIndex - 1);
    };

    if (!isModalOpen) return <></>;

    return (
        <>
            <ReactModal
                ref={componentRef}
                isOpen={isModalOpen}
                onClose={handleModalToggle}
                onRequestClose={handleModalToggle}
                className={`modal modal_sidedrawer ${showModalInRight ? 'open_right' : 'open_left'} ${
                    showSliderTransition &&
                    `modal_slider ${showModalInRight ? 'modal_slider-right' : 'modal_slider-left'}  ${customModalClass}`
                }`}>
                <div className={`modal_header ${modalHeaderClass}`}>
                    {header}
                    {showModalHeader && (
                        <div>
                            {nestIndex == 2 ? (
                                <Button
                                    onClick={handleNesting}
                                    customButtonAriaLabel={intl.formatMessage({
                                        id: 'p2p-favorites:side-drawer-goback'
                                    })}></Button>
                            ) : (
                                <Button
                                    className={`popup__crossicon ${closeButtonClass}`}
                                    onClick={handleModalToggle}
                                    dataTestid={''}
                                    customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}>
                                    <CloseIcon aria-hidden={true} />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                <div className={`modal_content modal_createlist_content ${modalContentClass}`}>{content}</div>
                {footer && (
                    <div data-testid={''} className={`modal_footer ${modalFooterClass}`}>
                        {footer}
                    </div>
                )}
            </ReactModal>
        </>
    );
};

SideDrawerModal.propTypes = {
    header: element,
    footer: element,
    content: element,
    isModalOpen: bool,
    handleModalToggle: func,
    nestIndex: number,
    setNestIndex: func,
    showModalHeader: bool,
    modalContentClass: string,
    modalHeaderClass: oneOfType([string, bool]),
    modalFooterClass: string,
    showModalInRight: bool,
    toggleOnOutsideClick: bool,
    closeButtonClass: string,
    showSliderTransition: bool,
    customModalClass: string
};
SideDrawerModal.defaultProps = {
    header: <></>,
    footer: null,
    content: <></>,
    isModalOpen: false,
    handleModalToggle: () => {},
    nestIndex: 0,
    setNestIndex: () => {},
    showModalHeader: false,
    modalContentClass: '',
    modalHeaderClass: '',
    modalFooterClass: '',
    showModalInRight: false,
    toggleOnOutsideClick: false,
    closeButtonClass: '',
    showSliderTransition: false,
    customModalClass: ''
};

export default React.memo(SideDrawerModal);
