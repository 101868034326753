import { bool, func, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import CrossIcon from '../../../../resources/images/closecircleblack.svg';
import SearchIcon from '../../../../resources/images/searchgreen.svg';
import Button from '../../atoms/button/button';
import Input from '../../atoms/input/Input';
import './searchInput.scss';

const SearchInput = props => {
    const {
        placeholder,
        value,
        dataTestId,
        handleInputChange,
        handleInputBlur,
        handleInputFocus,
        onClear,
        customClassName,
        inputAriaLabel,
        handleSearchClick,
        showSearchButton,
        label,
        onKeyPress
    } = props;
    const intl = useIntl();

    const handleKeyPress = event => {
        if (event.key === 'Enter') {
            handleSearchClick();
        }
    };

    return (
        <div className={`input-group input-group--append search_input ${customClassName}`}>
            <div className="input-group__input-wrap">
                <Input
                    type="text"
                    name="search_input"
                    placeholder={placeholder}
                    value={value}
                    dataTestId={dataTestId}
                    handleInputChange={handleInputChange}
                    onKeyDown={onKeyPress}
                    handleInputBlur={handleInputBlur}
                    handleInputFocus={handleInputFocus}
                    inputAriaLabel={
                        inputAriaLabel
                            ? `${inputAriaLabel}`
                            : `${intl.formatMessage({ id: 'account:ac-common-search-text' })}`
                    }
                    label={label}
                    onKeyPress={handleKeyPress}
                />
                {value && (
                    <Button
                        tabIndex={'0'}
                        customButtonAriaLabel={`${intl.formatMessage({
                            id: 'projects:clear-search-bar'
                        })}`}
                        data-testid={'crossIcon'}
                        className="cross_btn"
                        onClick={onClear}>
                        <CrossIcon aria-hidden="true" tabIndex={'-1'} />
                    </Button>
                )}
            </div>
            {showSearchButton && (
                <Button
                    className="input-group-append"
                    buttonAriaLabel={`${intl.formatMessage({
                        id: 'account:ac-common-search-text'
                    })}`}
                    onClick={handleSearchClick}>
                    <SearchIcon tabIndex={-1} aria-hidden={true} />
                </Button>
            )}
        </div>
    );
};

SearchInput.propTyp = {
    placeholder: string,
    onClear: func,
    handleInputChange: func,
    handleInputFocus: func,
    handleInputBlur: func,
    dataTestId: string,
    showSearchButton: bool,
    onKeyPress: func
};

SearchInput.defaultProps = {
    placeholder: '',
    dataTestId: '',
    onClear: () => {},
    handleInputChange: () => {},
    handleInputFocus: () => {},
    handleInputBlur: () => {},
    handleSearchClick: () => {},
    showSearchButton: true,
    onKeyPress: () => {}
};

export default SearchInput;
