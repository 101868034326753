import { gql } from '@apollo/client';

const UPDATE_PRODUCTS_IN_WISHLIST = gql`
    mutation updateProductsInWishlist(
        $wishlistId: ID!
        $wishlistItemId: ID!
        $qty: Float!
        $productPageNo: Int
        $productsSize: Int
        $productSortBy: WishlistProductSortingEnum!
        $accountId: Int!
    ) {
        updateProductsInWishlist(
            wishlistId: $wishlistId
            wishlistItems: [{ wishlist_item_id: $wishlistItemId, quantity: $qty }]
            accountId: $accountId
        ) {
            wishlist {
                name
                items_count
                id
                updated_at
                items_v2(currentPage: $productPageNo, pageSize: $productsSize, sortBy: $productSortBy) {
                    items {
                        id
                        description
                        quantity
                        product {
                            sku
                            name
                            thumbnail {
                                url
                            }
                        }
                    }
                    page_info {
                        current_page
                        page_size
                        total_pages
                    }
                }
            }
            user_errors {
                code
                message
                data {
                    sku
                    quantity
                    wishlistName
                    wishlistId
                }
            }
        }
    }
`;

export default UPDATE_PRODUCTS_IN_WISHLIST;
