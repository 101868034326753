import { STORAGE_CONFIG } from "../../constants/storageConfig";

export const userInitialState = {
    cartId: null,
    userProfile: null,
    accountProjects: [],
    allAccountProjects: [],
    userType: null,
    wishlist: {},
    isUserProfileLoading: true,
    isCheckoutLoading: JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCHECKOUTLOADING)) || false,
    showInactivityNotification: false,
    isOverRidePCLoading: false,
    isProjectsLoading: false,
    isJobSiteLoaded: '',
    hasAccountChanged: false,
    isProfileLoaded: false,
    isUserSigningIn: false,
    showSignInModal: false,
    showChooseAccountDrawer: false,
    showJobSiteDrawer: false,
    isCapAccountSelected: false,
    favoriteAccountListData: [],
    favoriteJobsiteListData: [],
    isFavoritesLoading: false
};
