import { callGtm } from '../../GTM/';
var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;
if (isGTMHidden === 'false') {
    var productApps = document.querySelectorAll('.faq');
    productApps?.forEach(productApp => {
        productApp?.addEventListener('click', function (e) {
            var eventAction = productApp.closest('.faq').querySelector('.faq__title').innerText.toLowerCase();
            var eventLabel = document.querySelector('.block__title')?.innerText;
            callGtm('uaevent', 'undefined', 'details page', eventAction, eventLabel, false);
        });
    });
}