import React from 'react';
import { bool, func, string } from 'prop-types';
import OktaBodyShimmer from './OktaBodyShimmer';

const OktaBodyContent = ({ isLoading, handleIframeLoad, iFrameSrc }) => {
    return (
        <div className="okta__content">
            {isLoading && <OktaBodyShimmer />}
            <iframe
                aria-label="use this window to sign in"
                data-testid="oktaIframe"
                src={iFrameSrc}
                className={`okta__signin-iframe ${
                    isLoading ? 'okta__signin-iframe--hide' : 'okta__signin-iframe--show'
                }`}
                onLoad={handleIframeLoad}
            />
        </div>
    );
};

OktaBodyContent.propTypes = {
    isLoading: bool,
    handleIframeLoad: func,
    iFrameSrc: string
};

OktaBodyContent.defaultProps = {
    isLoading: false,
    handleIframeLoad: () => {},
    iFrameSrc: ''
};

export default React.memo(OktaBodyContent);
