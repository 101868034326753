import React from 'react';
import Modal from 'react-modal';
import Button from '../../atoms/button';
import Close from '../../../../resources/images/close.svg';
import { useIntl } from 'react-intl';
import './sessionTimeout.scss';

const SessionTimeout = props => {
    const { title, handleOnClose, handleSignIn, onOpen } = props;
    const intl = useIntl();
    const [modalIsOpen, setIsOpen] = React.useState(true);

    const closeModal = () => {
        setIsOpen(false);
        handleOnClose();
    };

    const handleSignInBtn = () => {
        setIsOpen(false);
        handleSignIn();
    };

    return (
        <Modal role="none" isOpen={modalIsOpen} className={'session'} onAfterOpen={onOpen}>
            <div className="session__title-wrap">
                <h6 className="session__title" tabIndex={'0'}>
                    {title}
                </h6>
                <Button
                    onClick={closeModal}
                    tabindex={'0'}
                    data-testid={'modal-close-icon'}
                    customButtonAriaLabel={intl.formatMessage({ id: 'time-out-session-modal:clickToClose' })}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            </div>
            <div className="session__content" tabIndex={'0'}>
                {intl.formatMessage({ id: 'time-out-session-modal-message' })}
            </div>
            <div className="session__actions">
                <Button
                    className="button button-primary button-block session__signin"
                    customButtonAriaLabel={intl.formatMessage({ id: 'time-out-session-modal:clickToSignIn' })}
                    tabindex={'0'}
                    data-testid={'modal-sign-in'}
                    onClick={handleSignInBtn}>
                    {intl.formatMessage({ id: 'time-out-session-modal-sign-in' })}
                </Button>
                <Button
                    className="button button-outline-primary button-block"
                    data-testid={'modal-close-btn'}
                    onClick={closeModal}
                    customButtonAriaLabel={intl.formatMessage({ id: 'time-out-session-modal:clickToClose' })}>
                     {intl.formatMessage({ id: 'time-out-session-modal-close' })}
                </Button>
            </div>
        </Modal>
    );
};

export default SessionTimeout;
