import React from 'react';
import { oneOf, string, func } from 'prop-types';
import './defaultViewNoImage.scss';
import ToolDefault from '../../../../resources/images/tools.svg';

const DefaultViewNoImage = props => {
    const { cardType, text, className, onClick } = props;
    return (
        <div className={`defaultimage defaultimage--${cardType} ${className || ' '}`} onClick={onClick}>
            <div className="defaultimage__img-wrap">
                <ToolDefault className="defaultimage__img" />
            </div>
            <div className="defaultimage__text">{text}</div>
        </div>
    );
};

DefaultViewNoImage.propTypes = {
    cardType: oneOf(['minicart', 'card', 'consumables', 'p2p_wishlistlist']).isRequired,
    text: string,
    className: string,
    onClick: func
};

DefaultViewNoImage.defaultProps = {
    cardType: 'card',
    text: 'No image yet'
};
export default DefaultViewNoImage;
