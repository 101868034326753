
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import LoadingIndicator from '../LoadingIndicator';

const withSuspense = Component => {
    let WithSuspense = props => {
        return (
            <Suspense fallback={<LoadingIndicator />}>
                <Component {...props} />
            </Suspense>
        );
    };
    WithSuspense.displayName = `withSuspense(${Component.displayName || Component.name})`;
    return WithSuspense;
};

const Portal = props => {
    let { selector, children } = props;

    let elem;
    if (selector instanceof HTMLElement) {
        elem = selector;
    } else if (typeof selector === 'string') {
        elem = document.querySelector(selector);
    }

    if (elem) {
        // Only render children if mounting point is available
        return ReactDOM.createPortal(children, elem);
    }

    return null;
};

Portal.propTypes = {
    selector: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(HTMLElement)])
};

export default withSuspense(Portal);
