function loadScript(scriptText) {
  return new Promise((resolve, reject) => {
    try {
      const script = document.createElement('script');
      script.innerHTML = scriptText;
      document.body.appendChild(script);
      resolve();
    } catch (error) {
      reject(error);
    }
  });
}


loadScript(` window.__lc = window.__lc || {};
window.__lc.license = 9215640;
; (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n) } }; !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e }(window, document, [].slice))
`).then(() => {
  const chatbtn = document.createElement('button');
  chatbtn.classList.add('chat-button');
  chatbtn.ariaLabel = 'click for live chat';
  chatbtn.addEventListener('click', e => {
    e?.target?.focus();
    LiveChatWidget.call('maximize');
  });
  chatbtn.innerHTML = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16602 11.8307C6.16602 11.1867 6.68868 10.6641 7.33268 10.6641C7.97668 10.6641 8.49935 11.1867 8.49935 11.8307C8.49935 12.4747 7.97668 12.9974 7.33268 12.9974C6.68868 12.9974 6.16602 12.4747 6.16602 11.8307ZM11.9997 10.6641C11.3557 10.6641 10.833 11.1867 10.833 11.8307C10.833 12.4747 11.3557 12.9974 11.9997 12.9974C12.6437 12.9974 13.1663 12.4747 13.1663 11.8307C13.1663 11.1867 12.6437 10.6641 11.9997 10.6641ZM16.6657 10.6641C16.0217 10.6641 15.499 11.1867 15.499 11.8307C15.499 12.4747 16.0217 12.9974 16.6657 12.9974C17.3097 12.9974 17.8324 12.4747 17.8324 11.8307C17.8324 11.1867 17.3097 10.6641 16.6657 10.6641ZM21.2139 13.3429C20.6224 17.1392 17.5622 20.288 13.7717 20.9985C11.9412 21.3439 10.0769 21.1467 8.38525 20.4304C7.90575 20.2274 7.40991 20.1247 6.92341 20.1247C6.70175 20.1247 6.48241 20.1457 6.26658 20.1889L2.98591 20.8445L3.64275 17.558C3.78041 16.8755 3.69641 16.1452 3.40125 15.4464C2.68491 13.7547 2.48891 11.8915 2.83308 10.0599C3.54358 6.26936 6.69125 3.20919 10.4887 2.61769C13.5104 2.14753 16.4656 3.09953 18.5982 5.23219C20.7321 7.36602 21.6852 10.3224 21.2139 13.3429ZM20.2489 3.58026C17.5831 0.915596 13.8964 -0.273238 10.1292 0.308929C5.37273 1.05093 1.4294 4.88226 0.539231 9.6271C0.109898 11.9114 0.357231 14.2378 1.25206 16.3529C1.36523 16.6224 1.40023 16.8733 1.3559 17.0984L0.354898 22.1011C0.277898 22.4838 0.398065 22.8793 0.674565 23.1546C0.895065 23.3763 1.19256 23.4964 1.4994 23.4964C1.57523 23.4964 1.65106 23.4894 1.72806 23.4743L6.7249 22.4744C7.0119 22.4196 7.28956 22.5001 7.47506 22.5771C9.59256 23.4719 11.9189 23.7181 14.2009 23.2899C18.9469 22.3998 22.7782 18.4564 23.5202 13.6999C24.1059 9.93509 22.9147 6.2461 20.2489 3.58026Z" fill="#1C1C1C"></path>
  </svg>
`;
  document.body.appendChild(chatbtn);
}).catch(e => {
  const noscript = document.createElement('noscript');
  noscript.innerHTML = `<noscript
  ><a href="https://www.livechat.com/chat-with/9215640/" rel="nofollow"
    >Chat with us</a
  >, powered by
  <a
    href="https://www.livechat.com/?welcome"
    rel="noopener nofollow"
    target="_blank"
    >LiveChat</a
  ></noscript`;
})
 /*  (function () {
  const script = document.createElement('script');
  const noscript = document.createElement('noscript');
  script.innerHTML = ` window.__lc = window.__lc || {};
      window.__lc.license = 9215640;
      ; (function (n, t, c) { function i(n) { return e._h ? e._h.apply(null, n) : e._q.push(n) } var e = { _q: [], _h: null, _v: "2.0", on: function () { i(["on", c.call(arguments)]) }, once: function () { i(["once", c.call(arguments)]) }, off: function () { i(["off", c.call(arguments)]) }, get: function () { if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load."); return i(["get", c.call(arguments)]) }, call: function () { i(["call", c.call(arguments)]) }, init: function () { var n = t.createElement("script"); n.async = !0, n.type = "text/javascript", n.src = "https://cdn.livechatinc.com/tracking.js", t.head.appendChild(n) } }; !n.__lc.asyncInit && e.init(), n.LiveChatWidget = n.LiveChatWidget || e }(window, document, [].slice))
     `;
  noscript.innerHTML = `<noscript
      ><a href="https://www.livechat.com/chat-with/9215640/" rel="nofollow"
        >Chat with us</a
      >, powered by
      <a
        href="https://www.livechat.com/?welcome"
        rel="noopener nofollow"
        target="_blank"
        >LiveChat</a
      ></noscript`;
  //script.setAttribute('type', 'text/partytown');
  script.setAttribute('defer', '');
  noscript.setAttribute('defer', '');
  document.body.appendChild(script);
  document.body.appendChild(noscript);

  const chatbtn = document.createElement('button');
  chatbtn.classList.add('chat-button');
  chatbtn.ariaLabel = 'click for live chat';
  chatbtn.addEventListener('click', e => {
    e?.target?.focus();
    LiveChatWidget.call('maximize');
  });
  chatbtn.innerHTML = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.16602 11.8307C6.16602 11.1867 6.68868 10.6641 7.33268 10.6641C7.97668 10.6641 8.49935 11.1867 8.49935 11.8307C8.49935 12.4747 7.97668 12.9974 7.33268 12.9974C6.68868 12.9974 6.16602 12.4747 6.16602 11.8307ZM11.9997 10.6641C11.3557 10.6641 10.833 11.1867 10.833 11.8307C10.833 12.4747 11.3557 12.9974 11.9997 12.9974C12.6437 12.9974 13.1663 12.4747 13.1663 11.8307C13.1663 11.1867 12.6437 10.6641 11.9997 10.6641ZM16.6657 10.6641C16.0217 10.6641 15.499 11.1867 15.499 11.8307C15.499 12.4747 16.0217 12.9974 16.6657 12.9974C17.3097 12.9974 17.8324 12.4747 17.8324 11.8307C17.8324 11.1867 17.3097 10.6641 16.6657 10.6641ZM21.2139 13.3429C20.6224 17.1392 17.5622 20.288 13.7717 20.9985C11.9412 21.3439 10.0769 21.1467 8.38525 20.4304C7.90575 20.2274 7.40991 20.1247 6.92341 20.1247C6.70175 20.1247 6.48241 20.1457 6.26658 20.1889L2.98591 20.8445L3.64275 17.558C3.78041 16.8755 3.69641 16.1452 3.40125 15.4464C2.68491 13.7547 2.48891 11.8915 2.83308 10.0599C3.54358 6.26936 6.69125 3.20919 10.4887 2.61769C13.5104 2.14753 16.4656 3.09953 18.5982 5.23219C20.7321 7.36602 21.6852 10.3224 21.2139 13.3429ZM20.2489 3.58026C17.5831 0.915596 13.8964 -0.273238 10.1292 0.308929C5.37273 1.05093 1.4294 4.88226 0.539231 9.6271C0.109898 11.9114 0.357231 14.2378 1.25206 16.3529C1.36523 16.6224 1.40023 16.8733 1.3559 17.0984L0.354898 22.1011C0.277898 22.4838 0.398065 22.8793 0.674565 23.1546C0.895065 23.3763 1.19256 23.4964 1.4994 23.4964C1.57523 23.4964 1.65106 23.4894 1.72806 23.4743L6.7249 22.4744C7.0119 22.4196 7.28956 22.5001 7.47506 22.5771C9.59256 23.4719 11.9189 23.7181 14.2009 23.2899C18.9469 22.3998 22.7782 18.4564 23.5202 13.6999C24.1059 9.93509 22.9147 6.2461 20.2489 3.58026Z" fill="#1C1C1C"></path>
  </svg>
`;
  document.body.appendChild(chatbtn);
  // window.dispatchEvent(new CustomEvent('ptupdate'));
})();
*/