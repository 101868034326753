import React, { memo } from 'react';
import PropTypes from 'prop-types';

function Tab(props) {
    const { children, title, customButtonAriaLabel, className } = props;
    return <div aria-label={title} className={className} role="tabpanel">{children}</div>;
}

Tab.propTypes = {
    title: PropTypes.string,
    customButtonAriaLabel: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string
};

Tab.defaultProps = {
    title: '',
    customButtonAriaLabel: '',
    children: null,
    className: ''
};
export default memo(Tab);
