window.addEventListener('load', event => {
    var multicardSectionSlider = $('.multicard__carousel');
    multicardSectionSlider?.each(function (index, element) {
        try {
            var MultiCardSlider = $(this);
            var MultiCardSliderElement = MultiCardSlider.closest('.multicard__carousel-wrap');
            var prevArrowButton = MultiCardSliderElement.find('.multicard__action-prev');
            var nextArrowButton = MultiCardSliderElement.find('.multicard__action-next');

            if ($(window).width() < 1025) {
                MultiCardSlider?.slick({
                    arrows: true,
                    dots: true,
                    infinite: false,
                    slidesToShow: 4,
                    slidesToScroll: 4,
                    prevArrow: prevArrowButton,
                    nextArrow: nextArrowButton,
                    responsive: [
                        {
                            breakpoint: 1024.98,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 639.98,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });
            }
        } catch (error) {
            console.error('An error occurred while initializing the slick slider:', error);
        }
    });
});
