import React, { memo } from 'react';
import { bool, func } from 'prop-types';
import { useIntl } from 'react-intl';
import AlertModalWithButtons from '../../../../global/atoms/AlertModalWithButtons';
import { editQuoteDataLocators } from '../../../../quotes/dataLocators';
import './backToQuote.scss';

const BackToQuote = props => {
    const { isOpen, handleBackToQuoteModal, redirectToQuoteListPage } = props;

    const intl = useIntl();

    const getBackToQuoteWarning = () => {
        return (
                <p role="alert"> {intl.formatMessage({ id: 'editquote:back-to-quote-discard-modal-text' })}</p>
        );
    };

    return (
            <AlertModalWithButtons
                isOpen={isOpen}
                title={intl.formatMessage({ id: 'editquote:back-to-quote-discard-title' })}
                warningQuestion={getBackToQuoteWarning()}
                closeIconDataLocator={editQuoteDataLocators.back_to_quote_close_icon}
                handleContinueClick={redirectToQuoteListPage}
                continueButtonText={intl.formatMessage({
                    id: 'editquote:back-to-quote-discard-changes-cta'
                })}
                continueButtonDataLocator={editQuoteDataLocators.review_your_changes_cta}
                handleCancelClick={handleBackToQuoteModal}
                cancelButtonText={intl.formatMessage({ id: 'cart-footer:review-your-changes' })}
                cancelButtonDataLocator={editQuoteDataLocators.discard_changes_and_exit_cta}
                customClass={'back_to_quote_modal_title'}
            />
    );
};

BackToQuote.propsType = {
    isOpen: bool,
    handleBackToQuoteModal: func,
    redirectToQuoteListPage: func
};

BackToQuote.defaultProps = {
    isOpen: false,
    handleBackToQuoteModal: () => {},
    redirectToQuoteListPage: () => {}
};

export default memo(BackToQuote);
