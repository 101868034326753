(function ($) {
  $.fn.cascadeSlider = function (opt) {
      try {
          var $this = this,
              itemClass = opt.itemClass || 'cascade-slider_item',
              arrowClass = opt.arrowClass || 'cascade-slider_arrow',
              $item = $this?.find('.' + itemClass),
              $arrow = $this?.find('.' + arrowClass),
              itemCount = $item.length;

          var defaultIndex = 0;

          changeIndex(defaultIndex);

          $arrow?.on('click', function () {
              try {
                  var action = $(this)?.data('action'),
                      nowIndex = $item?.index($this?.find('.now'));

                  if (action == 'next') {
                      if (nowIndex == itemCount - 1) {
                          changeIndex(0);
                      } else {
                          changeIndex(nowIndex + 1);
                      }
                  } else if (action == 'prev') {
                      if (nowIndex == 0) {
                          changeIndex(itemCount - 1);
                      } else {
                          changeIndex(nowIndex - 1);
                      }
                  }
              } catch (innerError) {
                  console.error('Error handling arrow click:', innerError);
              }
          });

          // Add data attributes
          try {
              for (var i = 0; i < itemCount; i++) {
                  $item?.each(function (i) {
                      $(this)?.attr('data-slide-number', [i]);
                  });
              }
          } catch (innerError) {
              console.error('Error adding data attributes:', innerError);
          }

          function changeIndex(nowIndex) {
              try {
                  // Clear all classes
                  $this?.find('.now').removeClass('now');
                  $this?.find('.next').removeClass('next');
                  $this?.find('.prev').removeClass('prev');
                  if (nowIndex == itemCount - 1) {
                      $item.eq(0).addClass('next');
                  }
                  if (nowIndex == 0) {
                      $item?.eq(itemCount - 1).addClass('prev');
                  }

                  $item.each(function (index) {
                      if (index == nowIndex) {
                          $item?.eq(index).addClass('now');
                      }
                      if (index == nowIndex + 1) {
                          $item?.eq(index).addClass('next');
                      }
                      if (index == nowIndex - 1) {
                          $item?.eq(index).addClass('prev');
                      }
                  });
              } catch (innerError) {
                  console.error('Error changing index:', innerError);
              }
          }
      } catch (error) {
          console.error('Error initializing cascadeSlider:', error);
      }
  };
})(jQuery);
