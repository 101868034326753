import React from 'react';
import Button from '../button';
import { checkoutDatalocator } from '../../../checkoutv2/checkoutAndOrderSummary/dataLocators';
import '../confirmationStatusScreen/confirmationStatusScreen.scss';

const ConfirmationScreenButtons = ({ primaryBtnText, secondaryBtnText, primaryHandle, secondaryHandle }) => {
    return (
        <div className="status-button-container">
            {primaryBtnText && (
                <Button
                    className={`button button-primary button-block`}
                    type="button"
                    buttonAriaLabel={primaryBtnText}
                    onClick={primaryHandle}
                    data-testid={checkoutDatalocator.reservation_view_rentals_cta_testid}>
                    {primaryBtnText}
                </Button>
            )}
            {secondaryBtnText && (
                <Button
                    className={'button button-outline-primary'}
                    type="button"
                    buttonAriaLabel={secondaryBtnText}
                    onClick={secondaryHandle}
                    data-testid={checkoutDatalocator.reservation_continue_exploring_cta_testid}>
                    {secondaryBtnText}
                </Button>
            )}
        </div>
    );
};

export default ConfirmationScreenButtons;
