import enData from '../../i18n/en.json';

export const MOBILE_THUMBNAILS = 4;
export const DESKTOP_THUMBNAILS = 7;
export const TAB_THUMBNAILS = 4;
export const MAX_TOTAL_ITEMS_QUANTITY = 100;
export const MAX_VISIBLE_ITEMS = 3;
export const MAX_WISHLIST_ITEMS = 9;
export const MAX_TOTAL_ITEMS_TO_ADD = 20;
export const MAX_LIMIT_TO_SHOW_WISHLIST_LINK = 1;
export const MAX_NO_OF_PRODUCTS_TO_LOAD = 500;
export const PAGINATION_PAGE_RANGE_DISPLAYED = 5;
export const PAGINATION_MARGIN_PAGES_DISPLAYED = 1;

export const MAX_REMAINING_ITEMS_COUNT = 999;
export const dropdownValuesConstants = {
    LAST_MODIFIED: 'LAST_MODIFIED',
    LAST_ADDED: 'LAST_ADDED',
    ATOZ: 'A_Z',
    ZTOA: 'Z_A'
};

export const CREATE_LIST_MIN_CHARACTER_LENGTH = 3;
export const CREATE_LIST_MAX_CHARACTER_LENGTH = 40;
export const WISHLIST_MAX_PAGE_SIZE = 100;
export const LIMIT_TO_SHOW_SEARCH_BAR = 19;
export const RELOAD = 'reload';
export const ACTION_NAMES = {
    SAVE: 'SAVE',
    COPY: 'COPY',
    MOVE: 'MOVE'
};
export const actionBarDropdownConstants = {
    MOVE: 'move',
    COPY: 'copy',
    REMOVE: 'remove',
    CLEAR: 'clear',
    CREATE: 'create',
    SAVE: 'save'
};
export const ACTION_BAR_DROPDOWN_LABELS = [
    { label: enData['p2p-move-items-to-another-list'], value: actionBarDropdownConstants.MOVE },
    { label: enData['p2p-copy-items-to-another-list'], value: actionBarDropdownConstants.COPY },
    { label: enData['p2p-confirm-continueButtonText-removeProducts'], value: actionBarDropdownConstants.REMOVE },
    { label: enData['p2p-clear-all-selections'], value: actionBarDropdownConstants.CLEAR }
];

export const TOAST_ANIMATION_CLASSES = {
    DRAWER_TOAST_SLIDE_IN_CLASS: 'animate__slideInBottom',
    DRAWER_TOAST_SLIDE_OUT_CLASS: 'animate__slideOutBottom',
    PAGE_TOAST_SLIDE_IN_CLASS: 'animate__slideInRight',
    PAGE_TOAST_SLIDE_OUT_CLASS: 'animate__slideOutRight'
};

export const TOAST_ANIMATION_TYPE = {
    RIGHT: 'RIGHT',
    BOTTOM: 'BOTTOM'
};
