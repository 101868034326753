export const USER_TYPE = {
    GUEST: 'guest',
    CASH: 'cash',
    CREDIT: 'credit',
    PUNCHOUT: 'punchout'
};

export const USER_ACCOUNT_TYPE = {
    OVERRIDEACCOUNT: 'overrideAccount',
    PUNCHOUT: 'punchout',
    P2P: 'p2p',
    P2PCORP: 'p2pcorplink',
    P2PNONCORP: 'p2pnoncorplink'
};
