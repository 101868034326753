import React, { memo } from 'react';
import classes from './storeCard.css';

const StoreCardShimmer = () => (
    <div className={classes.storeCard}>
        <div className={classes.storeCardBody}>
            <div className={`${classes.storeCardIcon} ${classes.storeRadioShimmer} shimmer`} />
            <div className={classes.fullWidth}>
                <div className={`${classes.storeCardTitleShimmer} shimmer`} />
                <div className={`${classes.storeCardDateTimeShimmer} shimmer`} />
                <div className={`${classes.storeCardAddressShimmer} shimmer`} />
                <div className={`${classes.storeCardActionsShimmer} shimmer`} />
            </div>
        </div>
    </div>
);

export default memo(StoreCardShimmer);
