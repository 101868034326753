import moment from 'moment';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import useCheckLocationEmpty from '../../../hooks/useCheckLocationEmpty';
import { useFilterState } from '../filterContext';
import { checkIsRequestQuote, formatNearbyPC, isTier2Radius } from '../../global/utils/commonUtils';
import {
    checkIfPcPresentCI,
    getCITier1Pcs,
    getDateDiffInHrs,
    getNearByPcList,
    saveAtpStoresList
} from '../utils/atputils';
import { isValidString, logError } from '../../global/utils/logger';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { FULFILLMENT_TYPE, STORE_TYPE, TIER1_STORES_KEYS } from '../constants';
import { AUTHORITY_TYPE } from '../../global/constants';
import { TILE_STATES } from '../../global/modules/productTile/constants';

/**
 * useAtp is a custom hook that provides various utility functions to handle ATP (Available to Promise) logic.
 * It uses the filter state from the filter context.
 *
 * @returns {Object} An object containing various utility functions.
 */
//TODO P2P handling remaining
export const useAtp = () => {
    // Get the fulfillment type from the filter state.
    const [
        {
            viewCart: { pc, isInStorePickup },
            startDate
        }
    ] = useFilterState();
    const allNearbypcs = getNearByPcList();
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P; // need to change acc. to P2P condition
    const { isSelectedLocationEmpty } = useCheckLocationEmpty();
    const isLocationEmpty = isSelectedLocationEmpty();
    const overridePc = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
    const { disableinventorycheck, rollbackToCi } = ENV_CONFIG.INVENTORY_CHECK_CONFIGS || {};
    const isInventoryCheckDisabled = disableinventorycheck || false;
    const isAtpDisabled = rollbackToCi || false;

    const checkIfCIofSelPc = (selPc, inventoryDataObj = '{}') => {
        return checkIfPcPresentCI(selPc, inventoryDataObj, STORE_TYPE.INVENTORY);
    };

    const getTier1CIPcs = (inventoryDataObj = '{}', localNearbyPcs) => {
        return getCITier1Pcs(localNearbyPcs, inventoryDataObj, STORE_TYPE.INVENTORY);
    };
    const getAdjAtpTier1List = (inventoryDataObj = '{}', localNearbyPcs) => {
        return getCITier1Pcs(localNearbyPcs, inventoryDataObj, STORE_TYPE.ADJATP);
    };
    const getATPTier1List = (inventoryDataObj = '{}', localNearbyPcs) => {
        return getCITier1Pcs(localNearbyPcs, inventoryDataObj, STORE_TYPE.ATP);
    };

    const handlePickupCI = (catsku, inventoryDataObj = '{}', formattedSelectedPc, localNearbyPcs) => {
        const isInCIOfSelPc = checkIfCIofSelPc(formattedSelectedPc, inventoryDataObj);
        if (isInCIOfSelPc) {
            return TILE_STATES.AVAILABLE;
        } else {
            const listOfCIStores = getTier1CIPcs(inventoryDataObj, localNearbyPcs); //TODO-> identify optimised logic to compute and save this data
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.CI, value: listOfCIStores });
            return listOfCIStores?.length > 0
                ? isValidString(isAtpDisabled)
                    ? TILE_STATES.AVAILABLE
                    : TILE_STATES.CHANGE_STORE
                : TILE_STATES.UNAVAILABLE;
        }
    };

    const checkIfAdjATPofSelPc = (selPc, inventoryDataObj = '{}') => {
        return checkIfPcPresentCI(selPc, inventoryDataObj, STORE_TYPE.ADJATP);
    };
    const checkIfATPofSelPc = (selPc, inventoryDataObj = '{}') => {
        return checkIfPcPresentCI(selPc, inventoryDataObj, STORE_TYPE.ATP);
    };

    const handleCIAdjAtp = (catsku, inventoryDataObj = '{}', localNearbyPcs) => {
        const adjAtpTier1List = getAdjAtpTier1List(inventoryDataObj, localNearbyPcs);
        if (adjAtpTier1List?.length > 0) {
            //dispatch the list of adjatp stores
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.ADJ_ATP, value: adjAtpTier1List });
            return TILE_STATES.CHANGE_DATES_OR_STORE_GRT72HRS;
        }
        const atpTier1List = getATPTier1List(inventoryDataObj, localNearbyPcs);
        if (atpTier1List?.length > 0) {
            //dispatch the list of atp stores
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.ATP, value: atpTier1List });
            return TILE_STATES.CHANGE_DATES_AND_STORE_GRT24HRS;
        }
        return TILE_STATES.CHANGE_DATES_GRT72HRS;
    };

    const handleNotInCIAdjATP = (catsku, inventoryDataObj = '{}', localNearbyPcs) => {
        //if not in CI of sel-pc
        const adjAtpTier1List = getAdjAtpTier1List(inventoryDataObj, localNearbyPcs);
        if (adjAtpTier1List?.length > 0) {
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.ADJ_ATP, value: adjAtpTier1List });
            //dispatch the list of adjatp stores
            return TILE_STATES.CHANGE_STORE;
            // check in atp of tier1 if not in adjatptier1
        }
        const atpTier1List = getATPTier1List(inventoryDataObj, localNearbyPcs);

        if (atpTier1List?.length > 0) {
            //dispatch the list of atp stores
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.ATP, value: atpTier1List });
            return TILE_STATES.CHANGE_DATES_AND_STORE_GRT24HRS;
        }
        const tier1PcList = getTier1CIPcs(inventoryDataObj, localNearbyPcs);
        if (tier1PcList?.length > 0) {
            //present in tier1 CIs
            //dispatch the list
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.CI, value: tier1PcList });
            return TILE_STATES.CHANGE_DATES_AND_STORE_GRT72HRS;
        }
        return TILE_STATES.UNAVAILABLE;
    };
    const handlePickupAdjATP = (catsku, inventoryDataObj = '{}', formattedSelectedPc, localNearbyPcs) => {
        const isInAdjATPofSelPc = checkIfAdjATPofSelPc(formattedSelectedPc, inventoryDataObj);
        if (isInAdjATPofSelPc) return TILE_STATES.AVAILABLE;
        // not in adjatp, checking atp,
        const isInATPofSelPc = checkIfATPofSelPc(formattedSelectedPc, inventoryDataObj);
        if (isInATPofSelPc) return TILE_STATES.CHANGE_DATES_GRT24HRS;
        // not in ATP as well, checking CI
        const isInCIOfSelPc = checkIfCIofSelPc(formattedSelectedPc, inventoryDataObj);
        if (isInCIOfSelPc) {
            return handleCIAdjAtp(catsku, inventoryDataObj, localNearbyPcs);
        } else {
            return handleNotInCIAdjATP(catsku, inventoryDataObj, localNearbyPcs);
        }
    };

    const handleCIATP = (catsku, inventoryDataObj = '{}', localNearbyPcs) => {
        const atpTier1List = getATPTier1List(inventoryDataObj, localNearbyPcs);
        if (atpTier1List?.length > 0) {
            //dispatch the list of atp stores
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.ATP, value: atpTier1List });
            return TILE_STATES.CHANGE_DATES_OR_STORE_GRT72HRS;
        }

        return TILE_STATES.CHANGE_DATES_GRT72HRS;
    };

    const handleNotInCIATP = (catsku, inventoryDataObj = '{}', localNearbyPcs) => {
        const atpTier1List = getATPTier1List(inventoryDataObj, localNearbyPcs);
        if (atpTier1List.length > 0) {
            //dispatch the list of atp stores
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.ATP, value: atpTier1List });
            return TILE_STATES.CHANGE_STORE;
        }
        //not in ATP tier 1, check in CI Tier 1
        const tier1PcList = getTier1CIPcs(inventoryDataObj, localNearbyPcs);
        if (tier1PcList?.length > 0) {
            //present in tier1 CIs
            //dispatch the list
            saveAtpStoresList(catsku, { key: TIER1_STORES_KEYS.CI, value: tier1PcList });
            return TILE_STATES.CHANGE_DATES_AND_STORE_GRT72HRS;
        }
        //return unavailable if not in CI Tier 1
        return TILE_STATES.UNAVAILABLE;
    };

    const handlePickupATP = (catsku, inventoryDataObj, formattedSelectedPc, localNearbyPcs) => {
        const isInATPofSelPc = checkIfATPofSelPc(formattedSelectedPc, inventoryDataObj);
        if (isInATPofSelPc) return TILE_STATES.AVAILABLE;
        const isInCIOfSelPc = checkIfCIofSelPc(formattedSelectedPc, inventoryDataObj);
        if (isInCIOfSelPc) {
            return handleCIATP(catsku, inventoryDataObj, localNearbyPcs);
        } else {
            return handleNotInCIATP(catsku, inventoryDataObj, localNearbyPcs);
        }
    };
    /**
   * Handle the pickup flow based on the fulfillment type, start date time, end date time, and selected PC.

    *
   * @returns {string} The tile state based on the conditions.
   */

    const handlePickupFlow = (catsku, inventoryDataObj = '{}', dateDiff, formattedSelectedPc, localNearbyPcs) => {
        switch (true) {
            case dateDiff >= 72:
                return handlePickupCI(catsku, inventoryDataObj, formattedSelectedPc, localNearbyPcs);

            case dateDiff < 24:
                return handlePickupAdjATP(catsku, inventoryDataObj, formattedSelectedPc, localNearbyPcs);

            case dateDiff >= 24 && dateDiff < 72:
                return handlePickupATP(catsku, inventoryDataObj, formattedSelectedPc, localNearbyPcs);

            default:
                // Default code if none of the conditions are met
                return handlePickupCI(catsku, inventoryDataObj, formattedSelectedPc, localNearbyPcs);
        }
    };
    //TODO handle P2P flow
    const handleDeliveryCI = (catsku, inventoryDataObj, localNearbyPcs) => {
        const listOfCIStores = getTier1CIPcs(inventoryDataObj, localNearbyPcs); //TODO-> identify optimised logic to compute and save this data
        if (listOfCIStores?.length > 0) {
            return TILE_STATES.AVAILABLE;
        } else {
            return isP2PUser ? TILE_STATES.AVAILABLE_WITH_WARNING : TILE_STATES.UNAVAILABLE;
        }
    };

    const handleDeliveryAdjAtp = (catsku, inventoryDataObj, bsrList, localNearbyPcs) => {
        const bsrAdjAtpList = getCITier1Pcs(bsrList, inventoryDataObj, STORE_TYPE.ADJATP);
        if (bsrAdjAtpList?.length > 0) {
            //dispatch the pc list for this sku
            return TILE_STATES.AVAILABLE;
        }
        const bsrAtpList = getCITier1Pcs(bsrList, inventoryDataObj, STORE_TYPE.ATP);
        if (bsrAtpList?.length > 0) {
            return isP2PUser ? TILE_STATES.AVAILABLE : TILE_STATES.CHANGE_DATES_GRT24HRS; //TODO P2P available
        }
        const atpTier1List = getATPTier1List(inventoryDataObj, localNearbyPcs);
        if (atpTier1List?.length > 0) {
            return isP2PUser ? TILE_STATES.AVAILABLE : TILE_STATES.CHANGE_DATES_GRT24HRS; //TODO P2P available
        }
        const listOfCIStores = getTier1CIPcs(inventoryDataObj, localNearbyPcs); //Query if its bsR or tier 1 nearbypcs //TODO P2P handling
        if (listOfCIStores?.length > 0) {
            return isP2PUser ? TILE_STATES.AVAILABLE : TILE_STATES.CHANGE_DATES_GRT72HRS;
        } else {
            return isP2PUser ? TILE_STATES.AVAILABLE_WITH_WARNING : TILE_STATES.UNAVAILABLE;
        }
    };

    const handleDeliveryATP = (catsku, inventoryDataObj, localNearbyPcs) => {
        const atpTier1List = getATPTier1List(inventoryDataObj, localNearbyPcs);
        if (atpTier1List?.length > 0) {
            //dispatch the list of atp stores
            return TILE_STATES.AVAILABLE;
        }
        //check CI tier1
        const listOfCIStores = getTier1CIPcs(inventoryDataObj, localNearbyPcs); //TODO-> identify optimised logic to compute and save this data
        return listOfCIStores?.length > 0 ? TILE_STATES.CHANGE_DATES_GRT72HRS : TILE_STATES.UNAVAILABLE;
    };

    const handleDeliveryFlow = (catsku, inventoryDataObj = '{}', dateDiff, localBsrData = [], localNearbyPcs) => {
        switch (true) {
            case dateDiff >= 72:
                return handleDeliveryCI(catsku, inventoryDataObj, localNearbyPcs);

            case dateDiff < 24:
                return handleDeliveryAdjAtp(catsku, inventoryDataObj, localBsrData, localNearbyPcs);

            case dateDiff >= 24 && dateDiff < 72:
                return handleDeliveryATP(catsku, inventoryDataObj, localNearbyPcs);

            default:
                // Default code if none of the conditions are met
                return handleDeliveryCI(catsku, inventoryDataObj, localNearbyPcs);
        }
    };

    const handleP2PDeliveryFlow = (catsku, inventoryDataObj = '{}', dateDiff, localNearbyPcs) => {
        switch (true) {
            case dateDiff >= 48:
                return handleDeliveryCI(catsku, inventoryDataObj, localNearbyPcs);
            default:
                // Default code if none of the conditions are met
                return handleDeliveryCI(catsku, inventoryDataObj, localNearbyPcs); // Need to check for P2P delivery for every case CI check will be there or not
        }
    };

    const handleATP = payload => {
        switch (true) {
            case isP2PUser:
                return handleP2PATP(payload);

            default:
                return handleDotComATP(payload);
        }
    };

    const handleDotComATP = payload => {
        try {
            const {
                catsku,
                inventoryDataObj = '{}',
                showonlinecatalog = false,
                disableaddtocartoption = false,
                localStartDate = startDate,
                localIsInStorePickup = isInStorePickup,
                locationPCData = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS)),
                isLocalLocationEmpty = isLocationEmpty,
                localBsrData = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST)
                    ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST))
                    : [],
                localCompanyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                localIsTier2Radius = isValidString(isTier2Radius()),
                localNearbyPcs = getNearByPcList(),
                pcAvailability = true
            } = payload;

            let dateDiff = getDateDiffInHrs(localStartDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
            const fulfillmentType = localIsInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY;
            const storePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}');
            const selectedPc = locationPCData?.pc || storePC?.pc || pc;
            const formattedSelectedPc = selectedPc ? formatNearbyPC(selectedPc, `0${localCompanyID}`) : null;

            switch (true) {
                case checkIsRequestQuote(showonlinecatalog, disableaddtocartoption)?.isRequestQuote:
                    return TILE_STATES.AVAILABLE;

                case isLocalLocationEmpty && !isValidString(localStartDate) && !isValidString(isAtpDisabled):
                    return TILE_STATES.EMPTY_LOCATION_AND_EMPTY_DATES;

                case isLocalLocationEmpty:
                    return TILE_STATES.EMPTY_LOCATION;

                case isValidString(isAtpDisabled):
                    return pcAvailability ? TILE_STATES.AVAILABLE : TILE_STATES.UNAVAILABLE;

                case !isValidString(localStartDate):
                    return TILE_STATES.EMPTY_DATES;

                case isValidString(inventoryDataObj):
                case localIsTier2Radius:
                case !!overridePc?.pc:
                    return TILE_STATES.AVAILABLE;

                case fulfillmentType === FULFILLMENT_TYPE.PICKUP:
                    return handlePickupFlow(catsku, inventoryDataObj, dateDiff, formattedSelectedPc, localNearbyPcs);

                case fulfillmentType === FULFILLMENT_TYPE.DELIVERY:
                    return handleDeliveryFlow(catsku, inventoryDataObj, dateDiff, localBsrData, localNearbyPcs);

                default:
                    return TILE_STATES.AVAILABLE;
            }
        } catch (error) {
            logError(error, false, 'handleATP', [payload]);
            return TILE_STATES.AVAILABLE;
        }
    };

    const handleATPCart = payload => {
        try {
            const {
                catsku,
                inventoryDataObj = '{}',
                showonlinecatalog = false,
                disableaddtocartoption = false,
                localStartDate = startDate,
                localIsInStorePickup = isInStorePickup,
                locationPCData = JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS)),
                localBsrData = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST)
                    ? JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.BSR_PC_LIST))
                    : [],
                localCompanyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                localIsTier2Radius = isValidString(isTier2Radius()),
                localNearbyPcs = getNearByPcList(),
                isLocalLocationEmpty = isLocationEmpty
            } = payload;

            let dateDiff = getDateDiffInHrs(localStartDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
            const fulfillmentType = localIsInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY;
            const storePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}');
            const selectedPc = locationPCData?.pc || storePC?.pc || pc;
            const formattedSelectedPc = selectedPc ? formatNearbyPC(selectedPc, `0${localCompanyID}`) : null;
            switch (true) {
                case isLocalLocationEmpty:
                case !localNearbyPcs?.length:
                case isValidString(inventoryDataObj):
                case localIsTier2Radius:
                case !!overridePc?.pc:
                case !isValidString(localStartDate) && !isValidString(isAtpDisabled):
                case isValidString(isInventoryCheckDisabled):
                case checkIsRequestQuote(showonlinecatalog, disableaddtocartoption)?.isRequestQuote:
                    return TILE_STATES.AVAILABLE;

                case !isValidString(localStartDate):
                case isValidString(isAtpDisabled):
                    return fulfillmentType === FULFILLMENT_TYPE.PICKUP
                        ? handlePickupCI(catsku, inventoryDataObj, formattedSelectedPc, localNearbyPcs)
                        : handleDeliveryCI(catsku, inventoryDataObj, localNearbyPcs);

                case fulfillmentType === FULFILLMENT_TYPE.PICKUP:
                    return handlePickupFlow(catsku, inventoryDataObj, dateDiff, formattedSelectedPc, localNearbyPcs);

                case fulfillmentType === FULFILLMENT_TYPE.DELIVERY:
                    return handleDeliveryFlow(catsku, inventoryDataObj, dateDiff, localBsrData, localNearbyPcs);

                default:
                    return TILE_STATES.AVAILABLE;
            }
        } catch (error) {
            logError(error, false, 'handleATPCart', [payload]);
            return TILE_STATES.AVAILABLE;
        }
    };

    const getItemAvailabilityATP = (items = []) => {
        let availableItems = [];
        let conditionalItems = [];
        let unavailableItems = [];
        items?.map(item => {
            const tileStatus = handleATPCart({
                catsku: item?.product?.sku,
                inventoryDataObj: item?.product?.ec_pc_inventory
            });
            if (tileStatus === TILE_STATES.AVAILABLE || tileStatus === TILE_STATES.AVAILABLE_WITH_WARNING) {
                availableItems.push(item);
            } else if (tileStatus !== TILE_STATES.UNAVAILABLE) {
                conditionalItems.push({
                    ...item,
                    status: tileStatus,
                    prices: { row_total: { currency: '', value: 0 } }
                });
            } else {
                unavailableItems.push(item);
            }
        });
        return { availableItems, conditionalItems, unavailableItems };
    };

    const handleP2PATP = payload => {
        const {
            catsku,
            inventoryDataObj = '{}',
            isMinicart = false,
            localStartDate = startDate,
            localIsInStorePickup = isInStorePickup,
            locationPCData = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ)),
            isLocalLocationEmpty = isLocationEmpty,
            localCompanyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
            localIsTier2Radius = isValidString(isTier2Radius()),
            localNearbyPcs = getNearByPcList()
        } = payload;
        let dateDiff = getDateDiffInHrs(localStartDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        const fulfillmentType = localIsInStorePickup ? FULFILLMENT_TYPE.PICKUP : FULFILLMENT_TYPE.DELIVERY;
        const selectedPc = locationPCData?.pc || pc;
        const formattedSelectedPc = selectedPc ? formatNearbyPC(selectedPc, `0${localCompanyID}`) : null;
        switch (true) {
            case isLocalLocationEmpty &&
                !isValidString(localStartDate) &&
                !isValidString(ENV_CONFIG.IS_ATP_DISABLED) &&
                !isMinicart:
                return TILE_STATES.EMPTY_LOCATION_AND_EMPTY_DATES;

            case isLocalLocationEmpty && !isMinicart:
                return TILE_STATES.EMPTY_LOCATION;

            case isValidString(ENV_CONFIG.IS_ATP_DISABLED):
                return TILE_STATES.AVAILABLE;

            case !isValidString(localStartDate) && !isMinicart:
                return TILE_STATES.EMPTY_DATES;

            case isValidString(inventoryDataObj):
            case localIsTier2Radius:
            case !!overridePc?.pc:
                return TILE_STATES.AVAILABLE;

            case fulfillmentType === FULFILLMENT_TYPE.PICKUP:
                return handlePickupFlow(catsku, inventoryDataObj, dateDiff, formattedSelectedPc, localNearbyPcs);

            case fulfillmentType === FULFILLMENT_TYPE.DELIVERY:
                return handleP2PDeliveryFlow(catsku, inventoryDataObj, dateDiff, localNearbyPcs);

            default:
                return TILE_STATES.AVAILABLE;
        }
    };

    return {
        handleATP,
        handleATPCart,
        getItemAvailabilityATP
    };
};
