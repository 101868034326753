export const dataBricksConstant = {
    grant_type: 'client_credentials',
    client_id: '0oa3cjs4xbyricVYP1d7',
    client_secret: 'lkSpZKIfO-y4vPZSYyDlnDagwSOvWlTR6e8U4hbi',
    scope: 'Mulesoft',
    requestOrigin: 'SBRWEB',
    channel: 'b2b',
    defaultItemLength: 6,
    minproductLength : 4
};
