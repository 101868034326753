import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import {
    SET_ACTION_NAME,
    SET_CLEAR_WISHLISTS,
    SET_CURRENT_WISHLIST_ID,
    SET_FAVOURITES_ERROR,
    SET_IS_COPY_FAVORITE_BTN_CLICKED,
    SET_IS_FAVORITE,
    SET_IS_MOVE_FAVORITE_BTN_CLICKED,
    SET_IS_SAVE_FAVORITE_BTN_CLICKED,
    SET_PRODUCT_TO_WISHLIST,
    SET_SELECTED_WISHLISTS,
    SET_SHOW_SAVE_FAVORITE_MODAL,
    SET_WISHLIST_LIST,
    SET_WISHLIST_LOADING,
    SET_WISHLIST_UPDATING,
    SET_IS_SAVE_FAV_DRAWER_OPEN
} from '../actionTypes/actionTypes';

export const favoritesInitialState = {
    favourites: JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.MY_FAVORITES_DROPDOWN_DATA)) || [],
    favouritesLoading: undefined,
    favouritesListUpdating: undefined,
    error: '',
    showSaveToFavModal: false,
    favProduct: {},
    isSaveFavBtnClicked: false,
    isCopyFavBtnClicked: false,
    isMoveFavBtnClicked: false,
    actionName: '',
    currentWishlistId: '',
    selectedWishlists: [],
    favoriteProducts: [],
    isSaveToFavDrawerOpen: false
};

export const favoritesReducer = (state, action) => {
    switch (action.type) {
        case SET_WISHLIST_LIST:
            return {
                ...state,
                favourites: action.payload,
                favouritesLoading: false
            };
        case SET_FAVOURITES_ERROR:
            return {
                ...state,
                error: action.payload
            };
        case SET_SHOW_SAVE_FAVORITE_MODAL:
            return {
                ...state,
                showSaveToFavModal: action.payload
            };
        case SET_IS_SAVE_FAVORITE_BTN_CLICKED:
            return {
                ...state,
                isSaveFavBtnClicked: action.payload
            };
        case SET_IS_MOVE_FAVORITE_BTN_CLICKED:
            return {
                ...state,
                isMoveFavBtnClicked: action.payload
            };
        case SET_IS_COPY_FAVORITE_BTN_CLICKED:
            return {
                ...state,
                isCopyFavBtnClicked: action.payload
            };
        case SET_PRODUCT_TO_WISHLIST:
            return {
                ...state,
                favProduct: action.payload
            };
        case SET_CURRENT_WISHLIST_ID:
            return {
                ...state,
                currentWishlistId: action.payload
            };
        case SET_ACTION_NAME:
            return {
                ...state,
                actionName: action.payload
            };
        case SET_SELECTED_WISHLISTS:
            return {
                ...state,
                selectedWishlists: action.payload
            };
        case SET_CLEAR_WISHLISTS:
            return {
                ...state,
                selectedWishlists: []
            };
        case SET_WISHLIST_LOADING:
            return {
                ...state,
                favouritesLoading: action.payload // true or false
            };
        case SET_WISHLIST_UPDATING:
            return {
                ...state,
                favouritesListUpdating: action.payload // true or false
            };
        case SET_IS_FAVORITE:
            return {
                ...state,
                favoriteProducts: action.payload
            };
        case SET_IS_SAVE_FAV_DRAWER_OPEN:
            return {
                ...state,
                isSaveToFavDrawerOpen: action.payload,
            };

        default:
            return state;
    }
};
