
import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ConfigContext = React.createContext();

const ConfigContextProvider = props => {
    const [config] = useState(props.config);

    return <ConfigContext.Provider value={config}>{props.children}</ConfigContext.Provider>;
};

ConfigContextProvider.propTypes = {
    config: PropTypes.shape({
        storeView: PropTypes.string.isRequired,
        headers: PropTypes.object,
        graphqlEndpoint: PropTypes.string.isRequired,
        graphqlMethod: PropTypes.oneOf(['GET', 'POST']).isRequired,
        mountingPoints: PropTypes.shape({
            accountContainer: PropTypes.string,
            addressBookContainer: PropTypes.string,
            authBarContainer: PropTypes.string,
            cartTrigger: PropTypes.string,
            navPanel: PropTypes.string,
            bundleProductOptionsContainer: PropTypes.string,
            accountDetailsDialogContainer: PropTypes.string
        }),
        pagePaths: PropTypes.shape({
            addressBook: PropTypes.string,
            baseUrl: PropTypes.string
        })
    }).isRequired
};

export default ConfigContextProvider;

export const useConfigContext = () => useContext(ConfigContext);
