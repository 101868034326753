export const SET_WISHLIST_LIST = 'SET_WISHLIST_LIST';
export const SET_FAVOURITES_ERROR = 'SET_FAVOURITES_ERROR';
export const SET_SHOW_SAVE_FAVORITE_MODAL = 'SET_SHOW_SAVE_FAVORITE_MODAL';
export const SET_IS_SAVE_FAVORITE_BTN_CLICKED = 'SET_IS_SAVE_FAVORITE_BTN_CLICKED';
export const SET_PRODUCT_TO_WISHLIST = 'SET_PRODUCT_TO_WISHLIST';
export const SET_IS_MOVE_FAVORITE_BTN_CLICKED = 'SET_IS_MOVE_FAVORITE_BTN_CLICKED';
export const SET_IS_COPY_FAVORITE_BTN_CLICKED = 'SET_IS_COPY_FAVORITE_BTN_CLICKED';
export const SET_ACTION_NAME = 'SET_ACTION_NAME';
export const SET_CURRENT_WISHLIST_ID = 'SET_CURRENT_WISHLIST_ID';
export const SET_SELECTED_WISHLISTS = 'SET_SELECTED_WISHLISTS';
export const SET_CLEAR_WISHLISTS = 'SET_CLEAR_WISHLISTS';
export const SET_IS_FAVORITE = 'SET_IS_FAVORITE'
export const SET_WISHLIST_LOADING = 'SET_WISHLIST_LOADING';
export const SET_WISHLIST_UPDATING = 'SET_WISHLIST_UPDATING';
export const SET_IS_SAVE_FAV_DRAWER_OPEN = 'SET_IS_SAVE_FAV_DRAWER_OPEN';
