export const QUOTE_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FILTER_FORMAT = 'MMM DD, YYYY';
export const QUOTE_DELIVERY = 'Round-trip delivery';
export const QUOTE_INSTORE_PICKUP = 'In-store pickup';
export const QUOTES_PAGE_SIZE = 20;
export const QUOTES_DATA_LIMIT = 1000;
export const MAX_NO_OF_QUOTE_RECORDS = 2000;
export const PUNCHOUT = 'PUNCHOUT';

// keeping filter keys as a constant to reuse
export const QUOTE_FILTER_KEY_ACCOUNT = 'accounts';
export const QUOTE_FILTER_KEY_JOBSITE = 'jobsites';
export const QUOTE_FILTER_KEY_STATUS = 'status';
export const QUOTE_FILTER_KEY_RENTAL_START_DATE = 'rentalStartDateRange';
export const QUOTE_FILTER_KEY_EST_END_DATE = 'estimatedRentalEndDateRange';
export const QUOTE_FILTER_KEY_FULFILLMENT = 'fulfillmentType';
export const QUOTE_FILTER_KEY_CREATED_BY = 'createdBy';
export const START_DATE = 'startDate';
export const END_DATE = 'endDate';
export const IS_TRANSMITTED = 'isTransmitted';
export const IS_UNTRANSMITTED = 'isUnTransmitted';
export const DEFAULT_START_DATE = 30;
export const DEFAULT_MIN_DATE = 60;
export const QUOTE_FILTER_KEY_ORDERED_BY = 'orderedBy';

// status filter const
export const QUOTE_FILTER_STATUS_TRANSMITTED = 'Transmitted';
export const QUOTE_FILTER_STATUS_UNTRANSMITTED = 'Untransmitted';
export const STATUS = 'Status';

// fulfillmenttype filter const
export const QUOTE_FILTER_FULFILLMENT_ROUNDTRIP = 'Round-trip delivery';
export const QUOTE_FILTER_FULFILLMENT_INSTORE = 'In-store pickup';
export const QUOTE_DETAIL_SBR_PUNCHOUT = 'SBR PUNCHOUT';
export const QUOTE_DETAIL_SBR_EMPLOYEE = 'SBR EMPLOYEE';
export const QUOTE_DETAIL_START_DATE = 'Start date';
export const QUOTE_DETAIL_ACCOUNT = 'Account';
export const QUOTE_DETAIL_CREATED_BY = 'Created by';
export const QUOTE_DETAIL_ORDERED_BY = 'Ordered by';
export const QUOTE_DETAIL_JOBSITE = 'Jobsite';
export const QUOTE_DETAIL_SITE_CONTACT = 'Site contact';
export const QUOTE_DETAIL_FULFILLMENT_TYPE = 'Fulfillment type';
export const QUOTE_DETAIL_RECORD_TYPE_PUNCHOUT = 'PUNCHOUT';
export const QUOTE_DETAIL_STOCKCLASS_PRODUCTS = ['DEL', 'MISC', 'FUEL'];
export const QUOTE_DETAIL_PRODUCT_TYPE_CAT_CLASS = 'Cat-Class';
export const QUOTE_DETAIL_PRODUCT_TYPE_MISC_CHARGE_ITEMS = 'MISC Charge Items';
export const QUOTE_EXPORT_JOBSITE_FIELD = 'jobsite';

export const SORT_ASCENDING = 'ASC';
export const SORT_DESCENDING = 'DESC';
export const ESTIMATED_COST = 'estimatedCost';
export const RELOAD = 'reload';

export const QUOTE_CHOOSE_COLUMN_EXCUDED_FIELD = ['quote', 'status', 'jobsite', 'estimatedCost', 'dateCreated'];

export const DEFAULT_SELECTED_NON_CORP_QUOTES_COLUMN = [
    'quote',
    'status',
    'jobsite',
    'estimatedCost',
    'dateCreated',
    'startDate',
    'estimatedEndDate',
    'fullfillmentType',
    'orderedBy'
];

export const DEFAULT_SELECTED_CORP_QUOTES_COLUMN = [
    ...DEFAULT_SELECTED_NON_CORP_QUOTES_COLUMN,
    'accountName',
    'account'
];

export const DEFAULT_QUOTES_COLUMN_KEYS = [
    {
        key: 'quote',
        minWidth: 97
    },
    {
        key: 'accountName',
        minWidth: 200
    },
    {
        key: 'account',
        minWidth: 97
    },
    {
        key: 'status',
        minWidth: 129
    },
    {
        key: 'jobsite',
        minWidth: 240
    },
    {
        key: 'estimatedCost',
        minWidth: 150
    },
    {
        key: 'dateCreated',
        minWidth: 107
    },
    {
        key: 'startDate',
        minWidth: 150
    },
    {
        key: 'estimatedEndDate',
        minWidth: 107
    },
    {
        key: 'fullfillmentType',
        minWidth: 120
    },
    {
        key: 'orderedBy',
        minWidth: 120
    },
    {
        key: 'createdBy',
        minWidth: 160
    },
    {
        key: 'lastModifiedDate',
        minWidth: 160
    }
];
