import { gql } from '@apollo/client';

const ADD_REMOVE_PRODUCT_FROM_WISHLIST = gql`
    mutation addRemoveProductToWishlist($wishlistId: [ID!]!, $sku: String!, $qty: Int!, $accountId: Int!) {
        addOrRemoveProductsFromWishlist(sku: $sku, quantity: $qty, wishlistIds: $wishlistId, accountId: $accountId) {
            isFavorite
            wishlistsList {
                id
            }
        }
    }
`;
export default ADD_REMOVE_PRODUCT_FROM_WISHLIST;
