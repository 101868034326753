import { bool, number, shape, string } from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import './maxQtyErrorComponent.scss';
import { AUTHORITY_TYPE } from '../../constants';

const MaxQtyErrorComponent = ({ skipQtyObj, sku, dataTestId, isMergeCart, qtyError }) => {
    const intl = useIntl();
    const authorityType = useCheckAuthorityType();

    const renderError = () => {
        let error = '';
        if (isMergeCart && skipQtyObj[sku]) {
            const { added_qty, partial_update, qty } = skipQtyObj[sku];
            // added_qty is the quantity which we sent as a guest user to add
            if (partial_update) {
                error = `${intl.formatMessage({
                    id: 'pdp-max-qty-about-to-reach-text1'
                })} ${added_qty - qty} ${intl.formatMessage({ id: 'pdp-max-qty-about-to-reach-text2' })}`;
            } else {
                error = intl.formatMessage({ id: 'pdp-max-qty-reached-message' });
            }
        } else if (qtyError) {
            error = qtyError;
        }
        return error ? (
            <div
                className={`maxQtyErrorClass ${authorityType === AUTHORITY_TYPE.P2P ? 'p2pUser' : ''}`} //will remove this condition logic in future sprint ecom story
                data-testid={dataTestId}
                tabindex="0"
                aria-live="polite">
                {error}
            </div>
        ) : null;
    };

    return renderError();
};

export default MaxQtyErrorComponent;

MaxQtyErrorComponent.propsType = {
    skipQtyObj: shape({
        added_qty: number,
        partial_update: bool,
        qty: number,
        sku: string
    }),
    sku: string,
    dataTestId: string,
    isMergeCart: bool,
    qtyError: string
};

MaxQtyErrorComponent.defaultProps = {
    skipQtyObj: shape({
        added_qty: 0,
        partial_update: false,
        qty: 0,
        sku: ''
    }),
    sku: '',
    dataTestId: '',
    isMergeCart: false,
    qtyError: ''
};
