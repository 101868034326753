window.addEventListener('load', event => {
    var posterCarousels = $('.poster__carousel');
    posterCarousels?.each(function (index, element) {
        try {
            var posterCarousel = $(this);
            var posterCarouselBlock = posterCarousel?.closest('.poster__block');
            var prevArrowButton = posterCarouselBlock?.find('.poster__carouse-action-prev');
            var nextArrowButton = posterCarouselBlock?.find('.poster__carouse-action-next');

            posterCarousel?.slick({
                infinite: true,
                centerMode: true,
                arrows: true,
                slidesToShow: 3,
                slidesToScroll: 3,
                centerPadding: '225px',
                prevArrow: prevArrowButton,
                nextArrow: nextArrowButton,
                responsive: [
                    {
                        breakpoint: 1025,
                        settings: {
                            infinite: true,
                            centerMode: true,
                            centerPadding: '212px',
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    {
                        breakpoint: 640,
                        settings: {
                            infinite: true,
                            centerMode: true,
                            centerPadding: '90px',
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
            posterCarousel.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
                let nextIndex = currentSlide + 1;
                if (currentSlide - 1 == nextSlide || (nextSlide + 1 == slick.slideCount && currentSlide < nextSlide)) {
                    nextIndex = currentSlide - 1;
                }
                $(`[data-slick-index="${nextIndex - 1}"]`).addClass('slick-target');
                $(`[data-slick-index="${nextIndex}"]`).addClass('slick-target');
                $(`[data-slick-index="${nextIndex + 1}"]`).addClass('slick-target');
            });

            posterCarousel.on('afterChange', () => {
                $('.slick-slide').removeClass('slick-target');
            });
        } catch (error) {
            console.error('An error occurred while initializing the slick slider:', error);
        }
    });
});
