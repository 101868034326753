import get from 'lodash/get';
import moment from 'moment';
import { ADDRESS_COMPONENTS, ADDRESS_COMPONENT_TYPE } from '../../../constants/cartConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { convertDateToMomentDateTime, getSearchRadius, isTier2Radius } from './commonUtils';
import { logError } from './logger';
import { getStoresData } from '../modules/Stores/Api/getStoresData';

export const determineTypeOfAddress = component => {
    const types = get(component, 'types', []);
    if (
        types.includes(ADDRESS_COMPONENTS.LOCALITY) ||
        types.includes(ADDRESS_COMPONENTS.AL3) ||
        types.includes(ADDRESS_COMPONENTS.AL2)
    ) {
        return ADDRESS_COMPONENT_TYPE.CITY;
    }
    if (types.includes(ADDRESS_COMPONENTS.AL1)) {
        return ADDRESS_COMPONENT_TYPE.STATE;
    }
    if (types.includes(ADDRESS_COMPONENTS.POSTAL)) {
        return ADDRESS_COMPONENT_TYPE.POSTAL_CODE;
    }
    if (types.includes(ADDRESS_COMPONENTS.STREET_NO)) {
        return ADDRESS_COMPONENT_TYPE.STREET;
    }
    if (types.includes(ADDRESS_COMPONENTS.COUNTRY)) {
        return ADDRESS_COMPONENT_TYPE.COUNTRY;
    }
    if (types.includes(ADDRESS_COMPONENTS.ROUTE)) {
        return ADDRESS_COMPONENT_TYPE.ROUTE;
    }
    if (types.includes(ADDRESS_COMPONENTS.SUBLOCALITY)) {
        return ADDRESS_COMPONENT_TYPE.SUBLOCALITY;
    }
};

const getDateandTimeHandler = (localStartDate, localEndDate) => {
    let startDate = '';
    let endDate = '';
    if (localStartDate && localEndDate) {
        startDate = localStartDate;
        endDate = localEndDate;
    } else {
        startDate = moment();
        endDate = moment().add(1, 'days');
    }
    return { startDate, endDate };
};

const callBranchSelectorApi = async (
    lat = '',
    long = '',
    localAssets = [],
    localStartDate,
    localEndDate,
    isDelivery,
    isLocationChanged
) => {
    const { startDate, endDate } = getDateandTimeHandler(localStartDate, localEndDate);
    const { tier1SearchRadius } = getSearchRadius() || 100;
    if (lat && long && localAssets.length > 0) {
        const getStoresPayload = {
            latitude: Number(lat),
            longitude: Number(long),
            pickupDateTime: convertDateToMomentDateTime(startDate),
            returnDateTime: convertDateToMomentDateTime(endDate),
            assets: localAssets,
            isDelivery,
            isLocationChanged
        };
        try {
            let deliveryStoreIndex = 0;
            const { data, status } = await getStoresData({ ...getStoresPayload });
            deliveryStoreIndex = updateStoreBasedOnSelectedStore(data);
            // Checking the 1500 miles for the selectedStorePC insted of sources first PC
            if (parseInt(data?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite) <= tier1SearchRadius) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS, false); //if store within 100 miles
            } else if (parseInt(data?.data?.[deliveryStoreIndex]?.drivingDistanceFromJobsite) > tier1SearchRadius) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_SOURCES_TIER_2_RADIUS, true); //if store > 100 miles
            }
            return { data, status, deliveryStoreIndex };
        } catch (err) {
            logError(err, false, 'callBranchSelectorApi', [lat, long, localAssets, localStartDate, localEndDate]);
            return 0;
        }
    }
};

let { catid, catsku } = document.querySelector('.block--pdp-hero')?.dataset || {};
const isDevelopment = process.env.NODE_ENV === 'development';

const getProductId = () => {
    if (catsku) {
        return catsku;
    }
    return null;
};

export const getAssetsFromPdpUrl = () => {
    const productIdLocal = getProductId();
    let assets = [];
    if (productIdLocal) {
        assets.push({
            productId: productIdLocal,
            quantity: 1,
            sequenceNumber: 0
        });
    }
    return assets;
};

export const updateSelectedStoreDetailsForRoundTrip = (storeData = {}) => {
    try {
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP,
            JSON.stringify(storeData || {})
        );
    } catch (error) {
        logError(error, false, 'updateSelectedStoreDetailsForRoundTrip', storeData);
    }
};

export const handleBranchSelectorCall = async (
    lat,
    long,
    localStartDate = '',
    localEndDate = '',
    assets = [],
    isDelivery = false,
    isLocationChanged
) => {
    let pcVal = 0;
    let pcLat = '';
    let pcLong = '';
    let distance = '';
    let specialtyTypes = [];
    let storeData = [];

    const storesData = await callBranchSelectorApi(
        lat,
        long,
        assets,
        localStartDate,
        localEndDate,
        isDelivery,
        isLocationChanged
    );
    if (storesData?.data?.data) {
        pcVal = storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.pc;
        pcLat = storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.latitude;
        pcLong = storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.longitude;
        distance = storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.drivingDistanceFromJobsite;
        specialtyTypes = storesData?.data?.data?.[storesData?.deliveryStoreIndex]?.specialtyTypes;
        storeData = storesData?.data?.data; //TODO:- Priya has changed to storesData?.data?.data?.[storesData?.deliveryStoreIndex];
    }
    return { pcVal, pcLat, pcLong, distance, specialtyTypes, storeData };
};

export const updateStoreBasedOnSelectedStore = data => {
    try {
        let deliveryStoreIndex = 0;
        if (data?.data?.length > 0) {
            if (isTier2Radius()) {
                updateSelectedStoreDetailsForRoundTrip(data?.data[0]);
            } else {
                const selectedStoreDetailsData = JSON.parse(
                    localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.SELECTEDSTOREDETAILS)
                );
                deliveryStoreIndex = data?.data?.findIndex(item => selectedStoreDetailsData?.pc === item?.pc);
                deliveryStoreIndex = deliveryStoreIndex !== -1 ? deliveryStoreIndex : 0;
                updateSelectedStoreDetailsForRoundTrip(data?.data[deliveryStoreIndex]);
            }
        }
        return deliveryStoreIndex;
    } catch (error) {
        logError(error, false, 'updateStoreBasedOnSelectedStore', [data]);
        return 0;
    }
};
