import axiosInstance from '../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../constants/apiConfig';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { logError } from '../../global/utils/logger';
import {
    formatNearbyPC,
    getPCfromList,
    getSearchRadius,
    isAtpCheckRequired,
    isNearByPcsRequired,
    triggerCoveoSearchAPI
} from '../../global/utils/commonUtils';
import { updateContextBasedOnCompanyId } from '../../search/Engine';
import { productListingController } from '../../search/controllers/plplistcontroller';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { CATEGORY_PAGE_TEMPLATE_STRING } from '../../../constants/screenConstants';
import {
    SET_ATP_PC_LIST,
    SET_CID_PC_LIST,
    SET_HOME_ASSETS_RATES
} from '../../../aem-core-components/actions/constants';

const { GET_STORE_LOCATIONS, BRANCH_LOCATOR, GET_FILTER_SPECIALTY_DATA, GET_STREET_VIEW_IMAGE_DATA } = API_CONFIG;
const { tier1SearchRadius, tier2SearchRadius } = getSearchRadius() || {};
const getStoreLocations = async (latitude, longitude, distance, companyId = 0) => {
    try {
        let params = '';
        if (latitude) {
            params = { latitude: latitude, longitude: longitude, distance: distance };
        }
        const { data, error, status } = await axiosInstance.get(GET_STORE_LOCATIONS, {
            params,
            headers: { 'Content-Encoding': 'application/gzip', companyId: companyId }
        });
        return { data, error, status };
    } catch (error) {
        logError(error, false, 'getStoreLocations', [latitude, longitude, distance]);
        return { error };
    }
};

const getStoreDetailsFromPC = async (pc, companyId) => {
    try {
        const { data, error, status } = await axiosInstance.get(GET_STORE_LOCATIONS + '/' + pc, {
            headers: { 'Content-Encoding': 'application/gzip', companyId: companyId || 1 }
        });
        return { data, error, status };
    } catch (error) {
        logError(error, false, 'getStoreDetailsFromPC', [pc, companyId]);
        return { error };
    }
};

const getFilterSpecialtyData = async () => {
    try {
        const { data, error, status } = await axiosInstance.get(GET_FILTER_SPECIALTY_DATA);
        return { data, error, status };
    } catch (error) {
        logError(error, false, 'getFilterSpecialtyData', []);
        return { error };
    }
};

const resetNearbyPCs = () => {
    updateContextBasedOnCompanyId(
        [],
        [
            {
                key: 'nearbypcs',
                val: []
            }
        ]
    );
    if (window.location.pathname.includes('/search')) {
        triggerCoveoSearchAPI();
    }
    if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
        productListingController.refresh();
    }
};

const setGlobalLocationDetails = (
    latitude,
    longitude,
    storesDistance,
    cidPcList,
    atpPcList,
    dispatch,
    showUnavailableItems,
    stores,
    isStoreOrLocationChanged
) => {
    // stores will not have data when it is tier2 radius case and locations is not yet called
    if (stores?.pc || storesDistance == tier2SearchRadius) {
        const overridePC = JSON.parse(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}');
        if (!overridePC?.pc) {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES);
            dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
        }
        // sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CID_PC_LIST, JSON.stringify(cidPcList));
        // sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ATP_PC_LIST, JSON.stringify(atpPcList));
        dispatch({ type: SET_CID_PC_LIST, cidPcList: [...cidPcList] });
        dispatch({ type: SET_ATP_PC_LIST, atpPcList });
        updateContextBasedOnCompanyId(
            [],
            [
                {
                    key: 'nearbypcs',
                    val: isAtpCheckRequired()
                }
            ],
            isNearByPcsRequired(showUnavailableItems)
        );
        if (window.location.pathname.includes('/search')) {
            triggerCoveoSearchAPI();
        }
        if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
            productListingController.refresh();
        }
        if (isStoreOrLocationChanged) {
            const { pc, latitude: lat, longitude: long, distance, specialtyTypes } = stores;
            // TODO for reusability
            sessionStorage.setItem(
                STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ,
                JSON.stringify({
                    pc,
                    lat,
                    long,
                    localLat: latitude,
                    localLong: longitude,
                    distance,
                    specialtyTypes
                })
            );
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ANALYSISREGION, stores?.analysisRegion);
        }
    } else {
        if (!latitude && !longitude) {
            if (storesDistance == tier1SearchRadius) {
                sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
            } else {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS, true);
            }
        }

        dispatch({ type: 'endLoading' });
        resetNearbyPCs();
    }
};

/*The below function fetch the data for locations call and return is PC and allStores data and distance for which Pcs are fecthed */
const getPCfromLocations = async (latitude, longitude, locationDistance, dispatch, showUnavailableItems) => {
    try {
        let params = '';
        if (latitude) {
            params = { latitude: latitude, longitude: longitude, distance: locationDistance };
            const { data, error, status } = await axiosInstance.get(GET_STORE_LOCATIONS, {
                params,
                headers: { 'Content-Encoding': 'application/gzip' }
            });
            if (data?.data?.pcList?.length > 0) {
                const overridePC = JSON.parse(
                    sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.OVERRIDEPC) || '{}'
                );

                if (!overridePC?.pc) {
                    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.HOMEASSETSRATES);
                    dispatch({ type: SET_HOME_ASSETS_RATES, homeAssetsRates: {} });
                }
                const { pcDetails, pickupStorePcs } = getPCfromList(data?.data?.pcList);
                if (locationDistance == tier1SearchRadius) {
                    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS);
                    updateContextBasedOnCompanyId(
                        [],
                        [
                            {
                                key: 'nearbypcs',
                                val: isAtpCheckRequired()
                            }
                        ],
                        isNearByPcsRequired(showUnavailableItems)
                    );
                    if (window.location.pathname.includes('/search')) {
                        triggerCoveoSearchAPI();
                    }
                    if (ENV_CONFIG.PAGE_TEMPLATE_STRING == CATEGORY_PAGE_TEMPLATE_STRING) {
                        productListingController.refresh();
                    }
                } else {
                    resetNearbyPCs();
                }
                const { pc, latitude: lat, longitude: long, distance, specialtyTypes } = pcDetails;
                // TODO for reusability
                sessionStorage.setItem(
                    STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ,
                    JSON.stringify({
                        pc,
                        lat,
                        long,
                        localLat: latitude,
                        localLong: longitude,
                        distance,
                        specialtyTypes
                    })
                );
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ANALYSISREGION, pcDetails?.analysisRegion);
                return {
                    pcObj: {
                        pcVal: pc,
                        pcLat: lat,
                        pcLong: long,
                        distance,
                        specialtyTypes
                    },
                    pcList: pickupStorePcs,
                    distance: locationDistance,
                    error
                };
            } else if (locationDistance == tier1SearchRadius) {
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.IS_LOCATIONS_TIER_2_RADIUS, true);

                return getPCfromLocations(latitude, longitude, tier2SearchRadius, dispatch, showUnavailableItems);
            } else {
                dispatch({ type: 'endLoading' });
                resetNearbyPCs();
                return {
                    pcObj: {},
                    pcList: [],
                    distance: locationDistance,
                    error: null
                };
            }
        } else {
            return {
                pcObj: {},
                pcList: [],
                distance: locationDistance,
                error: null
            };
        }
    } catch (error) {
        logError(error, false, 'getPCfromLocations', [latitude, longitude, locationDistance]);
        return { error };
    }
};

const getBSRListAPI = async (
    lat,
    long,
    companyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1
) => {
    try {
        if (lat && long) {
            const { data, error } = await axiosInstance.get(BRANCH_LOCATOR, {
                params: {
                    jobsiteLatitude: lat,
                    jobsiteLongitude: long
                },
                headers: {
                    companyId: companyID
                }
            });
            return { data: data?.data?.branches, error };
        }
    } catch (error) {
        return { error };
    }
};

const getStreetViewData = async (lat, long) => {
    try {
        const streetViewConfigs = ENV_CONFIG?.STREET_VIEW_IMAGE_CONFIG;
        if (lat && long) {
            const { data, error } = await axiosInstance.get(GET_STREET_VIEW_IMAGE_DATA, {
                params: {
                    location: `${lat}, ${long}`,
                    size: streetViewConfigs?.streetviewimageSize || '600x300',
                    heading:  streetViewConfigs?.streetviewimageHeading || 0,
                    fov: streetViewConfigs?.streetviewimageFov || 120,
                    radius: streetViewConfigs?.streetviewimageRadius || 50,
                    pitch: streetViewConfigs?.streetviewimagePitch || 0
                },
                headers: {
                    isSilentOnError: true
                }
            });
            return { data: data?.data, error };
        }
    } catch (error) {
        logError(error, false, 'getStreetViewData', [lat, long]);
    }
};

export {
    getStoreLocations,
    getPCfromLocations,
    getStoreDetailsFromPC,
    getBSRListAPI,
    setGlobalLocationDetails,
    getFilterSpecialtyData,
    getStreetViewData
};
