export const getCookie = (name) =>{
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const [cookieName, cookieValue] = cookie.split('=');
        if (cookieName === name) {
            return decodeURIComponent(cookieValue);
        }
    }
    return null; // Return null if the cookie is not found
}

export const USER_TYPE = {
    GUEST: 'guest',
    CASH: 'cash',
    CREDIT: 'credit',
    PUNCHOUT: 'punchout'
};

export const getUserType =() =>{
    return sessionStorage.getItem('userType');
}
