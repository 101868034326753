import React, { useEffect, useState } from 'react';
import { ENTER_KEY } from '../../../../constants/screenConstants';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../constants';
import AccountListView from './AccountListView';
import {
    MIN_SEARCH_QUERY_LENGTH,
    isAccountNotActive
} from '../../utils/commonUtils';
import './ChooseAccountDrawer.scss';


const AllAccounts = (props) => {
    const { accountList, setAccountList, selectedValue, setSelectedValue, cardStyle, updateAccountDetailsHandler, handleTextInactive, sortSelectedAccounts, filterData, isUserProfileLoading, userProfile } = props;
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedAccountOnTop, setSelectedAccountOnTop] = useState(true);

    useEffect(() => {
        updateAccountList();
    }, [searchQuery]);

    useEffect(() => {
        if (selectedAccountOnTop) {
            const sortedAccountList = sortSelectedAccounts(selectedValue, userProfile?.accounts);
            setAccountList(sortedAccountList);
        }
    }, [selectedAccountOnTop, searchQuery]);

    const updateAccountList = () => {
        if (searchQuery?.length < MIN_SEARCH_QUERY_LENGTH) {
            setAccountList(userProfile?.accounts);
            setSelectedAccountOnTop(true);
        } else {
            setAccountList(filterData(accountList, userProfile?.accounts, searchQuery));
            setSelectedAccountOnTop(false);
        }
    };

    const onSearchInputChange = e => {
        setSearchQuery(e?.target?.value);
    };

    const onResetClick = () => {
        setSearchQuery('');
        setAccountList(userProfile?.accounts);
        setSelectedAccountOnTop(true);
    };

    const getAccountList = () => {
        if (isP2PUser) {
            return accountList?.filter(account => !isAccountNotActive(account.accountStatus)); //filter inactive accounts
        }
        return accountList;
    };

    const onSearchIconClick = () => {
        updateAccountList();
    };

    const onEnterKeyPressed = e => {
        if (e.key === ENTER_KEY) {
            updateAccountList();
        }
    };

    return (
        <AccountListView
            allAccountsList={userProfile?.accounts}
            accountList={accountList}
            searchQuery={searchQuery}
            onSearchInputChange={onSearchInputChange}
            onEnterKeyPressed={onEnterKeyPressed}
            onResetClick={onResetClick}
            onSearchIconClick={onSearchIconClick}
            getAccountList={getAccountList}
            selectedValue={selectedValue}
            isUserProfileLoading={isUserProfileLoading}
            setSelectedValue={setSelectedValue}
            cardStyle={cardStyle}
            updateAccountDetailsHandler={updateAccountDetailsHandler}
            handleTextInactive={handleTextInactive}
        />
    )
};

export default React.memo(AllAccounts);
