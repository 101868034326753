import React from 'react';
import { string } from 'prop-types';
import './noimageview.scss';
import ToolDefault from '../../../../../../resources/images/tools.svg';

const DefaultViewNoImage = props => {
    const { cardType, text } = props;
    return (
        <div className={`producttile__img producttile__img-noimage `}>
            <ToolDefault tabIndex={'-1'} aria-hidden={true} />
            <div className="producttile__img-noimage-text">{text}</div>
        </div>
    );
};

DefaultViewNoImage.propTypes = {
    text: string
};

DefaultViewNoImage.defaultProps = {
    text: 'No image yet'
};
export default DefaultViewNoImage;
