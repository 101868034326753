
import React from 'react';
import { oneOf, node, shape, string } from 'prop-types';

import defaultClasses from './button.css';

const getRootClassName = priority => `root_${priority}Priority`;

const Button = props => {
    const { children, priority, type, buttonAriaLabel, ...restProps } = props;

    let classes = props.classes ? Object.assign({}, defaultClasses, props.classes) : defaultClasses;

    const rootClassName = classes[getRootClassName(priority)];

    return (
        <button className={rootClassName} type={type} {...restProps} aria-label={buttonAriaLabel}>
            <span className={classes.content}>{children}</span>
        </button>
    );
};

Button.defaultProps = {
    priority: 'normal',
    buttonAriaLabel: '',
    type: 'button'
};

Button.propTypes = {
    priority: oneOf(['high', 'normal', 'low']),
    type: oneOf(['button', 'reset', 'submit']),
    children: node,
    buttonAriaLabel: string,
    classes: shape({
        content: string
    })
};

export default Button;
