import React from 'react';
import { number, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { currencyToLocale } from '../../../components/global/utils/commonUtils';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../../components/global/constants';

const Price = props => {
    const { value, currencyCode, className, included = false, minFractionDigits = 0, maxFractionDigits = 0 } = props;
    const intl = useIntl();
    const authorityType = useCheckAuthorityType();
    const isP2PUser = authorityType === AUTHORITY_TYPE.P2P;

    return (
        <b className={className}>
            {isP2PUser && included
                ? intl.formatMessage({ id: 'addons:included-force-item-label' })
                : currencyToLocale(value, minFractionDigits, maxFractionDigits)}
        </b>
    );
};

Price.propTypes = {
    /**
     * The numeric price
     */
    value: number.isRequired,
    /**
     * A string with any of the currency code supported by Intl.NumberFormat
     */
    currencyCode: string.isRequired,
    /**
     * Class name to use when styling this component
     */
    className: string
};

Price.defaultProps = {
    className: '',
    currencyCode: 'USD'
};

export default Price;
