import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { bool, func, number, string } from 'prop-types';
import Tooltip from '../../global/atoms/Tooltip';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';

const RentalCoachmark = ({
    isPickupstoreTooltipOpen,
    isLocationTooltipOpen,
    storeAnchorSelect,
    locationAnchorSelect,
    handlePickupstoreTooltipClose,
    handleLocationTooltipClose,
    zIndex,
    offset,
    userType
}) => {
    const capIntl = useIntl();
    const coachmarkData = ENV_CONFIG.COACHMARK_STATUS;

    return (
        <>
            <Tooltip
                isOpen={isPickupstoreTooltipOpen}
                anchorSelect={storeAnchorSelect}
                zIndex={zIndex}
                offset={offset}
                handleTooltipClose={handlePickupstoreTooltipClose}>
                {coachmarkData?.pickupstoretooltip || capIntl.formatMessage({ id: 'cap:coachmark-pickup-store' })}
            </Tooltip>
            {userType === USER_TYPE.CREDIT && (
                <Tooltip
                    isOpen={isLocationTooltipOpen}
                    anchorSelect={locationAnchorSelect}
                    zIndex={zIndex}
                    offset={offset}
                    handleTooltipClose={handleLocationTooltipClose}>
                    {coachmarkData?.creditlocationtooltip || capIntl.formatMessage({ id: 'cap:coachmark-rental-location' })}
                </Tooltip>
            )}
        </>
    );
};

RentalCoachmark.propTypes = {
    isPickupstoreTooltipOpen: bool,
    isLocationTooltipOpen: bool,
    storeAnchorSelect: string,
    locationAnchorSelect: string,
    handlePickupstoreTooltipClose: func,
    handleLocationTooltipClose: func,
    zIndex: number,
    offset: number,
    userType: string
};

RentalCoachmark.defaultProps = {
    isPickupstoreTooltipOpen: false,
    isLocationTooltipOpen: false,
    storeAnchorSelect: '',
    locationAnchorSelect: '',
    handlePickupstoreTooltipClose: () => {},
    handleLocationTooltipClose: () => {},
    zIndex: 5,
    offset: 7,
    userType: ''
};

export default memo(RentalCoachmark);
