import { defaultEngineOptions, productsuggestion_engine, relatedInfoSugesstions, relinfos_engine, rentals_engine } from '../Engine.js';
// @ts-ignore
const {
    buildCustomSearchBox,
    buildFieldSuggestion,
    buildOtherSuggestion
} = require('../browser/headless_extension_bundle.js');
const {
    buildSearchEngine,
    buildResultList,
    buildSearchBox,
    buildTab,
    buildFacet,
    buildPager,
    buildQuerySummary,
    buildSort,
    buildRelevanceSortCriterion,
    buildCategoryFacet,
    buildFieldSortCriterion,
    buildDidYouMean,
    buildResultsPerPage
} = require('@coveo/headless');
/* OPTIONS: searchbox parameters */
const sbOptions = {
    numberOfSuggestions: 10,
    highlightOptions: {
        exactMatchDelimiters: {
            open: '<mark>',
            close: '</mark>'
        }
    }
};

//create an engine and a result list controller for Rentals tab
const rentalListOptions = {
    options: {
        fieldsToInclude: [
            'categoryl1',
            'categoryl2',
            'categoryl3',
            'productcatsortindex',
            'ec_primary_image',
            'ec_skus',
            'ec_product_url',
            'ec_show_onlinecatalog',
            'ec_disable_addtocartoption',
            'source',
            'ec_category',
            'ec_category_id',
            'ec_name',
            'ec_brand',
            'ec_price',
            'ec_pc_inventory',
            'ec_meta_title',
            'ec_meta_description',
            'ec_specialtytypes',
            'ec_specialtytypecode',
            'ec_analysisgroupcode'
        ]
    }
};
const rentals_list = buildResultList(rentals_engine, rentalListOptions);

/* RelatedContent Suggestion add-on: to be assigned to the searchbox object */
const relatedcontentsuggestion_engine = buildSearchEngine(relatedInfoSugesstions);
const relatedcontentFilterExpression = null;
const relatedcontentsuggestion_groupname = 'RelatedContentSuggestions';
const relatedcontentSuggestions = buildOtherSuggestion(
    relatedcontentsuggestion_engine,
    relatedcontentsuggestion_groupname,
    'buildResultList',
    relatedcontentFilterExpression,
    { options: { fieldsToInclude: ['sfid'] } }
);

/* 
  Category Suggestion add-on: to be assigned to the searchbox object 
    - fetches values from jpderytest_category_l3 field - generated by IPE "jpdery_test_categoriesextendedfields" by splitting value of "categories" field from catalog
*/
const { organizationEndpoints, accessToken, organizationId, searchHub } = defaultEngineOptions.configuration;
const catl2_Suggestions = buildFieldSuggestion(
    'L2Categories',
    organizationEndpoints?.platform,
    accessToken,
    organizationId,
    searchHub,
    'categoryl2data',
    null,
    false,
    '::'
);

//create an engine and a result list controller for Related Infos tab
const relinfos_list = buildResultList(relinfos_engine, {
    options: { fieldsToInclude: ['content_type', 'thumbnail_image'] }
});

const rentals_productSuggestion = buildTab(productsuggestion_engine, {
    options: {
        id: 'RentalsTab',
        expression: ''
    },
    initialState: {
        isActive: true
    }
})

const productFilterExpression = '@source=="Product Catalog"';
const productsuggestion_groupname = 'ProductSuggestions';
const productSuggestions = buildOtherSuggestion(productsuggestion_engine, productsuggestion_groupname, 'buildResultList', null, { options: { fieldsToInclude: ['ec_product_url', 'ec_skus'] } });
const productNameSuggestions = buildFieldSuggestion(
    productsuggestion_groupname,
    organizationEndpoints?.platform,
    accessToken,
    organizationId,
    searchHub,
    'product_name_suggestions',
    '@ec_show_onlinecatalog=1',
    true,
    '::'
);

//need a customsearchbox  and to update result lists
// - this controller should be used by the visible searchbox on the PSP page
const search_based_addons = [productSuggestions, relatedcontentSuggestions];
const field_based_addons = [catl2_Suggestions];
const customSearchBox = buildCustomSearchBox(
    rentals_engine,
    { options: sbOptions },
    search_based_addons,
    field_based_addons
);

//need a searchbox controller for realted infos
//  - does not need to provide suggestions
//  - is the to submit keywords to the Related Infos engine.
const relinfos_searchbox_ctlr = buildSearchBox(relinfos_engine);

const popular_engine = buildSearchEngine(defaultEngineOptions);
const popularSearchTerms = buildSearchBox(popular_engine, { options: sbOptions });

//CHANGE OF DESIGN:
//  - initial design was to "hook/slave" teh Realted Info searchbox controllers to the visible/Rentals searchbox
//  - however, there is no "onsubmit"  can listen on to would tell us exactly whne the Rentals' searchbox is "submitting"
//  - hence: we have to change from passive (wait for an event) to active:
//    - your UI component should submit to both search boxes when user submits the keywords
//    - see sample user flow below "2.2) submit both searchboxes"

//Assing "Tabs" controllers to our engines
// - We want these to set the "originLevel2 / Tab" attribute to the request to Coveo. This attribute is used in Sunble-Commerce query pipeline to set apporpriate search Filters (rentals must search in Catalog source, related info search must search in sitemap/cotnent source).
// - We also initially set them active (otherwise Headless is using the "default" tab atribute.)

/* var rentalsFacetsList = ['ec_maxliftheight', 'ec_maxdiggingdepth', 'ec_maxdiggingreach', 'ec_platformextension'];
var rentalsFacetsControllerList = [];

for (let i = 0; i < rentalsFacetsList.length; i++) {
    let facetControllerProps = {
        options: {
            numberOfValues: 10,
            field: rentalsFacetsList[i]
        }
    };
    rentalsFacetsControllerList.push(buildFacet(rentals_engine, facetControllerProps));
} */

var relInfoFacetsList = ['content_type'];
var relInfoFacetsControllerList = [];

for (let i = 0; i < relInfoFacetsList.length; i++) {
    let facetControllerProps = {
        options: {
            numberOfValues: 10,
            field: relInfoFacetsList[i]
        }
    };
    relInfoFacetsControllerList.push(buildFacet(relinfos_engine, facetControllerProps));
}

var categoryFacet = buildCategoryFacet(rentals_engine, {
    options: { field: 'ec_category', numberOfValues: 10, delimitingCharacter: '|' }
});

var rentalsPagerController;
var relatedInfoPagerController;
const pagerProps = {
    options: {
        numberOfPages: 5
    }
};
rentalsPagerController = buildPager(rentals_engine, pagerProps);
relatedInfoPagerController = buildPager(relinfos_engine, pagerProps);

const resultsPagerProps = {
    initialState: {
        numberOfResults: 24
    }
};
const resultsPagerPropsRelInfo = {
    initialState: {
        numberOfResults: 10
    }
};
buildResultsPerPage(rentals_engine, resultsPagerProps);
buildResultsPerPage(relinfos_engine, resultsPagerPropsRelInfo);

/** query controller */

var querySummaryRelInfoController = buildQuerySummary(relinfos_engine);
var querySummaryProductResultsController = buildQuerySummary(rentals_engine);


const rentals_tab = buildTab(rentals_engine, {
    options: {
        id: 'RentalsTab',
        expression: ''
    },
    initialState: {
        isActive: true
    }
});
const relinfos_tab = buildTab(relinfos_engine, {
    options: {
        id: 'RelatedInfosTab',
        expression: ''
    },
    initialState: {
        isActive: true
    }
});

// now, let see what happens / add listeners to result lists:
//  - util_print_a_state:
//    - for this sample, we just output the controller's state to console.
//    - This function is passed to the "subscribe" function of our various controllers

// - listen to state chagne of rentals result list
let rentals_list_last_state = '';

// - listen to state chagne of relatedinfos result list
let relinfos_list_last_state = '';


var relevanceSortCriterion = buildRelevanceSortCriterion();
var nameSortAsc = buildFieldSortCriterion('title', 'ascending');
var nameSortDesc = buildFieldSortCriterion('title', 'descending');

var sortController = buildSort(rentals_engine, {
    initialState: {
        criterion: relevanceSortCriterion
    }
});

var diduMeanRentals = buildDidYouMean(rentals_engine);
var diduMeanRelated = buildDidYouMean(relinfos_engine);
//searchFor('concrete');
//rel info sort
// var relNameSortAsc = buildFieldSortCriterion(field: 'title', order: 'ascending' }]);
// var relNameSortDesc = buildFieldSortCriterion(field: 'title', order: 'descending' }]);

var reliSortController = buildSort(relinfos_engine, {
    initialState: {
        criterion: relevanceSortCriterion
    }
});

rentals_engine.enableAnalytics()
relinfos_engine.enableAnalytics()
export {
    customSearchBox,
    relinfos_searchbox_ctlr,
    rentals_engine,
    relinfos_engine,
    rentals_list,
    relinfos_list,
    rentalsPagerController,
    relatedInfoPagerController,
    querySummaryProductResultsController,
    querySummaryRelInfoController,
    sortController,
    reliSortController,
    relevanceSortCriterion,
    nameSortAsc,
    nameSortDesc,
    categoryFacet,
    diduMeanRentals,
    diduMeanRelated,
    relInfoFacetsControllerList,
    popularSearchTerms,
    relatedcontentsuggestion_engine,
    productsuggestion_engine,
};
