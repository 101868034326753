import axios from 'axios';
import axiosInstance from '../../../../../api/axiosDotComInstance';
import { API_CONFIG } from '../../../../../constants/apiConfig';
import { generateCorrelationId, logError } from '../../../utils/logger';
import { STORAGE_CONFIG } from '../../../../../constants/storageConfig';
import { cookieValue } from '../../../../../aem-core-components/utils/cookieUtils';
import { frontendConfig } from '../../../utils/commonUtils';
import { dataBricksConstant } from '../dataBricksConstant';
const { GENERATE_ACCESS_TOKEN,GET_RECOMMENDATIONS } = API_CONFIG;

export const generateAccessToken = async () => {
    try {
        const tokenBody = {
            grant_type: `${dataBricksConstant?.grant_type}`,
            client_id: frontendConfig?.openidmuleclientid || dataBricksConstant?.client_id,
            client_secret: frontendConfig?.openidmuleclientsecret || dataBricksConstant?.client_secret,
            scope: `${dataBricksConstant?.scope}`,
        };
        const { data, error } = await axiosInstance.post(GENERATE_ACCESS_TOKEN, tokenBody, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'isSilentOnError' : true
            }
        });
        return { data, error };
    } catch (error) {
        return {
            data: null,
            error: error
        };
    }
};
export const setAccessTokenSession = async () => {
    try {
        const { data: { access_token: token = '' } = {}, error: authError = {} } = (await generateAccessToken()) || {};
        if (token) {
            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.AUTHACCESSTOKEN, token);
        } else {
            throw new Error(authError);
        }
    } catch (error) {
        logError(error);
    }
};
const getLoginToken = () => {
    return JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.USER_LOGIN))?.loginToken || cookieValue(STORAGE_CONFIG.COOKIES.LOGINTOKEN) || '';
};
export const getDataBricks = async userAccount => {
    try {
        const getAuthAccessToken = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.AUTHACCESSTOKEN);
        const { data, error } = await axios.get(
            `${frontendConfig?.muledomain}${GET_RECOMMENDATIONS}`,
            {
                headers: {
                    account: `${userAccount}`,
                    authorization: `Bearer ${getAuthAccessToken}`,
                    'x-correlation-Id': generateCorrelationId(),
                    companyId: parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1,
                    loginToken: getLoginToken(),
                    requestOrigin: `${dataBricksConstant?.requestOrigin}`,
                    channel: `${dataBricksConstant?.channel}`,
                    isSilentOnError: true,
                    client_id: frontendConfig?.openidmuleclientid || dataBricksConstant?.client_id,
                    client_secret: frontendConfig?.openidmuleclientsecret || dataBricksConstant?.client_secret,
                }
            }
        );
        return { data, error };
    } catch (error) {
        return { error };
    }
};

