import React, { memo, useState } from 'react';
import { func, node, string } from 'prop-types';
import { useIntl } from 'react-intl';
import AlertIcon from '../../../../resources/images/alert-triangle-red.svg';
import './expandableAlert.scss';
import { ENTER_KEY } from '../../../../constants/screenConstants';

const ExpandableAlert = ({ role, icon, title, expandableContent, downChevronIcon, className, dataTestId }) => {
    const intl = useIntl();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleAlert = event => {
        event?.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    const getCustomAriaLabel = () => {
        if (role !== 'button') {
            return title();
        }
        const actionMessage = intl.formatMessage({
            id: isExpanded
                ? 'common:click-here-collapse-msg' 
                : 'common:click-here-expand-msg'
        });
        return `${actionMessage} ${title()}`;
    };

    const onKeyPressHandler = event => {
        if (event?.key === ENTER_KEY) {
            toggleAlert(event);
        }
    };

    return (
        <div
            role={role}
            className={`expandable-alert ${className}`}
            onClick={toggleAlert}
            onKeyPress={e => onKeyPressHandler(e)}
            data-testid={dataTestId}
            tabIndex={0}
            aria-label={getCustomAriaLabel()}
            aria-expanded={isExpanded}>
            {icon || <AlertIcon className="expandable-alert__alert-icon" tabIndex="-1" aria-hidden="true" />}
            <div className="expandable-alert__content">
                <div>{title()}</div>
                {isExpanded && <div>{expandableContent()}</div>}
            </div>
            <div className={`expandable-alert__down-chevron-icon ${isExpanded ? 'chevron-expand' : ''}`}>
                {downChevronIcon || <i className="icon icon-chevron-down-bold" />}
            </div>
        </div>
    );
};

ExpandableAlert.propTypes = {
    role: string,
    icon: node,
    title: func,
    expandableContent: func,
    downChevronIcon: node,
    dataTestId: string,
    className: string
};

ExpandableAlert.defaultProps = {
    role: 'alert',
    icon: null,
    title: () => { },
    expandableContent: () => { },
    downChevronIcon: null,
    dataTestId: '',
    className: ''
};

export default memo(ExpandableAlert);
