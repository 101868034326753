var faqSearch = document.querySelector('.faq__search'); //search form
var searchFaq = document.getElementById('searchFaq'); // search input
var faqListWrap = document.querySelectorAll('.faq__list'); // faq list
var faqList = document.querySelectorAll('.faq__item'); //faqItem
var faqError = document.querySelector('.faq__error'); // error Section
var faqInputClose = document.querySelector('.faq__input-close'); // close Button
var faqItemBrowseWrap = document.querySelector('.faq__item-browse-wrap'); // Browse Topics
var faqTitles = document.querySelectorAll('.faq__title');
var faqCount = document.querySelector('.faq__count');
var faqBrowseRow = document.querySelector('.faq__browse-row');
var allFaqWrap = document.querySelectorAll('.faq__item-faq');

faqTitles.forEach(faqtitle => {
    faqtitle?.addEventListener('click', function (e) {
        var faqCurrent = e.currentTarget;
        var question = faqCurrent.closest('.faq__item-faq');
        var questionButton = faqCurrent.querySelector('.faq__button');
        if (question) {
            question.classList.toggle('faq__item-faq--active');
            questionButton.setAttribute(
                'aria-expanded',
                questionButton.getAttribute('aria-expanded') === 'true' ? 'false' : 'true'
            );
        }
    });
});

faqList.forEach(faqTitle => {
    var faqBrowseLinkWrap = document.createElement('div');
    faqBrowseLinkWrap.classList.add('col-lg-6', 'col-md-6', 'faq__browse-links');
    faqBrowseLinkWrap.innerHTML = `<a href=#${faqTitle.id} class="faq__browse-link" tabindex='0'>${
        faqTitle.querySelector('.faq__title-top').innerText
    }</a>`;
    var faqBrowseLinkWrapContent = faqBrowseLinkWrap;
    if (faqBrowseRow !== null) {
        faqBrowseRow?.append(faqBrowseLinkWrapContent);
    }
});

const faqSections = document.querySelectorAll('.faq__item-faq');
for (faqSection of faqSections) {
    if (faqSection.childElementCount === 0) {
        faqSection.classList.add('is-empty');
    }
}

const handleSearchFqa = event => {
    var mainCount = 0;
    event.preventDefault();
    faqInputClose.classList.add('active');
    faqInputClose.ariaHidden = 'false';

    if (searchFaq.value === '') {
        faqItemBrowseWrap.classList.remove('faq__item-hide');
        faqInputClose.classList.remove('active');
        faqInputClose.ariaHidden = 'true';
    }
    // display divs
    allFaqWrap.forEach(faq => {
        faq.classList.remove('faq__item-faq--active');
        var splitValue = faq.textContent.toLowerCase().split('\n');

        function filterWords(word) {
            return word.includes(searchFaq.value.toLowerCase());
        }

        mainCount = mainCount + splitValue.filter(filterWords).length;

        if (!faq.textContent.toLowerCase().includes(searchFaq.value.toLowerCase()) && searchFaq.value.length >= 3) {
            faq.classList.add('faq__item-faq--hide');
            faq.classList.remove('faq__item-faq--visible');
        } else {
            //faq.closest('.faq__item').classList.remove("faq__item-faq--hide");
            faq.classList.remove('faq__item-faq--hide');
            faq.classList.add('faq__item-faq--visible');
            faqCount.classList.remove('faq__count-show');
            faqError.classList.remove('active');
        }
    });
    faqList.forEach(faqitem => {
        var faqChildElementCount = faqitem.childElementCount - 1;
        const faqitemHide = faqitem.querySelectorAll('.faq__item-faq--hide').length;
        if (faqChildElementCount === faqitemHide) {
            faqitem.classList.add('faq__item-hide');
        } else {
            faqitem.classList.remove('faq__item-hide');
        }
    });

    if (searchFaq.value.length >= 3) {
        faqItemBrowseWrap.classList.add('faq__item-hide');
        if (mainCount >= 1) {
            faqError.classList.remove('active');
            faqCount.classList.add('faq__count-show');
            
            faqCount.innerHTML = `${mainCount} results for "${searchFaq.value.toLowerCase()}"`;
        } else {
            faqError.classList.add('active');
            faqCount.classList.remove('faq__count-show');
        }
    } else {
        faqItemBrowseWrap.classList.remove('faq__item-hide');
    }
};
const handleSearchInput = event => {
    if (event.target.value != '') {
        faqInputClose.classList.add('active');
    } else {
        faqInputClose.classList.remove('active');
    }
};

faqSearch?.addEventListener('submit', handleSearchFqa);
searchFaq?.addEventListener('input', handleSearchInput);
faqInputClose?.addEventListener('click', function () {
    faqSearch?.reset();
});
