
import React, { Fragment } from 'react';
import { node, shape, string } from 'prop-types';
import { BasicText, asField } from 'informed';
import { FieldIcons, Message } from '../Field';
import classes from './textInput.css';
const TextInput = props => {
    const { after, before, fieldState, message, ...rest } = props;
    return (
        <Fragment>
            <FieldIcons after={after} before={before}>
                <BasicText {...rest} fieldState={fieldState} className={classes.input} aria-label={rest.ariaLabel} />
            </FieldIcons>
            <Message fieldState={fieldState}>{message}</Message>
        </Fragment>
    );
};
TextInput.propTypes = {
    after: node,
    before: node,
    classes: shape({
        input: string
    }),
    fieldState: shape({
        value: string
    }),
    message: node
};
export default asField(TextInput);