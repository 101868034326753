import { useEffect, useState } from 'react';
import { useCartState } from '../contexts/cart';
import { DELIVERY_CHARGE_FLAG } from '../constants/cartConstants';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { FREE_DELIVERY, FREE_PICKUP } from '../components/global/constants';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import { useCheckUser } from './useCheckUser';
import { useFilterState } from '../components/cap';

export const useCheckDeliveryCharge = () => {
    const [deliveryChargeFlag, setDeliveryChargeFlag] = useState(DELIVERY_CHARGE_FLAG.SHOW_ESTIMATES);
    const [{ userProfile }] = useUserContext();
    const [{ cart, userAccount }] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const userType = useCheckUser();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { deliveryPickUpCharges } =
        orderEstimates?.totals || {};
    const { accounts } = userProfile || [];

    useEffect(() => {
        if (userType == USER_TYPE.CREDIT) {
            const { freeDelivery, freePickUp } =
                accounts?.find(account => account?.id == userAccount?.accountNumber) || {};
            const isCreditNewAddress = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS)
                ? JSON.parse(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ISCREDITNEWADDRESS))
                : false;
            if (freeDelivery == FREE_DELIVERY && freePickUp == FREE_PICKUP) {
                setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.FREE);
            } else if (freeDelivery == FREE_DELIVERY || freePickUp == FREE_PICKUP) {
                setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.TBD);
            } else if (projectDetails?.CJDLVY == 'N') {
                setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.TBD);
            } else {
                if (isCreditNewAddress) {
                    if (viewCart?.jobSiteZip) {
                        checkEstimateResponse();
                    } else {
                        setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.ZERO_DELIVERY_WITHOUT_ZIP);
                    }
                } else {
                    if (projectDetails?.selectedProjectZip) {
                        checkEstimateResponse();
                    } else {
                        setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.ZERO_DELIVERY_WITHOUT_ZIP);
                    }
                }
            }
        }
        else {
            checkEstimateResponse();
        }
    }, [userAccount?.accountNumber, projectDetails?.CJDLVY, deliveryPickUpCharges]);

    const checkEstimateResponse = () => {
        if(deliveryPickUpCharges > 0) {
            setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.SHOW_ESTIMATES);
        }
        else {
            setDeliveryChargeFlag(DELIVERY_CHARGE_FLAG.TBD);
        }
    }

    return deliveryChargeFlag;
};
