import { gql } from '@apollo/client';

const CREATE_RESERVATION_MUTATION = gql`
    mutation createReservationMutation(
        $cart_id: String!
        $storeId: Int!
        $oktaId: String
        $companyId: String!
        $customerType: String!
        $customer: Int
        $driverLicenseState: String
        $driverLicenseNumber: String
        $jobNumber: String
        $isDelivery: Boolean!
        $deliveryInstructions: String
        $startDateTime: String!
        $endDateTime: String!
        $earliestDateTime: String!
        $optOutRPP: String!
        $optOutFuelCharge: String!
        $deliveryCharge: Float
        $orderByEmail: String!
        $areaCode: String!
        $phoneNumber: String!
        $sessionID: String
        $tokenID: String
        $tokenPvID: String
        $tokenZipCode: String,
        $tokenCardType: String,
        $tokenCardHolderName: String,
        $tokenLast4Digits: String,
        $tokenExpirationDate: String
        $allocateAsset: Boolean!
        $channel: String!
        $address1: String
        $city: String
        $state: String
        $zip: String
        $distance: Float
        $pickUpCharge: Float
        $orderedBy: String
        $sourceSystem: String!
        $salesRepresentativeName: String
        $glAccount: String
        $businessArea: String
        $companyArea: String
        $costCenter: String
        $profitCenter: String
        $wbsElement: String
        $perner: String
        $distributionChannel: String
        $companyCode: String
        $orderedFor: String
        $approvedBy: String
        $eventName: String
        $purchaseOrderNumber: String
        $customBillingFields: [CustomBillingFields]
        $salesRepresentativeNumber: String
        $products: [CreateReservationProducts]!
        $salesItems: [CreateReservationSalesItems]
        $purchaseOrderItems: [CreateReservationPurchaseOrderItems]
        $sunbeltMarketingId:String
        $sunbeltSessionId:String
        $sunbeltAnonymousId:String
        $ipAddress:String
        $repCustomerId:String
        $reservationConfirmationScreen:String!
        $payMethod:String
        $reservationDateTime: String
        $sunbeltLocation: String
        $cartCreatedAt: String
        $cartUpdatedAt: String
        $NoDeliveryFee: Boolean
    ) {
        createReservation(
            cart_id: $cart_id
            storeId: $storeId
            companyId: $companyId
            oktaId: $oktaId
            customerType: $customerType
            customer: $customer
            driverLicenseState: $driverLicenseState
            driverLicenseNumber: $driverLicenseNumber
            jobNumber: $jobNumber
            isDelivery: $isDelivery
            deliveryInstructions: $deliveryInstructions
            startDateTime: $startDateTime
            endDateTime: $endDateTime
            earliestDateTime: $earliestDateTime
            optOutRPP: $optOutRPP
            optOutFuelCharge: $optOutFuelCharge
            deliveryCharge: $deliveryCharge
            orderByEmail: $orderByEmail
            areaCode: $areaCode
            phoneNumber: $phoneNumber
            sessionID: $sessionID
            tokenID: $tokenID
            tokenPvID: $tokenPvID
            tokenZipCode: $tokenZipCode,
            tokenCardType: $tokenCardType,
            tokenCardHolderName: $tokenCardHolderName,
            tokenLast4Digits: $tokenLast4Digits,
            tokenExpirationDate: $tokenExpirationDate
            allocateAsset: $allocateAsset
            channel: $channel
            address1: $address1
            city: $city
            state: $state
            zip: $zip
            distance: $distance
            pickUpCharge: $pickUpCharge
            orderedBy: $orderedBy
            sourceSystem: $sourceSystem
            salesRepresentativeName: $salesRepresentativeName
            glAccount: $glAccount
            businessArea: $businessArea
            companyArea: $companyArea
            costCenter: $costCenter
            profitCenter: $profitCenter
            wbsElement: $wbsElement
            perner: $perner
            distributionChannel: $distributionChannel
            companyCode: $companyCode
            orderedFor: $orderedFor
            approvedBy: $approvedBy
            eventName: $eventName
            purchaseOrderNumber: $purchaseOrderNumber
            customBillingFields: $customBillingFields
            salesRepresentativeNumber: $salesRepresentativeNumber
            products: $products
            salesItems: $salesItems
            purchaseOrderItems: $purchaseOrderItems
            sunbeltMarketingId:$sunbeltMarketingId
            sunbeltSessionId:$sunbeltSessionId
            sunbeltAnonymousId:$sunbeltAnonymousId
            ipAddress:$ipAddress
            repCustomerId:$repCustomerId
            reservationConfirmationScreen:$reservationConfirmationScreen
            payMethod:$payMethod
            reservationDateTime:$reservationDateTime
            sunbeltLocation:$sunbeltLocation
            cartCreatedAt:$cartCreatedAt
            cartUpdatedAt:$cartUpdatedAt
            NoDeliveryFee:$NoDeliveryFee
        ) {
            status
            message
            data {
                magento_order_id
                oms_order_id
                oms_status
            }
        }
    }
`;

export default CREATE_RESERVATION_MUTATION;
